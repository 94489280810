import "./../stylesheets/withdraw.css";
import paypal from "./../../images/paypal.png";
import Warning from "./../../images/warning.svg";
import paystackLogo from "./../../images/paystack-2.svg";
import downBtn from "./../../images/down-button-dark.png";

import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import * as Sentry from "@sentry/react";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function WithdrawPaystack() {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const method = useParams();

  const [name, setName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [addBankError, setAddBankError] = useState("");

  const [bankPopupState, setBankPopupState] = useState(false);
  const [banks, setBanks] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    const handleLoad = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }
    };
    const getBanks = async () => {
      try {
        const response = await api.get(`transfer-methods/banks`);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setBanks(data.banks);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    handleLoad();
    getBanks();
  }, [navigate, isAuthenticated]);

  const handleNameChange = (event) => {
    setName(formatName(event.target.value));

    if (event.target.value.length <= 3) {
      document.querySelector(".name-error").classList.remove("hidden");
    } else {
      document.querySelector(".name-error").classList.add("hidden");
    }
  };

  function formatName(value) {
    value = value.replace(/^\s+/, "");
    value = value.replace(/[^a-zA-Z\s]/g, "");
    value = value.replace(/\b\w/g, (match) => match.toUpperCase());

    return value;
  }

  const handleAccountNumberChange = (event) => {
    setAccountNumber(formatAccountNumber(event.target.value));

    if (event.target.value.length <= 6) {
      document.querySelector(".account-error").classList.remove("hidden");
    } else {
      document.querySelector(".account-error").classList.add("hidden");
    }
  };

  function formatAccountNumber(value) {
    value = value.replace(/[a-zA-Z]/g, (match) => match.toUpperCase());
    value = value.replace(/[^a-zA-Z0-9]/g, "");

    return value;
  }
  const checkName = (name) => {
    if (name.length <= 3) {
      return false;
    } else {
      return true;
    }
  };

  const checkAccountNumber = (accountNumber) => {
    if (accountNumber.length <= 6) {
      return false;
    }
    return true;
  };
  const checkBank = (bank) => {
    if (bank.length < 1) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (banks) {
      if (
        checkName(name) &&
        checkAccountNumber(accountNumber) &&
        checkBank(selectedBank)
      ) {
        document
          .querySelector(".withdraw-button-click")
          .classList.remove("button-disabled");
      } else {
        document
          .querySelector(".withdraw-button-click")
          .classList.add("button-disabled");
      }
    }
  }, [name, accountNumber, selectedBank, banks]);
  const handleSubmit = async () => {
    document.querySelector(".name-error").classList.add("hidden");
    document.querySelector(".bank-name-error").classList.add("hidden");
    document.querySelector(".account-error").classList.add("hidden");

    if (selectedBank == null) {
      document.querySelector(".bank-name-error").classList.remove("hidden");
    }

    if (name.length === 0) {
      document.querySelector(".name-error").classList.remove("hidden");
    }

    if (accountNumber.length === 0) {
      document.querySelector(".account-error").classList.remove("hidden");
    }

    if (selectedBank == null || name.length === 0 || accountNumber == null) {
      return;
    }

    document.querySelector(".add-bank-error").classList.add("hidden");

    if (!isAuthenticated) {
      navigate("/login");
    }

    const body = {
      payment_method: "paystack",
      acc_name: name,
      bank_code: selectedBank.code,
      bank_name: selectedBank.name,
      acc_num: accountNumber,
    };

    try {
      const response = await api.post(`transfer-methods/add`, body);

      handleResponseStatus(response, navigate);
      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        navigate("/accounts");
      } else {
        setAddBankError(data.error.replace("_", " "));
        document.querySelector(".add-bank-error").classList.remove("hidden");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (banks) {
      setSelectedBank(banks.find((bank) => bank.code === "214"));
    }
  }, [banks]);

  return (
    <section className="withdraw-section">
      <div className="withdraw-top-heading">
        <h3>
          Add Paystack Account
          <Popup
            className="login-error-popup"
            trigger={
              <img src={Warning} alt="" className="add-bank-error hidden"></img>
            }
            on="hover"
            position="top center"
            arrow={false}
          >
            {addBankError}
          </Popup>
        </h3>
      </div>
      <div className="withdraw-main">
        {method.method === "paypal" && (
          <div className="withdraw-main-heading">
            <h3>Paypal</h3>
            <img alt="" src={paypal}></img>
          </div>
        )}
        {method.method === "bank" && (
          <div className="withdraw-main-heading">
            <h3>Bank Transfer</h3>
            <img alt="" src={selectedBank}></img>
          </div>
        )}

        {method.method === "paystack" && (
          <div className="withdraw-main-heading">
            <h3>Paystack</h3>
            <img alt="" src={paystackLogo}></img>
          </div>
        )}
        {banks && (
          <>
            <div className="withdraw-form">
              <div className="withdrawal-name">
                <p>Account Title</p>
                <input
                  placeholder=""
                  className="withdrawal-name-input"
                  onChange={handleNameChange}
                  value={name}
                />
                <Popup
                  className="login-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="name-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Invalid Name Entered
                </Popup>
              </div>
              {banks && (
                <div className="withdrawal-bank">
                  <p>
                    Bank Name
                    <Popup
                      className="login-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="bank-name-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Select a bank!
                    </Popup>
                  </p>
                  <Popup
                    className="bank-dropdown"
                    trigger={
                      <div className="withdrawal-name-input">
                        {selectedBank == null ? "Select" : selectedBank.name}
                        <img
                          src={downBtn}
                          style={
                            bankPopupState
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                          alt=""
                          className="upload-beat-genre-dropdown-button"
                        />
                      </div>
                    }
                    onOpen={() => {
                      setBankPopupState(true);
                    }}
                    onClose={() => {
                      setBankPopupState(false);
                    }}
                    open={bankPopupState}
                    position="bottom center"
                    arrow={false}
                  >
                    {banks.map((bank, index) => {
                      return (
                        <div className="service-item" key={index}>
                          <label
                            className="checkbox-container"
                            onClick={() => {
                              setSelectedBank(bank);
                              document
                                .querySelector(".bank-name-error")
                                .classList.add("hidden");
                              setBankPopupState(false);
                            }}
                          >
                            {bank.name}
                          </label>
                        </div>
                      );
                    })}
                  </Popup>
                </div>
              )}
              <div className="withdrawal-account">
                <p>Account Number</p>
                <input
                  placeholder=""
                  className="withdrawal-account-input"
                  onChange={handleAccountNumberChange}
                  value={accountNumber}
                  maxLength={10}
                />
                <Popup
                  className="login-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="account-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Invalid Account Number Entered
                </Popup>
              </div>

              <div className="withdraw-button">
                <button
                  onClick={handleSubmit}
                  className="withdraw-button-click"
                >
                  Add Account
                </button>
              </div>
            </div>
          </>
        )}
        {!banks && (
          <TailSpin
            height="40"
            width="40"
            color="#FE2B0D"
            ariaLabel="tail-spin-loading"
            visible={true}
            wrapperClass="loading"
            className="loading"
          />
        )}
      </div>
    </section>
  );
}
export default WithdrawPaystack;
