import "./../stylesheets/contract.css";
import Warning from "./../../images/warning.svg";
import downBtn from "./../../images/down-button-dark.png";
import cross from "./../../images/cross-black.svg";
import more from "./../../images/more-dark.svg";
import art from "../../images/user.png";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Popup from "reactjs-popup";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function Contract({ setShowUnauthenticatedPopup }) {
  const location = useLocation();
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates } = useCurrencyContext();
  const [producer, setProducer] = useState(null);
  const [contractName, setContractName] = useState("");
  const [contractDescription, setContractDescription] = useState("");
  const [contractBudget, setContractBudget] = useState(
    selectedCurrency === "GBP" ? "£" : "N"
  );
  const [popupGenre, setPopupGenre] = useState([]);
  const [terms, setTerms] = useState(false);

  const [contractErrorMessage, setContractErrorMessage] = useState("");

  const [popupStates, setPopupStates] = useState({
    genre: false,
    duration: false,
  });

  const [saveChanges, setSaveChanges] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    if (!location.state) {
      navigate("/beats");
    }

    const getProducerData = async () => {
      try {
        const response = await api.get(
          `producers/${location.state.producerId}`
        );
        handleResponseStatus(response, navigate);
        const data = response.data;
        setProducer(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getProducerData();
  }, [navigate, isAuthenticated, location.state, setShowUnauthenticatedPopup]);

  useEffect(() => {
    const getGenres = async () => {
      try {
        const response = await api.get(`genres`);

        handleResponseStatus(response, navigate);
        const data = response.data;

        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setPopupGenre(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getGenres();
  }, [navigate]);

  const handleContractNameChange = (event) => {
    setContractName(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      event.target.value.length < 8 ||
      event.target.value.length > 100 ||
      !regex.test(event.target.value)
    )
      document.querySelector(".name-error").classList.remove("hidden");
    else document.querySelector(".name-error").classList.add("hidden");
  };

  const handleContractDescriptionChange = (event) => {
    setContractDescription(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      event.target.value.length < 50 ||
      event.target.value.length > 500 ||
      !regex.test(event.target.value)
    )
      document.querySelector(".description-error").classList.remove("hidden");
    else document.querySelector(".description-error").classList.add("hidden");
  };

  const handleContractBudgetChange = (event) => {
    setContractBudget(formatCurrency(event.target.value.slice(0, 6)));

    if (event.target.value.length === 0)
      document.querySelector(".budget-error").classList.remove("hidden");
    else document.querySelector(".budget-error").classList.add("hidden");
  };

  function formatCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `${selectedCurrency === "GBP" ? "£" : "N"}${value}`;
    return value;
  }

  function checkGenreChecked(obj) {
    for (let key in obj) {
      const skill = obj[key];
      if (
        skill &&
        typeof skill === "object" &&
        skill.hasOwnProperty("checked") &&
        skill.checked
      )
        return true;
    }
    return false;
  }

  const handleGenreCheckboxChange = (id) => {
    setPopupGenre((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: !genre.checked } : genre
      )
    );

    setPopupGenre((updatedGenre) => {
      if (checkGenreChecked(updatedGenre)) {
        document.querySelector(".genre-error").classList.add("hidden");
      } else {
        document.querySelector(".genre-error").classList.remove("hidden");
      }
      return updatedGenre;
    });
  };

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  function checkValues() {
    let valid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      contractName.length < 3 ||
      contractName.length > 100 ||
      !regex.test(contractName)
    ) {
      document.querySelector(".name-error").classList.remove("hidden");
      valid = false;
    }

    if (
      contractDescription.length < 50 ||
      contractDescription.length > 500 ||
      !regex.test(contractDescription)
    ) {
      document.querySelector(".description-error").classList.remove("hidden");
      valid = false;
    }

    if (contractBudget <= 25 || contractBudget >= 10000) {
      document.querySelector(".budget-error").classList.remove("hidden");
      valid = false;
    }

    if (!checkGenreChecked(popupGenre)) {
      document.querySelector(".genre-error").classList.remove("hidden");
      valid = false;
    }

    if (!terms) {
      document.querySelector(".term-error").classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  const handleSubmitContract = async () => {
    if (!checkValues()) {
      return;
    }
    setSaveChanges(true);
    const genres = [];
    popupGenre.forEach((genre) => {
      if (genre.checked === true) {
        genres.push(genre.id);
      }
    });
    let amount = 0;
    if (selectedCurrency === "GBP") {
      amount = contractBudget.slice(1);
    } else {
      amount = (contractBudget.slice(1) / rates).toFixed(2);
    }

    const body = {
      name: contractName,
      description: contractDescription,
      budget: parseFloat(amount),
      accepted_terms_and_conditions: terms,
      requested_to: producer.id,
      genres: [genres],
    };

    try {
      const response = await api.post(`commissions/request`, body);

      handleResponseStatus(response, navigate);
      const data = response.data;

      setContractBudget(selectedCurrency === "GBP" ? "£" : "N");

      if (response.status === 201) {
        navigate(`/messages/${data.data.chat_id}`);
      } else {
        setContractErrorMessage(response.error);
        document.querySelector(".bid-error").classList.remove("hidden");
        return;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {producer && (
        <section className="contract">
          <div className="contract-heading">
            <h2>
              Hire {producer.name}{" "}
              <Popup
                className="upload-error-popup"
                trigger={
                  <img
                    src={Warning}
                    alt=""
                    className="contract-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                {contractErrorMessage}
              </Popup>
            </h2>
            <h3>
              You can message the freelancer to agree on the terms e.g
              budget,time etc
            </h3>
          </div>

          <div className="contract-container">
            <div className="contract-form">
              <div className="contract-form-input-div">
                <label>
                  Project Name
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="name-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a name!
                  </Popup>
                </label>
                <input
                  type="text"
                  placeholder="e.g I need a producer for my song"
                  className="contract-name"
                  onChange={handleContractNameChange}
                ></input>
              </div>
              <div className="contract-form-input-div">
                <label>
                  Project Description
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="description-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a description of 50-500 characters!
                  </Popup>
                </label>
                <textarea
                  placeholder="Describe your project"
                  className="contract-description"
                  onChange={handleContractDescriptionChange}
                />
              </div>
              <div className="contract-form-input-div">
                <label>
                  What is your budget
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="budget-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a budget!
                  </Popup>
                </label>
                <input
                  type="text"
                  placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}20`}
                  className="contract-budget"
                  value={contractBudget}
                  onChange={handleContractBudgetChange}
                />
              </div>
              <div className="contract-form-input-div">
                <label>
                  Genre
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="genre-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please select at least 1 genre!
                  </Popup>
                </label>
                <Popup
                  className="genre-contract-dropdown"
                  trigger={
                    <div>
                      {!checkGenreChecked(popupGenre) && <>Select</>}
                      {checkGenreChecked(popupGenre) && (
                        <>
                          {popupGenre
                            .filter((genre) => genre.checked)
                            .slice(0, 7)
                            .map((genre) => (
                              <button
                                className="genre-remove-button"
                                key={genre.name}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  handleGenreCheckboxChange(
                                    popupGenre.findIndex(
                                      (g) => g.id === genre.id
                                    )
                                  );
                                }}
                              >
                                <img src={cross} alt="remove" />
                                {genre.name}
                              </button>
                            ))}
                          {popupGenre.filter((genre) => genre.checked).length >
                            7 && (
                            <div className="genre-remove-button">
                              <img src={more} alt="" />
                            </div>
                          )}
                        </>
                      )}
                      <img
                        src={downBtn}
                        style={
                          popupStates.genre
                            ? { transform: "rotate(180deg)" }
                            : { transform: "none" }
                        }
                        alt=""
                        className="contract-beat-genre-dropdown-button"
                      />
                    </div>
                  }
                  onOpen={() => handlePopupOpen("genre")}
                  onClose={() => handlePopupClose("genre")}
                  position="bottom center"
                  arrow={false}
                >
                  {popupGenre.map((genre, index) => {
                    return (
                      <div className="service-item" key={index}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={genre.checked || false}
                            onChange={() => handleGenreCheckboxChange(index)}
                          />
                          {genre.name}
                        </label>
                      </div>
                    );
                  })}
                </Popup>
              </div>

              <div className="service-item">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={terms}
                    onChange={() => {
                      if (!terms) {
                        setTerms(true);
                        document
                          .querySelector(".term-error")
                          .classList.add("hidden");
                      } else {
                        setTerms(false);
                        document
                          .querySelector(".term-error")
                          .classList.remove("hidden");
                      }
                    }}
                    className="contract-terms"
                  />
                  I agree to the&nbsp;
                  <Link to="/terms">Terms & Conditions</Link>
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="term-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please agree to the Terms & Conditions!
                  </Popup>
                </label>
              </div>
            </div>
            <div className="contract-producer-details">
              <div className="contract-producer-details__img">
                <img
                  src={
                    producer.profile_image_url
                      ? producer.profile_image_url
                      : art
                  }
                  alt=""
                />
              </div>
              <div className="contract-producer-details__details">
                <h2>{producer.name}</h2>
                <p>
                  {producer.services.map((service) => service.name).join(" / ")}
                </p>
              </div>
            </div>
          </div>

          <div className="contract-hire-button" onClick={handleSubmitContract}>
            {saveChanges && (
              <Bars
                height="40"
                width="40"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="loading-bars"
                visible={saveChanges}
              />
            )}
            {!saveChanges && "Hire Freelancer"}
          </div>
        </section>
      )}
    </>
  );
}

export default Contract;
