import "./stylesheets/terms.css";

function AboutUs() {
  return (
    <section className="terms">
      <h2>About Us</h2>

      <div className="term-container">
        <p>
          Welcome to <span className="term-bold">THA THROWDOWN</span>, your
          premier online marketplace for musical beats, with a primary focus on
          the vibrant and captivating genre of Afrobeat. We are committed to
          supporting music producers, beat makers, sound engineers, and other
          music professionals by providing a dynamic freelance platform where
          they can monetize their talent and expertise for wealth generation.
        </p>
      </div>

      <h4>Our Mission:</h4>
      <div className="term-container">
        <p>
          At <span className="term-bold">THA THROWDOWN</span>, our mission is to
          foster creativity, collaboration, and entrepreneurship within the
          music industry. We believe that music is a universal language that
          connects people from all walks of life, and we are passionate about
          promoting diverse musical expressions, with Afrobeat taking center
          stage. Our platform strives to empower artists to showcase their
          unique sound, cultivate their brand, and turn their passion for music
          into a sustainable source of income.
        </p>
      </div>

      <h4>Our Niche - Afrobeat:</h4>
      <div className="term-container">
        <p>
          Afrobeat is more than just a genre; it is a cultural movement that has
          transcended borders and captured the hearts of music enthusiasts
          worldwide. We are proud to be at the forefront of promoting and
          celebrating this rich musical heritage. On our platform, you'll find a
          vast collection of authentic and contemporary Afrobeat beats, each
          reflecting the vibrant rhythms, soulful melodies, and infectious
          energy that define this genre.
        </p>
      </div>

      <h4>Expanding Horizons - Beyond Afrobeat:</h4>
      <div className="term-container">
        <p>
          While Afrobeat is our primary niche, we also recognize the incredible
          diversity of musical talent and creativity across genres. As a result,
          we offer a wide selection of beats spanning various styles, from
          hip-hop and R&B to pop, electronic, and more. Our goal is to create a
          melting pot of musical inspiration, where artists and producers can
          find the perfect beat to complement their artistic vision.
        </p>
      </div>

      <h4>Empowering Music Professionals:</h4>
      <div className="term-container">
        <p>
          At <span className="term-bold">THA THROWDOWN</span>, we prioritize the
          success and well-being of our community of music professionals.
          Whether you are an established producer or a budding talent, we are
          here to support your journey. By creating a user-friendly and secure
          platform, we enable artists to upload, showcase, and sell their beats
          effortlessly. Our transparent and efficient payment system ensures
          that every artist receives fair compensation for their hard work.
        </p>
      </div>

      <h4>Building a Collaborative Community:</h4>
      <div className="term-container">
        <p>
          We firmly believe that music thrives in a collaborative and supportive
          environment. Therefore,
          <span className="term-bold"> THA THROWDOWN </span>encourages artists,
          producers, and sound engineers to interact, connect, and collaborate
          on projects. Through our platform, you can build meaningful
          relationships, share knowledge, and grow both personally and
          professionally.
        </p>
      </div>

      <h4>Passion for Excellence:</h4>
      <div className="term-container">
        <p>
          Excellence is at the core of everything we do at
          <span className="term-bold"> THA THROWDOWN</span>. Our team is
          composed of dedicated individuals who share a genuine passion for
          music and technology. We continuously strive to enhance our platform's
          features, security, and overall user experience, ensuring that you
          have the best tools at your disposal to excel in your musical journey.
        </p>
      </div>

      <h4>Join the THA THROWDOWN Community:</h4>
      <div className="term-container">
        <p>
          Whether you're an aspiring music producer or an artist in search of
          the perfect beat,<span className="term-bold"> THA THROWDOWN </span>
          invites you to be a part of our vibrant community. Together, let's
          embrace the power of music and creativity to forge a path towards
          prosperity and artistic fulfillment.
        </p>
      </div>

      <div className="terms-placeholder"></div>
      <div className="term-container">
        <p>
          Thank you for choosing
          <span className="term-bold"> THA THROWDOWN</span>. Let's make music
          that moves the world!
        </p>
      </div>
      <div className="term-container">
        <p>
          For any inquiries or assistance, please don't hesitate to contact us
          at{" "}
          <a href="mailto:info@thathrowdown.com" className="terms-email">
            info@thathrowdown.com
          </a>
          .
        </p>
      </div>
    </section>
  );
}

export default AboutUs;
