import image from "./../../images/404.png";
import "./../stylesheets/notFound.css";
function NotFound() {
  return (
    <section className="not-found">
      <div className="not-found-image">
        <img alt="404 Error" src={image}></img>
      </div>
      <div className="not-found-main">
        <h2>404</h2>
        <h3>Not Found</h3>
        <p>The page you are trying to find does not exist</p>
      </div>
    </section>
  );
}
export default NotFound;
