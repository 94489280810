import "./../stylesheets/resetPassword.css";
import Warning from "./../../images/warning.svg";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { useNavigation } from "../../context/navigationContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
function ResetPassword() {
  const { navigate } = useNavigation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { token } = useParams();
  const [email, setEmail] = useState("");

  function getPasswordStrength(password) {
    const containsUpperCase = /[A-Z]/;
    const containsLowerCase = /[a-z]/;
    const containsNumber = /[0-9]/;
    const containsSpecialChar = /[@$!%*?&]/;
    let passwordStrength = 0;

    if (containsUpperCase.test(password)) passwordStrength++;
    if (containsLowerCase.test(password)) passwordStrength++;
    if (containsNumber.test(password)) passwordStrength++;
    if (containsSpecialChar.test(password)) passwordStrength++;

    return passwordStrength;
  }

  function handlePasswordInput(event) {
    setPassword(event.target.value);
    if (
      getPasswordStrength(event.target.value) < 3 ||
      event.target.value.length < 8
    ) {
      document.querySelector(".password-error").classList.remove("hidden");
    } else {
      document.querySelector(".password-error").classList.add("hidden");
    }

    if (event.target.value === confirmPassword)
      document.querySelector(".confirm-password-error").classList.add("hidden");
    else
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
  }

  function handleConfirmPasswordInput(event) {
    setConfirmPassword(event.target.value);

    if (password === event.target.value)
      document.querySelector(".confirm-password-error").classList.add("hidden");
    else
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
  }

  function checkValues() {
    let valid = true;

    if (getPasswordStrength(password) < 3 || password.length < 8) {
      document.querySelector(".password-error").classList.remove("hidden");
      valid = false;
    }
    if (password !== confirmPassword) {
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  const handleResetButtonClick = async function (event) {
    event.preventDefault();

    if (!checkValues()) return;

    try {
      const response = await api.post(`reset-password`, {
        token: token,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      });
      handleResponseStatus(response, navigate);
      navigate("/login");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const receivedEmail = searchParams.get("email");
    setEmail(receivedEmail);
  }, []);

  return (
    <section className="resetPassword">
      <div className="reset-container">
        <h4>Set New Password</h4>
        <div className="reset__group">
          <label for="password">Password</label>
          <div className="reset-input-container">
            <input
              autocomplete="off"
              className="login__input"
              name="password"
              id="password"
              type="password"
              onChange={(event) => handlePasswordInput(event)}
            />
            <Popup
              className="login-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="password-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Password too short/weak!
            </Popup>
          </div>
        </div>
        <div className="reset__group">
          <label for="confirmPassword">Confirm Password</label>
          <div className="reset-input-container">
            <input
              autocomplete="off"
              className="login__input"
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              onChange={(event) => handleConfirmPasswordInput(event)}
            />
            <Popup
              className="login-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="confirm-password-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Passwords do not match!
            </Popup>
          </div>
        </div>
        <div className="reset__group">
          <button className="btn__primary " onClick={handleResetButtonClick}>
            Change Password
          </button>
        </div>
        <div className="reset__group">
          <a href="/login">Return to Log In</a>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
