import "./../stylesheets/userPage.css";
import "./../../Fonts/fonts.css";

import React, { useEffect, useState } from "react";

import BeatsRows from "./../common/beatsRows";
import * as Sentry from "@sentry/react";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import { getPurchased } from "../../actions/authActions";
import { useDispatch } from "react-redux";

function PurchasedBeats() {
  const [userBeats, setUserBeats] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates } = useCurrencyContext();
  const dispatch = useDispatch();
  useEffect(() => {
    const getNextPage = async () => {
      if (nextPage) {
        if (nextPage) {
          const response = await api.get(nextPage);
          handleResponseStatus(response, navigate);
          const data = response.data;
          setUserBeats([...userBeats, ...data.data]);
          setNextPage(data.links.next?.split("api/")[1]);
        }
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [userBeats, nextPage, navigate]);

  const handleDownload = async (id) => {
    if (isAuthenticated) {
      try {
        const response = await api.get(`beats/${id}/download`);

        handleResponseStatus(response, navigate);
        const data = response.data;
        window.location.href = data;
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const handleLoad = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }
      try {
        const response = await api.get(`purchase/beats`);
        // await getPurchases();
        handleResponseStatus(response, navigate);
        const getPurchases = async () => {
          try {
            const response = await dispatch(getPurchased());
            return response;
          } catch (error) {
            console.error("Callback login failed:", error);
            throw error;
          }
        };

        await getPurchases();
        const data = response.data;
        setUserBeats(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    handleLoad();
  }, [navigate, isAuthenticated, dispatch]);
  return (
    <>
      <section className="userBeats">
        <div className="heading">
          <h3 className="heading-beat-count">
            {userBeats.length === 1
              ? `${userBeats.length} Beat Purchased`
              : `${userBeats.length} Beats Purchased`}
          </h3>
        </div>
      </section>
      {userBeats && (
        <BeatsRows
          beats={userBeats}
          showDownloadBeat={true}
          handleDownload={handleDownload}
          hideMore={false}
          hideCertain={true}
          hideBuy={true}
          hideDownload={true}
          rates={rates}
          selectedCurrency={selectedCurrency}
        />
      )}
    </>
  );
}

export default PurchasedBeats;
