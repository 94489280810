import BuyPopup from "./../buyPopup";
import "./../stylesheets/beat.css";
import PlayButton from "./../../images/musicPlay.svg";
import PauseButton from "./../../images/pause.svg";
import CartRed from "./../../images/cartRed.svg";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { usePlayerContext } from "../../context/playerContext";
import { pause, play, playNow } from "../../actions/playerActions";
import { useCurrencyContext } from "../../context/currencyContext";

function AlbumDescription({ setShowUnauthenticatedPopup, album }) {
  const [tracks, setTracks] = useState(album.tracks);

  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);
  const { formatCurrency } = useCurrencyContext();

  const [trackDurations, setTrackDurations] = useState(null);
  //   const [trackElapsedTime, setTrackElapsedTime] = useState([]);
  //   const [currentSelectedTrack, setCurrentSelectedTrack] = useState(null);
  //   const [isCurrentTrackPlaying, setIsCurrentPlaying] = useState(null);

  const { playerState, playerDispatch } = usePlayerContext();

  const { currentItemIndex, currentTime, queue, isPlaying } = playerState;

  const handlePlaySong = (index) => {
    if (
      currentItemIndex !== null &&
      queue[currentItemIndex].slug === tracks[index].slug
    ) {
      if (isPlaying) {
        playerDispatch(pause());
      } else {
        playerDispatch(play());
      }
      return;
    }

    playerDispatch(playNow(tracks[index]));
  };

  const getIndexElapsedTime = (index) => {
    if (!tracks || currentItemIndex === null) {
      return null;
    }

    if (queue[currentItemIndex].slug !== tracks[index].slug) {
      return null;
    }

    return currentTime;
  };

  //   const handlePause = () => {
  //     const audio = document.querySelector(".audio-player");
  //     audio.pause();
  //     setIsPlayerPlaying(false);
  //   };

  //   const handleButton = async (index) => {
  //     let timeUpdateListener = null;
  //     if (index === currentSelectedTrack) {
  //       if (!isCurrentTrackPlaying) {
  //         setTrackElapsedTime(0);
  //         setIsCurrentPlaying(true);
  //         // await handlePlay(beat.tracks[index].id, index);
  //         waitForAudioPlayer(function (audio) {
  //           if (audio !== null) {
  //             timeUpdateListener = function () {
  //               const currentTime = audio.currentTime;
  //               if (trackElapsedTime !== null) {
  //                 setTrackElapsedTime(currentTime);
  //               }
  //             };
  //             audio.addEventListener("timeupdate", timeUpdateListener);
  //           }
  //         });
  //       } else {
  //         setIsCurrentPlaying(false);
  //         if (timeUpdateListener !== null) {
  //           waitForAudioPlayer(function (audio) {
  //             audio.removeEventListener("timeupdate", timeUpdateListener);
  //           });
  //         }
  //         handlePause();
  //       }
  //     } else {
  //       setTrackElapsedTime(null);
  //       if (timeUpdateListener !== null) {
  //         waitForAudioPlayer(function (audio) {
  //           audio.removeEventListener("timeupdate", timeUpdateListener);
  //         });
  //       }
  //       //   await handlePlay(beat.tracks[index].id, index);
  //       setTrackElapsedTime(0);
  //       setCurrentSelectedTrack(index);

  //       setIsCurrentPlaying(true);
  //       waitForAudioPlayer(function (audio) {
  //         if (audio !== null) {
  //           if (document.querySelector(".player")) {
  //             document.querySelector(".player").style.display = "flex";
  //           }
  //           timeUpdateListener = function () {
  //             const currentTime = audio.currentTime;
  //             setTrackElapsedTime(currentTime);
  //           };
  //           audio.addEventListener("timeupdate", timeUpdateListener);
  //         }
  //       });
  //     }

  //     function waitForAudioPlayer(callback) {
  //       const audio = document.querySelector(".audio-player");
  //       if (audio) {
  //         callback(audio);
  //       } else {
  //         setTimeout(() => {
  //           waitForAudioPlayer(callback);
  //         }, 1000);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     if (currentSelectedTrack !== null) {
  //       let beatDetailName = document.querySelector(".beat-detail-name");
  //       if (!beatDetailName) {
  //         beatDetailName = document.querySelector(".beat-mobile-detail-name");
  //       }
  //       if (!beatDetailName) {
  //         return;
  //       }
  //       if (isPlayerPlaying === isCurrentTrackPlaying) {
  //         if (currentlyPlaying?.id) {
  //           const playingId = beat.tracks[currentSelectedTrack]?.id;
  //           if (playingId !== currentlyPlaying.id) {
  //             let found;
  //             beat.tracks.forEach((item, index) => {
  //               if (
  //                 item.id.toString() === currentlyPlaying.id?.toString() &&
  //                 found === -1
  //               ) {
  //                 found = index;
  //               }
  //             });
  //             if (found !== -1) {
  //               setCurrentSelectedTrack(found);
  //             }
  //           } else {
  //             return;
  //           }
  //         } else {
  //           return;
  //         }
  //       }

  //       const dataId = beatDetailName.getAttribute("data-id");
  //       if (Number(dataId) !== beat.tracks[currentSelectedTrack]?.id) {
  //         setIsCurrentPlaying(isPlayerPlaying);

  //         let foundIndex = -1;
  //         beat.tracks.forEach((item, index) => {
  //           if (item.id.toString() === dataId?.toString() && foundIndex === -1) {
  //             foundIndex = index;
  //           }
  //         });
  //         if (foundIndex !== -1) {
  //           setCurrentSelectedTrack(foundIndex);
  //         }
  //       }
  //       if (isPlayerPlaying !== isCurrentTrackPlaying) {
  //         setIsCurrentPlaying(isPlayerPlaying);
  //       }
  //     } else {
  //       if (currentlyPlaying?.id && beat?.tracks) {
  //         let foundIndex = -1;
  //         beat.tracks.forEach((item, index) => {
  //           if (
  //             item.id.toString() === currentlyPlaying?.id?.toString() &&
  //             foundIndex === -1
  //           ) {
  //             foundIndex = index;
  //           }
  //         });
  //         if (foundIndex !== -1) {
  //           let timeUpdateListener = null;

  //           setCurrentSelectedTrack(foundIndex);
  //           setIsCurrentPlaying(true);
  //           waitForAudioPlayer(function (audio) {
  //             if (audio !== null) {
  //               if (document.querySelector(".player")) {
  //                 document.querySelector(".player").style.display = "flex";
  //               }
  //               timeUpdateListener = function () {
  //                 const currentTime = audio.currentTime;
  //                 setTrackElapsedTime(currentTime);
  //               };
  //               audio.addEventListener("timeupdate", timeUpdateListener);
  //             }
  //           });
  //         }
  //       }
  //     }
  //     function waitForAudioPlayer(callback) {
  //       const audio = document.querySelector(".audio-player");
  //       if (audio) {
  //         callback(audio);
  //       } else {
  //         setTimeout(() => {
  //           waitForAudioPlayer(callback);
  //         }, 1000);
  //       }
  //     }
  //   }, [
  //     isPlayerPlaying,
  //     currentSelectedTrack,
  //     isCurrentTrackPlaying,
  //     beat,
  //     setIsPlayerPlaying,
  //     playerId,
  //     currentlyPlaying,
  //     setTrackElapsedTime,
  //     trackElapsedTime,
  //   ]);

  useEffect(() => {
    if (!album || !album.tracks) {
      return;
    }

    const getLengths = async () => {
      setTracks(album.tracks);

      const lengths = [];

      for (let i = 0; i < album.tracks.length; i++) {
        const track = album.tracks[i];
        try {
          const len = await fetchAudioFile(track.preview_path);
          lengths.push(len);
        } catch (error) {
          const tracks = album.tracks.filter((_, ind) => ind !== i);
          setTracks(tracks);
        }
      }

      setTrackDurations(lengths);
    };

    const fetchAudioFile = (audioFileUrl) => {
      return new Promise((resolve, reject) => {
        const audio = document.createElement("audio");
        audio.src = audioFileUrl;
        audio.addEventListener("loadedmetadata", function () {
          resolve(audio.duration);
        });
        audio.addEventListener("error", function () {
          reject(new Error("Error loading audio file"));
        });
        audio.load();
      });
    };

    getLengths();
  }, [tracks, album]);

  function formatTime(timeInSeconds) {
    var minutes = Math.floor(timeInSeconds / 60);
    var seconds = Math.floor(timeInSeconds % 60);
    var formattedMinutes = String(minutes).padStart(2, "0");
    var formattedSeconds = String(seconds).padStart(2, "0");
    return formattedMinutes + ":" + formattedSeconds;
  }

  return (
    <>
      <section className="album__description">
        <audio className="temp-audio" />
        <section className="album-section-description-div">
          <h3>About Album</h3>
          <p className="album-section-description-p">{album.description}</p>
        </section>
        <section className="album-section-all-music">
          <div className="all__songs__main">
            {album && tracks && (
              <h3 className="all__songs__main--heading">{`${tracks.length} ${
                tracks.length === 1 ? "Song" : "Songs"
              }`}</h3>
            )}
            <div className="all__songs__list">
              {(!album || !trackDurations) && (
                <div>
                  <div className="spinner-container">
                    <TailSpin
                      height="40"
                      width="40"
                      color="#FE2B0D"
                      ariaLabel="tail-spin-loading"
                      visible={true}
                      wrapperClass="loading"
                      className="loading"
                    />
                  </div>
                </div>
              )}
              {album &&
                trackDurations &&
                tracks &&
                tracks.map((track, index) => {
                  return (
                    <div className="all__songs__list__item" key={index}>
                      <button
                        data-id={track.id}
                        onClick={() => {
                          handlePlaySong(index);
                        }}
                      >
                        <img
                          src={
                            getIndexElapsedTime(index) !== null && isPlaying
                              ? PauseButton
                              : PlayButton
                          }
                          alt="play"
                        />
                      </button>
                      <h3>{track.name}</h3>

                      <div className="duration">
                        {trackDurations[index] && (
                          <>
                            <p>
                              {formatTime(getIndexElapsedTime(index) / 1000)}
                            </p>
                            <div className="duration-bar-container">
                              <div className="duration-bar-back" />
                              <div
                                className="duration-bar-front"
                                style={{
                                  width:
                                    getIndexElapsedTime(index) !== null
                                      ? `${
                                          getIndexElapsedTime(index) /
                                          (trackDurations[index] * 10)
                                        }%`
                                      : "0%",
                                }}
                              />
                            </div>
                            <p>{formatTime(trackDurations[index])}</p>
                          </>
                        )}
                      </div>
                      <button
                        onClick={() => {
                          setIsBuyPopupOpen(true);
                        }}
                      >
                        <img src={CartRed} alt="c"></img>
                        {formatCurrency(track.price)}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </section>

      <BuyPopup
        beat={album}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />
    </>
  );
}

export default AlbumDescription;
