import "./../stylesheets/beat.css";

import thumbs_up from "./../../images/thumbs-up.svg";
import thumbs_up_red from "./../../images/thumbs-up-red.svg";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useAuthContext } from "../../context/authContext";
import { useNavigation } from "../../context/navigationContext";
import Warning from "./../../images/warning.svg";
import Popup from "reactjs-popup";
import api from "../../utils/api";
import handleResponseStatus from "../../utils/handleResponseStatus";
function AlbumComments({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const [comments, setComments] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [comment, setComment] = useState(null);
  function handleDescriptionChange(event) {
    setComment(event.target.value);

    if (event.target.value.length < 3)
      document.querySelector(".description-error").classList.remove("hidden");
    else document.querySelector(".description-error").classList.add("hidden");
  }
  const id = useParams();
  const handleLike = async (event) => {
    const image = event.target.closest(".item-like-image");
    const id = event.target.closest(".comments-item").dataset.id;
    const item = event.target.closest(".comments-item");
    if (event.target.closest(".comments-item").dataset.liked === "true") {
      if (isAuthenticated) {
        try {
          const response = await api.delete(`comments/${id}/unlike`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
          event.target.closest(".comments-item").dataset.liked = false;
          image.src = thumbs_up;
          item.querySelector(".item-detail-like-quantity").innerHTML =
            parseInt(
              item.querySelector(".item-detail-like-quantity").innerHTML,
              10
            ) - 1;
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    } else {
      if (isAuthenticated) {
        try {
          const response = await api.post(`comments/${id}/like`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
          event.target.closest(".comments-item").dataset.liked = true;
          item.querySelector(".item-detail-like-quantity").innerHTML =
            parseInt(
              item.querySelector(".item-detail-like-quantity").innerHTML,
              10
            ) + 1;
          image.src = thumbs_up_red;
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }
  };
  useEffect(() => {
    const getNextPage = async () => {
      if (nextPage) {
        const response = await api.get(nextPage);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setComments([...comments, ...data.data]);
        setNextPage(data.links.next?.split("api/")[1]);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [comments, nextPage, navigate]);
  const handleCommentSubmit = async () => {
    try {
      const response = await api.post(`albums/${id.id}/comments`, {
        content: comment,
      });

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

      try {
        const response = await api.get(`albums/${id.id}/comments`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;
        setComments(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
      document.querySelector(".comments-textarea").value = "";
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    const handleLoad = async () => {
      try {
        const response = await api.get(`albums/${id.id}/comments`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setComments(data.data);
        setNextPage(data.links.next?.split("api/")[1]);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [id.id, navigate, setShowUnauthenticatedPopup]);
  return (
    <section className="beat-section-comments-div">
      <div className="comments-textbox">
        {!isAuthenticated && (
          <div className="comments-login-error">
            You must be logged in to comment!
          </div>
        )}
        <label className="form-beats_label">
          Description
          <Popup
            className="upload-error-popup"
            trigger={
              <img
                src={Warning}
                alt=""
                className="description-error hidden"
              ></img>
            }
            on="hover"
            position="top center"
            arrow={false}
          >
            {comment?.length < 3 ? "Minimum characters are 3!" : ""}
          </Popup>
        </label>
        <textarea
          disabled={!isAuthenticated}
          className="comments-textarea"
          value={comment}
          onChange={handleDescriptionChange}
          placeholder="Write Comment here"
          style={{ cursor: isAuthenticated ? "auto" : "not-allowed" }}
        ></textarea>
        <button
          disabled={!isAuthenticated}
          className="comments-submit-button"
          onClick={handleCommentSubmit}
          style={{ cursor: isAuthenticated ? "pointer" : "not-allowed" }}
        >
          Submit
        </button>
      </div>
      <div className="comments-display-section">
        <h3 className="comments-heading">Comments({comments.length})</h3>
        <div className="comments-sections">
          {comments &&
            comments.map((comment) => {
              return (
                <div
                  className="comments-item"
                  data-id={comment.id}
                  key={comment.id}
                  data-liked={false}
                >
                  <div className="comments-item-image">
                    <img alt="" src={comment.author.avatar}></img>
                  </div>
                  <div className="comments-item-detail">
                    <h3 className="item-detail-name">{comment.author.name}</h3>
                    <h4 className="item-detail-date">
                      {comment.created_at.split("T")[0]}
                    </h4>
                    <h3 className="item-detail-comment">{comment.content}</h3>
                  </div>
                  <div className="comments-item-like">
                    <h4 className="item-detail-like-quantity">
                      {comment.likes}
                    </h4>
                    <button className="item-like-button" onClick={handleLike}>
                      <img
                        alt=""
                        className="item-like-image"
                        src={thumbs_up}
                      ></img>{" "}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}

export default AlbumComments;
