import "./../../index.css";
import "./../../Fonts/fonts.css";
import beat from "./../../images/beats.svg";
import disk from "./../../images/disk.svg";
import searchicon from "./../../images/search-icon.svg";
import Warning from "./../../images/warning2.svg";
import * as Sentry from "@sentry/react";
import Producers from "./../common/producers";
import "./../stylesheets/beatsLanding.css";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../../context/navigationContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
function ProducerLanding() {
  const { navigate } = useNavigation();
  const [producers, setProducers] = useState(null);
  const [nextPage, setNextPage] = useState("");
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const [searchInput, setSearchInput] = useState(
    location.state ? location.state.searchTerm : ""
  );

  const [servicesFilters, setServicesFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    if (location.state) {
      setSearchInput(location.state.searchTerm);
      document.querySelector(".beats-search-input").value =
        location.state.searchTerm;
    }
  }, [location.state]);

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleSearchInput = async (event) => {
    await delay(1500);
    setSearchInput(event.target.value);
    setSelectedFilter(null);
    if (event.target.value.length === 0) {
      getAllProducers();
      document.querySelector(".search-error").classList.add("hidden");
    } else if (event.target.value.length < 3)
      document.querySelector(".search-error").classList.remove("hidden");
    else document.querySelector(".search-error").classList.add("hidden");
  };

  const getAllProducers = async function () {
    try {
      const response = await api.get(`producers`);

      handleResponseStatus(response, navigate);
      const data = response.data;
      setProducers(data.data);
      setLoading(false);
      setNextPage(data.links.next?.split("api/")[1]);
    } catch (err) {
      Sentry.captureException(err);
    }
  };
  const hanldeBeatsButtonClick = () => {
    navigate("/beats");
  };
  useEffect(() => {
    const getNextPage = async (e) => {
      setLoading(true);

      try {
        if (!nextPage) {
          setLoading(false);
          return;
        } else {
          const response = await api.get(nextPage);
          handleResponseStatus(response, navigate);
          const data = response.data;
          setNextPage(data.links.next?.split("api/")[1]);
          setProducers([...producers, ...data.data]);
        }
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [nextPage, producers, setProducers, navigate]);

  useEffect(() => {
    const getGenres = async function () {
      try {
        const response = await api.get(`services`);
        handleResponseStatus(response, navigate);
        const data = response.data;
        const uniqueServices = [];
        const uniqueNames = new Set();

        data.data.forEach((service) => {
          if (!uniqueNames.has(service.name)) {
            uniqueNames.add(service.name);
            uniqueServices.push(service);
          }
        });

        const initialFilters = uniqueServices.map((service) => ({
          name: service.name,
          id: service.id,
          checked: false,
        }));

        setServicesFilters(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getGenres();
  }, [navigate]);

  useEffect(() => {
    const getFilterBeats = async () => {
      const body = { services: [selectedFilter], mode: "and" };

      try {
        const response = await api.post(`search/producers`, body);
        handleResponseStatus(response, navigate);
        const data = response.data;

        setProducers(data.data);
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getSearchProducers = async function () {
      setSelectedFilter(null);

      if (searchInput.length === 0) {
        return;
      } else if (searchInput.length < 3) {
        document.querySelector(".search-error").classList.remove("hidden");
        return;
      }

      setNextPage(null);
      const body = { term: searchInput, mode: "or" };
      try {
        const response = await api.post(`search/producers`, body);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setProducers(data.data);
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getAllProducers = async function () {
      try {
        const response = await api.get(`producers`);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setProducers(data.data);
        setLoading(false);
        setNextPage(data.links.next?.split("api/")[1]);
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    const fetchData = async () => {
      if (searchInput.length === 0) {
        await getAllProducers();
      } else if (!selectedFilter && searchInput.length >= 3) {
        await getSearchProducers();
      } else if (selectedFilter) {
        await getFilterBeats();
      }
    };

    setLoading(true);
    fetchData();
  }, [selectedFilter, searchInput, navigate]);

  const handleFilterButtonClick = async (id) => {
    document.querySelector(".beats-search-input").value = "";
    setSearchInput("");

    if (selectedFilter === id) {
      setLoading(true);
      setSelectedFilter(null);
    } else {
      setProducers([]);
      setNextPage(null);
      setLoading(true);
      setSelectedFilter(id);
    }
  };

  return (
    <>
      <section className="beats-topbar">
        <h3 className="beats-heading">Explore Producers:</h3>
        <div className="display-options">
          <button
            className="beats-option beats-button"
            data-type="beats"
            onClick={hanldeBeatsButtonClick}
          >
            <img alt="" src={beat}></img>Beats
          </button>
          <button
            className="producers-option beats-button button-selected"
            data-type="producers"
          >
            <img alt="" src={disk}></img>Producers
          </button>
          <div className="beats-search-box">
            <img alt="" className="beats-search-icon" src={searchicon}></img>
            <div className="search-input-container">
              <input
                className="beats-search-input"
                type="text"
                placeholder="Search Producers"
                defaultValue={searchInput}
                onInput={(event) => handleSearchInput(event)}
              ></input>
              <Popup
                className="upload-error-popup"
                trigger={
                  <img
                    src={Warning}
                    alt=""
                    className="search-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                Enter at least 3 characters!
              </Popup>
            </div>
          </div>
        </div>
        <div className="filters">
          <div className="beats-search-options">
            {servicesFilters.map((service) => (
              <div
                className={`search-option-button ${
                  selectedFilter === service.id
                    ? "search-option-button-selected"
                    : ""
                }`}
                key={service.id}
                onClick={() => handleFilterButtonClick(service.id)}
              >
                <img alt="" src={searchicon}></img>
                <p>{service.name}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Producers producers={producers} loading={loading} />
    </>
  );
}

export default ProducerLanding;
