import "./../stylesheets/loading.css";
import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import { useAuthContext } from "../../context/authContext";
function Loading({ message, setShowUnauthenticatedPopup }) {
  const [Message, setMessage] = useState(null);
  const { navigate } = useNavigation();
  const { loginCallback, isAuthenticated } = useAuthContext();
  useEffect(() => {
    setMessage(message);
  }, [message]);
  useEffect(() => {
    const handleLoad = async () => {
      if (window.location.href.split("callback/")[1]) {
        const id = window.location.href.split("callback/")[1];
        const response = await api.get(`auth/callback/${id}`);
        if (response.error) {
          return response;
        }
        response.data.data.expires_in =
          response.data.data.expires_in * 1000 + Date.now();
        localStorage.setItem("token", JSON.stringify(response.data?.data));
        await loginCallback();
        navigate("/");
      } else if (window.location.href.split("confirm_payment/commission?")[1]) {
        if (localStorage.getItem("commission_deposit_vendor_id")) {
          const id = localStorage.getItem("commission_deposit_vendor_id");

          if (isAuthenticated) {
            const commission_id = localStorage.getItem("commission_deposit_id");

            try {
              const response = await api.post(
                `commissions/${commission_id}/confirm-payment`,
                { id: id }
              );

              handleResponseStatus(
                response,
                navigate,
                setShowUnauthenticatedPopup
              );
              const data = response.data;
              if (data.success) {
                navigate("/messages");
              }
            } catch (error) {
              Sentry.captureException(error);
            }
          }
        }
      }
    };
    handleLoad();
  }, [navigate, isAuthenticated, loginCallback, setShowUnauthenticatedPopup]);
  return (
    <section className="loading-section">
      <div className="loading-div">
        <TailSpin
          height="80"
          width="80"
          color="#FE2B0D"
          ariaLabel="tail-spin-loading"
          visible={true}
          wrapperClass="loading"
          className="loading"
        />

        <div className="loading-description">
          <h3>{Message}</h3>
        </div>
      </div>
    </section>
  );
}
export default Loading;
