import Warning from "./../../images/warning.svg";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import * as Sentry from "@sentry/react";
import { useNavigation } from "../../context/navigationContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
function ForgotPW({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const [email, setEmail] = useState("");
  const [isRequestSent, setIsRequestSent] = useState(false);

  const handleContinueButtonClick = async function (event) {
    event.preventDefault();

    try {
      const response = await api.post(`forgot-password`, { email });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;

      if (response.status === 422) {
        document.querySelector(".email-error").classList.remove("hidden");
      }
      if (data.status === "We have emailed your password reset link.")
        setIsRequestSent(true);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <>
      <section className="section__forgotpw">
        {!isRequestSent && (
          <div className="login__container">
            <div className="forgotpw__details">
              <h3 className="forgotpw__heading">Forgot your Password?</h3>
              <p className="forgotpw__explanation">
                Enter your email address and we'll send you a link to reset your
                password
              </p>
            </div>
            <form className="login__form">
              <div className="login__group forgotpw__input">
                <label for="email">Email address </label>
                <div className="login-input-container">
                  <input
                    autocomplete="off"
                    className="login__input"
                    name="email"
                    id="email"
                    placeholder="Email address"
                    type="email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                      document
                        .querySelector(".email-error")
                        .classList.add("hidden");
                    }}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="email-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    The entered email doesn't exist!
                  </Popup>
                </div>
              </div>
              <button
                className="btn__primary"
                onClick={handleContinueButtonClick}
              >
                Continue
              </button>
              <a className="btn__secondary" href="/login">
                Return to log in
              </a>
            </form>
          </div>
        )}

        {isRequestSent && (
          <div className="forgotpw__container">
            <div className="forgotpw__details">
              <h3 className="forgotpw__heading">
                Password change request received
              </h3>
              <p className="forgotpw__explanation">
                Check your email address for a link to reset your password
              </p>
            </div>
            <Link className="forgotpw_return" to="/login">
              Return to log in
            </Link>
          </div>
        )}
      </section>
    </>
  );
}

export default ForgotPW;
