import sample from "./../../images/user.png";
import { useNavigation } from "../../context/navigationContext";

import Popup from "reactjs-popup";

function MessagesChatlistPopup({ trigger, activeChat, chatList, user }) {
  const { navigate } = useNavigation();

  const isSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  return (
    <Popup
      className="messages-sidebar-popup messages-chatlist-popup"
      trigger={trigger}
      arrow={false}
      keepTooltipInside={"body"}
    >
      <div className="messages-items">
        {chatList &&
          chatList.map((chat) => {
            const formatMessageTime = (createdAt) => {
              const messageDate = new Date(createdAt);

              if (!isSameDate(messageDate, new Date())) {
                return messageDate.toLocaleString("en-US", {
                  day: "numeric",
                  month: "long",
                });
              } else {
                return messageDate.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                });
              }
            };

            return (
              <div
                style={
                  activeChat === chat.id ? { backgroundColor: "#f5f5f5" } : null
                }
                key={chat.id}
                className="messages-item"
                onClick={() => {
                  if (activeChat !== chat.id) {
                    navigate(`/messages/${chat.id}`);
                  }
                }}
              >
                <img
                  alt=""
                  src={
                    chat.members[0].id === user.id
                      ? chat.members[1].profile_image_url
                        ? chat.members[1].profile_image_url
                        : sample
                      : chat.members[0].profile_image_url
                      ? chat.members[0].profile_image_url
                      : sample
                  }
                  className="messages-item-image"
                ></img>
                <div className="messages-item-detail">
                  <div className="messages-item-detail-div">
                    <h3 className="messages-item-detail-name">
                      {chat.members[0].id === user.id
                        ? chat.members[1].name
                        : chat.members[0].name}
                    </h3>
                    <p
                      className="messages-item-detail-description"
                      style={
                        chat.unread_messages && chat.unread_messages > 0
                          ? { fontWeight: "500" }
                          : null
                      }
                    >
                      {chat.last_message
                        ? `${
                            chat.last_message.posted_by === user.id
                              ? `You`
                              : chat.members.find(
                                  (member) =>
                                    member.id === chat.last_message.posted_by
                                ).first_name
                          }: ${
                            chat.last_message.content.length > 5
                              ? chat.last_message.content.substring(0, 5) +
                                "..."
                              : chat.last_message.content === ""
                              ? "Attachment"
                              : chat.last_message.content
                          }`
                        : " "}
                    </p>
                  </div>
                  <div className="messages-item-detail-time">
                    {chat.unread_messages != null &&
                      chat.unread_messages > 0 && (
                        <div className="message-item-detail-unread">
                          {chat.unread_messages}
                        </div>
                      )}

                    <p className="last_message_time">
                      {chat.last_message
                        ? formatMessageTime(chat.last_message.created_at)
                        : formatMessageTime(chat.created_at)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Popup>
  );
}
export default MessagesChatlistPopup;
