import "./stylesheets/cartOverview.css";
import cross from "./../images/cross-black.svg";
import * as Sentry from "@sentry/react";
import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import Popup from "reactjs-popup";
import tool from "./../images/tool-tip.svg";
import handleResponseStatus from "../utils/handleResponseStatus";
import { useNavigation } from "../context/navigationContext";
import { useAuthContext } from "../context/authContext";
import { useCurrencyContext } from "../context/currencyContext";
import api from "../utils/api";
import { usePlayerContext } from "../context/playerContext";
function CartOverview({ setShowUnauthenticatedPopup }) {
  const { selectedCurrency, rates, formatCurrency } = useCurrencyContext();

  const [cart, setCart] = useState([]);
  const [serviceFee, setServiceFee] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [popupMessage, setPopupMessage] = useState("");
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const handleRemoveItem = async (event) => {
    const item = event.target.closest(".cart-checkout-item");
    const id = item.dataset.id;
    if (isAuthenticated) {
      try {
        const response = await api.post(`cart/remove`, { cart: id });

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        if (data.success === true) {
          const newArray = cart.filter(
            (obj) => obj.id.toString() !== id.toString()
          );

          if (newArray.length <= 0) {
            setCart([]);
            setPopupMessage("Item Removed from Cart");
            setShowMessagePopup(true);
            return;
          }
          setCart(newArray);
          let newPrice = totalPrice - serviceFee;
          newPrice =
            newPrice -
            (selectedCurrency === "GBP"
              ? item
                  .querySelector(".cart-checkout-item-price")
                  .innerHTML.split("£")[1]
              : item
                  .querySelector(".cart-checkout-item-price")
                  .innerHTML.split("N")[1]) *
              100;

          if (selectedCurrency !== "P") {
            newPrice = newPrice / rates;
          }
          let service = newPrice * 0.036 + 30;
          setServiceFee(service);
          setTotalPrice(newPrice + service);
          setPopupMessage("Item Removed from Cart");
          setShowMessagePopup(true);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      const cart = JSON.parse(localStorage.getItem("cart")).data;
      const newArray = cart.filter(
        (obj) => obj.item.id.toString() !== id.toString()
      );
      const new_cart = { data: newArray };
      localStorage.setItem("cart", JSON.stringify(new_cart));
      if (newArray.length <= 0) {
        setCart([]);
        setPopupMessage("Item Removed from Cart");
        setShowMessagePopup(true);
        return;
      }
      let cartUpdated = [];
      for (let i = 0; i < newArray.length; i++) {
        cartUpdated.push(newArray[i].item);
      }
      setCart(cartUpdated);
      let newPrice = totalPrice - serviceFee;

      newPrice =
        newPrice -
        (selectedCurrency === "GBP"
          ? item
              .querySelector(".cart-checkout-item-price")
              .innerHTML.split("£")[1]
          : item
              .querySelector(".cart-checkout-item-price")
              .innerHTML.split("N")[1]) *
          100;
      if (selectedCurrency !== "GBP") {
        newPrice = newPrice / rates;
      }
      if (newPrice === 0) {
        setTotalPrice(0);
        setServiceFee(0);
      } else {
        let service = newPrice * 0.036 + 30;
        setServiceFee(service);
        setTotalPrice(newPrice + service);
      }
      setPopupMessage("Item Removed from Cart");
      setShowMessagePopup(true);
    }
  };
  useEffect(() => {
    const handleLoad = async () => {
      if (isAuthenticated) {
        try {
          const response = await api.get(`cart/summary`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
          const data = response.data;
          setCart(data.data);
          if (data.data.length === 0) {
            const button = document.querySelector(".cart-checkout-button");
            button.disabled = true;
            button.style.cursor = "not-allowed";
          }
          let service;
          if (data.price !== 0) {
            service = data.price * 0.036 + 30;
          }
          setServiceFee(service);
          setTotalPrice(data.price + service);
        } catch (error) {
          Sentry.captureException(error);
        }
      } else if (localStorage.getItem("cart")) {
        const cart = JSON.parse(localStorage.getItem("cart")).data;
        let tempCart = [];
        let total = 0;
        cart.forEach((item) => {
          tempCart.push(item.item);
          total += item.item.price;
        });
        setCart(tempCart);
        let service;
        if (total !== 0) {
          service = total * 0.036 + 30;
        }
        setServiceFee(service);
        setTotalPrice(total + service);
      }
      setLoading(false);
    };
    handleLoad();
  }, [navigate, isAuthenticated, setShowUnauthenticatedPopup]);
  const handelCheckout = () => {
    if (isAuthenticated) {
      navigate("/checkout");
    } else {
      const previous = {
        location: "/checkout",
      };
      localStorage.setItem("previous", JSON.stringify(previous));
      navigate("/login?redirect_url=checkout");
    }
  };

  const { playerState } = usePlayerContext();

  return (
    <section className="cart-checkout">
      <div className="cart-checkout-div">
        <div className="cart-checkout-heading">
          <h3>Cart Review</h3>
        </div>
        <div className="cart-checkout-items">
          {loading && (
            <TailSpin
              height="40"
              width="40"
              color="#FE2B0D"
              ariaLabel="tail-spin-loading"
              visible={loading}
              wrapperClass="loading"
              className="loading"
            />
          )}
          {!loading && (
            <>
              {cart && cart.length > 0 && (
                <div className="cart-checkout-topRow">
                  <div className="cart-checkout-item-detail-container">
                    <h3 className="cart-checkout-item-heading">Item</h3>
                    <h3 className="cart-checkout-item-price">Price</h3>
                  </div>
                </div>
              )}
              {cart && cart.length === 0 && (
                <div>
                  <h3 className="empty-cart-tag">Empty Cart</h3>
                </div>
              )}
              {isAuthenticated &&
                cart &&
                cart.map((item) => {
                  return (
                    <div
                      className="cart-checkout-item"
                      key={item.id}
                      data-id={item.id}
                    >
                      <div className="cart-checkout-item-div">
                        <img
                          alt=""
                          className="cart-checkout-item-image"
                          src={
                            item.beat
                              ? item.beat.artwork_url
                              : item.song
                              ? item.song.cover_path
                              : item.album
                              ? item.album.cover_path
                              : item.artwork_url
                          }
                        ></img>
                        <div className="cart-checkout-item-details">
                          <h3 className="cart-checkout-item-name">
                            {item.beat
                              ? item.beat.name
                              : item.song
                              ? item.song.name
                              : item.album
                              ? item.album.name
                              : item.name}
                          </h3>
                          {item.beat && item.beat.producer && (
                            <h3 className="cart-checkout-item-artist">
                              {item.beat.producer.name}
                            </h3>
                          )}
                          {item.song && item.song.artist && (
                            <h3 className="cart-checkout-item-artist">
                              {item.song.artist.name}
                            </h3>
                          )}
                          {item.album && item.album.artist && (
                            <h3 className="cart-checkout-item-artist">
                              {item.album.artist.name}
                            </h3>
                          )}
                        </div>
                      </div>

                      <h3 className="cart-checkout-item-price">
                        {item.price === 0 ? "Free" : formatCurrency(item.price)}
                      </h3>
                      <button
                        className="cart-checkout-item-remove-button"
                        onClick={handleRemoveItem}
                      >
                        <img
                          alt=""
                          className="cart-checkout-item-remove-image"
                          src={cross}
                        ></img>
                      </button>
                    </div>
                  );
                })}

              {!isAuthenticated &&
                cart &&
                cart.map((item) => {
                  return (
                    <div
                      className="cart-checkout-item"
                      key={item.id}
                      data-id={item.id}
                    >
                      <div className="cart-checkout-item-detail-container">
                        <div className="cart-checkout-item-div">
                          <img
                            alt=""
                            className="cart-checkout-item-image"
                            src={
                              item.artwork_url
                                ? item.artwork_url
                                : item.cover_path
                            }
                          ></img>
                          <div className="cart-checkout-item-details">
                            <h3 className="cart-checkout-item-name">
                              {item.name}
                            </h3>
                            {item.producer && (
                              <h3 className="cart-checkout-item-artist">
                                {item.producer.name}
                              </h3>
                            )}
                            {item.artist && (
                              <h3 className="cart-checkout-item-artist">
                                {item.artist.name}
                              </h3>
                            )}
                          </div>
                        </div>

                        <h3 className="cart-checkout-item-price">
                          {item.price === 0
                            ? "Free"
                            : formatCurrency(item.price)}
                        </h3>
                      </div>
                      <button
                        className="cart-checkout-item-remove-button"
                        onClick={handleRemoveItem}
                      >
                        <img
                          alt=""
                          className="cart-checkout-item-remove-image"
                          src={cross}
                        ></img>
                      </button>
                    </div>
                  );
                })}
              {cart && cart.length > 0 && (
                <div className="cart-checkout-total-price">
                  {totalPrice >= 0 && (
                    <div className="cart-checkout-total-price__main">
                      <div className="cart-checkout-total-price__div">
                        <div className="cart-checkout-service-fee">
                          <Popup
                            className="upload-error-popup service-fee-text"
                            trigger={<img src={tool} alt="" />}
                            position="top center"
                            on="hover"
                          >
                            <p>
                              Service Fee: This small fee helps us maintain our
                              platform and ensure a seamless experience. Thank
                              you for supporting us!
                            </p>
                          </Popup>
                          <h3>
                            Service Fee:&nbsp; {formatCurrency(serviceFee)}
                          </h3>
                        </div>
                        <div className="cart-checkout-total__amount">
                          <h3>
                            Total Amount:&nbsp; {formatCurrency(totalPrice)}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {cart && cart.length > 0 && (
                <div className="cart-checkout-button-div">
                  <button
                    className="cart-checkout-button"
                    onClick={handelCheckout}
                  >
                    Checkout
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
    </section>
  );
}
export default CartOverview;
