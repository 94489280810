import "./stylesheets/terms.css";

function FAQ() {
  return (
    <section className="terms">
      <h2>Frequently Asked Questions</h2>
      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">
          What types of licenses do you offer for your beats?
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          We offer various licenses, including Exclusive and Non-Exclusive, each
          with distinct usage rights.
        </p>
      </div>

      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">
          Can I hire a producer to make me a custom beat?
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          Absolutely. Select a producer, fill in the form with required
          information, and submit to initiate the hiring process.
        </p>
      </div>

      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">Am I protected during the hiring process?</p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          Rest assured, you are protected. Funds aren’t released to the producer
          until you are satisfied with the custom beat.
        </p>
      </div>

      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">
          How do I purchase beats and choose a license?
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          Easily search for beats by license type, add them to your cart, and
          proceed to checkout.
        </p>
      </div>

      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">Do you provide refunds for purchased beats?</p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          Due to the digital nature, refunds are generally not offered. Review
          beats carefully before purchase.
        </p>
      </div>

      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">
          What should I do if I face problems with a purchased beat?
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          If you encounter issues or need assistance, contact our support team
          for a prompt resolution.
        </p>
      </div>

      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">Can I own a beat exclusively?</p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          Yes, by obtaining an Exclusive license, you gain full ownership,
          ensuring exclusivity for your project.
        </p>
      </div>

      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">
          How should I credit the producer when using a beat?
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          Properly attribute the beat by including the producer’s name in the
          credits of your work.
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">Why am I not receiving emails?</p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          Check your spam folder for missing emails, and adjust settings to
          whitelist important senders.
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: 0 }}>
        <span className="term-bold">Q.</span>
        <p className="term-bold">
          Where can I seek help or ask further questions?
        </p>
      </div>
      <div className="term-container" style={{ marginBottom: "2%" }}>
        <span className="term-bold">A.</span>
        <p>
          For inquiries related to licenses, disputes, or other matters, reach
          out to our dedicated support team:{" "}
          <a href={`mailto:info@thathrowdown.com`}>Thathrowdown</a>
        </p>
      </div>
    </section>
  );
}

export default FAQ;
