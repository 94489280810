import "./../stylesheets/Home.css";
import TrendingSongsMusic from "./../musicLanding/trendingSongsMusic";
import TrendingAlbumsMusic from "./../musicLanding/trendingAlbumsMusic";
import FeaturedTracks from "./../musicLanding/featuredTracks";
import MusicLandingCarousel from "./musicLandingCarousel";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useNavigation } from "../../context/navigationContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
function MusicLanding({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const { navigate } = useNavigation();
  const [songs, setSongs] = useState(null);
  const [albums, setAlbums] = useState(null);
  const [featuredTracks, setFeaturedTracks] = useState(null);
  const [featuredAlbums, setFeaturedAlbums] = useState(null);
  const [featuredAlbums1, setFeaturedAlbums1] = useState(null);
  const [featuredMusic, setFeaturedMusic] = useState(null);
  const [carouselItems, setCarouselItems] = useState(null);

  useEffect(() => {
    const getSongs = async function () {
      try {
        const response = await api.get(`top/songs`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setSongs(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getAlbums = async function () {
      try {
        const response = await api.get(`top/albums`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setAlbums(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getBannerItems = async function () {
      try {
        const response = await api.get(`highlights?location=landing_page`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        if (data.songs.length === 0 && data.albums.length === 0) {
          try {
            const response = await api.get(`top/songs`);

            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            setFeaturedTracks(data.data);
          } catch (error) {
            Sentry.captureException(error);
          }
        } else {
          setFeaturedTracks([...data.songs]);

          setFeaturedAlbums(data.albums);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    const getFeaturedTracks = async function () {
      try {
        const response = await api.get(`highlights?location=featured_music`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setFeaturedMusic(data.songs);
        setFeaturedAlbums1(data.albums);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getFeaturedTracks();
    getSongs();
    getAlbums();
    getBannerItems();
  }, [navigate, setShowUnauthenticatedPopup]);

  useEffect(() => {
    const shuffleArray = (array) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };

    if (featuredAlbums && featuredTracks) {
      const combined = [...featuredAlbums, ...featuredTracks];
      const shuffledArray = shuffleArray(combined);
      setCarouselItems(shuffledArray);
    }
  }, [featuredAlbums, featuredTracks]);

  return (
    <div className="app-container">
      <MusicLandingCarousel items={carouselItems} />
      <TrendingSongsMusic
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        updateCartCounter={updateCartCounter}
        setUpdateCartCounter={setUpdateCartCounter}
        handleLocalCart={handleLocalCart}
        handleCart={handleCart}
        songs={songs}
      />
      <TrendingAlbumsMusic
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        updateCartCounter={updateCartCounter}
        setUpdateCartCounter={setUpdateCartCounter}
        handleLocalCart={handleLocalCart}
        handleCart={handleCart}
        albums={albums}
      />
      <FeaturedTracks
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        updateCartCounter={updateCartCounter}
        setUpdateCartCounter={setUpdateCartCounter}
        handleLocalCart={handleLocalCart}
        handleCart={handleCart}
        featuredTracks={featuredMusic}
        albums={featuredAlbums1}
      />
    </div>
  );
}

export default MusicLanding;
