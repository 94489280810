import "./../stylesheets/userPage.css";
import "./../../Fonts/fonts.css";
import React, { useState, useEffect } from "react";
import PurchasedAlbums from "./purchasedAlbums";
import PurchasedBeats from "./purchasedBeats";
import PurchasedMusic from "./purchasedMusic";
import { useNavigation } from "../../context/navigationContext";
import { useParams } from "react-router-dom";
function Purchases() {
  const [selector, setSelector] = useState("purchased");
  const { navigate } = useNavigation();
  const { type } = useParams();

  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "purchases") {
      if (type === "beats") {
        setSelector("purchased");
      } else if (type === "music") {
        setSelector("purchased__music");
      } else if (type === "albums") {
        setSelector("purchased__albums");
      } else {
        setSelector("purchased");
      }
    }
  }, [type]);
  return (
    <section className="Purchases">
      <div className="navigation">
        <button
          className={`packs-button ${selector === "purchased" ? "active" : ""}`}
          onClick={() => {
            if (selector !== "purchased") navigate("purchases/beats");
          }}
        >
          Purchased Beats
        </button>
        <button
          className={`packs-button ${
            selector === "purchased__music" ? "active" : ""
          }`}
          onClick={() => {
            if (selector !== "purchased__music") navigate("/purchases/music");
          }}
        >
          Purchased Music
        </button>
        <button
          className={`packs-button ${
            selector === "purchased__albums" ? "active" : ""
          }`}
          onClick={() => {
            if (selector !== "purchased__albums") navigate("/purchases/albums");
          }}
        >
          Purchased Albums
        </button>
      </div>

      {selector === "purchased" && <PurchasedBeats />}
      {selector === "purchased__albums" && <PurchasedAlbums />}
      {selector === "purchased__music" && <PurchasedMusic />}
    </section>
  );
}

export default Purchases;
