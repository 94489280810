import React from "react";
import { Helmet } from "react-helmet";

const description =
  "Tha Throwdown: Your go-to destination for authentic Afrobeats instrumentals. Buy, collaborate, and customize your beats with top-tier producers. :musical_note::star2: #ThaThrowdown #Afrobeats #BeatsForSale #CustomBeats #MusicProduction";

const Meta = ({ metadata }) => {
  return (
    <div>
      <Helmet>
        <title>{`Thathrowdown ${
          metadata?.title ? `- ${metadata.title}` : ""
        }`}</title>
        <meta property="og:type" content="website" />

        <meta property="og:url" content={process.env.REACT_APP_FRONTEND_URL} />

        <meta
          name="og:description"
          content={metadata?.description ? metadata.description : description}
        />

        <meta name="description" content={description} />

        <meta
          property="image"
          content={metadata?.image ? metadata.image : "%PUBLIC_URL%/logo.png"}
        />

        
      </Helmet>
    </div>
  );
};

export default Meta;
