import "./../stylesheets/setup.css";
import "./../stylesheets/navbar.css";
import api from "../../utils/api";
import Logo from "./../../images/Logo.png";
import shoppingCart from "./../../images/Icon-shopping-cart.png";
import downBtn from "./../../images/down-button.png";
import searchIcon from "./../../images/search-icon-light.png";
import searchArrowDown from "./../../images/Path-2.png";
import bellIcon from "./../../images/bell-icon.png";
import profile from "./../../images/profile.png";
import * as Sentry from "@sentry/react";
import alert from "./../../images/alert.png";
import msg from "./../../images/message.svg";
import eye from "./../../images/eye.svg";
import Logout from "./../../images/logout.svg";
import basket from "./../../images/basket.svg";
import disk from "./../../images/disk.svg";
import playlist from "./../../images/playlist.svg";
import paper from "./../../images/paper.svg";
import message from "./../../images/message1.svg";
import music from "./../../images/musoc.svg";
import Wallet from "./../../images/wallet.svg";
import Warning from "./../../images/warning2.svg";
import more from "./../../images/list.svg";
import UK from "./../../images/UK.svg";
import Music from "./../../images/Music.svg";
import Nigeria from "./../../images/nigeria.svg";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import cross from "./../../images/cross.svg";
import { Link, useLocation } from "react-router-dom";
import NavbarSidebar from "./navbarSidebar";
import NavbarCartPopup from "./navbarCartPopup";
import NavbarCountryPopup from "./navbarCountryPopup";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import InfiniteScroll from "react-infinite-scroller";

// const musicPages = ["music", "albums", "artists", "artist", "album"];
// const beatsPages = ["beats", "producers", "beat"];

function Navbar({ setShowUnauthenticatedPopup, updateCartCounter }) {
  const { navigate } = useNavigation();
  const location = useLocation();
  const { user, isAuthenticated, wallet, logout } = useAuthContext();
  const { selectedCurrency, selectCurrency, formatCurrency } =
    useCurrencyContext();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMobileNotificationPopup, setShowMobileNotificationPopup] =
    useState(false);
  const [cart, setCart] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationsNext, setNotificationsNext] = useState(null);

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [searchCategory, setSearchCategory] = useState("Beats");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [unreadMessages, setUnreadMessages] = useState(0);

  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [cartItemLength, setCartItemLength] = useState(null);

  const [popupStates, setPopupStates] = useState({
    cartPopup: false,
    notificationPopup: false,
    userPopup: false,
    countryPopup: false,
  });

  function searchWordInString(word, string) {
    return string.includes(word);
  }

  const handleWalletClick = () => {
    navigate("/wallet");
  };

  const handleSearchInputChange = (event) => {
    const regex = /[^a-zA-Z0-9\s]/g;

    const filteredValue = event.target.value.replace(regex, "");
    setSearchInputValue(filteredValue);
    if (
      event.target.value.length === 0 ||
      event.target.value.length >= 3 ||
      event.target.value.length <= 25
    ) {
      document.querySelector(".navbar-search-error").classList.add("hidden");
      if (showSearch)
        document
          .querySelector(".navbar-mobile-search-error")
          .classList.add("hidden");
    } else {
      document.querySelector(".navbar-search-error").classList.remove("hidden");
      if (showSearch)
        document
          .querySelector(".navbar-mobile-search-error")
          .classList.remove("hidden");
    }
  };

  const changeCurrency = (currency) => {
    selectCurrency(currency);
  };

  const handleNotificationClick = async (event) => {
    const item = event.target.closest(".notification--item");
    const id = item.dataset.id;
    const index = item.dataset.index;
    item.remove();
    try {
      const response = await api.post("notification/read", { id: id });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
    } catch (error) {
      Sentry.captureException(error);
    }
    if (searchWordInString("Bid", notifications[index].type)) {
      setPopupStates((prevState) => ({
        ...prevState,
        notificationPopup: false,
      }));
      navigate("/bids");
    } else if (searchWordInString("Commission", notifications[index].type)) {
      const id = notifications[index].data.message_id;
      setPopupStates((prevState) => ({
        ...prevState,
        notificationPopup: false,
      }));
      navigate(`/messages/${id}`);
    }
  };

  const handleInboxClick = async () => {
    navigate("/messages");
    setPopupStates((prevState) => ({
      ...prevState,
      notificationPopup: false,
    }));
    setShowMobileNotificationPopup(false);
  };

  const handleProfileClick = async () => {
    if (user.role === "seller") {
      navigate("/user/uploads/beats");
    } else {
      navigate(`/user/purchased/beats`);
    }
  };

  const handleRegisterButton = async () => {
    navigate("/signup");
  };

  const handleUploadButton = async (page) => {
    navigate(`/upload/${page}`);
  };

  const getSearchBeats = async () => {
    if (searchInputValue.length < 3 || searchInputValue.length > 25) {
      document.querySelector(".navbar-search-error").classList.add("hidden");
      document
        .querySelector(".navbar-mobile-search-error")
        .classList.remove("hidden");
      return;
    }

    const searchVal = searchInputValue;
    setSearchInputValue("");
    document.querySelector(".nav-search").value = "";
    if (showSearch)
      document.querySelector(".nav-mobile-search-input").value = "";
    setShowSearch(false);

    if (searchCategory === "Beats") {
      navigate(`/beats`, { state: { searchTerm: searchVal } });
    } else if (searchCategory === "Producers") {
      navigate(`/producers`, { state: { searchTerm: searchVal } });
    } else if (searchCategory === "Songs") {
      navigate(`/music/all`, { state: { searchTerm: searchVal } });
    } else if (searchCategory === "Albums") {
      navigate(`/albums/all`, { state: { searchTerm: searchVal } });
    }
  };

  const handleNotifications = async () => {
    try {
      const response = await api.get("notification/get_unread");
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

      const data = response.data;
      setNotifications(data.data);

      setNotificationsNext(data.next_page_url?.split("/api/")[1]);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const handleNotificationsDot = async () => {
      setUnreadNotifications(false);

      try {
        const response = await api.get("notification/get_unread");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;
        setNotifications(data.data);
        setNotificationsNext(data.next_page_url?.split("/api/")[1]);
        const filteredNotifications = data.data.filter(
          (notification) => notification.data.description
        );

        if (filteredNotifications.length > 0) {
          setUnreadNotifications(true);
        }
      } catch (error) {
        Sentry.captureException(error);
      }

      try {
        const response = await api.get("conversations/unread");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;
        if (response.status === 200) {
          setUnreadMessages(data.total_unread);
        }

        if (data.total_unread > 0) {
          setUnreadNotifications(true);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    if (isAuthenticated) {
      handleNotificationsDot();
    }
  }, [navigate, setShowUnauthenticatedPopup, isAuthenticated]);

  useEffect(() => {
    const handleCartCounter = async () => {
      if (isAuthenticated) {
        try {
          const response = await api.get("cart/summary");
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

          const data = response.data;
          setCart(data.data);
          if (data.data) {
            setCartItemLength(data.data.length);
          }
          if (data.data.length === 0) {
            if (document.querySelector(".checkout")) {
              const my_button = document.querySelector(".checkout");
              my_button.disabled = true;
              my_button.style.cursor = "not-allowed";
            }
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        if (localStorage.getItem("cart")) {
          setCart(JSON.parse(localStorage.getItem("cart")).data);
          setCartItemLength(
            JSON.parse(localStorage.getItem("cart")).data.length
          );
        }
      }
    };
    handleCartCounter();
  }, [
    navigate,
    setShowUnauthenticatedPopup,
    updateCartCounter,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (location.pathname !== "/") {
      document.querySelector(".navbar").style.backgroundColor = "#0D0D0D";
      if (showSearch) {
        document.querySelector(".nav-mobile-search").style.backgroundColor =
          "#0D0D0D";
      }
    } else {
      document.querySelector(".navbar").style.backgroundColor = "#0D0D0D80";
      if (showSearch) {
        document.querySelector(".nav-mobile-search").style.backgroundColor =
          "#0D0D0D80";
      }
    }
    window.addEventListener("beforeunload", (event) => {
      if (localStorage.getItem("player_state")) {
        localStorage.removeItem("player_state");
      }
      if (localStorage.getItem("playing_queue")) {
        localStorage.removeItem("playing_queue");
      }
    });
  }, [location.pathname, showSearch]);

  const handlePurchase = () => {
    navigate("/purchases/beats");
  };

  const handleCart = async () => {
    if (isAuthenticated) {
      try {
        const response = await api.get("cart/summary");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;
        setCart(data.data);
        setCartItemLength(data.data.length);
        if (data.data.length === 0) {
          const my_button = document.querySelector(".checkout");
          my_button.disabled = true;
          my_button.style.cursor = "not-allowed";
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      if (localStorage.getItem("cart")) {
        setCart(JSON.parse(localStorage.getItem("cart")).data);
        setCartItemLength(JSON.parse(localStorage.getItem("cart")).data.length);
      }
    }
  };

  const notificationContainerRef = useRef(null);

  const getNotificationsNextPage = async (url, options) => {
    console.log(url);
    const response = await api.get(url);
    const data = response.data;
    return data;
  };

  const handleLoadMoreNotifications = async () => {
    console.log("getting next");
    if (notificationsNext) {
      const data = await getNotificationsNextPage(notificationsNext);

      setNotifications([...notifications, ...data.data]);
      setNotificationsNext(data.next_page_url?.split("/api/")[1]);
    }
  };

  useEffect(() => {
    const getUnreadCounter = async () => {
      if (notificationsOpen) {
        try {
          const response = await api.get("conversations/unread");
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

          const data = response.data;
          if (response.status === 200) {
            setUnreadMessages(data.total_unread);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    getUnreadCounter();
  }, [notificationsOpen, navigate, setShowUnauthenticatedPopup]);

  const [popupMessage, setPopupMessage] = useState("");
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const handleRemoveCartItem = async (e) => {
    const item = e.target.closest(".cart--item");
    const id = item.dataset.id;
    if (isAuthenticated) {
      try {
        const response = await api.post("cart/remove", { cart: id });

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;
        if (data.success === true) {
          item.remove();
          setPopupMessage("Item Removed from Cart");
          setShowMessagePopup(true);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      const cart = JSON.parse(localStorage.getItem("cart")).data;
      const newArray = cart.filter(
        (obj) => obj.item.id.toString() !== id.toString()
      );
      const new_cart = { data: newArray };
      localStorage.setItem("cart", JSON.stringify(new_cart));
      item.remove();
      setPopupMessage("Item Removed from Cart");
      setShowMessagePopup(true);
    }
    setCartItemLength(cartItemLength - 1);
  };

  const handleLogout = async () => {
    try {
      const response = await api.post("logout", {});

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

      logout();
      setCart(null);
      setCartItemLength(0);
      navigate("/");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const handleViewCartButton = () => {
    navigate("/cart");
  };

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  const handleLoginButton = () => {
    const previous = {
      location: window.location.pathname,
    };
    localStorage.setItem("previous", JSON.stringify(previous));
    navigate("/login");
  };

  const handleCheckoutButton = () => {
    if (isAuthenticated) {
      navigate("/checkout");
    } else {
      const previous = {
        location: "/checkout",
      };
      localStorage.setItem("previous", JSON.stringify(previous));
      navigate("/login");
    }
  };

  const isMusicPage = () => {
    return (
      location.pathname.startsWith("/music") ||
      location.pathname.startsWith("/albums")
    );
  };

  const isBeatsPage = () => {
    return location.pathname.startsWith("/beats");
  };

  return (
    <>
      {showSidebar && (
        <NavbarSidebar
          user={user}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          isAuthenticated={isAuthenticated}
          showMobileNotificationPopup={showMobileNotificationPopup}
          setShowMobileNotificationPopup={setShowMobileNotificationPopup}
          unreadNotifications={unreadNotifications}
          handleLoginButton={handleLoginButton}
          handleLogout={handleLogout}
          handleNotifications={handleNotifications}
          setNotificationsOpen={setNotificationsOpen}
          handleInboxClick={handleInboxClick}
          notifications={notifications}
          handleNotificationClick={handleNotificationClick}
        />
      )}

      <nav className="navbar">
        <div className="nav_left">
          <img
            className="sidebar-toggle"
            src={more}
            alt="more"
            onClick={() => {
              setShowSidebar(true);
            }}
          />

          <img
            className="search-toggle"
            src={searchIcon}
            alt="search"
            onClick={() => {
              setShowSearch(!showSearch);
            }}
          />

          <NavbarCountryPopup
            trigger={
              <button className="country__selector country__selector__mobile nav__button">
                <img
                  src={selectedCurrency === "GBP" ? UK : Nigeria}
                  className="navbar_cta-btn-img"
                  alt=""
                />

                <img
                  src={downBtn}
                  className="navbar-down-arrow"
                  style={
                    popupStates.countryPopup
                      ? { transform: "rotate(180deg)" }
                      : { transform: "none" }
                  }
                  alt=""
                />
              </button>
            }
            handlePopupOpen={handlePopupOpen}
            handleCart={handleCart}
            handlePopupClose={handlePopupClose}
            selectedCurrency={selectedCurrency}
            changeCurrency={changeCurrency}
          />
          <button
            className="shopping__mobile nav__button search-toggle"
            onClick={() => {
              navigate("/cart");
            }}
          >
            <img src={shoppingCart} className="navbar_cta-btn-img" alt="" />
            {cartItemLength && cartItemLength !== 0 ? (
              <div className="cart-items-dot__mobile">{cartItemLength}</div>
            ) : (
              <></>
            )}
          </button>
          <Link to="/">
            <img src={Logo} className="navbar-logo" alt="Throwdown logo" />
          </Link>

          <div className="nav-search--container">
            <img
              src={searchIcon}
              alt=""
              className="nav-icon--search search-container--left"
              onClick={getSearchBeats}
            />
            <input
              className="nav-search"
              type="text"
              placeholder="Search"
              onChange={handleSearchInputChange}
              value={searchInputValue}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  getSearchBeats();
                }
              }}
            />

            <Popup
              className="navbar-search-category-popup"
              trigger={
                <button className="nav-icon--search search-container--right">
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="navbar-search-error hidden"
                      />
                    }
                    on="hover"
                    position="bottom center"
                    arrow={false}
                  >
                    Enter at least 3 characters!
                  </Popup>
                  <p>{searchCategory}</p>
                  <img
                    src={searchArrowDown}
                    alt=""
                    className="searchArrowDown"
                  />
                </button>
              }
              position="bottom center"
            >
              {(close) => (
                <div>
                  <div
                    className={`search-category__option ${
                      searchCategory === "Beats"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Beats")
                        setSearchCategory("Beats");
                      close();
                    }}
                  >
                    Beats
                  </div>
                  <div
                    className={`search-category__option ${
                      searchCategory === "Producers"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Producers")
                        setSearchCategory("Producers");
                      close();
                    }}
                  >
                    Producers
                  </div>
                  <div
                    className={`search-category__option ${
                      searchCategory === "Songs"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Songs")
                        setSearchCategory("Songs");
                      close();
                    }}
                  >
                    Songs
                  </div>
                  <div
                    className={`search-category__option ${
                      searchCategory === "Albums"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Albums")
                        setSearchCategory("Albums");
                      close();
                    }}
                  >
                    Albums
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </div>

        {!isAuthenticated && (
          <div className="nav_right">
            <Link to="/music">
              Music
              {isMusicPage() && <span className="navbar-page-underline" />}
            </Link>
            <Link to="/beats">
              Beats
              {isBeatsPage() && <span className="navbar-page-underline" />}
            </Link>

            <span className="vertical-divider" />

            <NavbarCountryPopup
              trigger={
                <button className="country__selector nav__button">
                  <img
                    src={selectedCurrency === "GBP" ? UK : Nigeria}
                    className="navbar_cta-btn-img"
                    alt=""
                  />

                  <img
                    src={downBtn}
                    className="navbar-down-arrow"
                    style={
                      popupStates.countryPopup
                        ? { transform: "rotate(180deg)" }
                        : { transform: "none" }
                    }
                    alt=""
                  />
                </button>
              }
              handlePopupOpen={handlePopupOpen}
              handleCart={handleCart}
              handlePopupClose={handlePopupClose}
              selectedCurrency={selectedCurrency}
              changeCurrency={changeCurrency}
            />

            <button className="nav__login" onClick={handleLoginButton}>
              Login
            </button>
            <button className="nav__register" onClick={handleRegisterButton}>
              Register
            </button>
            <NavbarCartPopup
              cart={cart}
              trigger={
                <button className="shopping nav__button">
                  <img
                    src={shoppingCart}
                    className="navbar_cta-btn-img"
                    alt=""
                  />
                  {cartItemLength && cartItemLength !== 0 ? (
                    <div className="cart-items-dot">{cartItemLength}</div>
                  ) : (
                    <></>
                  )}
                  <img
                    src={downBtn}
                    className="navbar-down-arrow"
                    style={
                      popupStates.cartPopupLogout
                        ? { transform: "rotate(180deg)" }
                        : { transform: "none" }
                    }
                    alt=""
                  />
                </button>
              }
              handlePopupOpen={handlePopupOpen}
              handlePopupClose={handlePopupClose}
              handleCart={handleCart}
              handleRemoveCartItem={handleRemoveCartItem}
              handleViewCartButton={handleViewCartButton}
              handleCheckoutButton={handleCheckoutButton}
              isAuthenticated={isAuthenticated}
              formatCurrency={formatCurrency}
            />
          </div>
        )}

        {isAuthenticated && user && (
          <div className="nav_right">
            <Link to="/music">
              Music
              {isMusicPage() && <span className="navbar-page-underline" />}
            </Link>
            <Link to="/beats">
              Beats
              {isBeatsPage() && <span className="navbar-page-underline" />}
            </Link>
            {user.role === "seller" && (
              <Popup
                className="navbar-popup nav-sell-beat-popup"
                trigger={<button className="navbar_upload-btn">Upload</button>}
                on="hover"
              >
                {(close) => (
                  <>
                    <button
                      className="sell__options"
                      onClick={() => {
                        handleUploadButton("beat");
                        close();
                      }}
                    >
                      Upload Beat
                    </button>

                    <button
                      className="sell__options"
                      onClick={() => {
                        handleUploadButton("song");
                        close();
                      }}
                    >
                      Upload Song
                    </button>
                    <button
                      className="sell__options"
                      onClick={() => {
                        handleUploadButton("album");
                        close();
                      }}
                    >
                      Upload Album
                    </button>
                  </>
                )}
              </Popup>
            )}
            <span className="vertical-divider" />

            <NavbarCountryPopup
              trigger={
                <button className="country__selector nav__button logged_in_country">
                  <img
                    src={selectedCurrency === "GBP" ? UK : Nigeria}
                    className="navbar_cta-btn-img"
                    alt=""
                  />

                  <img
                    src={downBtn}
                    className="navbar-down-arrow"
                    style={
                      popupStates.countryPopup
                        ? { transform: "rotate(180deg)" }
                        : { transform: "none" }
                    }
                    alt=""
                  />
                </button>
              }
              handlePopupOpen={handlePopupOpen}
              handleCart={handleCart}
              handlePopupClose={handlePopupClose}
              selectedCurrency={selectedCurrency}
              changeCurrency={changeCurrency}
            />

            <NavbarCartPopup
              cart={cart}
              trigger={
                <button className="shopping nav__button__login">
                  <img
                    src={shoppingCart}
                    className="navbar_cta-btn-img"
                    alt=""
                  />
                  {cartItemLength && cartItemLength !== 0 ? (
                    <div className="cart-items-dot">{cartItemLength}</div>
                  ) : (
                    <></>
                  )}
                  <img
                    src={downBtn}
                    style={
                      popupStates.cartPopupLogin
                        ? { transform: "rotate(180deg)" }
                        : { transform: "none" }
                    }
                    alt=""
                  />
                </button>
              }
              handlePopupOpen={handlePopupOpen}
              handlePopupClose={handlePopupClose}
              handleCart={handleCart}
              formatCurrency={formatCurrency}
              handleRemoveCartItem={handleRemoveCartItem}
              handleViewCartButton={handleViewCartButton}
              handleCheckoutButton={handleCheckoutButton}
              isAuthenticated={isAuthenticated}
            />

            <Popup
              className="navbar-popup notification-popup"
              trigger={
                <button className="bell nav__button__login">
                  <img src={bellIcon} className="navbar_cta-btn-img" alt="" />
                  {unreadNotifications && (
                    <div className="notification-unread-dot" />
                  )}
                  <img
                    src={downBtn}
                    style={
                      popupStates.notificationPopup
                        ? { transform: "rotate(180deg)" }
                        : { transform: "none" }
                    }
                    alt=""
                  />
                </button>
              }
              onOpen={() => {
                handlePopupOpen("notificationPopup");
                handleNotifications();
                setNotificationsOpen(true);
              }}
              onClose={() => {
                handlePopupClose("notificationPopup");
                setNotificationsOpen(false);
              }}
              open={popupStates["notificationPopup"]}
              onClick={handleNotifications}
              position="bottom right"
            >
              <div className="bell-div">
                <div className="inbox">
                  <button className="inbox-button" onClick={handleInboxClick}>
                    <div className="msg-icon">
                      <img alt="" src={msg}></img>
                    </div>
                    <div className="inbox-text">
                      <h3>Inbox</h3>
                      <p>{unreadMessages} unread messages</p>
                    </div>
                  </button>
                </div>
                <div className="notifications" ref={notificationContainerRef}>
                  <h3>Notification</h3>
                  <InfiniteScroll
                    loadMore={handleLoadMoreNotifications}
                    hasMore={true}
                    initialLoad={false}
                    useWindow={false}
                    getScrollParent={() => notificationContainerRef.current}
                    threshold={5}
                  >
                    {notifications &&
                      notifications.map((item, index) => {
                        const message = item.data.description;
                        let desc = message;
                        if (item.data.description) {
                          const regex = /£(\d+(\.\d+)?)/g;
                          const match = message.match(regex);

                          if (match) {
                            match.forEach((match) => {
                              const number = parseFloat(match.replace(/£/, ""));

                              desc = desc.replace(
                                match,
                                formatCurrency(number * 100)
                              );
                            });
                          }
                        }

                        const formatMessageTime = (createdAt) => {
                          const messageDate = new Date(createdAt);

                          if (!isSameDate(messageDate, new Date())) {
                            return messageDate.toLocaleString("en-US", {
                              day: "numeric",
                              month: "long",
                            });
                          } else {
                            return messageDate.toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            });
                          }
                        };
                        return (
                          item.data.description && (
                            <div
                              className="notification--item"
                              data-id={item.id}
                              data-index={index}
                              key={index}
                              onClick={handleNotificationClick}
                            >
                              <img alt="" src={alert}></img>
                              <h4>{desc}</h4>
                              <p>{formatMessageTime(item.created_at)}</p>
                            </div>
                          )
                        );
                      })}
                  </InfiniteScroll>
                </div>
              </div>
            </Popup>

            <Popup
              className="navbar-popup user-popup"
              trigger={
                <button className="nav__button__login user-options-button">
                  <img src={profile} className="navbar_cta-btn-img" alt="" />
                  <img
                    src={downBtn}
                    style={
                      popupStates.userPopup
                        ? { transform: "rotate(180deg)" }
                        : { transform: "none" }
                    }
                    alt=""
                  />
                </button>
              }
              onOpen={() => handlePopupOpen("userPopup")}
              onClose={() => handlePopupClose("userPopup")}
              position="bottom right"
            >
              {(close) => (
                <div className="user-div">
                  <div className="user-information-div">
                    <div
                      className="user-image-div"
                      onClick={() => {
                        handleProfileClick();
                        close();
                      }}
                    >
                      <img alt="" src={profile}></img>
                      <span className="user-profile-name">
                        <h3>{user.name}</h3>
                      </span>
                    </div>
                    <div
                      className="wallet"
                      onClick={() => {
                        close();
                        handleWalletClick();
                      }}
                    >
                      <h4 className="wallet-balance-nav">Wallet Balance:</h4>
                      {wallet >= 0 && <h4>{formatCurrency(wallet)}</h4>}
                    </div>
                  </div>
                  <div className="user-buttons">
                    <button
                      className=""
                      onClick={() => {
                        close();
                        if (user.role === "seller") {
                          navigate("/user/uploads/beats");
                        } else {
                          navigate("/user/purchased/beats");
                        }
                      }}
                    >
                      <img alt="" src={music}></img>
                      My Beats
                    </button>
                    <button className="">
                      <button
                        className=""
                        onClick={() => {
                          close();
                          if (user.role === "seller") {
                            navigate("/user/uploads/music");
                          } else {
                            navigate("/user/purchased/music");
                          }
                        }}
                      >
                        <img alt="" src={Music}></img>
                        My Music
                      </button>
                    </button>
                    <button
                      className=""
                      onClick={() => {
                        close();
                        navigate("/playlists");
                      }}
                    >
                      <img alt="" src={playlist}></img>
                      My Playlist
                    </button>
                    <button
                      className=""
                      onClick={() => {
                        close();
                        navigate("/wallet");
                      }}
                    >
                      <img alt="" src={Wallet}></img>
                      My Wallet
                    </button>
                    <button
                      className=""
                      onClick={(event) => {
                        close();
                        navigate("/messages");
                      }}
                    >
                      <img alt="" src={message}></img>Messages
                    </button>
                    <button
                      className=""
                      onClick={(event) => {
                        close();
                        handlePurchase();
                      }}
                    >
                      <img alt="" src={basket}></img>Purchases
                    </button>
                    <button
                      className=""
                      onClick={() => {
                        close();
                        navigate("/bids");
                      }}
                    >
                      <img alt="" src={disk}></img>
                      Beats Bid
                    </button>
                  </div>
                  <div className="user-system">
                    <button
                      className=""
                      onClick={() => {
                        close();
                        navigate("/terms");
                      }}
                    >
                      <img alt="" src={paper}></img>
                      Terms and Conditions
                    </button>
                    <button
                      className=""
                      onClick={() => {
                        close();
                        navigate("/privacy");
                      }}
                    >
                      <img alt="" src={eye}></img>
                      Privacy Policy
                    </button>
                    <button
                      className="user--logout--button"
                      onClick={(event) => {
                        close();
                        handleLogout();
                      }}
                    >
                      <img alt="" src={Logout}></img>Logout
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        )}
      </nav>

      {showSearch && (
        <nav className="nav-mobile-search">
          <div className="nav-mobile-search-container">
            <img src={searchIcon} alt="" onClick={getSearchBeats} />
            <input
              className="nav-mobile-search-input"
              type="text"
              placeholder="Search"
              onChange={handleSearchInputChange}
              value={searchInputValue}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  getSearchBeats();
                }
              }}
            />

            <Popup
              className="navbar-mobile-search-category-popup"
              trigger={
                <button>
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="navbar-mobile-search-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="bottom center"
                    arrow={false}
                  >
                    Enter at least 3 characters!
                  </Popup>
                  <p>{searchCategory}</p>
                  <img
                    src={searchArrowDown}
                    alt=""
                    className="searchArrowDown"
                  />
                </button>
              }
              position="bottom center"
            >
              <div
                className={`search-category__option ${
                  searchCategory === "Beats"
                    ? "search-category__option-selected"
                    : ""
                }`}
                onClick={() => {
                  if (searchCategory !== "Beats") setSearchCategory("Beats");
                }}
              >
                Beats
              </div>
              <div
                className={`search-category__option ${
                  searchCategory === "Producers"
                    ? "search-category__option-selected"
                    : ""
                }`}
                onClick={() => {
                  if (searchCategory !== "Producers")
                    setSearchCategory("Producers");
                }}
              >
                Producers
              </div>
              <div
                className={`search-category__option ${
                  searchCategory === "Songs"
                    ? "search-category__option-selected"
                    : ""
                }`}
                onClick={() => {
                  if (searchCategory !== "Songs") setSearchCategory("Songs");
                }}
              >
                Songs
              </div>
              <div
                className={`search-category__option ${
                  searchCategory === "Albums"
                    ? "search-category__option-selected"
                    : ""
                }`}
                onClick={() => {
                  if (searchCategory !== "Albums") setSearchCategory("Albums");
                }}
              >
                Albums
              </div>
            </Popup>
          </div>
        </nav>
      )}
      <Popup
        className={`add-queue-popup`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
      <Popup
        className="mobile-notification-popup"
        open={showMobileNotificationPopup}
        onOpen={() => {
          setShowMobileNotificationPopup(true);
          handleNotifications();
          setNotificationsOpen(true);
        }}
        onClose={() => {
          setShowMobileNotificationPopup(false);
        }}
        onClick={handleNotifications}
        modal
        nested
      >
        <div className="bell-div">
          <div className="inbox">
            <button className="inbox-button" onClick={handleInboxClick}>
              <div className="msg-icon">
                <img alt="" src={msg}></img>
              </div>
              <div className="inbox-text">
                <h3>Inbox</h3>
                <p>3 unread messages</p>
              </div>
              <div
                className="mobile-notification-close-icon"
                onClick={() => {
                  setShowMobileNotificationPopup(false);
                }}
              >
                <img alt="" src={cross}></img>
              </div>
            </button>
          </div>
          <div className="notifications">
            <h3>Notification</h3>
            <InfiniteScroll
              loadMore={handleLoadMoreNotifications}
              hasMore={true}
              initialLoad={false}
              useWindow={false}
              getScrollParent={() => notificationContainerRef.current}
              threshold={5}
            >
              {notifications &&
                notifications.map((item, index) => {
                  const message = item.data.description;
                  let desc = message;
                  if (item.data.description) {
                    const regex = /£(\d+(\.\d+)?)/g;
                    const match = message.match(regex);

                    if (match) {
                      match.forEach((match) => {
                        const number = parseFloat(match.replace(/£/, ""));

                        desc = desc.replace(
                          match,
                          formatCurrency(number * 100)
                        );
                      });
                    }
                  }

                  const formatMessageTime = (createdAt) => {
                    const messageDate = new Date(createdAt);

                    if (!isSameDate(messageDate, new Date())) {
                      return messageDate.toLocaleString("en-US", {
                        day: "numeric",
                        month: "long",
                      });
                    } else {
                      return messageDate.toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      });
                    }
                  };
                  return (
                    item.data.description && (
                      <div
                        className="notification--item"
                        data-id={item.id}
                        data-index={index}
                        key={index}
                        onClick={handleNotificationClick}
                      >
                        <img alt="" src={alert}></img>
                        <h4>{desc}</h4>
                        <p>{formatMessageTime(item.created_at)}</p>
                      </div>
                    )
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default Navbar;
