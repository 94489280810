import { useEffect, useState } from "react";

import beat from "./../images/musical-notes-outline.svg";
import producer from "./../images/user.svg";

function ImgLoader({ className, src, type }) {
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsImgLoaded(true);
    };
  }, [src]);

  return (
    <>
      {isImgLoaded && (
        <img
          src={src}
          alt=""
          className={className}
          style={{ opacity: isImgLoaded ? "1" : "0" }}
        />
      )}

      {!isImgLoaded && (
        <div
          className={className}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#EFEFEF",
          }}
        >
          <img
            src={type === "beat" ? beat : producer}
            style={{ width: "35%" }}
            alt=""
          />
        </div>
      )}
    </>
  );
}

export default ImgLoader;
