import cross from "./../../images/cross.svg";
import Logo from "./../../images/Logo.png";

import Popup from "reactjs-popup";

export default function NavbarCartPopup({
  cart,
  trigger,
  handlePopupOpen,
  handlePopupClose,
  handleCart,
  handleRemoveCartItem,
  handleViewCartButton,
  handleCheckoutButton,
  isAuthenticated,
  formatCurrency,
}) {
  return (
    <>
      <Popup
        className="navbar-popup shopping-popup"
        trigger={trigger}
        onOpen={() => {
          handlePopupOpen("cartPopup");
          handleCart();
        }}
        onClose={() => handlePopupClose("cartPopup")}
        position="bottom right"
      >
        {(close) => (
          <div className="shopping-cart">
            <h2>Cart</h2>
            <div className="cart-items">
              {cart &&
                cart.map((item) => {
                  return (
                    <div
                      className="cart--item"
                      data-id={!isAuthenticated ? item?.item?.id : item?.id}
                      key={!isAuthenticated ? item?.item?.id : item?.id}
                    >
                      {
                        <img
                          alt=""
                          className="beat-logo"
                          src={
                            !isAuthenticated
                              ? item?.item?.artwork_url
                                ? item?.item?.artwork_url
                                : item?.item?.cover_path
                                ? item?.item?.cover_path
                                : Logo
                              : item?.beat
                              ? item?.beat?.artwork_url
                              : item?.song
                              ? item?.song?.cover_path
                              : item?.album
                              ? item?.album?.cover_path
                              : Logo
                          }
                        ></img>
                      }

                      <h3>
                        {!isAuthenticated
                          ? item?.item?.name
                          : item?.beat
                          ? item?.beat?.name
                          : item?.song
                          ? item?.song?.name
                          : item?.album?.name}
                      </h3>

                      <p>
                        {!isAuthenticated
                          ? item?.price
                            ? formatCurrency(item.price)
                            : item?.item?.price === 0
                            ? "Free"
                            : formatCurrency(item.item.price)
                          : item?.price === 0
                          ? "Free"
                          : formatCurrency(item.price)}
                      </p>
                      <button onClick={handleRemoveCartItem}>
                        <img alt="" src={cross} className="cross"></img>
                      </button>
                    </div>
                  );
                })}
            </div>

            <div className="cart-buttons">
              <button
                className="view"
                onClick={() => {
                  close();
                  handleViewCartButton();
                }}
              >
                View Cart
              </button>
              <button
                className="checkout"
                onClick={(event) => {
                  close();
                  handleCheckoutButton();
                }}
              >
                Checkout
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
}
