import "./../stylesheets/beat.css";
import like from "./../../images/beat-like.svg";
import like_filled from "./../../images/like-filled.svg";
import * as Sentry from "@sentry/react";
import share from "./../../images/beat-share.svg";
import comment from "./../../images/beat-comment.svg";
import playlist from "./../../images/beat-playlist.svg";
import cart from "./../../images/cart.svg";
import BeatDescription from "./../beat/beatDescription";
import MusicComments from "./musicComments";
import play from "./../../images/play_button.svg";
import add from "./../../images/add.svg";
import more from "./../../images/more.svg";
import Popup from "reactjs-popup";
import AddToPlaylistPopup from "./../addToPlaylistPopup";
import SharePopup from "./../sharePopup";
import BuyPopup from "./../buyPopup";
import ImgLoader from "./../imgLoader";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigation } from "../../context/navigationContext";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
import { usePlayerContext } from "../../context/playerContext";
import { addToQueue, playNow } from "../../actions/playerActions";
function Music({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const {
    navigate,
    navigateToArtists,
    navigateToComments,
    navigateToAlbum,
    navigateToMusic,
  } = useNavigation();
  const { isAuthenticated, user } = useAuthContext();

  const { formatCurrency } = useCurrencyContext();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [isAddToPlaylistPopupOpen, setIsAddToPlaylistPopupOpen] =
    useState(false);
  const [addPopupBeat, setAddPopupBeat] = useState();

  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

  const [isLiked, setIsLiked] = useState(false);
  const [hoveredPlayButton, setHoveredPlayButton] = useState(null);
  const [description, setDescription] = useState(true);
  const [comments, setComments] = useState(false);
  const [beat, setBeat] = useState({});
  const Id = useParams();
  const handleProducerClick = (event) => {
    const id = event.target.dataset.id;
    navigateToArtists(id);
  };
  const handleAlbumClick = (event) => {
    navigateToAlbum(event);
  };
  useEffect(() => {
    const loadLikes = async () => {
      if (isAuthenticated) {
        const ids = user.liked_beats;
        const id_int = parseInt(Id.id, 10);

        if (ids.includes(id_int)) {
          document.querySelector(".extra-like-image").src = like_filled;
          setIsLiked(true);
        }
      }
    };
    loadLikes();
  }, [Id.id, user, isAuthenticated]);
  useEffect(() => {
    const handleLoad = async () => {
      try {
        const response = await api.get(`songs/${Id.id}`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setBeat(data.data);
        if (window.location.pathname.split("/")[3] === "comments") {
          setComments(true);
          setDescription(false);

          document.querySelector(".active").classList.remove("active");
          document
            .querySelector(".beat-section-comments")
            .classList.add("active");
        } else {
          document.querySelector(".active").classList.remove("active");
          document
            .querySelector(".beat-section-description-button")
            .classList.add("active");
          setComments(false);
          setDescription(true);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [Id.id, navigate, setShowUnauthenticatedPopup]);
  const handleLike = async (event) => {
    if (isAuthenticated) {
      if (isLiked) {
        document.querySelector(".extra-like-image").src = like;
        setIsLiked(false);
        setPopupMessage("Song unliked!");
        setShowMessagePopup(true);
        const quantity = document.querySelector(".like-quantity");
        quantity.innerHTML = quantity.innerHTML - 1;

        try {
          const response = await api.delete(`songs/${Id.id}/unlike`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        try {
          const response = await api.post(`songs/${Id.id}/like`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

          const data = response.data;
          if (data.message === "OK") {
            document.querySelector(".extra-like-image").src = like_filled;
            setIsLiked(true);
            setPopupMessage("Song liked!");
            setShowMessagePopup(true);
            const quantity = document.querySelector(".like-quantity");
            quantity.innerHTML = parseInt(quantity.innerHTML, 10) + 1;
          }
          if (data.error === "You`ve already liked the Song.") {
            document.querySelector(".extra-like-image").src = like_filled;
            const quantity = document.querySelector(".like-quantity");
            quantity.innerHTML = parseInt(quantity.innerHTML, 10) + 1;
            setIsLiked(true);
            setPopupMessage("Song liked!");
            setShowMessagePopup(true);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    } else {
      setPopupMessage("You must be logged in to like an Album!");
      setShowMessagePopup(true);
    }
  };

  const handleCommentClick = () => {
    if (!comments) {
      const id = Id.id;
      navigateToComments("music", id);
    }
  };

  const handleDescription = () => {
    navigateToMusic(Id.id);
  };

  const handleComments = () => {
    if (!comments) {
      const id = Id.id;
      navigateToComments("music", id);
    }
  };

  const { playerState, playerDispatch } = usePlayerContext();

  const handlePlayItem = (item) => {
    playerDispatch(playNow(item));
  };

  const handleAddToQueue = (item) => {
    playerDispatch(addToQueue(item));
  };
  const handleAddToCart = async (id, price) => {
    if (isAuthenticated) {
      const response = await handleCart(id, "song", price);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = beat;
      const response = await handleLocalCart(desiredObject, "song", price);
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setPopupMessage("Added to cart");
        setShowMessagePopup(true);
      } else {
        setPopupMessage("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };
  return (
    <>
      {beat && (
        <section className="top-heading">
          <div className="beat-section-image" data-id={beat.slug}>
            <div className="beats-play_button">
              <div className="beats-play-div">
                <button
                  className="beats-play play__button__beats"
                  onClick={() => {
                    handlePlayItem(beat);
                  }}
                >
                  <img src={play} alt="play"></img>
                </button>
              </div>
              <div className="beats-extras">
                {!beat.is_free && (
                  <div className="beats-extras__placeholder"></div>
                )}
                <button onClick={() => handleAddToQueue(beat)}>
                  <img alt="" src={add} data-id={beat.slug}></img>
                </button>
                <Popup
                  className="beat-rows-popup"
                  trigger={
                    <button>
                      <img alt="" src={more}></img>
                    </button>
                  }
                  position={["bottom left", "bottom center"]}
                  keepTooltipInside={"body"}
                  arrow={false}
                  onOpen={(event) => {
                    setHoveredPlayButton(
                      event.target.closest(".beats-play_button")
                    );
                    event.target
                      .closest(".beats-play_button")
                      .classList.add("beats-play_button-hovered");
                  }}
                  onClose={() => {
                    hoveredPlayButton.classList.remove(
                      "beats-play_button-hovered"
                    );
                  }}
                >
                  {(close) => (
                    <>
                      <div
                        className="popup-option"
                        onClick={(event) => {
                          close();
                          handlePlayItem(beat);
                        }}
                        data-id={beat.slug}
                      >
                        Play
                      </div>
                      <div
                        className="popup-option"
                        onClick={(event) => {
                          close();
                          handleAddToCart(beat.slug);
                        }}
                        data-id={beat.slug}
                      >
                        Add To Cart
                      </div>
                      {beat.artist && (
                        <div
                          className="popup-option"
                          onClick={(event) => {
                            close();
                            handleProducerClick(event);
                          }}
                          data-id={beat?.artist?.slug}
                        >
                          View Artist
                        </div>
                      )}
                      <div
                        className="popup-option"
                        onClick={() => {
                          close();
                          setAddPopupBeat(beat);
                          setIsAddToPlaylistPopupOpen(true);
                        }}
                      >
                        Add to Playlist
                      </div>
                      <div
                        className="popup-option"
                        onClick={(event) => {
                          close();
                        }}
                      >
                        Comment
                      </div>
                      <div
                        className="popup-option"
                        onClick={() => {
                          close();
                          setIsSharePopupOpen(true);
                        }}
                      >
                        Share
                      </div>
                    </>
                  )}
                </Popup>
              </div>
            </div>

            <ImgLoader
              src={beat.cover_path}
              className="beat-section-artwork"
              type="beat"
            />
          </div>
          <div className="beat-section-description">
            <h2 className="beat-section-name">{beat.name}</h2>
            {beat.artist && (
              <h3
                className="beat-section-artist"
                onClick={handleProducerClick}
                data-id={beat?.artist?.slug}
              >
                {beat.artist.name}
              </h3>
            )}
            {beat.album && (
              <p
                className="beat-section-album"
                onClick={() => handleAlbumClick(beat?.album.slug)}
                data-id={beat?.album?.slug}
              >
                Album: <span>{beat.album.name}</span>
              </p>
            )}
            {beat.created_at && (
              <h3 className="beat-section-date">
                Posted: {beat.created_at.split("T")[0]}
              </h3>
            )}
            <div className="beat-section-extras">
              <div className="extras-like">
                <div>
                  <h3 className="like-quantity">{beat.likes}</h3>
                  <button className="like-button" onClick={handleLike}>
                    <img alt="" src={like} className="extra-like-image"></img>
                  </button>
                </div>
                <h4 className="extra-like-name">Likes</h4>
              </div>
              <div className="extras-share">
                <div>
                  <h3 className="share-quantity">‎</h3>
                  <button
                    className="share-button"
                    onClick={() => {
                      setIsSharePopupOpen(true);
                    }}
                  >
                    <img alt="" src={share}></img>
                  </button>
                </div>
                <h4 className="extra-share-name">Share</h4>
              </div>
              <div className="extras-comment">
                <div>
                  <h3 className="comment-quantity">{beat.comments_count}</h3>
                  <button
                    className="comment-button"
                    onClick={handleCommentClick}
                  >
                    <img alt="" src={comment}></img>
                  </button>
                </div>
                <h4 className="extra-comment-name">Comments</h4>
              </div>
              <div className="extras-playlist">
                <div>
                  <button
                    className="playlist-button"
                    onClick={() => {
                      setAddPopupBeat(beat);
                      setIsAddToPlaylistPopupOpen(true);
                    }}
                  >
                    <img alt="" src={playlist}></img>
                  </button>
                </div>
                <h4 className="extra-playlist-name">Add to Playlist</h4>
              </div>
            </div>
            <div className="beat-section-buy-beat">
              {beat && (
                <button
                  className="beat-section-buy-button"
                  onClick={() => {
                    setIsBuyPopupOpen(true);
                  }}
                >
                  <img alt="" src={cart}></img>
                  {beat.price === 0 ? "Free" : formatCurrency(beat.price)}
                </button>
              )}
            </div>
            <div className="beat-section-options">
              <button
                className="beat-section-description-button active"
                onClick={handleDescription}
              >
                Description
              </button>
              <button
                className="beat-section-comments"
                onClick={handleComments}
              >
                Comments
              </button>
            </div>
          </div>
        </section>
      )}

      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>

      {description && beat && <BeatDescription beat={beat} />}
      {comments && beat && (
        <MusicComments
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}

      <BuyPopup
        beat={beat}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        handleAddToCart={handleAddToCart}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />

      {isAddToPlaylistPopupOpen && (
        <AddToPlaylistPopup
          isAddToPlaylistPopupOpen={isAddToPlaylistPopupOpen}
          setIsAddToPlaylistPopupOpen={setIsAddToPlaylistPopupOpen}
          beat={addPopupBeat}
          setPopupMessage={setPopupMessage}
          setShowMessagePopup={setShowMessagePopup}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          type={"song"}
        />
      )}

      {isSharePopupOpen && (
        <SharePopup
          isSharePopupOpen={isSharePopupOpen}
          setIsSharePopupOpen={setIsSharePopupOpen}
          beat={beat}
          type={"song"}
        />
      )}
    </>
  );
}

export default Music;
