import "./../stylesheets/wallet.css";
import { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import cross from "./../../images/cross.svg";
import paypal from "./../../images/paypal.png";
import warning from "./../../images/warning.svg";
import paystackLogo from "./../../images/paystack-2.svg";
import Popup from "reactjs-popup";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function Wallet({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { isAuthenticated, wallet } = useAuthContext();
  const { selectedCurrency, formatCurrency } = useCurrencyContext();
  const [transactions, setTransactions] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [depositDisabled, setDepositDisabled] = useState(false);

  const [balance, setBalance] = useState("");
  const [selectedDepositMethod, setSelectedDepositMethod] = useState(null);
  const [saveChanges, setSaveChanges] = useState(false);

  const handleChangeDepositMethod = (method) => {
    setSelectedDepositMethod(method);
  };
  const [depositInput, setDepositInput] = useState(
    selectedCurrency === "GBP" ? "£" : "N"
  );

  useEffect(() => {
    if (selectedDepositMethod === "Paystack") {
      setDepositInput("N");
    }
  }, [selectedDepositMethod]);

  function formatInputCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `${selectedDepositMethod === "Paystack" ? "N" : "£"}${value}`;

    return value;
  }

  const handleDepositInputChange = (event) => {
    setServerDepositErrorMessage(null);

    const amount = formatInputCurrency(event.target.value);
    setDepositInput(amount);

    if (
      event.target.value.length <= 1 ||
      (selectedDepositMethod === "Paystack" && amount.slice(1) < 5000)
    ) {
      document.querySelector(".deposit-error").classList.remove("hidden");
      setDepositDisabled(true);
    } else {
      document.querySelector(".deposit-error").classList.add("hidden");
      setDepositDisabled(false);
    }
  };

  const [serverDepositErrorMessage, setServerDepositErrorMessage] =
    useState(null);

  const handlePaypal = async () => {
    if (!depositInput | (depositInput.length <= 1)) {
      document.querySelector(".bid-error").classList.remove("hidden");
      return;
    }

    if (isAuthenticated) {
      setSaveChanges(true);
      const body = {
        payment_method: "paypal",
        amount: depositInput.slice(1) * 100,
      };

      try {
        const response = await api.post(`wallet/deposit`, body);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setSaveChanges(false);

        if (response.status === 422) {
          const data = await response.json();

          setServerDepositErrorMessage(data.message);
          document.querySelector(".deposit-error").classList.remove("hidden");

          return;
        }

        if (data.vendor_order_id) {
          localStorage.setItem("deposit_vendor_id", data.vendor_order_id);
          window.location.href = data.link;
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const handlePaystack = async () => {
    if (!depositInput || depositInput.length <= 1) {
      document.querySelector(".bid-error").classList.remove("hidden");
    }

    if (
      !depositInput ||
      depositInput.length <= 1 ||
      depositInput.slice(1) < 50
    ) {
      return;
    }

    if (isAuthenticated) {
      setSaveChanges(true);

      const body = {
        payment_method: "paystack",
        amount: parseInt(depositInput.slice(1)),
      };

      try {
        const response = await api.post(`wallet/deposit`, body);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        setSaveChanges(false);
        if (response.status === 422) {
        }

        if (data.vendor_order_id) {
          localStorage.setItem("deposit_vendor_id", data.vendor_order_id);
          window.location.href = data.link;
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const handleWithdraw = () => {
    navigate("/accounts");
  };
  const handleTypeTransaction = async (event) => {
    if (event.target.innerHTML === "Show all") {
      document
        .querySelector(".selected-transaction-type")
        .classList.remove("selected-transaction-type");
      document
        .querySelector(".wallet-transactions-all")
        .classList.add("selected-transaction-type");

      try {
        const response = await api.get(`transactions`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setTransactions(data.data);
        setNextPage(data.links.next?.split("api/")[1]);
      } catch (error) {
        Sentry.captureException(error);
      }
    } else if (event.target.innerHTML === "Last 30 days") {
      document
        .querySelector(".selected-transaction-type")
        .classList.remove("selected-transaction-type");
      document
        .querySelector(".wallet-transactions-30-days")
        .classList.add("selected-transaction-type");

      try {
        const response = await api.get(`transactions?filter=30d`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setTransactions(data.data);
        setNextPage(data.links.next?.split("api/")[1]);
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      document
        .querySelector(".selected-transaction-type")
        .classList.remove("selected-transaction-type");
      document
        .querySelector(".wallet-transactions-1-year")
        .classList.add("selected-transaction-type");

      try {
        const response = await api.get(`transactions?filter=lastyear`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setTransactions(data.data);
        setNextPage(data.links.next?.split("api/")[1]);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };
  useEffect(() => {
    const handleLoad = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }
      setBalance(wallet);
      try {
        const response = await api.get(`transactions?filter=30d`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setTransactions(data.data);
        setNextPage(data.links.next?.split("api/")[1]);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [navigate, setShowUnauthenticatedPopup, wallet, isAuthenticated]);
  useEffect(() => {
    const getNextPage = async () => {
      if (nextPage) {
        const response = await api.get(nextPage);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setTransactions([...transactions, ...data.data]);
        setNextPage(data.links.next?.split("api/")[1]);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [transactions, nextPage, navigate]);
  return (
    <section className="wallet-section">
      <div className="wallet-heading">
        <h3 className="wallet-heading-tag">My Wallet</h3>
      </div>
      <div className="wallet-balance">
        <div className="wallet-balance-heading">
          <div className="wallet-balance-heading-div">
            <p className="wallet-balance-name">My Balance</p>
            {balance !== "" && (
              <h3 className="wallet-balance-price">
                {formatCurrency(balance)}
              </h3>
            )}
          </div>
          <div className="wallet-balance-withdraw">
            <button
              className="wallet-balance-withdraw-button"
              onClick={handleWithdraw}
            >
              Withdraw Funds
            </button>
            <Popup
              className="create-playlist-popup wallet-popup"
              trigger={
                <button className="wallet-balance-withdraw-button">
                  Deposit
                </button>
              }
              modal
              onClose={() => {
                setSelectedDepositMethod(null);
              }}
            >
              {(close) => (
                <>
                  <div className="wallet-popup-heading">
                    <h3>Deposit Method</h3>
                  </div>
                  <img
                    className="counter-offer-popup__close"
                    src={cross}
                    alt=""
                    onClick={close}
                  />

                  <div className="wallet-popup-container">
                    <button
                      style={
                        selectedDepositMethod === "Paypal"
                          ? { border: "1px solid #FE2B0D" }
                          : {}
                      }
                      onClick={() => {
                        handleChangeDepositMethod("Paypal");
                      }}
                    >
                      Paypal
                      <img src={paypal} alt="" />
                    </button>

                    <button
                      style={
                        selectedDepositMethod === "Paystack"
                          ? { border: "1px solid #FE2B0D" }
                          : {}
                      }
                      onClick={() => {
                        handleChangeDepositMethod("Paystack");
                      }}
                    >
                      Paystack
                      <img src={paystackLogo} alt="" />
                    </button>
                    {(selectedDepositMethod === "Paypal" ||
                      selectedDepositMethod === "Paystack") && (
                      <>
                        <label>
                          Deposit Amount
                          <Popup
                            className="upload-error-popup"
                            trigger={
                              <img
                                src={warning}
                                alt=""
                                className="deposit-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            {serverDepositErrorMessage && (
                              <>{serverDepositErrorMessage}</>
                            )}
                            {!serverDepositErrorMessage &&
                            selectedDepositMethod === "Paystack" &&
                            depositInput.slice(1) < 50
                              ? "Minimum deposit amount is K50"
                              : depositInput.length < 2
                              ? "Deposit amount cannot be empty!"
                              : null}
                          </Popup>
                        </label>
                        <input
                          type="text"
                          placeholder={`${
                            selectedDepositMethod === "Paystack" ? "K" : "£"
                          }20`}
                          value={depositInput}
                          onChange={handleDepositInputChange}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              if (selectedDepositMethod === "Paystack") {
                                handlePaystack();
                              } else if (selectedDepositMethod === "paypal") {
                                handlePaypal();
                              }
                            }
                          }}
                        />
                        <div className="button-container">
                          <button
                            disabled={depositDisabled}
                            className="button-container__submit deposit__button"
                            onClick={
                              selectedDepositMethod === "Paystack"
                                ? handlePaystack
                                : handlePaypal
                            }
                          >
                            {saveChanges && (
                              <Bars
                                height="40"
                                width="40"
                                color="#fff"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass="loading-bars"
                                visible={saveChanges}
                              />
                            )}
                            {!saveChanges && "Deposit"}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </Popup>
          </div>
        </div>
        <div className="wallet-transactions">
          <div className="wallet-transactions-top">
            <p>Recent transactions:</p>
            <div className="wallet-transactions-buttons">
              <button
                className="wallet-transactions-30-days selected-transaction-type"
                onClick={handleTypeTransaction}
              >
                Last 30 days
              </button>
              <button
                className="wallet-transactions-1-year"
                onClick={handleTypeTransaction}
              >
                Last one year
              </button>
              <button
                className="wallet-transactions-all"
                onClick={handleTypeTransaction}
              >
                Show all
              </button>
            </div>
          </div>
          <div className="wallet-transactions-history">
            <div className="wallet-transactions-history-head">
              <h3 className="item-date">Date</h3>
              <h3 className="item-name">Contract Name</h3>
              <h3 className="item-amount-type">Type</h3>
              <h3 className="item-amount">Amount</h3>
              <h3 className="item-status">Status</h3>
            </div>
            <div className="wallet-transactions-history-items">
              {transactions &&
                transactions.map((item) => {
                  const type =
                    item.type === "BEAT_SOLD"
                      ? "BEAT SALE"
                      : item.type === "COMMISSION_WITHDRAWAL"
                      ? "COMMISSION PAID"
                      : item.type === "COMMISSION_DEPOSIT"
                      ? "COMMISSION RECEIVED"
                      : item.type;
                  return (
                    <div
                      className="wallet-transactions-history-item"
                      key={item.id}
                    >
                      <h3 className="item-date">
                        {item.created_at.split("T")[0]}
                      </h3>
                      <h3 className="item-name">{item.user_name}</h3>
                      <h3
                        className="item-amount-type"
                        style={
                          item.type === "DEPOSIT" ||
                          item.type === "BEAT_SOLD" ||
                          item.type === "COMMISSION_DEPOSIT"
                            ? { color: "#2C7919" }
                            : item.type === "WITHDRAW" ||
                              item.type === "COMMISSION_WITHDRAWAL"
                            ? { color: "#D35644" }
                            : { color: "black" }
                        }
                      >
                        {type}
                      </h3>
                      <h3 className="item-amount">{formatCurrency(item.amount)}</h3>
                      <h3
                        className="item-status"
                        style={
                          item.status === "COMPLETED"
                            ? { color: "#2C7919" }
                            : item.status === "DECLINED"
                            ? { color: "#D35644" }
                            : item.status === "PENDING"
                            ? { color: "#FFC107" }
                            : { color: "black" }
                        }
                      >{`${item.status}`}</h3>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Wallet;
