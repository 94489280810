import "./../stylesheets/editProfile.css";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import Warning from "./../../images/warning.svg";
import * as Sentry from "@sentry/react";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import handleResponseStatus from "../../utils/handleResponseStatus";
function ChangePassword({ handlePrevious }) {
  const { navigate } = useNavigation();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function getPasswordStrength(password) {
    const containsUpperCase = /[A-Z]/;
    const containsLowerCase = /[a-z]/;
    const containsNumber = /[0-9]/;
    const containsSpecialChar = /[@$!%*?&]/;
    let passwordStrength = 0;

    if (containsUpperCase.test(password)) passwordStrength++;
    if (containsLowerCase.test(password)) passwordStrength++;
    if (containsNumber.test(password)) passwordStrength++;
    if (containsSpecialChar.test(password)) passwordStrength++;

    return passwordStrength;
  }

  function handleOldPasswordInput(event) {
    setOldPassword(event.target.value);
    document.querySelector(".old-password-error").classList.add("hidden");
  }

  function handleNewPasswordInput(event) {
    setNewPassword(event.target.value);
    if (
      getPasswordStrength(event.target.value) < 3 ||
      event.target.value.length < 8
    ) {
      document.querySelector(".password-error").classList.remove("hidden");
    } else {
      document.querySelector(".password-error").classList.add("hidden");
    }

    if (event.target.value === confirmPassword)
      document.querySelector(".confirm-password-error").classList.add("hidden");
    else
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
  }

  function handleConfirmPasswordInput(event) {
    setConfirmPassword(event.target.value);

    if (newPassword === event.target.value)
      document.querySelector(".confirm-password-error").classList.add("hidden");
    else
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
  }

  function checkValues() {
    let valid = true;

    if (getPasswordStrength(newPassword) < 3 || newPassword.length < 8) {
      document.querySelector(".password-error").classList.remove("hidden");
      valid = false;
    }
    if (newPassword !== confirmPassword) {
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  const handleSubmit = async () => {
    if (!checkValues()) return;
    try {
      const response = await api.post(`update-password`, {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      });

      handleResponseStatus(response, navigate);
      if (response.status === 422) {
        document
          .querySelector(".old-password-error")
          .classList.remove("hidden");
        return;
      }
      navigate("/user");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <section className="password-section">
      <section className="edit-profile">
        <div className="input-div">
          <label>Input Old Password</label>
          <div className="edit-input-container">
            <input
              type="password"
              className="old-password-input"
              onChange={(event) => handleOldPasswordInput(event)}
            ></input>
            <Popup
              className="edit-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="old-password-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Invalid Password!
            </Popup>
          </div>
        </div>
        <div className="input-div">
          <label>Enter New Password</label>
          <div className="edit-input-container">
            <input
              className="new-password-input"
              type="password"
              onChange={(event) => handleNewPasswordInput(event)}
            ></input>
            <Popup
              className="edit-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="password-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Password too short/weak!
            </Popup>
          </div>
        </div>
        <div className="input-div">
          <label>Confirm New Password</label>
          <div className="edit-input-container">
            <input
              className="confirm-password-input"
              type="password"
              onChange={(event) => handleConfirmPasswordInput(event)}
            ></input>

            <Popup
              className="edit-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="confirm-password-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Passwords do not match!
            </Popup>
          </div>
        </div>
      </section>
      <div className="edit-profile-button-container">
        <button className="previous" onClick={handlePrevious}>
          Previous
        </button>
        <button className="save-continue" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </section>
  );
}

export default ChangePassword;
