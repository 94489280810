import "./../stylesheets/player.css";
import cart from "./../../images/cart.svg";
import more from "./../../images/more-vertical.svg";
import downArrow from "./../../images/caret-down.svg";
import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import "react-range-slider-input/dist/style.css";
import BuyPopup from "./../buyPopup";
import AddToPlaylistPopup from "./../addToPlaylistPopup";
import ReactPlayer from "react-player";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import SkipPreviousRoundedIcon from "@mui/icons-material/SkipPreviousRounded";
import { useCurrencyContext } from "../../context/currencyContext";
import { Slider } from "@mui/material";
import QueueMusicRoundedIcon from "@mui/icons-material/QueueMusicRounded";
import { styled } from "@mui/system";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import VolumeMuteRoundedIcon from "@mui/icons-material/VolumeMuteRounded";
import VolumeDownRoundedIcon from "@mui/icons-material/VolumeDownRounded";
import VolumeUpRoundedIcon from "@mui/icons-material/VolumeUpRounded";
import { usePlayerContext } from "./../../context/playerContext";
import { Oval } from "react-loader-spinner";
import logdna from "@logdna/browser";
import {
  play,
  pause,
  setCurrentTime,
  setTotalTime,
  setVolume,
  clearInfoMessage,
  playSpecificQueueItem,
  playNext,
  playPrev,
  setLiked,
  showInfoMessage,
  toggleMobileState,
} from "./../../actions/playerActions";
import { useAuthContext } from "../../context/authContext";
import api from "../../utils/api";
import { useNavigation } from "../../context/navigationContext";
import { PlayerMobileQueue } from "./playerMobileQueue.";
import handleResponseStatus from "../../utils/handleResponseStatus";

function PlayerNew({
  handleLocalCart,
  handleCart,
  setUpdateCartCounter,
  updateCartCounter,
}) {
  const playerRef = useRef(null);

  const { playerState, playerDispatch } = usePlayerContext();

  const {
    currentItemIndex,
    currentTime,
    totalTime,
    isPlaying,
    queue,
    infoPopupMessage,
    volume,
    isLiked,
    mobileExpanded,
    starting,
  } = playerState;

  const [currentItem, setCurrentItem] = useState(null);
  const [playUrl, setPlayUrl] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const { selectedCurrency, rates, formatCurrency } = useCurrencyContext();

  const [mobile, setMobile] = useState(false);

  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [showInfoMessagePopup, setShowInfoMessagePopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState(false);
  const [isAddToPlaylistPopupOpen, setIsAddToPlaylistPopupOpen] =
    useState(false);

  //   const [mobilePlayerState, setMobilePlayerState] = useState("collapsed");
  const [showMobileQueuePopup, setShowMobileQueuePopup] = useState(false);
  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);

  const [playlistPopupMessage, setPlaylistPopupMessage] = useState("");
  const [showPlaylistMessagePopup, setShowPlaylistMessagePopup] =
    useState(false);

  useEffect(() => {
    setCurrentItem(queue[currentItemIndex]);
  }, [currentItemIndex, queue]);

  useEffect(() => {
    function getDeviceType() {
      const userAgent = navigator.userAgent;

      if (/iPad/.test(userAgent)) {
        return "iPad";
      } else if (/Mobile/.test(userAgent)) {
        return "Mobile";
      } else {
        return "Desktop";
      }
    }

    if (getDeviceType() === "Mobile") {
      setMobile(true);
    }
  }, []);

  const handlePlay = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (!isPlaying) {
      playerDispatch(play());
    }
  };

  const handlePause = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (isPlaying) {
      playerDispatch(pause());
    }
  };

  const onProgress = (event) => {
    if (!isPlaying) {
      return;
    }

    playerDispatch(setCurrentTime(event.playedSeconds * 1000));
  };

  useEffect(() => {
    if (infoPopupMessage) {
      setShowInfoMessagePopup(true);

      setTimeout(() => {
        playerDispatch(clearInfoMessage());
        setShowInfoMessagePopup(false);
      }, 1500);
    }
  }, [infoPopupMessage, playerDispatch]);

  const {
    user,
    purchases,
    isLoading: isAuthLoading,
    likeToggle,
    isAuthenticated,
  } = useAuthContext();

  const onReady = () => {
    if (isAuthLoading) {
      return;
    }

    setIsLoading(false);
  };
  useEffect(() => {
    const getCurrentItemType = () => {
      let type;

      if (currentItem?.play_url) {
        type = "beats";
      } else {
        type = "songs";
      }

      return type;
    };

    const getUrl = async () => {
      const type = getCurrentItemType();

      if (!user || !purchases) {
        return currentItem?.play_url || currentItem?.preview_path;
      }

      let purchased = [];

      if (type === "beats") {
        purchased = purchases.beats;
      } else {
        purchased = [...purchases.songs];

        purchases.albums.forEach((album) => {
          album.tracks.forEach((track) => {
            purchased.push(track);
          });
        });
      }

      for (let i = 0; i < purchased.length; i++) {
        if (purchased[i].slug === currentItem?.slug) {
          const response = await api.get(`${type}/${currentItem?.slug}/play`);
          const url = response.data;
          return url;
        }
      }

      return currentItem?.play_url || currentItem?.preview_path;
    };

    const setUrl = async () => {
      const purchaseUrl = await getUrl();
      setPlayUrl(purchaseUrl);
    };

    if (currentItem) {
      setUrl();
    }
  }, [currentItem, user, purchases]);

  const onError = (e) => {
    if (queue[0].hidden) {
      return;
    }

    logdna.error(`Player Error: ${e}`, {}, "error");
    // console.error(`Player Error: ${e}`);
    playerDispatch(showInfoMessage("Error playing item!"));
    if (queue.length > 1) {
      playerDispatch(playNext());
    } else {
      playerDispatch(pause());
    }
  };

  const handleVolumeChange = (event, newValue) => {
    playerDispatch(setVolume(newValue ? newValue : 0));
  };

  const handleVolumeClick = () => {
    if (volume === 0) {
      handleVolumeChange(null, 50);
    } else {
      handleVolumeChange(null, 0);
    }
  };

  const handleDuration = (duration) => {
    playerDispatch(setTotalTime(duration * 1000));
  };

  //   let interval = null;

  const handleSeekChange = (event, newValue) => {
    event.stopPropagation();

    playerDispatch(setCurrentTime((newValue / 100) * totalTime));
    playerRef.current.seekTo(newValue / 100, "fraction");
  };

  const onBuffer = () => {
    setIsLoading(true);
  };
  const onBufferEnd = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (currentTime === 0 && playerRef.current) {
      playerRef.current.seekTo(0);
    }
  }, [currentTime]);

  const getCurrentItemType = () => {
    let type;

    if (currentItem?.play_url) {
      type = "beats";
    } else {
      type = "songs";
    }

    return type;
  };

  const handlePlaySpecificQueueItem = (item) => {
    if (currentItem.slug === item.slug) {
      return;
    }

    playerDispatch(playSpecificQueueItem(item));
  };

  const {
    navigate,
    navigateToBeat,
    navigateToMusic,
    navigateToProducer,
    navigateToArtists,
  } = useNavigation();

  const handleItemClick = () => {
    if (getCurrentItemType() === "beats") {
      navigateToBeat(currentItem?.slug);
    } else {
      navigateToMusic(currentItem?.slug);
    }
  };

  const handleArtistClick = () => {
    if (getCurrentItemType() === "beats") {
      navigateToProducer(currentItem?.producer.slug);
    } else {
      navigateToArtists(currentItem?.artist?.slug);
    }
  };

  const handleNextItem = (e) => {
    if (e) {
      e.stopPropagation();
    }

    playerDispatch(pause());
    playerDispatch(playNext());
    playerRef.current.seekTo(0);
    playerDispatch(play());
  };

  const handlePrevItem = (e) => {
    if (e) {
      e.stopPropagation();
    }

    playerDispatch(pause());
    playerRef.current.seekTo(0);
    playerDispatch(playPrev());
  };

  const checkIsLiked = () => {
    if (!user) {
      return false;
    }

    const type = getCurrentItemType();
    const likedItems = user[`liked_${type}`];
    if (likedItems.includes(currentItem?.id)) {
      return true;
    }

    return false;
  };

  const handleLikeToggle = async (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (!user) {
      navigate("/login");
      return;
    }

    await likeToggle(
      currentItem.slug,
      getCurrentItemType(),
      checkIsLiked() ? "unlike" : "like",
      currentItem.id
    );
  };

  useEffect(() => {
    const checkIsLiked = () => {
      if (!user) {
        return false;
      }

      const type = getCurrentItemType();
      const likedItems = user[`liked_${type}`];
      if (likedItems.includes(currentItem?.id)) {
        return true;
      }

      return false;
    };

    const getCurrentItemType = () => {
      let type;
      if (currentItem?.play_url) {
        type = "beats";
      } else {
        type = "songs";
      }

      return type;
    };

    if (currentItem) {
      playerDispatch(setLiked(checkIsLiked()));
    }
  }, [currentItem, user, playerDispatch]);

  const handleAddToCart = async (id, price) => {
    if (isAuthenticated) {
      const response = await handleCart(
        id,
        currentItem?.artwork_url ? "beat" : "song",
        price
      );
      handleResponseStatus(response, navigate);
      if (response?.error) {
        setMessagePopup(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setMessagePopup("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = currentItem;
      const response = await handleLocalCart(
        desiredObject,
        currentItem?.artwork_url ? "beat" : "song",
        price
      );
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setMessagePopup("Added to cart");
        setShowMessagePopup(true);
      } else {
        setMessagePopup("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };

  const toggleMobileExpand = (e) => {
    e.stopPropagation();

    const currentState = isPlaying;
    if (currentState) {
      playerDispatch(pause());
    }

    playerDispatch(toggleMobileState());

    if (currentState) {
      playerDispatch(play());
    }
  };

  const CustomSlider = styled(Slider)({
    color: "#fe2b0d", // Custom color
    marginLeft: "1rem",
    "& .MuiSlider-thumb": {
      width: 8,
      height: 8,
      // transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
      "&::before": {
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
      },
      "&:hover, &.Mui-focusVisible": {
        boxShadow: `0px 0px 0px 4px ${"rgb(0 0 0 / 16%)"}`,
      },
      "&.Mui-active": {
        width: 10,
        height: 10,
      },
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
  });

  const VolumeSlider = styled(Slider)({
    color: "#fe2b0d", // Custom color
    height: 150,
    width: 8,
    // "& .MuiSlider-track": {
    //   border: "none",
    // },
    // "& .MuiSlider-thumb": {
    //   display: "none",
    // },
    // "& .MuiSlider-rail": {
    //   opacity: 0.5,
    //   backgroundColor: "#bfbfbf",
    // },
    // "& .MuiSlider-vertical": {
    //   "& .MuiSlider-thumb": {
    //     display: "none",
    //   },
    // },
    // '& input[type="range"]': {
    //   WebkitAppearance: "slider-vertical",
    // },
  });

  const CustomMobileSlider = styled(Slider)({
    color: "#fe2b0d",
    margin: "0",
    transform: "translate(-1%, -5px)",
    padding: "0 !important",
    "& .MuiSlider-thumb": {
      width: 8,
      height: 8,
      zIndex: 99999,
      "&::before": {
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
        zIndex: 99999,
      },
      "&.Mui-active": {
        width: 10,
        height: 10,
        zIndex: 99999,
      },
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "rgba(0,0,0,0)",
    },
  });

  const CustomMobileSliderExpanded = styled(Slider)({
    color: "#fe2b0d",
    margin: "0",
    marginBottom: "3%",
    height: "10px",
    // transform: "translate(-1%, -5px)",
    padding: "0 !important",
    "& .MuiSlider-thumb": {
      width: 8,
      height: 8,
      zIndex: 99999,
      "&::before": {
        boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
        zIndex: 99999,
      },
      "&.Mui-active": {
        width: 10,
        height: 20,
        zIndex: 99999,
      },
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
  });

  //   if (currentItem === null) {
  //     console.log("lol");
  //     return null;
  //   }

  const playerVisible = currentItem && !starting;

  return (
    <>
      {!mobile && (
        <section
          className="player"
          style={{ display: playerVisible ? "initial" : "none" }}
        >
          <div className="player-div" data-id={currentItem?.slug}>
            <div className="player-image">
              <img
                alt=""
                src={
                  currentItem?.artwork_url
                    ? currentItem?.artwork_url
                    : currentItem?.cover_path
                }
                className="player-image-tag"
              ></img>
            </div>
            <div className="beat-detail">
              <div className="beat-detail-wrapper">
                <h3 className="beat-detail-name" onClick={handleItemClick}>
                  {currentItem?.name}
                </h3>
              </div>
              {currentItem?.producer && (
                <div className="beat-detail-wrapper">
                  <p className="beat-detail-artist" onClick={handleArtistClick}>
                    {currentItem?.producer === undefined
                      ? "Not Available"
                      : currentItem?.producer.name}
                  </p>
                </div>
              )}
              {currentItem?.artist && (
                <div className="beat-detail-wrapper">
                  <p className="beat-detail-artist" onClick={handleArtistClick}>
                    {currentItem?.artist === undefined
                      ? "Not Available"
                      : currentItem?.artist.name}
                  </p>
                </div>
              )}
            </div>
            <div className="player-controls">
              <button className="backward" onClick={handlePrevItem}>
                <SkipPreviousRoundedIcon sx={{ color: "#000000" }} />
              </button>
              {isLoading ? (
                <Oval
                  height="40"
                  width="40"
                  color="black"
                  secondaryColor="rgba(0,0,0,0)"
                  ariaLabel="tail-spin-loading"
                  visible={true}
                  wrapperClass="player-loading"
                  className="player-loading"
                />
              ) : !isPlaying ? (
                <button className="play-player" onClick={handlePlay}>
                  <PlayArrowRoundedIcon sx={{ color: "#000000" }} />
                </button>
              ) : (
                <button className="pause-player" onClick={handlePause}>
                  <PauseRoundedIcon sx={{ color: "#000000" }} />
                </button>
              )}
              <button className="forward" onClick={handleNextItem}>
                <SkipNextRoundedIcon sx={{ color: "#000000" }} />
              </button>
            </div>
            {playUrl && (
              <ReactPlayer
                ref={playerRef}
                url={playUrl}
                playing={isPlaying}
                onReady={onReady}
                onProgress={onProgress}
                progressInterval={200}
                onDuration={handleDuration}
                width={0}
                height={0}
                onError={onError}
                volume={!volume ? 0 : volume / 100}
                onEnded={handleNextItem}
                onBuffer={onBuffer}
                onBufferEnd={onBufferEnd}
              />
            )}
            <div className="player-duration">
              <p className="time-start">
                {new Date(currentTime).toISOString().substr(14, 5)}
              </p>
              <CustomSlider
                value={(currentTime / totalTime) * 100}
                onChange={handleSeekChange}
                min={0}
                max={100}
                step={0.1}
                className="player-duration-bar"
              />
              <p className="total-time">
                {new Date(totalTime).toISOString().substr(14, 5)}
              </p>
            </div>
            <div className="volume-control">
              <Popup
                className="player-volume-popup"
                trigger={
                  <button className="volume" onClick={handleVolumeClick}>
                    {volume > 50 && (
                      <VolumeUpRoundedIcon sx={{ color: "#000000" }} />
                    )}
                    {volume > 0 && volume <= 50 && (
                      <VolumeDownRoundedIcon sx={{ color: "#000000" }} />
                    )}
                    {volume === 0 && (
                      <VolumeMuteRoundedIcon sx={{ color: "#000000" }} />
                    )}
                  </button>
                }
                on="hover"
                arrow={false}
                position="top center"
                closeOnDocumentClick={false}
              >
                <VolumeSlider
                  onChange={handleVolumeChange}
                  value={volume}
                  orientation="vertical"
                />
              </Popup>
            </div>
            <button
              className="buy-beat-button"
              onClick={() => {
                setIsBuyPopupOpen(true);
              }}
            >
              <img alt="" src={cart} />
              {currentItem?.price === 0
                ? "Free"
                : formatCurrency(currentItem?.price)}
            </button>
            <div className="queue-handler">
              <div className="like-beat-player">
                <Popup
                  className="player-like-popup"
                  trigger={
                    <button>
                      {isLiked ? (
                        <FavoriteRoundedIcon
                          sx={{ color: "#fe2b0d" }}
                          onClick={handleLikeToggle}
                        />
                      ) : (
                        <FavoriteBorderRoundedIcon
                          sx={{ color: "#000000" }}
                          onClick={handleLikeToggle}
                        />
                      )}
                    </button>
                  }
                  on="hover"
                  arrow={false}
                  position="top center"
                >
                  {isLiked
                    ? getCurrentItemType === "songs"
                      ? "Unlike Song"
                      : "Unlike Beat"
                    : getCurrentItemType === "songs"
                    ? "Like Song"
                    : "Like Beat"}
                </Popup>
              </div>

              <div className="add-beat-player">
                <Popup
                  className="player-like-popup"
                  trigger={
                    <button
                      onClick={() => {
                        if (!user) {
                          navigate("/login");
                          return;
                        }
                        setIsAddToPlaylistPopupOpen(true);
                      }}
                    >
                      <AddRoundedIcon sx={{ color: "#000000" }} />
                    </button>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Add To Playlist
                </Popup>
              </div>

              <div className="queue-beat-player">
                <Popup
                  className="player-queue-popup"
                  trigger={
                    <button>
                      <QueueMusicRoundedIcon sx={{ color: "#000000" }} />
                    </button>
                  }
                  // onOpen={handleOpenQueue}
                  position="top left"
                  arrow={false}
                >
                  {(close) => (
                    <>
                      <div className="queue-popup-header">Playing Queue</div>
                      {queue?.map((queueItem, index) => {
                        if (!queueItem || !queueItem.slug) {
                          return null;
                        }

                        if (!currentItem || !currentItem.slug) {
                          return null;
                        }

                        return (
                          <div
                            className={`queue-popup-row ${
                              queueItem.slug === currentItem.slug
                                ? "queue-popup-row-playing"
                                : ""
                            }`}
                            data-id={queueItem?.id}
                            key={index}
                            data-index={index}
                            onClick={() => {
                              close();
                              handlePlaySpecificQueueItem(queueItem);
                            }}
                          >
                            <div className="track-number">{index + 1}</div>
                            <img
                              alt=""
                              src={
                                queueItem?.artwork_url
                                  ? queueItem?.artwork_url
                                  : queueItem?.cover_path
                              }
                            />
                            <div className="track-details">
                              <h4>{queueItem?.name}</h4>
                              {queue && (
                                <p>
                                  {queueItem?.producer
                                    ? queueItem?.producer.name
                                    : queueItem?.artist.name}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Popup>
              </div>
            </div>
          </div>
        </section>
      )}

      {mobile && (
        <section
          className="player"
          style={{
            height: mobileExpanded ? "50%" : undefined,
            display: playerVisible ? "initial" : "none",
          }}
        >
          <div
            className={`player-mobile-div ${
              mobileExpanded ? "player-mobile-div-expanded" : ""
            }`}
            onClick={toggleMobileExpand}
            data-id={currentItem?.slug}
          >
            {playUrl && (
              <ReactPlayer
                ref={playerRef}
                url={playUrl}
                playing={isPlaying}
                onReady={onReady}
                onProgress={onProgress}
                progressInterval={1000}
                onDuration={handleDuration}
                width={0}
                height={0}
                onError={onError}
                volume={volume === 0 ? 0 : volume / 100}
                onEnded={handleNextItem}
                onBuffer={onBuffer}
                onBufferEnd={onBufferEnd}
                config={{
                  file: {
                    forceAudio: true,
                  },
                }}
              />
            )}
            <div className="player-mobile-duration">
              {!mobileExpanded && (
                <CustomMobileSlider
                  value={(currentTime / totalTime) * 100}
                  onChange={handleSeekChange}
                  min={0}
                  max={100}
                  step={0.1}
                  className="player-duration-bar"
                />
              )}
            </div>

            {mobileExpanded && (
              <div className="expanded-options">
                <img
                  className="player-mobile-collapse-button"
                  src={downArrow}
                  alt=""
                  onClick={toggleMobileExpand}
                />
                <Popup
                  className="beat-player-more"
                  trigger={
                    <button
                      className="player-mobile-more-options-exp"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <img alt="" src={more}></img>
                    </button>
                  }
                  arrow={false}
                  position="bottom right"
                >
                  {(close) => (
                    <>
                      <div
                        className="popup-option"
                        onClick={(e) => {
                          e.stopPropagation();
                          close();
                          handleNextItem();
                        }}
                      >
                        Play Next
                      </div>
                      <div
                        className="popup-option"
                        onClick={(e) => {
                          e.stopPropagation();
                          close();
                          handlePrevItem();
                        }}
                      >
                        Play Previous
                      </div>
                      <div
                        className="popup-option"
                        onClick={(e) => {
                          e.stopPropagation();
                          close();
                          setShowMobileQueuePopup(true);
                        }}
                      >
                        Queue
                      </div>
                      <div
                        className="popup-option"
                        onClick={(e) => {
                          e.stopPropagation();
                          close();
                          setIsBuyPopupOpen(true);
                        }}
                        data-id={currentItem?.slug}
                      >
                        Buy
                      </div>

                      <div
                        className="popup-option"
                        onClick={(e) => {
                          e.stopPropagation();
                          close();
                          handleArtistClick();
                        }}
                      >
                        {currentItem?.artist ? "View Artist" : "View Producer"}
                      </div>

                      <div
                        className="popup-option"
                        onClick={(e) => {
                          e.stopPropagation();
                          close();
                          setIsAddToPlaylistPopupOpen(true);
                        }}
                      >
                        Add to Playlist
                      </div>
                    </>
                  )}
                </Popup>
              </div>
            )}

            <div className="player-mobile-details">
              <div className="player-mobile-image-div">
                <img
                  alt=""
                  className="player-mobile-image"
                  src={
                    currentItem?.artwork_url
                      ? currentItem?.artwork_url
                      : currentItem?.cover_path
                  }
                />
              </div>
              <div className="beat-mobile-detail">
                <div className="beat-mobile-moving move-start1">
                  <h3 className="beat-mobile-detail-name">
                    {currentItem?.name}
                  </h3>
                  <p className="dot-seperator">&#x2022;</p>
                  {currentItem?.producer && (
                    <p className="beat-mobile-detail-artist">
                      {currentItem?.producer === undefined
                        ? "Not Available"
                        : currentItem?.producer.name}
                    </p>
                  )}
                </div>
                <div className="beat-mobile-moving move-start2">
                  <h3 className="beat-mobile-detail-name">
                    {currentItem?.name}
                  </h3>
                  <p className="dot-seperator">&#x2022;</p>
                  {currentItem?.producer && (
                    <p className="beat-mobile-detail-artist">
                      {currentItem?.producer === undefined
                        ? "Not Available"
                        : currentItem?.producer.name}
                    </p>
                  )}
                  {currentItem?.artist && (
                    <p
                      className="beat-mobile-detail-artist"
                      data-id={currentItem?.artist?.slug}
                    >
                      {currentItem?.artist === undefined
                        ? "Not Available"
                        : currentItem?.artist.name}
                    </p>
                  )}
                </div>
              </div>

              {mobileExpanded && (
                <CustomMobileSliderExpanded
                  value={(currentTime / totalTime) * 100}
                  onChange={handleSeekChange}
                  min={0}
                  max={100}
                  step={0.1}
                  className="player-duration-bar"
                />
              )}

              {!mobileExpanded && (
                <>
                  <button
                    className="like-beat-mobile-player"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLikeToggle();
                    }}
                  >
                    {isLiked ? (
                      <FavoriteRoundedIcon
                        sx={{ color: "#fe2b0d" }}
                        onClick={handleLikeToggle}
                      />
                    ) : (
                      <FavoriteBorderRoundedIcon
                        sx={{ color: "#000000" }}
                        onClick={handleLikeToggle}
                      />
                    )}
                  </button>
                  {isLoading ? (
                    <Oval
                      height="40"
                      width="40"
                      color="black"
                      secondaryColor="rgba(0,0,0,0)"
                      ariaLabel="tail-spin-loading"
                      visible={true}
                      wrapperClass="player-loading"
                      className="player-loading"
                    />
                  ) : !isPlaying ? (
                    <button
                      className="like-beat-mobile-player"
                      onClick={handlePlay}
                    >
                      <PlayArrowRoundedIcon sx={{ color: "#000000" }} />
                    </button>
                  ) : (
                    <button
                      className="like-beat-mobile-player"
                      onClick={handlePause}
                    >
                      <PauseRoundedIcon sx={{ color: "#000000" }} />
                    </button>
                  )}
                  <Popup
                    className="beat-player-more"
                    trigger={
                      <button
                        className="player-mobile-more-options like-beat-mobile-player"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <img alt="" src={more}></img>
                      </button>
                    }
                    arrow={false}
                    position="top right"
                  >
                    {(close) => (
                      <>
                        <div
                          className="popup-option"
                          onClick={(e) => {
                            e.stopPropagation();
                            close();
                            handleNextItem();
                          }}
                        >
                          Play Next
                        </div>
                        <div
                          className="popup-option"
                          onClick={(e) => {
                            e.stopPropagation();

                            close();
                            handlePrevItem();
                          }}
                        >
                          Play Previous
                        </div>
                        <div
                          className="popup-option"
                          onClick={(e) => {
                            e.stopPropagation();
                            close();
                            setShowMobileQueuePopup(true);
                          }}
                        >
                          Queue
                        </div>
                        <div
                          className="popup-option"
                          onClick={(e) => {
                            e.stopPropagation();
                            close();
                            setIsBuyPopupOpen(true);
                          }}
                          data-id={currentItem?.slug}
                        >
                          Buy
                        </div>

                        {currentItem?.producer && (
                          <div
                            className="popup-option"
                            onClick={(e) => {
                              e.stopPropagation();
                              close();
                              handleArtistClick();
                            }}
                          >
                            View Producer
                          </div>
                        )}
                        <div
                          className="popup-option"
                          onClick={(e) => {
                            e.stopPropagation();
                            close();
                            setIsAddToPlaylistPopupOpen(true);
                          }}
                        >
                          Add to Playlist
                        </div>
                      </>
                    )}
                  </Popup>
                </>
              )}

              {mobileExpanded && (
                <div className="expanded-player-controls">
                  <button
                    className="like-beat-mobile-player"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLikeToggle();
                    }}
                  >
                    {isLiked ? (
                      <FavoriteRoundedIcon
                        sx={{ color: "#fe2b0d" }}
                        onClick={handleLikeToggle}
                      />
                    ) : (
                      <FavoriteBorderRoundedIcon
                        sx={{ color: "#000000" }}
                        onClick={handleLikeToggle}
                      />
                    )}
                  </button>
                  <button className="backward" onClick={handlePrevItem}>
                    <SkipPreviousRoundedIcon sx={{ color: "#000000" }} />
                  </button>
                  <>
                    {isLoading ? (
                      <Oval
                        height="40"
                        width="40"
                        color="black"
                        secondaryColor="rgba(0,0,0,0)"
                        ariaLabel="tail-spin-loading"
                        visible={true}
                        wrapperClass="player-loading"
                        className="player-loading"
                      />
                    ) : !isPlaying ? (
                      <button
                        className="like-beat-mobile-player"
                        onClick={handlePlay}
                      >
                        <PlayArrowRoundedIcon sx={{ color: "#000000" }} />
                      </button>
                    ) : (
                      <button
                        className="like-beat-mobile-player"
                        onClick={handlePause}
                      >
                        <PauseRoundedIcon sx={{ color: "#000000" }} />
                      </button>
                    )}
                  </>
                  <button className="forward" onClick={handleNextItem}>
                    <SkipNextRoundedIcon sx={{ color: "#000000" }} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      <Popup
        className="add-queue-popup add-queue-player-popup"
        modal
        open={showInfoMessagePopup}
      >
        {infoPopupMessage}
      </Popup>
      <Popup
        className="add-queue-popup add-queue-player-popup"
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {messagePopup}
      </Popup>
      {isBuyPopupOpen && (
        <BuyPopup
          beat={currentItem}
          setShowBuyPopup={setIsBuyPopupOpen}
          showBuyPopup={isBuyPopupOpen}
          handleAddToCart={handleAddToCart}
          // setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          rates={rates}
          selectedCurrency={selectedCurrency}
        />
      )}
      <PlayerMobileQueue
        showMobileQueuePopup={showMobileQueuePopup}
        setShowMobileQueuePopup={setShowMobileQueuePopup}
        queue={queue}
        handlePlay={handlePlaySpecificQueueItem}
        currentItem={currentItem}
      />

      {isAddToPlaylistPopupOpen && (
        <AddToPlaylistPopup
          isAddToPlaylistPopupOpen={isAddToPlaylistPopupOpen}
          setIsAddToPlaylistPopupOpen={setIsAddToPlaylistPopupOpen}
          beat={currentItem}
          setPopupMessage={setPlaylistPopupMessage}
          setShowMessagePopup={setShowPlaylistMessagePopup}
          //   setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          type={
            currentItem.producer
              ? "beat"
              : currentItem.songs_count
              ? "album"
              : "song"
          }
        />
      )}

      <Popup
        className={`add-queue-popup add-queue-player-popup`}
        modal
        open={showPlaylistMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowPlaylistMessagePopup(false);
          }, 1500);
        }}
      >
        {playlistPopupMessage}
      </Popup>
    </>
  );
}

export default PlayerNew;
