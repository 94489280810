import "./../stylesheets/upload.css";
import Artwork from "./../../images/artwork-thumbnail.png";
import Tick from "./../../images/thumbnail-tick.png";
import Add from "./../../images/AddPortfolio.png";
import Remove from "./../../images/remove-thumbnail.png";
import UploadCloud from "./../../images/upload-cloud.png";
import Warning from "./../../images/warning.svg";
import downBtn from "./../../images/down-button-dark.png";
import cross from "./../../images/cross-black.svg";
import more from "./../../images/more-dark.svg";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import Autocomplete from "./autocomplete";
import { Circle } from "rc-progress";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function UploadSong({ setShowUnauthenticatedPopup, uploadSuccess }) {
  const { navigate } = useNavigation();
  const { user, isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates, formatCurrency, convertRate } =
    useCurrencyContext();
  const [disable, setDisable] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [musicFileError, setMusicFileError] = useState(
    "Please provide a file!"
  );
  const [artist, setArtist] = useState(null);
  const fileInputRef = useRef(null);
  const [description, setDescription] = useState("");
  const [lyrics, setLyrics] = useState(null);
  const [ISRCCode, setISRCCode] = useState(null);
  const [recordLabel, setRecordLabel] = useState("");

  const [price, setPrice] = useState(null);
  const [payWhatYouLike, setPayWhatYouLike] = useState("no");

  const [popupGenre, setPopupGenre] = useState([]);

  const [collaborator, setCollaborator] = useState("");
  const [popupCollaborator, setPopupCollaborator] = useState([]);
  const [split, setSplit] = useState("");
  const [collaborators, setCollaborators] = useState([]);

  const [popupStates, setPopupStates] = useState({
    genre: false,
    collaborator: false,
  });
  const [saveChanges, setSaveChanges] = useState(false);

  const [homepageFeature, setHomepageFeature] = useState(0);
  const [landingPageFeature, setLandingPageFeature] = useState(0);
  const [featuredMusic, setFeaturedMusic] = useState(0);

  const [selectedStreamingFile, setSelectedStreamingFile] = useState(null);
  const [selectedUploadFile, setSelectedUploadFile] = useState(null);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabledItems, setDisabledITems] = useState([]);
  useEffect(() => {
    const getUser = async () => {
      if (!isAuthenticated) {
        return;
      }

      if (user.role !== "seller") {
        navigate("/");
      }
    };

    getUser();
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  }, [navigate, isAuthenticated, user]);

  const handleUploadFileChange = async (event) => {
    document.querySelector(".image-error").classList.add("hidden");
    setSelectedUploadFile(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".artwork-thumbnail");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const handleDragImage = async (event) => {
    event.preventDefault();

    document.querySelector(".image-error").classList.add("hidden");
    setSelectedUploadFile(event.dataTransfer.files[0]);
    var reader = new FileReader();
    var file = event.dataTransfer.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".artwork-thumbnail");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  function handleAlbumNameChange(event) {
    setAlbumName(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      event.target.value.length === 0 ||
      !regex.test(event.target.value) ||
      event.target.value.length < 2 ||
      event.target.value.length > 100
    )
      document.querySelector(".name-error").classList.remove("hidden");
    else document.querySelector(".name-error").classList.add("hidden");
  }

  function formatInputCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `${selectedCurrency === "GBP" ? "£" : "N"}${value}`;

    return value;
  }

  function handleTrackPriceChange(event) {
    setPrice(formatInputCurrency(event.target.value));
    if (event.target.value === "£" || event.target.value === "N") {
      document.querySelector(".price-label-error").classList.remove("hidden");
      return;
    }
    const standardValue = convertRate(
      formatInputCurrency(event.target.value).slice(1)
    );
    if (
      event.target.value.length < 1 ||
      standardValue === 0 ||
      standardValue < 1 ||
      standardValue > 500
    ) {
      document.querySelector(".price-label-error").classList.remove("hidden");
    } else document.querySelector(".price-label-error").classList.add("hidden");
  }
  function handleDescriptionChange(event) {
    setDescription(event.target.value);
    if (event.target.value.length !== 0 && event.target.value.length > 500) {
      document.querySelector(".description-error").classList.remove("hidden");
    } else if (
      event.target.value.length !== 0 &&
      event.target.value.length < 10
    ) {
      document.querySelector(".description-error").classList.remove("hidden");
    } else {
      document.querySelector(".description-error").classList.add("hidden");
    }
  }

  function handleLyricsChange(event) {
    if (
      event.target.value == null ||
      event.target.value.length === 0 ||
      event.target.value === ""
    ) {
      setLyrics(null);
      document.querySelector(".lyrics-error").classList.add("hidden");

      return;
    }

    setLyrics(event.target.value);
    if (event.target.value.length < 100)
      document.querySelector(".lyrics-error").classList.remove("hidden");
    else document.querySelector(".lyrics-error").classList.add("hidden");
  }

  function handleTrackISRCCodeChange(event) {
    setISRCCode(
      event.target.value == null || event.target.value.length === 0
        ? null
        : event.target.value
    );
    if (
      event.target.value?.length !== 0 &&
      !/^[A-Z0-9]{12}$/.test(event.target.value)
    ) {
      document.querySelector(".isrc-code-error").classList.remove("hidden");
    } else {
      document.querySelector(".isrc-code-error").classList.add("hidden");
    }
  }

  function formatNumber(value) {
    value = value.replace(/[^0-9]/g, "");
    return value;
  }
  function handleCollaboratorSplitChange(event) {
    setSplit(formatNumber(event.target.value));
    if (event.target.value.length === 0)
      document.querySelector(".split-error").classList.remove("hidden");
    else if (event.target.value < 5 || event.target.value > 95) {
      document.querySelector(".split-error").classList.remove("hidden");
    } else document.querySelector(".split-error").classList.add("hidden");
  }
  function handleAddCollaborator(event) {
    event.preventDefault();
    let valid = true;
    if (collaborator == null) {
      document
        .querySelector(".collaborator-name-error")
        .classList.remove("hidden");
      valid = false;
    }
    let totalSplit = 0;
    for (let i = 0; i < collaborators.length; i++) {
      totalSplit += collaborators[i].split;
    }
    if (parseInt(totalSplit) + parseInt(split) > 95) {
      document.querySelector(".split-error").classList.remove("hidden");
      valid = false;
    }
    if (split.length === 0) {
      document.querySelector(".split-error").classList.remove("hidden");
      valid = false;
    }
    if (split < 5 || split > 95) {
      document.querySelector(".split-error").classList.remove("hidden");
      valid = false;
    }

    if (!checkGenreChecked(popupCollaborator)) {
      document
        .querySelector(".collaborator-role-error")
        .classList.remove("hidden");
      valid = false;
    }

    if (!valid) {
      return;
    }
    const role = popupCollaborator.find((role) => role.checked);
    setCollaborators([
      ...collaborators,
      {
        user: collaborator.id,
        split: Number(split),
        role: role.id,
        roleName: role.name,
        userName: collaborator.name,
      },
    ]);
    setCollaborator("");
    setSplit("");
    const id = role.id;
    setPopupCollaborator((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: false } : { ...genre, checked: false }
      )
    );
  }

  function checkGenreChecked(obj) {
    for (let key in obj) {
      const skill = obj[key];
      if (
        skill &&
        typeof skill === "object" &&
        skill.hasOwnProperty("checked") &&
        skill.checked
      )
        return true;
    }

    return false;
  }

  function checkValues() {
    let valid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (albumName.length === 0 || !regex.test(albumName)) {
      document.querySelector(".name-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".name-error").classList.add("hidden");
    }

    if (!artist) {
      document.querySelector(".artist-name-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".artist-name-error").classList.add("hidden");
    }

    if (lyrics != null && lyrics.length < 100) {
      document.querySelector(".lyrics-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".lyrics-error").classList.add("hidden");
    }
    if (
      ISRCCode !== null &&
      ISRCCode.length !== 0 &&
      !/^[A-Z0-9]{12}$/.test(ISRCCode)
    ) {
      document.querySelector(".isrc-code-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".isrc-code-error").classList.add("hidden");
    }
    if (description.length !== 0 && description.length > 500) {
      document.querySelector(".description-error").classList.remove("hidden");
      valid = false;
    } else if (description.length !== 0 && description.length < 10) {
      document.querySelector(".description-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".description-error").classList.add("hidden");
    }
    const standardValue = convertRate(price?.slice(1));

    if (
      price?.slice(1).length === 0 ||
      parseInt(standardValue) < 1 ||
      parseInt(standardValue) > 500
    ) {
      document.querySelector(".price-label-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".price-label-error").classList.add("hidden");
    }
    if (
      recordLabel?.name?.length < 2 ||
      recordLabel?.name?.length > 100 ||
      recordLabel === ""
    ) {
      document.querySelector(".record-label-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".record-label-error").classList.add("hidden");
    }

    if (!checkGenreChecked(popupGenre)) {
      document.querySelector(".genre-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".genre-error").classList.add("hidden");
    }

    if (!selectedUploadFile) {
      document.querySelector(".image-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".image-error").classList.add("hidden");
    }

    if (!selectedStreamingFile) {
      document.querySelector(".streaming-error").classList.remove("hidden");
      valid = false;
    } else {
      document.querySelector(".streaming-error").classList.add("hidden");
    }

    return valid;
  }

  useState(() => {
    const getGenres = async () => {
      try {
        const response = await api.get("genres");
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre, index) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setPopupGenre(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getRoles = async () => {
      try {
        const response = await api.get("roles");
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre, index) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setPopupCollaborator(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getGenres();
    getRoles();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      setProductOptions(null);
      try {
        const response = await api.get("products?for=song");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        const paypalOptions = [];

        data.data.forEach((product) => {
          if (
            product.provider ===
            (selectedCurrency === "GBP" ? "paypal" : "paystack")
          ) {
            paypalOptions.push(product);
          }
        });

        const landingPageItems = paypalOptions
          .filter((item) => item.name.includes("Landing Page"))
          .map((item) => ({
            ...item,
            name: item.name.includes("One Week") ? "Weekly" : "Monthly",
          }));

        const homePageItems = paypalOptions
          .filter((item) => item.name.includes("Home Page"))
          .map((item) => ({
            ...item,
            name: item.name.includes("One Week") ? "Weekly" : "Monthly",
          }));

        const featuredMusicItems = paypalOptions
          .filter((item) => item.name.includes("Featured Music"))
          .map((item) => ({
            ...item,
            name: item.name.includes("One Week") ? "Weekly" : "Monthly",
          }));

        const featuredArtistItems = paypalOptions
          .filter((item) => item.name.includes("Featured Artist"))
          .map((item) => ({
            ...item,
            name: item.name.includes("One Week") ? "Weekly" : "Monthly",
          }));

        setProductOptions({
          homePageItems: homePageItems,
          landingPageItems: landingPageItems,
          featuredMusicItems: featuredMusicItems,
          featuredArtistItems: featuredArtistItems,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getProducts();
  }, [selectedCurrency, navigate, setShowUnauthenticatedPopup]);

  const [progress, setProgress] = useState(5);

  const handleSubmit = async (event) => {
    setDisable(true);

    setShowError(false);
    event.preventDefault();
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    const tempProducts = [];
    if (homepageFeature !== 0) {
      tempProducts.push(homepageFeature);
    }
    if (landingPageFeature !== 0) {
      tempProducts.push(landingPageFeature);
    }

    if (featuredMusic !== 0) {
      tempProducts.push(featuredMusic);
    }
    if (tempProducts.length !== 0) {
      const body = { products: tempProducts };

      try {
        const response = await api.post(`products/availability`, body);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        const newAvailability = {};
        Object.keys(data).forEach((key) => {
          newAvailability[key] = data[key] ? false : true;
        });
        let found = false;
        setDisabledITems(newAvailability);
        Object.keys(newAvailability).forEach((key) => {
          if (newAvailability[key] === true) {
            if (parseInt(key) === homepageFeature) {
              setHomepageFeature(0);
              found = true;
            } else if (parseInt(key) === landingPageFeature) {
              setLandingPageFeature(0);
              found = true;
            } else if (parseInt(key) === featuredMusic) {
              setFeaturedMusic(0);
              found = true;
            }
          }
        });
        if (found) {
          document.querySelector(".product-error").classList.remove("hidden");
          setShowError(true);
          setErrorMessage("Incorrect values entered!");
          setDisable(false);
          return;
        } else {
          document.querySelector(".product-error").classList.add("hidden");
        }
      } catch (error) {
        uploadSuccess("Song");
        navigate("upload/failure");
        Sentry.captureException(error);
        return;
      }
    }

    if (!checkValues()) {
      setShowError(true);
      setErrorMessage("Incorrect values entered!");
      setDisable(false);
      return;
    }

    setSaveChanges(true);
    setProgress(5);

    const genres = [];
    popupGenre.forEach((genre) => {
      if (genre.checked === true) {
        genres.push(genre.id);
      }
    });

    const collaboratorsFiltered = [];

    collaborators.forEach((collaborator) => {
      collaboratorsFiltered.push({
        user: collaborator.user,
        split: collaborator.split,
        role: collaborator.role,
      });
    });
    let body;
    if (artist.id) {
      body = {
        name: albumName,
        artist_id: artist.id,
        description: description,
        price:
          selectedCurrency === "GBP"
            ? parseInt(price?.slice(1))
            : (parseInt(price?.slice(1)) / rates).toFixed(2),
        pay_what_you_like: payWhatYouLike === "yes",
        filename: selectedStreamingFile.name,
        genres: genres,
        cover_name: selectedUploadFile.name,
        collaborators: collaboratorsFiltered,
      };
      if (lyrics) {
        body.lyrics = lyrics;
      }
      if (ISRCCode) {
        body.isrc = ISRCCode;
      }
      if (recordLabel.id) {
        body.label_id = recordLabel.id;
      } else {
        body.label = recordLabel.name;
      }
    } else {
      body = {
        name: albumName,
        artist: artist.name,
        description: description,
        price:
          selectedCurrency === "GBP"
            ? price?.slice(1)
            : price?.slice(1) / rates,
        pay_what_you_like: payWhatYouLike === "yes",
        filename: selectedStreamingFile.name,
        genres: genres,
        cover_name: selectedUploadFile.name,
        collaborators: collaboratorsFiltered,
      };
      if (lyrics) {
        body.lyrics = lyrics;
      }
      if (ISRCCode) {
        body.isrc = ISRCCode;
      }
      if (recordLabel.id) {
        body.label_id = recordLabel.id;
      } else {
        body.label = recordLabel.name;
      }
    }

    try {
      const response = await api.post(`songs`, body);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      if (response.status === 400) {
        setShowError(true);
        setErrorMessage(response.error.message);
        setDisable(false);
        setSaveChanges(false);
        return;
      }
      if (response.status === 409) {
        setShowError(true);
        setErrorMessage(response.error.message);
        setDisable(false);
        setSaveChanges(false);

        return;
      }
      if (response.status === 422) {
        setShowError(true);
        setErrorMessage(response.error.message);
        setDisable(false);
        setSaveChanges(false);
        return;
      }
      if (response.status !== 200) {
        uploadSuccess("Song");
        navigate("/upload/failure");
      }

      setProgress(30);

      var img = document.querySelector(".artwork-thumbnail");
      const res = await fetch(img.src);
      const blob = await res.blob();
      const imageUpload = await fetch(data.cover, {
        method: "PUT",
        body: blob,
      });

      if (imageUpload.status === 403) {
        setShowError(true);
        setErrorMessage("Authentication error! Please login again");
        setDisable(false);
        setSaveChanges(false);

        return;
      }
      if (response.status !== 200) {
        uploadSuccess("Song");
        navigate("/upload/failure");
        return;
      }

      setProgress(60);

      let mp3Upload = null;
      if (selectedStreamingFile.name.endsWith("wav")) {
        mp3Upload = await fetch(data.upload, {
          method: "PUT",
          body: selectedStreamingFile,
        });
      } else {
        const formData = new FormData();
        formData.append("file", selectedStreamingFile);
        mp3Upload = await fetch(data.upload, {
          method: "PUT",
          body: formData,
        });
      }

      if (mp3Upload.status === 200) {
        setProgress(80);
      } else {
        uploadSuccess("Song");
        navigate("/upload/failure");
        return;
      }

      const id = data.id;
      const products = [];

      if (homepageFeature !== 0) {
        products.push({ id, type: "song", product: homepageFeature });
      }
      if (landingPageFeature !== 0) {
        products.push({ id, type: "song", product: landingPageFeature });
      }

      if (featuredMusic !== 0) {
        products.push({ id, type: "song", product: featuredMusic });
      }

      if (products.length !== 0) {
        const body = { items: products };
        try {
          const response = await api.post(`products/buy`, body);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

          setProgress(100);

          const data = response.data;
          if (response.status === 200) {
            window.location.href = data.link;
          } else {
            setDisable(false);
            setSaveChanges(false);
            uploadSuccess("Song");
            navigate("/error/notfound/product");
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        setProgress(100);
        uploadSuccess("Song");
        navigate("/upload/success");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleStreamingFileChange = (event) => {
    document.querySelector(".streaming-error").classList.add("hidden");
    const maxSize = 100 * 1024 * 1024;

    if (event.target.files[0] && event.target.files[0].size > maxSize) {
      setMusicFileError(
        "Your file is too large. Please keep it under 100MB and try again."
      );
      document.querySelector(".streaming-error").classList.remove("hidden");

      setSelectedUploadFile(null);
      return;
    }
    setSelectedStreamingFile(event.target.files[0]);
  };

  const handleRemove = (event) => {
    document.querySelector(".image-error").classList.remove("hidden");
    document.querySelector(".artwork-thumbnail").src = null;
    setSelectedUploadFile(null);
  };

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  const handleHomepageFeatureChange = (event) => {
    setHomepageFeature(Number(event.target.value));
  };

  const handleLandingPageFeatureChange = (event) => {
    setLandingPageFeature(Number(event.target.value));
  };

  const handleFeaturedMusicChange = (event) => {
    setFeaturedMusic(Number(event.target.value));
  };

  const handleGenreCheckboxChange = (id) => {
    setPopupGenre((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: !genre.checked } : genre
      )
    );

    setPopupGenre((updatedGenre) => {
      if (checkGenreChecked(updatedGenre)) {
        document.querySelector(".genre-error").classList.add("hidden");
      } else {
        document.querySelector(".genre-error").classList.remove("hidden");
      }
      return updatedGenre;
    });
  };

  const handleRoleCheckboxChange = (id) => {
    setPopupCollaborator((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: true } : { ...genre, checked: false }
      )
    );

    setPopupCollaborator((updatedGenre) => {
      if (checkGenreChecked(updatedGenre)) {
        document
          .querySelector(".collaborator-role-error")
          .classList.add("hidden");
      } else {
        document
          .querySelector(".collaborator-role-error")
          .classList.remove("hidden");
      }
      return updatedGenre;
    });
  };
  const handleDeleteFile = () => {
    setSelectedStreamingFile(null);
    fileInputRef.current.value = "";
  };
  const [productOptions, setProductOptions] = useState(null);
  const handleDrop = (event) => {
    event.preventDefault();
    document.querySelector(".streaming-error").classList.add("hidden");
    const file = event.dataTransfer.files[0];

    if (file && file.size > 100 * 1024 * 1024) {
      setMusicFileError(
        "Your file is too large. Please keep it under 100MB and try again."
      );
      document.querySelector(".streaming-error").classList.remove("hidden");
      setSelectedStreamingFile(null);
      return;
    }
    setSelectedStreamingFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <section className="upload-beats">
        <h1 className="upload-beats__heading-primary">Upload Song</h1>

        <form>
          <div className="upload-form__form-input">
            <div className="form-input_song">
              <div>
                <h4 className="upload-beats__heading-secondary">
                  Track Information
                </h4>
                <label className="form-beats_label">
                  Song name
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="name-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Invalid name: Only letters, numbers, and spaces are allowed.
                  </Popup>
                </label>
                <input
                  type="text"
                  className="form-beats-input input-name"
                  onChange={(event) => handleAlbumNameChange(event)}
                />
                <label className="form-beats_label">
                  Artist name
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="artist-name-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a valid name!
                  </Popup>
                </label>

                <Autocomplete
                  endPoint="artists/autocomplete"
                  customClassName={"input-artist-name"}
                  selectedValue={artist}
                  setSelectedValue={setArtist}
                  errorClassName={"artist-name-error"}
                />

                <label className="form-beats_label">
                  Description (optional)
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="description-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    {description.length < 10
                      ? "Minimum characters are 10!"
                      : "Maximum characters are 500!"}
                  </Popup>
                </label>
                <textarea
                  className=" custom-textarea form-beats-input input-description"
                  onChange={(event) => handleDescriptionChange(event)}
                />

                <label className="form-beats_label">
                  Track Lyrics (Optional)
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="lyrics-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Minimum characters are 100!
                  </Popup>
                </label>
                <textarea
                  className=" custom-textarea form-beats-input input-description"
                  onChange={(event) => handleLyricsChange(event)}
                />

                <label className="form-beats_label">
                  Track ISRC Code (Optional)
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="isrc-code-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a valid code of 12 characters!
                  </Popup>
                </label>
                <input
                  type="text"
                  className="form-beats-input input-artist-name"
                  onChange={(event) => handleTrackISRCCodeChange(event)}
                />

                <label className="form-beats_label">
                  Record Label
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="record-label-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a record label!
                  </Popup>
                </label>
                <Autocomplete
                  endPoint="labels/autocomplete"
                  customClassName={"input-artist-name"}
                  selectedValue={recordLabel}
                  setSelectedValue={setRecordLabel}
                  errorClassName={"record-label-error"}
                />
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">
                  Pricing and Sales
                </h4>
                <label className="form-beats_label">
                  Track Price
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="price-label-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    {`Oops! The price must be between ${formatCurrency(
                      100
                    )} and ${formatCurrency(50000)} for this song`}
                  </Popup>
                </label>
                <input
                  type="tel"
                  className="form-beats-input liscense-price"
                  value={price}
                  placeholder={`${formatCurrency(100)} or more`}
                  onChange={handleTrackPriceChange}
                />
                <p className="Checkmark-questions">
                  Pay what you like - allow fans to contribute more than the set
                  price
                </p>
                <div className="radio-btn-flex">
                  <label className="container">
                    No
                    <input
                      type="radio"
                      name="payWhatYouLike"
                      value="no"
                      checked={payWhatYouLike === "no"}
                      onChange={() => {
                        setPayWhatYouLike("no");
                      }}
                    />
                    <span className="Checkmark"></span>
                  </label>
                  <label className="container">
                    Yes
                    <input
                      type="radio"
                      name="payWhatYouLike"
                      value="yes"
                      checked={payWhatYouLike === "yes"}
                      onChange={() => {
                        setPayWhatYouLike("yes");
                      }}
                    />
                    <span className="Checkmark"></span>
                  </label>
                </div>
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">
                  Upload Audio
                </h4>
                <div className="upload-song-dark">
                  <label className="form-beats_label">
                    Please select an audio file (max 100MB).
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="streaming-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      {musicFileError}
                    </Popup>
                  </label>
                  <div
                    className="upload-track-button-container"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <label htmlFor="file-upload-streaming">Upload File</label>

                    <input
                      type="file"
                      id="file-upload-streaming"
                      accept=".mp3, .wav"
                      ref={fileInputRef}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onChange={handleStreamingFileChange}
                    />

                    {selectedStreamingFile && (
                      <div className="music-label-name">
                        <img src={Tick} alt="" />
                        {selectedStreamingFile.name}
                      </div>
                    )}

                    {selectedStreamingFile && (
                      <div
                        className="music-label-delete"
                        onClick={handleDeleteFile}
                      >
                        Delete
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">Genre Tags</h4>
                <label className="form-beats_label">
                  Tags
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="genre-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please select at least 1 genre!
                  </Popup>
                </label>
                <Popup
                  className="genre-dropdown"
                  trigger={
                    <div className="form-beats-input form-option">
                      {!checkGenreChecked(popupGenre) && <>Select</>}
                      {checkGenreChecked(popupGenre) && (
                        <>
                          {popupGenre
                            .filter((genre) => genre.checked)
                            ?.slice(0, 7)
                            .map((genre) => (
                              <button
                                className="genre-remove-button"
                                key={genre.name}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  handleGenreCheckboxChange(
                                    popupGenre.findIndex(
                                      (g) => g.id === genre.id
                                    )
                                  );
                                }}
                              >
                                <img src={cross} alt="remove" />
                                {genre.name}
                              </button>
                            ))}
                          {popupGenre.filter((genre) => genre.checked).length >
                            7 && (
                            <div className="genre-remove-button">
                              <img src={more} alt="" />
                            </div>
                          )}
                        </>
                      )}
                      <img
                        src={downBtn}
                        style={
                          popupStates.genre
                            ? { transform: "rotate(180deg)" }
                            : { transform: "none" }
                        }
                        alt=""
                        className="upload-beat-genre-dropdown-button"
                      />
                    </div>
                  }
                  onOpen={() => handlePopupOpen("genre")}
                  onClose={() => handlePopupClose("genre")}
                  position="bottom center"
                  arrow={false}
                >
                  {popupGenre.map((genre, index) => {
                    return (
                      <div className="service-item" key={index}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={genre.checked || false}
                            onChange={() => handleGenreCheckboxChange(index)}
                          />
                          {genre.name}
                        </label>
                      </div>
                    );
                  })}
                </Popup>
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">
                  Optional Collaborator
                </h4>
                <div className="upload-song-dark">
                  <label className="form-beats_label">
                    Collaborator User-Name
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="collaborator-name-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please provide a name!
                    </Popup>
                  </label>
                  <Autocomplete
                    endPoint="collaborators/autocomplete"
                    customClassName={"input-name"}
                    selectedValue={collaborator}
                    setSelectedValue={setCollaborator}
                    errorClassName={"collaborator-name-error"}
                  />
                  <label className="form-beats_label">
                    Collaborator Role
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="collaborator-role-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please select at least 1 role!
                    </Popup>
                  </label>
                  <Popup
                    className="genre-dropdown genre-dropdown-dark"
                    trigger={
                      <div className="form-beats-input form-option">
                        {!checkGenreChecked(popupCollaborator) && <>Select</>}
                        {checkGenreChecked(popupCollaborator) && (
                          <>
                            {
                              popupCollaborator.find((genre) => genre.checked)
                                ?.name
                            }
                          </>
                        )}
                        <img
                          src={downBtn}
                          style={
                            popupStates.collaborator
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                          alt=""
                          className="upload-beat-genre-dropdown-button"
                        />
                      </div>
                    }
                    onOpen={() => handlePopupOpen("collaborator")}
                    onClose={() => handlePopupClose("collaborator")}
                    position="bottom center"
                    arrow={false}
                  >
                    {(close) => (
                      <>
                        {popupCollaborator.map((genre, index) => {
                          return (
                            <div className="service-item" key={index}>
                              <label
                                className="checkbox-container"
                                onClick={() => {
                                  handleRoleCheckboxChange(index);
                                  close();
                                }}
                              >
                                {genre.name}
                              </label>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </Popup>
                  <label className="form-beats_label">
                    Revenue Split
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="split-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Split percentages must be between 5% and 95%. Total split
                      percentages must equal 95%, please adjust and retry
                    </Popup>
                  </label>
                  <input
                    className="form-beats-input input-revenue"
                    onChange={(event) => handleCollaboratorSplitChange(event)}
                  />
                </div>
                <button
                  className="add-collaborator"
                  onClick={handleAddCollaborator}
                >
                  <img src={Add} alt="" />
                  Add {collaborators.length === 0 ? "" : "Another"} Collaborator
                </button>
                {collaborators.length > 0 && (
                  <div className="collaborators-list">
                    {collaborators.map((collaborator, index) => {
                      return (
                        <div key={index} className="collaborator-row">
                          <span className="collaborator-split">
                            {collaborator.split}%
                          </span>
                          - {collaborator.userName}
                          <span className="collaborator-role">
                            ({collaborator.roleName})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              {productOptions && (
                <div>
                  <h4 className="upload-beats__heading-secondary">
                    <label className="form-beats_heading">
                      Reach More People!
                      <Popup
                        className="upload-error-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="product-error hidden"
                          ></img>
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Some Products you selected are not available!
                      </Popup>
                    </label>
                  </h4>

                  <div className="reach-more-container">
                    <ReachMore
                      name={"homepage"}
                      titleText={"Feature on Homepage"}
                      items={productOptions.homePageItems}
                      currentVal={homepageFeature}
                      updaterFunction={handleHomepageFeatureChange}
                      disabledItems={disabledItems}
                      formatCurrency={formatCurrency}
                    />
                    <ReachMore
                      name={"landingPage"}
                      titleText={"Feature on Music Page Banner"}
                      items={productOptions.landingPageItems}
                      currentVal={landingPageFeature}
                      updaterFunction={handleLandingPageFeatureChange}
                      disabledItems={disabledItems}
                      formatCurrency={formatCurrency}
                    />
                    <ReachMore
                      name={"featuredMusic"}
                      titleText={"Featured Music"}
                      items={productOptions.featuredMusicItems}
                      currentVal={featuredMusic}
                      updaterFunction={handleFeaturedMusicChange}
                      disabledItems={disabledItems}
                      formatCurrency={formatCurrency}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="form-input_artwork-upload">
              <div
                className="artwork_img-box artwork_img-box_song"
                onDrop={handleDragImage}
                onDragOver={handleDragOver}
              >
                <h3>
                  Upload Cover Artwork
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="image-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a valid image!
                  </Popup>
                </h3>
                <p>Artwork Dimension</p>
                <p>Preferred: 1500x1500px, Minimum: 500x500px</p>
                <div>
                  {!selectedUploadFile && (
                    <label
                      htmlFor="file-upload-artwork"
                      className={`user-file-upload-label ${
                        selectedUploadFile ? "" : "upload-animation"
                      }`}
                    >
                      <img src={UploadCloud} alt="" />
                      Drag and drop picture here <br /> Or
                      <p className="upload-picture">Upload Picture</p>
                    </label>
                  )}
                  {!selectedUploadFile && (
                    <input
                      type="file"
                      id="file-upload-artwork"
                      accept="image/png, image/jpeg, image/gif, image/svg"
                      onChange={handleUploadFileChange}
                    />
                  )}
                  {selectedUploadFile && (
                    <p className="profile-selected-file upload-animation">
                      <img src={Artwork} alt="" className="artwork-thumbnail" />
                    </p>
                  )}
                  {selectedUploadFile && (
                    <div className="imageName-delete user-delete upload-animation">
                      <img src={Tick} alt="" className="tick" />
                      <span className="img_file-name">
                        {selectedUploadFile.name}
                      </span>
                      <img src={Remove} alt="" onClick={handleRemove} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="upload-container">
            <button
              className="save-changes"
              onClick={handleSubmit}
              disabled={disable}
            >
              {saveChanges && (
                <Circle
                  percent={progress}
                  strokeWidth={10}
                  strokeColor="#fff"
                  style={{ height: "4rem", width: "4rem" }}
                  trailColor="#dfdfdf"
                />
              )}
              {!saveChanges && "Upload Track"}
            </button>

            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className={`api-error ${!showError ? "hidden" : ""}`}
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {errorMessage}
            </Popup>
          </div>
        </form>
      </section>
    </div>
  );
}

export default UploadSong;

function ReachMore({
  name,
  titleText,
  items,
  currentVal,
  updaterFunction,
  disabledItems,
  formatCurrency,
}) {
  return (
    <div className="reach-more-category-container">
      <div className="reach-more-heading">{titleText}</div>

      <div className="reach-more">
        <div className="reach-more-row">
          <label className="container">
            No
            <input
              type="radio"
              name={name}
              value={0}
              checked={currentVal === 0}
              onChange={updaterFunction}
            />
            <span className="Checkmark" />
          </label>
        </div>

        {items.map((item) => {
          return (
            <div className="reach-more-row" key={item.id}>
              <label className="container">
                {item.name} - {formatCurrency(item.price)}
                <input
                  type="radio"
                  name={name}
                  value={item.id}
                  checked={currentVal === item.id}
                  onChange={updaterFunction}
                  disabled={disabledItems[item.id] || false}
                />
                <span className="Checkmark" />
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
