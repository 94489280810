import "./../stylesheets/wallet.css";
import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useNavigation } from "../../context/navigationContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
import { useAuthContext } from "../../context/authContext";
function Withdrawals({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const handleLoad = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }

      try {
        const response = await api.get(`withdraw`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setTransactions(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [navigate, setShowUnauthenticatedPopup, isAuthenticated]);
  return (
    <section className="wallet-section">
      <div className="wallet-heading">
        <h3 className="wallet-heading-tag">My Withdrawals</h3>
      </div>
      <div className="wallet-balance">
        <div className="wallet-transactions">
          <div className="wallet-transactions-top">
            <p>Recent transactions:</p>
            <div className="wallet-transactions-buttons">
              <button className="wallet-transactions-30-days">
                Last 30 days
              </button>
              <button className="wallet-transactions-1-year">
                Last one year
              </button>
              <button className="wallet-transactions-all">Show all</button>
            </div>
          </div>
          <div className="wallet-transactions-history">
            <div className="wallet-transactions-history-head">
              <h3 className="item-method">Method</h3>
              <h3 className="item-amount">Amount</h3>
              <h3 className="item-status">Status</h3>
            </div>
            <div className="wallet-transactions-history-items">
              {transactions &&
                transactions.map((item) => {
                  return (
                    <div
                      className="wallet-transactions-history-item"
                      key={item.id}
                    >
                      <h3 className="item-method">
                        {item.payment_method === "paypal" ? "PayPal" : "Bank"}
                      </h3>
                      <h3 className="item-amount">${item.amount}</h3>
                      <h3 className="item-status">{item.status}</h3>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Withdrawals;
