import "./stylesheets/beatsBid.css";
import * as Sentry from "@sentry/react";
import cross from "./../images/cross.svg";
import submit from "./../images/bid.png";
import accept from "./../images/accept.svg";
import reject from "./../images/reject.svg";
import cart from "./../images/cart.svg";
import warning from "./../images/warning.svg";
import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import { useNavigation } from "../context/navigationContext";
import { useAuthContext } from "../context/authContext";
import handleResponseStatus from "../utils/handleResponseStatus";
import { useCurrencyContext } from "../context/currencyContext";
import api from "../utils/api";
import { usePlayerContext } from "../context/playerContext";
function BeatsBid({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleCart,
}) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates, formatCurrency } = useCurrencyContext();

  const [role, setRole] = useState("buyer");
  const [buyerBids, setBuyerBids] = useState(null);
  const [sellerBids, setSellerBids] = useState(null);
  const [nextPageBuyer, setNextPageBuyer] = useState(null);
  const [nextPageSeller, setNextPageSeller] = useState(null);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [showCounterOfferPopup, setShowCounterOfferPopup] = useState(false);
  const [counterBidInput, setCounterBidInput] = useState(
    selectedCurrency === "GBP" ? "£" : "N"
  );
  const [counterBidErrorMessage, setCounterBidErrorMessage] = useState();
  const [counterOfferPopupData, setCounterOfferPopupData] = useState(false);

  const [isShowOfferConfirmation, setIsShowOfferConfirmation] = useState(false);
  const [offerConfirmationMessage, setOfferConfirmationMessage] = useState("");
  const [confirmImage, setConfirmImage] = useState();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
    const getSellerBids = async () => {
      try {
        const response = await api.get(`bids`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        if (data.data.length === 0) {
          setSellerBids(null);
        } else {
          setSellerBids(data.data);
          setNextPageSeller(data.links.next?.split("api/")[1]);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getBuyerBids = async () => {
      try {
        const response = await api.get(`bids/list`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setBuyerBids(data.data);
        setNextPageBuyer(data.links.next?.split("api/")[1]);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const handleRoleChange = async () => {
      if (role === "seller") {
        await getSellerBids();
      } else if (role === "buyer") {
        await getBuyerBids();
      }
    };

    handleRoleChange();
  }, [isAuthenticated, role, navigate, setShowUnauthenticatedPopup]);

  const getSellerBids = async () => {
    try {
      const response = await api.get(`bids`);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      if (data.data.length === 0) {
        setSellerBids(null);
      } else {
        setSellerBids(data.data);
        setNextPageSeller(data.links.next?.split("api/")[1]);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  useEffect(() => {
    const getNextPage = async () => {
      if (nextPageSeller) {
        const response = await api.get(nextPageSeller);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setSellerBids([...sellerBids, ...data.data]);
        setNextPageSeller(data.links.next?.split("api/")[1]);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [sellerBids, navigate, nextPageSeller]);
  useEffect(() => {
    const getNextPage = async () => {
      if (nextPageBuyer) {
        const response = await api.get(nextPageBuyer);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setBuyerBids([...buyerBids, ...data.data]);
        setNextPageBuyer(data.links.next?.split("api/")[1]);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [buyerBids, navigate, nextPageBuyer]);
  const handleCounterBidInputChange = (event) => {
    setCounterBidInput(formatInputCurrency(event.target.value));

    if (event.target.value.length <= 1) {
      setCounterBidErrorMessage("The input field cannot be empty!");
      document.querySelector(".bid-error").classList.remove("hidden");
    } else {
      document.querySelector(".bid-error").classList.add("hidden");
    }
  };

  function formatInputCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `${selectedCurrency === "GBP" ? "£" : "N"}${value}`;

    return value;
  }

  const handleCounterOffer = async () => {
    if (!counterBidInput || counterBidInput.length <= 1) {
      setCounterBidErrorMessage("The input field cannot be empty!");
      document.querySelector(".bid-error").classList.remove("hidden");
      return;
    }
    let amount = 0;
    if (selectedCurrency === "GBP") {
      amount = counterBidInput.slice(1);
    } else {
      amount = (counterBidInput.slice(1) / rates).toFixed(2);
    }

    const body = {
      beat: counterOfferPopupData.beatId,
      buyer: counterOfferPopupData.bidderId,
      price: amount * 100,
    };

    try {
      const response = await api.post(`counter/make`, body);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      setCounterBidInput(`${selectedCurrency === "GBP" ? "£" : "N"}`);

      if (response.status === 401) {
        setCounterBidErrorMessage("Error message");
        document.querySelector(".bid-error").classList.remove("hidden");
        return;
      } else if (response.status === 422) {
        setCounterBidErrorMessage(
          "This offer has already been accepted/rejected"
        );
        document.querySelector(".bid-error").classList.remove("hidden");
        return;
      } else if (response.status === 200) {
        getSellerBids();
        setShowCounterOfferPopup(false);
        setOfferConfirmationMessage(
          `Your bid has been sent! Brace for response!`
        );
        setConfirmImage(accept);
        setIsShowOfferConfirmation(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleRejectCounterOffer = async (beat, price) => {
    try {
      const response = await api.post(`bids/cancel`, { beat: beat });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response.status === 200) {
        setOfferConfirmationMessage(
          `You have rejected the counter offer of ${
            selectedCurrency === "GBP" ? "£" : "N"
          }${formatCurrency(price)}`
        );
        setConfirmImage(reject);
        setIsShowOfferConfirmation(true);
        getBuyerBids();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleAcceptCounterOffer = async (beat, price) => {
    try {
      const response = await api.post(`counter/accept`, { beat: beat });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response.status === 200) {
        getBuyerBids();
        setOfferConfirmationMessage(
          `You have accepted the counter offer of ${formatCurrency(price)}`
        );
        setConfirmImage(accept);
        setIsShowOfferConfirmation(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleAcceptOffer = async (beat, buyer, name, price) => {
    try {
      const response = await api.post(`bids/accept`, {
        beat: beat,
        buyer: buyer,
      });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response.status === 200) {
        getSellerBids();
        setOfferConfirmationMessage(
          `You have accepted the offer of ${formatCurrency(price)} from ${name}`
        );
        setConfirmImage(accept);
        setIsShowOfferConfirmation(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleRejectOffer = async (beat, buyer, name, price) => {
    try {
      const response = await api.post(`bids/reject`, {
        beat: beat,
        buyer: buyer,
      });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response.status === 200) {
        setOfferConfirmationMessage(
          `You have rejected the offer of ${formatCurrency(price)} from ${name}`
        );
        setConfirmImage(reject);
        setIsShowOfferConfirmation(true);
        getSellerBids();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const getBuyerBids = async () => {
    try {
      const response = await api.get(`bids/list`);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      setBuyerBids(data.data);
      setNextPageBuyer(data.links.next?.split("api/")[1]);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleAddToCart = async (id) => {
    if (isAuthenticated) {
      const response = await handleCart(id, "beat");
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    }
  };

  const { playerState } = usePlayerContext();

  return (
    <>
      <section className="bids-topbar">
        <div className="bids-display-options">
          <button
            className={` ${role === "buyer" ? "bids-button-selected" : ""}`}
            onClick={() => {
              if (role !== "buyer") setRole("buyer");
            }}
          >
            Sent Bids
          </button>
          <button
            className={` ${role === "seller" ? "bids-button-selected" : ""}`}
            onClick={() => {
              if (role !== "seller") setRole("seller");
            }}
          >
            Received Bids
          </button>
        </div>
      </section>

      {role === "seller" && (
        <section className="beatsBid-seller">
          <h2>Received bids</h2>
          <div className="bids-container-seller">
            <div className="bids-container-row bids-container-row-header">
              <div className="bids-container-row__date">Date</div>
              <div className="bids-container-row__name">Beat</div>
              <div className="bids-container-row__amount">Bid Amount</div>
              <div className="bids-container-row__status">Status</div>
              <div className="bids-container-row__action">Action</div>
            </div>

            {sellerBids &&
              sellerBids.map((beat) => {
                return beat.bidders.map((bidder) => {
                  const status =
                    bidder.pivot.status === 0
                      ? "Pending"
                      : bidder.pivot.status === 1
                      ? "Accepted"
                      : bidder.pivot.status === 2
                      ? "Rejected"
                      : "Expired";

                  const beatId = beat.id;
                  const bidderId = bidder.id;
                  const bidderName = bidder.name;
                  const bidPrice = bidder.pivot.price;

                  return (
                    <div
                      className="bids-container-row"
                      key={bidder.id}
                      data-id={
                        (beat.id, bidder.id, bidder.name, bidder.pivot.price)
                      }
                    >
                      <div className="bids-container-row__date">
                        {new Date(bidder.pivot.created_at).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </div>
                      <div className="bids-container-row__name">
                        {beat.name}
                      </div>
                      <div className="bids-container-row__amount">
                        (
                        {bidder.pivot.counter
                          ? formatCurrency(bidder.pivot.counter)
                          : formatCurrency(bidder.pivot.price)}
                        )
                      </div>
                      <div
                        className="bids-container-row__status"
                        style={
                          status === "Pending"
                            ? { color: "#F29423" }
                            : status === "Accepted"
                            ? { color: "#2C7919" }
                            : status === "Rejected" || status === "Expired"
                            ? { color: "#F55446" }
                            : { color: "black" }
                        }
                      >
                        {status}
                      </div>
                      <div
                        className={`bids-container-row__action ${
                          status === "Accepted" ||
                          status === "Rejected" ||
                          status === "Expired"
                            ? "bids-container-row__action-disabled"
                            : ""
                        }`}
                      >
                        {status === "Pending" && (
                          <button
                            className="bids-container-row__action-counter"
                            onClick={() => {
                              setCounterOfferPopupData({
                                bidderId: bidderId,
                                beatId: beatId,
                              });
                              setShowCounterOfferPopup(true);
                            }}
                          >
                            Counter offer
                          </button>
                        )}
                        {(status === "Accepted" ||
                          status === "Rejected" ||
                          status === "Expired") && (
                          <button
                            className="bids-container-row__action-counter"
                            disabled={true}
                          >
                            Counter offer
                          </button>
                        )}

                        <button
                          className="bids-container-row__action-accept"
                          onClick={() => {
                            handleAcceptOffer(
                              beatId,
                              bidderId,
                              bidderName,
                              bidPrice / 100
                            );
                          }}
                          disabled={
                            status === "Accepted" ||
                            status === "Rejected" ||
                            status === "Expired"
                          }
                        >
                          Accept offer
                        </button>
                        <button
                          className="bids-container-row__action-reject"
                          onClick={() => {
                            handleRejectOffer(
                              beatId,
                              bidderId,
                              bidderName,
                              bidPrice / 100
                            );
                          }}
                          disabled={
                            status === "Accepted" ||
                            status === "Rejected" ||
                            status === "Expired"
                          }
                        >
                          Reject offer
                        </button>
                      </div>
                    </div>
                  );
                });
              })}
            {!sellerBids && (
              <div className="bids-container-row">
                <div className="empty-bids">No bids received</div>
              </div>
            )}
          </div>
        </section>
      )}

      {role === "buyer" && (
        <section className="beatsBid-buyer">
          <h2>Sent bids</h2>
          <div className="bids-container-buyer">
            <div className="bids-container-row bids-container-row-header">
              <div className="bids-container-row__date">Date</div>
              <div className="bids-container-row__name">Beat</div>
              <div className="bids-container-row__amount">Bid Amount</div>
              <div className="bids-container-row__status">Status</div>
              <div className="bids-container-row__action"></div>
            </div>

            {buyerBids &&
              buyerBids.map((bid) => {
                const status =
                  bid.status === "ACEEPTED"
                    ? "Accepted"
                    : bid.status === "REJECTED"
                    ? "Rejected"
                    : bid.status === "PENDING"
                    ? "Pending"
                    : "Expired";
                return (
                  <div className="bids-container-row" key={bid.id}>
                    <div className="bids-container-row__date">
                      {new Date(bid.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </div>
                    <div className="bids-container-row__name">{bid.name}</div>
                    <div className="bids-container-row__amount">
                      (
                      {bid.counter
                        ? formatCurrency(bid.counter)
                        : formatCurrency(bid.amount)}
                      )
                    </div>
                    <div
                      className="bids-container-row__status"
                      style={
                        status === "Pending"
                          ? { color: "#F29423" }
                          : status === "Accepted"
                          ? { color: "#2C7919" }
                          : status === "Rejected" || status === "Expired"
                          ? { color: "#F55446" }
                          : { color: "black" }
                      }
                    >
                      {status}
                    </div>
                    <div className="bids-container-row__action">
                      {status === "Pending" && bid.counter && (
                        <>
                          <button
                            className="bids-container-row__action-accept bids-container-row__action-counters"
                            onClick={() => {
                              handleAcceptCounterOffer(bid.id, bid.counter);
                            }}
                          >
                            Accept Counter Offer
                          </button>
                          <button
                            className="bids-container-row__action-reject bids-container-row__action-counters"
                            onClick={() => {
                              handleRejectCounterOffer(bid.id, bid.counter);
                            }}
                          >
                            Reject Counter Offer
                          </button>
                        </>
                      )}
                      {status === "Accepted" && (
                        <button
                          className="bids-container-row__action-addToCart"
                          onClick={() => {
                            handleAddToCart(bid.slug);
                          }}
                        >
                          <img src={cart} alt="" />
                          Add To Cart
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            {!buyerBids && (
              <div className="bids-container-row">
                <div className="empty-bids">No bids sent</div>
              </div>
            )}
          </div>
        </section>
      )}

      <Popup
        className="counter-offer-popup"
        open={isShowOfferConfirmation}
        onClose={() => {
          setIsShowOfferConfirmation(false);
        }}
        modal
      >
        <img
          className="counter-offer-popup__close"
          src={cross}
          alt=""
          onClick={() => {
            setIsShowOfferConfirmation(false);
          }}
        />
        <div className="counter-offer-popup-container">
          <img className="offer-confirmation" src={confirmImage} alt="" />
          <h4>{offerConfirmationMessage}</h4>
        </div>
      </Popup>
      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
      <Popup
        className="counter-offer-popup"
        open={showCounterOfferPopup}
        onOpen={() => {
          setShowCounterOfferPopup(true);
        }}
        onClose={() => {
          setShowCounterOfferPopup(false);
          setCounterOfferPopupData(null);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowCounterOfferPopup(false);
            }}
          />
          <div className="place-bid-popup-heading-container">
            <h3>Counter offer</h3>
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={warning} alt="" className="bid-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {counterBidErrorMessage}
            </Popup>
          </div>
          <div className="counter-offer-popup-container">
            <input
              type="text"
              placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}20`}
              value={counterBidInput}
              onChange={handleCounterBidInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleCounterOffer();
                }
              }}
            />
            <div className="button-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowCounterOfferPopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-container__submit"
                onClick={async (event) => {
                  await handleCounterOffer();
                }}
              >
                <img src={submit} alt="" />
                Submit Offer
              </button>
            </div>
          </div>
        </>
      </Popup>
    </>
  );
}

export default BeatsBid;
