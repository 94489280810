// api.js

const BASE_URL = process.env.REACT_APP_BACKEND_URL; // Replace with your API base URL
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const handleResponse = async (response) => {
  const contentType = response.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    // If response is JSON
    if (response.ok) {
      return { data: await response.json(), status: response.status };
    } else {
      const errorData = await response.json();
      return { error: errorData, status: response.status };
    }
  } else {
    // If response is plain text
    if (response.ok) {
      return { data: await response.text(), status: response.status };
    } else {
      const errorData = await response.text();
      return { error: errorData, status: response.status };
    }
  }
};

const api = {
  get: async (endpoint) => {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON.parse(tokenData).access_token : null;
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      // method: "GET",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
    });
    return handleResponse(response);
  },

  post: async (endpoint, body) => {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON.parse(tokenData).access_token : null;
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      body: JSON.stringify(body),
    });
    return handleResponse(response);
  },

  put: async (endpoint, body) => {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON.parse(tokenData).access_token : null;
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "PUT",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      body: JSON.stringify(body),
    });
    return handleResponse(response);
  },

  delete: async (endpoint) => {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON.parse(tokenData).access_token : null;
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "DELETE",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
    });
    return handleResponse(response);
  },
};

export default api;
