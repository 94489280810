import "./../stylesheets/accounts.css";
import { useState, useEffect } from "react";
import paypal from "./../../images/paypal.png";
import bank from "./../../images/bank2.png";
import paystackLogo from "./../../images/paystack-2.svg";
import cross from "./../../images/cross.svg";
import * as Sentry from "@sentry/react";
import add from "./../../images/add_button.svg";
import Popup from "reactjs-popup";
import { useNavigation } from "../../context/navigationContext";
import { useAuthContext } from "../../context/authContext";
import api from "../../utils/api";
import handleResponseStatus from "../../utils/handleResponseStatus";
function Accounts({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();

  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    const handleLoad = async () => {
      if (isAuthenticated) {
        try {
          const response = await api.get(`transfer-methods/accounts`);

          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
          const data = response.data;
          setAccounts(data.data);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };
    handleLoad();
  }, [navigate, isAuthenticated, setShowUnauthenticatedPopup]);

  const handleClickAccount = (id, type) => {
    if (type === "paypal") {
      navigate(`/withdraw/paypal/${id}`);
    } else if (type === "bank_transfer") {
      navigate(`/withdraw/bank/${id}`);
    } else if (type === "paystack") {
      navigate(`/withdraw/paystack/${id}`);
    }
  };

  const handlePaypal = async () => {
    if (isAuthenticated) {
      const body = { payment_method: "paypal" };

      try {
        const response = await api.post(`transfer-methods/add`, body);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem("withdraw_vendor_id", data.payment_account);

          window.location.href = data.link;
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const handleBank = async () => {
    navigate("/withdraw/add/bank");
  };

  const handlePaystack = async () => {
    navigate("/withdraw/add/paystack");
  };

  const handleDelete = async (event) => {
    event.stopPropagation();
    if (isAuthenticated) {
      const item = event.target.closest(".accounts-item");
      const id = event.target.closest(".accounts-item").dataset.id;

      try {
        const response = await api.delete(`transfer-methods/${id}`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        item.remove();
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };
  return (
    <section className="accounts-section">
      <h3 className="accounts-heading">My Accounts</h3>
      <div className="accounts-div">
        <div className="accounts-top">
          <h3>
            {accounts.length} {accounts.length === 1 ? "Account" : "Accounts"}{" "}
            Owned
          </h3>
          <Popup
            className="create-playlist-popup wallet-popup"
            trigger={
              <button className="account-add">
                <img src={add} alt="" />
                Add Account
              </button>
            }
            modal
          >
            {(close) => (
              <>
                <div className="wallet-popup-heading">
                  <h3>Add Account</h3>
                </div>
                <img
                  className="counter-offer-popup__close"
                  src={cross}
                  alt=""
                  onClick={close}
                />
                <div className="wallet-popup-container">
                  {!accounts.some(
                    (item) => item.payment_method.toLowerCase() === "paypal"
                  ) && (
                    <button onClick={handlePaypal}>
                      Paypal
                      <img src={paypal} alt="" />
                    </button>
                  )}

                  {!accounts.some(
                    (item) =>
                      item.payment_method.toLowerCase() === "bank_transfer"
                  ) && (
                    <button onClick={handleBank}>
                      Bank transfer
                      <img src={bank} alt="" />
                    </button>
                  )}

                  {!accounts.some(
                    (item) => item.payment_method.toLowerCase() === "paystack"
                  ) && (
                    <button onClick={handlePaystack}>
                      Paystack
                      <img src={paystackLogo} alt="" />
                    </button>
                  )}
                </div>
              </>
            )}
          </Popup>
        </div>
        <div className="accounts-items">
          {accounts.map((account, index) => {
            return (
              <div className="accounts-item" data-id={account.id} key={index}>
                <div className="accounts-item-details">
                  <p>{index + 1}</p>
                  <img
                    alt="paypal"
                    src={
                      account.payment_method === "paypal"
                        ? paypal
                        : account.payment_method === "paystack"
                        ? paystackLogo
                        : bank
                    }
                  ></img>
                  {account.payment_method === "paypal" && (
                    <h3>{account.email_address}</h3>
                  )}
                  {account.payment_method === "bank_transfer" && (
                    <h3>
                      {account.bank_name} - {account.acc_num.slice(-4)}
                    </h3>
                  )}
                  {account.payment_method === "paystack" && (
                    <h3>
                      {account.bank_name} - {account.acc_num.slice(-4)}
                    </h3>
                  )}
                </div>
                <div className="account-options">
                  <button
                    className="account-withdraw"
                    onClick={() => {
                      handleClickAccount(account.id, account.payment_method);
                    }}
                  >
                    Withdraw
                  </button>
                  <button className="account-delete" onClick={handleDelete}>
                    Delete Account
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
export default Accounts;
