import React, { createContext, useContext, useReducer } from "react";
import playerReducer from "./../reducers/playerReducers";

const PlayerContext = createContext();

export const initialPlayerState = {
  queue: [],
  isPlaying: false,
  currentTime: 0,
  totalTime: 0,
  volume: 0,
  isLiked: false,
  currentItemIndex: null,
  infoPopupMessage: null,
  mobileExpanded: false,
  starting: true,
};

export const PlayerProvider = ({ children }) => {
  const [playerState, playerDispatch] = useReducer(
    playerReducer,
    initialPlayerState
  );

  return (
    <PlayerContext.Provider value={{ playerState, playerDispatch }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerContext = () => useContext(PlayerContext);
