import "./../stylesheets/playlistLanding.css";
import "./../../index.css";
import "./../../Fonts/fonts.css";
import add from "./../../images/Add-Orange.svg";
import art from "./../../images/1.png";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import CreatePlaylistPopup from "./../createPlaylistPopup";
import * as Sentry from "@sentry/react";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function Playlists({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const [playlists, setPlaylists] = useState(null);
  const [isCreatePlaylistPopupOpen, setIsCreatePlaylistPopupOpen] =
    useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [nextPage, setNextPage] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    const getPlaylists = async () => {
      try {
        const response = await api.get(`playlist`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setNextPage(data.links.next?.split("api/")[1]);
        setPlaylists(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getPlaylists();
  }, [navigate, isAuthenticated, setShowUnauthenticatedPopup]);

  const location = useLocation();

  useEffect(() => {
    if (location && location.state && location.state.redirectByDelete) {
      setPopupMessage("Playlist deleted successfully");
      setShowMessagePopup(true);
    }
  }, [location]);
  useEffect(() => {
    const getNextPage = async () => {
      if (nextPage) {
        const response = await api.get(nextPage);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setPlaylists([...playlists, ...data.data]);
        setNextPage(data.links.next?.split("api/")[1]);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [playlists, nextPage, navigate]);
  const getPlaylists = async () => {
    try {
      const response = await api.get(`playlist`);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      setNextPage(data.links.next?.split("api/")[1]);
      setPlaylists(data.data);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <section className="playlists">
        <div className="playlists-heading">
          <h2>My Playlists</h2>
          <button
            className="create-playlist-button"
            onClick={() => {
              setIsCreatePlaylistPopupOpen(true);
            }}
          >
            <img src={add} alt="" />
            Create Playlist
          </button>
        </div>
        {playlists && (
          <div className="playlists-cards-container">
            {playlists.map((playlist) => {
              return (
                <div
                  className="playlist-card"
                  data-id={playlist.id}
                  key={playlist.id}
                  onClick={() => {
                    navigate(`/playlists/${playlist.id}`);
                  }}
                >
                  <img src={playlist.image ? playlist.image : art} alt="" />
                  <h3>{playlist.name}</h3>
                  <h4>
                    {playlist.items_count > 1
                      ? `${playlist.items_count} Tracks`
                      : playlist.items_count === 0
                      ? "No Tracks"
                      : "1 Track"}
                  </h4>
                </div>
              );
            })}
          </div>
        )}
      </section>

      {isCreatePlaylistPopupOpen && (
        <CreatePlaylistPopup
          isCreatePlaylistPopupOpen={isCreatePlaylistPopupOpen}
          setIsCreatePlaylistPopupOpen={setIsCreatePlaylistPopupOpen}
          getPlaylists={getPlaylists}
          setShowMessagePopup={setShowMessagePopup}
          setPopupMessage={setPopupMessage}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}

      <Popup
        className="add-queue-popup"
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
    </>
  );
}

export default Playlists;
