// utils/handleResponseStatus.js
import logdna from "@logdna/browser";

const handleResponseStatus = (
  response,
  navigate,
  setShowUnauthenticatedPopup
) => {
  if (response.status === 429) {
    navigate("/error/requests");
  } else if (response.status === 500) {
    logdna.error(`Server Error: ${response}`, {}, "error");
    navigate("/error/server");
  } else if (response.status === 404) {
    navigate("/error/notfound");
  } else if (response.status === 403) {
    setShowUnauthenticatedPopup(true);
  } else if (response.status === 409) {
    navigate("/error/unverified");
  }
};

export default handleResponseStatus;
