import { combineReducers } from "redux";
import authReducer from "./authReducers";
import currencyReducer from "./currencyReducers";
import playerReducer from "./playerReducers";

const rootReducer = combineReducers({
  auth: authReducer,
  currency: currencyReducer,
  player: playerReducer,
});

export default rootReducer;
