import image from "./../../images/oops.jpg";
import "./../stylesheets/notFound.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function UploadFailure({ typeUpload }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const buttonClick = () => {
    navigate(
      `/user/uploads/${
        typeUpload === "Album"
          ? "albums"
          : typeUpload === "Song"
          ? "music"
          : "beats"
      }`
    );
  };
  const homeClick = () => {
    navigate("/");
  };
  return (
    <section className="error-section">
      <div className="error-div">
        <div className="error-image-div">
          <img alt="" src={image}></img>
        </div>
        <div className="product-not-found-description">
          Upload Failed! Your {typeUpload} has not been uploaded. You will have
          to re-upload it.
        </div>
        <div className="buttons__failure">
          <button className="save-changes" onClick={buttonClick}>
            Upload Again?
          </button>
          <button className="save-changes" onClick={homeClick}>
            Home
          </button>
        </div>
      </div>
    </section>
  );
}
export default UploadFailure;
