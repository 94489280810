import cross from "./../../images/cross-black.svg";
import Popup from "reactjs-popup";

export const PlayerMobileQueue = ({
  showMobileQueuePopup,
  setShowMobileQueuePopup,
  queue,
  handlePlay,
  currentItem,
}) => {
  return (
    <Popup
      className="player-mobile-queue-popup"
      open={showMobileQueuePopup}
      onClose={() => {
        setShowMobileQueuePopup(false);
      }}
      modal
      arrow={false}
    >
      <div className="queue-popup-header">
        Playing Queue
        <img
          src={cross}
          alt=""
          onClick={() => {
            setShowMobileQueuePopup(false);
          }}
        />
      </div>
      {queue.map((item, index) => {
        return (
          <div
            className={`queue-popup-row ${
              item?.slug === currentItem?.slug ? "queue-popup-row-playing" : ""
            }`}
            data-id={item?.slug}
            key={index}
            data-index={index}
            onClick={(event) => {
              setShowMobileQueuePopup(false);
              handlePlay(item);
            }}
          >
            <div className="track-number">{index + 1}</div>
            <img
              alt=""
              src={item?.artwork_url ? item?.artwork_url : item?.cover_path}
            ></img>
            <div className="track-details">
              <h4>{item?.name}</h4>
              {queue && (
                <p>
                  {item?.producer ? item?.producer.name : item?.artist.name}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </Popup>
  );
};
