import "./stylesheets/player.css";
import * as Sentry from "@sentry/react";
import cart from "./../images/cart.svg";
import cross from "./../images/cross.svg";
import bid from "./../images/bid.png";
import player_play from "./../images/player_play.svg";
import player_pause from "./../images/pause.svg";
import info from "./../images/info.svg";
import submit from "./../images/bid.png";
import accept from "./../images/accept.svg";
import warning from "./../images/warning.svg";
import contribute from "./../images/contribute.svg";
import tool from "./../images/tool-tip.svg";
import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { useNavigation } from "../context/navigationContext";
import { useAuthContext } from "../context/authContext";
import { useCurrencyContext } from "../context/currencyContext";
import api from "../utils/api";
import { usePlayerContext } from "../context/playerContext";
import { pause, play } from "../actions/playerActions";
import ReactPlayer from "react-player";
import handleResponseStatus from "./../utils/handleResponseStatus";
const nonExclusiveBullets = [
  "Untagged MP3 + WAV",
  "Stems / Trackout files",
  "Sell up to 20,000 copies",
  "LIMITED to 100,000 streams",
  "Paid performances",
  "Radio airplay",
  "Producer still owns rights to item",
];

const exclusiveBullets = [
  "Untagged MP3 + WAV",
  "Stems / Trackout files",
  "Sell unlimited copies",
  "UNLIMITED distribution",
  "Keep 100% royalties",
  "Paid performances",
  "Radio airplay",
  "Own exclusive rights",
];

function BuyPopup({ beat, showBuyPopup, setShowBuyPopup, handleAddToCart }) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const {
    selectedCurrency,
    rates,
    formatCurrency: formatSelectedCurrency,
    convertRate,
  } = useCurrencyContext();

  const [isPlaceBidPopupOpen, setIsPlaceBidPopupOpen] = useState(false);
  const [bidInput, setBidInput] = useState();
  const [bidErrorMessage, setBidErrorMessage] = useState();
  const [isShowOfferConfirmation, setIsShowOfferConfirmation] = useState(false);
  const [offerConfirmationMessage, setOfferConfirmationMessage] = useState("");

  const [showSupportPopup, setShowSupportPopup] = useState(false);
  const handleBidInputChange = (event) => {
    setBidInput(formatCurrency(event.target.value));
    if (event.target.value === "£" || event.target.value === "N") {
      document.querySelector(".bid-error").classList.remove("hidden");
      return;
    }
    if (event.target.value.length < 1) {
      setBidErrorMessage("The input field cannot be empty!");
      document.querySelector(".bid-error").classList.remove("hidden");
    } else {
      document.querySelector(".bid-error").classList.add("hidden");
    }
  };

  function formatCurrency(value) {
    let value1 = value?.replace(/[^0-9]/g, "");
    value1 = `${selectedCurrency === "GBP" ? "£" : "N"}${value1}`;

    return value1;
  }

  const handleSubmitBid = async (event) => {
    event.preventDefault();

    if (!bidInput || bidInput.length <= 1) {
      setBidErrorMessage("The input field cannot be empty!");
      document.querySelector(".bid-error").classList.remove("hidden");
      return;
    }
    try {
      const response = await api.post(`bids/create`, {
        beat: beat.id,
        price:
          selectedCurrency === "GBP"
            ? bidInput.slice(1) * 100
            : ((bidInput.slice(1) / rates) * 100).toFixed(0),
      });
      handleResponseStatus(response, navigate);
      if (response.status === 422) {
        setBidErrorMessage("You have already placed a bid on this item!");
        document.querySelector(".bid-error").classList.remove("hidden");
        return;
      }
      setIsPlaceBidPopupOpen(false);
      setOfferConfirmationMessage(
        `Your bid has been sent! Brace for response!`
      );
      setIsShowOfferConfirmation(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleSubmitSupport = async (id) => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
    if (!bidInput || bidInput.length <= 1) {
      setBidErrorMessage("The input field cannot be empty!");
      document.querySelector(".bid-error").classList.remove("hidden");
      return;
    }
    if (convertRate(bidInput.slice(1)) <= beat.price / 100) {
      setBidErrorMessage("The price must be higher than the item price!");
      document.querySelector(".bid-error").classList.remove("hidden");
      return;
    }
    handleAddToCart(id, convertRate(bidInput.slice(1)));
    setShowSupportPopup(false);
  };

  const { playerState, playerDispatch } = usePlayerContext();

  const { currentItemIndex, currentTime, totalTime, isPlaying, queue } =
    playerState;

  const [samePlayers, setSamePlayer] = useState(false);

  useEffect(() => {
    const isSamePlayers = () => {
      if (currentItemIndex === null || !queue[currentItemIndex]) {
        return false;
      }

      if (beat.slug !== queue[currentItemIndex].slug) {
        return false;
      }

      return true;
    };

    if (beat) {
      setSamePlayer(isSamePlayers());
    }
  }, [currentItemIndex, queue, beat]);

  const [popupPlayerPlaying, setPopupPlayerPlaying] = useState(false);
  const [popupPlayerTotalTime, setPopupPlayerTotalTime] = useState(null);
  const [popupPlayerCurrentTime, setPopupPlayerCurrentTime] = useState(null);
  const popupPlayerRef = useRef(null);

  const onDuration = (duration) => {
    setPopupPlayerTotalTime(duration * 1000);
  };

  const onProgress = (event) => {
    setPopupPlayerCurrentTime(event.playedSeconds * 1000);
  };

  const isPlayerPlaying = () => {
    if (samePlayers) {
      return isPlaying;
    }

    return popupPlayerPlaying;
  };

  const getCurrentTime = () => {
    if (samePlayers) {
      return currentTime;
    }

    return popupPlayerCurrentTime;
  };

  const getTotalTime = () => {
    if (samePlayers) {
      return totalTime;
    }

    return popupPlayerTotalTime;
  };

  const handlePlayPauseButton = () => {
    if (samePlayers) {
      if (isPlaying) {
        playerDispatch(pause());
      } else {
        playerDispatch(play());
      }
      return;
    }

    setPopupPlayerPlaying(!popupPlayerPlaying);
  };

  const { user, purchases } = useAuthContext();

  const [playUrl, setPlayUrl] = useState(null);

  useEffect(() => {
    const getCurrentItemType = () => {
      let type;

      if (beat.play_url) {
        type = "beats";
      } else {
        type = "songs";
      }

      return type;
    };

    const getUrl = async () => {
      const type = getCurrentItemType();

      if (!user || !purchases) {
        return beat.play_url || beat.preview_path;
      }

      let purchased = [];

      if (type === "beats") {
        purchased = purchases.beats;
      } else {
        purchased = [...purchases.songs];

        purchases.albums.forEach((album) => {
          album.tracks.forEach((track) => {
            purchased.push(track);
          });
        });
      }

      for (let i = 0; i < purchased.length; i++) {
        if (purchased[i].slug === beat.slug) {
          const response = await api.get(`${type}/${beat.slug}/play`);
          return response.data;
        }
      }

      return beat.play_url || beat.preview_path;
    };

    const setUrl = async () => {
      const purchaseUrl = await getUrl();
      setPlayUrl(purchaseUrl);
    };

    if (beat && !samePlayers) {
      setUrl();
    }
  }, [beat, user, purchases, samePlayers]);

  return (
    <>
      <Popup
        className="player-buy-popup"
        modal
        open={showBuyPopup}
        onOpen={() => {
          setShowBuyPopup(true);
        }}
        onClose={() => {
          if (popupPlayerPlaying) {
            handlePlayPauseButton();
          }
          setShowBuyPopup(false);
        }}
        nested
      >
        {beat && (
          <div className="buy-popup-container">
            <button
              className="close-popup"
              onClick={() => {
                // if (document.querySelector(".popup-audio-player")) {
                //   document.querySelector(".popup-audio-player").pause();
                // }
                if (popupPlayerPlaying) {
                  handlePlayPauseButton();
                }
                setShowBuyPopup(false);
              }}
            >
              <img src={cross} alt="" />
            </button>

            <div className="buy-popup-left-container">
              <div className="buy-popup-left">
                <img
                  className="buy-popup-album-art"
                  src={beat.artwork_url ? beat.artwork_url : beat.cover_path}
                  alt=""
                />
                {!samePlayers && playUrl && (
                  <ReactPlayer
                    ref={popupPlayerRef}
                    url={playUrl}
                    loop={true}
                    playing={popupPlayerPlaying}
                    onProgress={onProgress}
                    progressInterval={50}
                    onDuration={onDuration}
                    width={0}
                    height={0}
                    // onError={onError}
                    // onReady={onReady}
                    volume={0.5}
                  />
                )}
                {(beat.preview_path || beat.play_url) && (
                  <div className="buy-popup-player">
                    <div
                      className="popup_player"
                      onClick={handlePlayPauseButton}
                    >
                      <img
                        alt=""
                        src={isPlayerPlaying() ? player_pause : player_play}
                        className="player_play_image"
                      />
                    </div>
                    <audio
                      src={beat.play_url ? beat.play_url : beat.preview_path}
                      className="popup-audio-player"
                    />
                    <p className="player_current_time">
                      {new Date(getCurrentTime()).toISOString().substr(14, 5)}
                    </p>
                    <div className="popup_player-duration">
                      <span
                        className="player-bar"
                        style={{
                          width: (getCurrentTime() / getTotalTime()) * 100,
                        }}
                      />
                    </div>
                    <p className="player_total_duration">
                      {new Date(getTotalTime()).toISOString().substr(14, 5)}
                    </p>
                  </div>
                )}
                <h4>{beat.name}</h4>
                <p>
                  {beat.producer
                    ? beat.producer.name
                    : beat.artist
                    ? beat.artist.name
                    : "Not Available"}
                </p>
                <p>
                  Published:{" "}
                  {new Date(beat.created_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </p>
                {beat.bpm && <p>BPM: {beat.bpm}</p>}
                <div className="tags-container">
                  {beat.genres &&
                    beat.genres.map((genre) => (
                      <div className="tag" key={genre.id}>
                        {genre.name}
                      </div>
                    ))}
                </div>
              </div>
              <div className="buy-popup-price">
                Price:&nbsp;&nbsp;&nbsp;&nbsp;
                {beat.price === 0 ? "Free" : formatSelectedCurrency(beat.price)}
              </div>
            </div>

            <div className="buy-popup-right-container">
              {!beat.is_exclusive && !beat.uploader && (
                <>
                  <div className="buy-popup-right">
                    <div className="buy-popup-type">Non-Exclusive License</div>
                    <div className="buy-popup-right-bullets">
                      {nonExclusiveBullets.map((bullet, index) => {
                        return (
                          <div className="buy-popup-right-row" key={index}>
                            <div className="bullet"></div>
                            <p>{bullet}</p>
                          </div>
                        );
                      })}
                    </div>

                    <div className="buy-popup-right-buy">
                      {!beat.is_free && (
                        <div
                          className="buy"
                          onClick={(event) => {
                            if (document.querySelector(".popup-audio-player")) {
                              document
                                .querySelector(".popup-audio-player")
                                .pause();
                            }
                            handleAddToCart(beat.slug);

                            setShowBuyPopup(false);
                          }}
                          data-id={beat.id}
                          style={
                            beat.uploader
                              ? { width: "100%", marginRight: 0 }
                              : null
                          }
                        >
                          <img src={cart} alt="" />
                          Add To Cart
                        </div>
                      )}
                      {beat.is_biddable && beat.is_free === false && (
                        <Popup
                          className="login-error-popup sell-item-error-popup"
                          trigger={
                            <div
                              className="bid"
                              onClick={() => {
                                document
                                  .querySelector(".popup-audio-player")
                                  .pause();
                                setBidInput(null);
                                setIsPlaceBidPopupOpen(true);
                              }}
                              data-id={beat.id}
                            >
                              <img src={bid} alt="" />
                              Make a bid
                            </div>
                          }
                          on="hover"
                          position="top center"
                          arrow={false}
                          disabled={isAuthenticated}
                        >
                          You must be logged in to place a bid!
                        </Popup>
                      )}
                    </div>
                    {beat.uploader && beat.pay_what_you_like && (
                      <div
                        className="buy-popup-right-contribute"
                        onClick={() => {
                          setShowSupportPopup(true);
                        }}
                      >
                        <img src={contribute} alt="" />
                        Pay What You Want
                        <Popup
                          className="upload-error-popup"
                          trigger={<img src={tool} alt="" />}
                          position="top center"
                          on="hover"
                        >
                          Your generous support directly funds the artist's
                          work.
                        </Popup>
                      </div>
                    )}
                  </div>
                </>
              )}

              {!beat.is_exclusive && beat.uploader && (
                <>
                  <div className="buy-popup-right">
                    <div className="buy-popup-right-bullets">
                      <div className="buy-popup-right-row">
                        <p style={{ width: "100%" }}>
                          Find your new favorite tunes with us! Each track here
                          is crafted by gifted artists eager to soundtrack your
                          moments.
                        </p>
                      </div>

                      <div className="buy-popup-type" style={{ margin: "0" }}>
                        What You'll Get:
                      </div>
                      <div className="buy-popup-right-row">
                        <div className="bullet"></div>
                        <p>High-quality MP3 download for offline listening</p>
                      </div>
                      <div className="buy-popup-right-row">
                        <div className="bullet"></div>
                        <p>
                          This purchase includes unlimited streaming via our app
                          and also grants you a high-quality MP3 download
                        </p>
                      </div>

                      <div className="buy-popup-type" style={{ margin: "0" }}>
                        Like this song?
                      </div>
                      <div className="buy-popup-right-row">
                        <p style={{ width: "100%" }}>
                          Hit 'Add to Cart' and enjoy {beat.artist.name}'s "
                          {beat.name}" courtesy of {beat.label.name}
                        </p>
                      </div>
                    </div>

                    {!beat.is_free && (
                      <div className="buy-popup-right-buy">
                        <div
                          className="buy"
                          onClick={(event) => {
                            if (document.querySelector(".popup-audio-player")) {
                              document
                                .querySelector(".popup-audio-player")
                                .pause();
                            }
                            handleAddToCart(beat.slug);

                            setShowBuyPopup(false);
                          }}
                          data-id={beat.id}
                          style={
                            beat.uploader
                              ? { width: "100%", marginRight: 0 }
                              : null
                          }
                        >
                          <img src={cart} alt="" />
                          Add To Cart
                        </div>
                      </div>
                    )}
                    {beat.uploader && beat.pay_what_you_like && (
                      <div
                        className="buy-popup-right-contribute"
                        onClick={() => {
                          setShowSupportPopup(true);
                        }}
                      >
                        <img src={contribute} alt="" />
                        Pay What You Want
                        <Popup
                          className="upload-error-popup"
                          trigger={<img src={tool} alt="" />}
                          position="top center"
                          on="hover"
                        >
                          Your generous support directly funds the artist's
                          work.
                        </Popup>
                      </div>
                    )}
                  </div>
                </>
              )}

              {beat.is_exclusive && (
                <>
                  <div className="buy-popup-right">
                    <div className="buy-popup-type">Exclusive License</div>
                    <div className="buy-popup-right-bullets">
                      {exclusiveBullets.map((bullet, index) => {
                        return (
                          <div className="buy-popup-right-row" key={index}>
                            <div className="bullet"></div>
                            <p>{bullet}</p>
                          </div>
                        );
                      })}
                    </div>
                    <div className="buy-popup-right-buy">
                      {!beat.is_free && (
                        <div
                          className="buy"
                          onClick={(event) => {
                            document
                              .querySelector(".popup-audio-player")
                              .pause();
                            handleAddToCart(beat.slug);
                            setShowBuyPopup(false);
                          }}
                          data-id={beat.id}
                        >
                          <img src={cart} alt="" />
                          Add To Cart
                        </div>
                      )}
                      {beat.is_biddable && beat.is_free === false && (
                        <div
                          className="bid"
                          onClick={() => {
                            document
                              .querySelector(".popup-audio-player")
                              .pause();
                            setBidInput(null);
                            setIsPlaceBidPopupOpen(true);
                          }}
                          data-id={beat.id}
                        >
                          <img src={bid} alt="" />
                          Make a bid
                        </div>
                      )}
                    </div>
                    {beat.uploader && beat.pay_what_you_like && (
                      <div
                        className="buy-popup-right-contribute"
                        onClick={() => {
                          setShowSupportPopup(true);
                        }}
                      >
                        <img src={contribute} alt="" />
                        Pay What You Want
                        <Popup
                          className="upload-error-popup"
                          trigger={<img src={tool} alt="" />}
                          position="top center"
                          on="hover"
                        >
                          Your generous support directly funds the artist's
                          work.
                        </Popup>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Popup>

      <Popup
        className="place-bid-popup"
        open={isPlaceBidPopupOpen}
        onOpen={() => {
          if (!isAuthenticated) {
            navigate("/login");
            setShowBuyPopup(false);
            setIsPlaceBidPopupOpen(false);
            return;
          }

          setIsPlaceBidPopupOpen(true);
        }}
        onClose={() => {
          setIsPlaceBidPopupOpen(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setBidInput(0);
              setIsPlaceBidPopupOpen(false);
            }}
          />
          <div className="place-bid-popup-container">
            <div className="place-bid-popup-heading-container">
              <h3>Place a bid</h3>
              <Popup
                className="upload-error-popup"
                trigger={
                  <img src={warning} alt="" className="bid-error hidden"></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                {bidErrorMessage}
              </Popup>
            </div>
            <input
              type="text"
              placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}20`}
              value={bidInput}
              onChange={handleBidInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmitBid(event);
                }
              }}
            />

            <div className="button-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setBidInput(null);
                  setIsPlaceBidPopupOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-container__submit"
                onClick={async (event) => {
                  await handleSubmitBid(event);
                }}
              >
                <img src={submit} alt="" />
                Submit Bid
              </button>
            </div>
          </div>
          <div className="place-bid-popup-info">
            <h5>
              <img src={info} alt="" />
              Place a bid
            </h5>
            <p>
              Submit your proposal to the item maker and wait for their reply
            </p>
            <p>
              Once the item maker approves your proposal, it will be added to
              your shopping cart for you to complete the transaction
            </p>
          </div>
        </>
      </Popup>

      <Popup
        className="counter-offer-popup"
        open={isShowOfferConfirmation}
        onClose={() => {
          setIsShowOfferConfirmation(false);
        }}
        modal
      >
        <img
          className="counter-offer-popup__close"
          src={cross}
          alt=""
          onClick={() => {
            setIsShowOfferConfirmation(false);
          }}
        />
        <div className="counter-offer-popup-container">
          <img className="offer-confirmation" src={accept} alt="" />
          <h4>{offerConfirmationMessage}</h4>
        </div>
      </Popup>

      <Popup
        className="place-bid-popup"
        open={showSupportPopup}
        onOpen={() => {
          setShowSupportPopup(true);
        }}
        onClose={() => {
          setShowSupportPopup(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowSupportPopup(false);
            }}
          />
          <div className="place-bid-popup-container support-input-popup">
            <div className="place-bid-popup-heading-container">
              <h3>Support the Artist</h3>
              <Popup
                className="upload-error-popup"
                trigger={
                  <img src={warning} alt="" className="bid-error hidden"></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                {bidErrorMessage}
              </Popup>
            </div>
            <label>Enter your amount</label>
            {beat && (
              <input
                type="text"
                placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}20`}
                value={bidInput}
                data-id={beat.id}
                onChange={handleBidInputChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSubmitSupport(beat.slug);
                  }
                }}
              />
            )}

            <div className="button-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowSupportPopup(false);
                }}
              >
                Cancel
              </button>
              {beat && (
                <button
                  className="button-container__submit"
                  data-id={beat.id}
                  onClick={async (event) => {
                    await handleSubmitSupport(beat.slug);
                  }}
                >
                  <img src={submit} alt="" />
                  Support
                </button>
              )}
            </div>
          </div>
        </>
      </Popup>
    </>
  );
}

export default BuyPopup;
