import "./../stylesheets/finishRegistration.css";
import Mail from "./../../images/Mail.svg";
import { useEffect, useState } from "react";
import UploadProfile from "./../upload/UploadProfile";
import { useNavigation } from "../../context/navigationContext";
import { useAuthContext } from "../../context/authContext";

function FinishRegistration({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const [showUploadProfile, setShowUploadProfile] = useState(false);
  const { user } = useAuthContext();

  const [redirectCountdown, setRedirectCountdown] = useState(10);

  useEffect(() => {
    if (!showUploadProfile && redirectCountdown === 0 && user) {
      setShowUploadProfile(true);
    }
  }, [redirectCountdown, showUploadProfile, navigate, user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (redirectCountdown > 0) {
        setRedirectCountdown(redirectCountdown - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [redirectCountdown]);

  const handleLogoUpload = () => {
    if (user) {
      setShowUploadProfile(true);
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      {!showUploadProfile && (
        <section className="finishRegistration">
          {user && (
            <div className="message-div-1">
              <img alt="" src={Mail}></img>
              <h4>You're one step closer!</h4>
              <p>
                We have sent an email to {user.email} with a link to verify your
                email. You need to verify your email to complete registration
              </p>
              <p>
                You will be redirected to complete your registration in{" "}
                {redirectCountdown} seconds
              </p>
              <button className="finish-redirect" onClick={handleLogoUpload}>
                Take me there now
              </button>
            </div>
          )}
        </section>
      )}
      {showUploadProfile && (
        <UploadProfile
          user={user}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}
    </>
  );
}

export default FinishRegistration;
