import logout from "./../../images/logout.svg";
import shoppingCart from "./../../images/Icon-shopping-cart.png";
import userIcon from "./../../images/person-circle-outline.svg";
import bellIcon from "./../../images/bell-icon.png";
import playlist from "./../../images/playlist.svg";
import message from "./../../images/message1.svg";
import basket from "./../../images/basket.svg";
import wallet from "./../../images/wallet.svg";
import producer from "./../../images/disk.svg";
import cross from "./../../images/cross.svg";
import disk from "./../../images/disk.svg";
import Music from "./../../images/Music.svg";

import { Link } from "react-router-dom";
import { Sidebar, SubMenu, MenuItem, Menu } from "react-pro-sidebar";

export default function NavbarSidebar({
  user,
  showSidebar,
  setShowSidebar,
  isAuthenticated,
  setShowMobileNotificationPopup,
  unreadNotifications,
  handleLoginButton,
  handleLogout,
}) {
  return (
    <>
      <Sidebar
        onBackdropClick={() => setShowSidebar(false)}
        toggled={showSidebar}
        breakPoint="always"
      >
        <img
          className="sidebar-close-button"
          src={cross}
          alt=""
          onClick={() => {
            setShowSidebar(false);
          }}
        />
        <div className="sidebar-button-container">
          {user && user.role === "seller" && (
            <Menu>
              <SubMenu label="Upload">
                <MenuItem className="first__item">
                  <Link
                    className="sidebar-register-button"
                    style={{ marginBottom: "15%" }}
                    to="/upload/beat "
                    onClick={() => {
                      setShowSidebar(false);
                    }}
                  >
                    Upload Beat
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    className="sidebar-register-button"
                    style={{ marginBottom: "15%" }}
                    to="/upload/song "
                    onClick={() => {
                      setShowSidebar(false);
                    }}
                  >
                    Upload Song
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    className="sidebar-register-button"
                    style={{ marginBottom: "15%" }}
                    to="/upload/album "
                    onClick={() => {
                      setShowSidebar(false);
                    }}
                  >
                    Upload Album
                  </Link>
                </MenuItem>
              </SubMenu>
            </Menu>
          )}
          <Link
            className="sidebar-button"
            to="/music"
            onClick={() => {
              setShowSidebar(false);
            }}
          >
            <img src={Music} alt="" />
            <span>Music</span>
          </Link>
          <Link
            className="sidebar-button"
            to="/beats"
            onClick={() => {
              setShowSidebar(false);
            }}
          >
            <img src={Music} alt="" />
            <span>Beats</span>
          </Link>

          <Link
            className="sidebar-button"
            to="/producers"
            onClick={() => {
              setShowSidebar(false);
            }}
          >
            <img src={producer} alt="" />
            <span>Producers</span>
          </Link>
          {isAuthenticated && (
            <>
              <Link
                className="sidebar-button"
                to="/user/uploads/beats"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={Music} alt="" />
                <span>My Beats</span>
              </Link>
              <Link
                className="sidebar-button"
                to="/user/uploads/music"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={Music} alt="" />
                <span>My Music</span>
              </Link>
              <Link
                className="sidebar-button"
                to="/playlists"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={playlist} alt="" />
                <span>My Playlists</span>
              </Link>

              <Link
                className="sidebar-button"
                to="/wallet"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={wallet} alt="" />
                <span>My Wallet</span>
              </Link>
              <Link
                className="sidebar-button"
                to="/messages"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={message} alt="" />
                <span>Messages</span>
              </Link>
              <Link
                className="sidebar-button"
                to="/purchases/beats"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={basket} alt="" />
                <span>Purchases</span>
              </Link>
              <Link
                className="sidebar-button"
                to="/bids"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={disk} alt="" />
                <span>Beats Bids</span>
              </Link>
            </>
          )}
          <Link
            className="sidebar-button"
            to="/cart"
            onClick={() => {
              setShowSidebar(false);
            }}
          >
            <img src={shoppingCart} alt="" />
            <span>Cart</span>
          </Link>

          {isAuthenticated && (
            <div
              className="sidebar-button"
              onClick={() => {
                setShowSidebar(false);
                setShowMobileNotificationPopup(true);
              }}
            >
              <img src={bellIcon} alt="" />
              {unreadNotifications && (
                <div className="notification-unread-dot mobile" />
              )}
              <span>Notifications</span>
            </div>
          )}
          {isAuthenticated && (
            <>
              <Link
                className="sidebar-button"
                to={user.role === "seller" ? "/user/uploads/beats":"/user/purchased/beats"}
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={userIcon} alt="" />
                <span>Profile</span>
              </Link>
              <div
                className="sidebar-button"
                onClick={() => {
                  setShowSidebar(false);
                  handleLogout();
                }}
              >
                <img alt="" src={logout} />
                <span>Logout</span>
              </div>
            </>
          )}
        </div>
        <div className="sidebar-user-button-container">
          {!isAuthenticated && (
            <>
              <div
                className="sidebar-button"
                onClick={() => {
                  handleLoginButton();
                  setShowSidebar(false);
                }}
              >
                <span>Login</span>
              </div>
              <Link
                className="sidebar-register-button"
                to="/signup"
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                Register
              </Link>
            </>
          )}
        </div>
      </Sidebar>
    </>
  );
}
