import "./../stylesheets/beatsLanding.css";
import Producer1 from "./../../images/user.png";
import { TailSpin } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";
import ImgLoader from "./../imgLoader";
import { useNavigation } from "../../context/navigationContext";

function Producers({ producers, loading }) {
  const { navigateToProducer } = useNavigation();
  const handleProducerClick = (event) => {
    const id = event.target.closest(".producer--item").dataset.id;
    navigateToProducer(id);
  };

  const [loaderCount, setLoaderCount] = useState(10);

  useEffect(() => {
    const width = window.innerWidth;

    if (width > 900) {
      setLoaderCount(10);
    } else if (width > 600) {
      setLoaderCount(6);
    } else if (width > 300) {
      setLoaderCount(4);
    } else {
      setLoaderCount(2);
    }
  }, []);

  return (
    <section className="producers ">
      <div className="producer-artist">
        {!producers &&
          Array.from({ length: loaderCount }).map((_, index) => (
            <div className="producer--item" key={index}>
              <div className="producer-artist-img-holder">
                <Skeleton className="artist-img" circle={true} />
              </div>
              <span>
                <Skeleton count={0.5} containerClassName="skeleton-loading" />
              </span>
              <p>
                <Skeleton count={0.7} containerClassName="skeleton-loading" />
              </p>
            </div>
          ))}
        {producers &&
          producers.map((producer, index) => {
            return (
              <div
                className="producer--item"
                key={index}
                data-id={producer.slug}
                onClick={handleProducerClick}
              >
                <div className="producer-artist-img-holder">
                  <ImgLoader
                    src={
                      producer.profile_image_url === null
                        ? Producer1
                        : producer.profile_image_url
                    }
                    className="artist-img"
                    type="producer"
                  />
                </div>
                <span className="producer-landing-name">{producer.name}</span>
                <p>
                  {producer.beats_count === 1
                    ? `${producer.beats_count} Beat Uploaded`
                    : `${producer.beats_count} Beats Uploaded`}
                </p>
              </div>
            );
          })}
      </div>
      {loading && producers && (
        <TailSpin
          height="40"
          width="40"
          color="#FE2B0D"
          ariaLabel="tail-spin-loading"
          visible={loading}
          wrapperClass="loading"
          className="loading"
        />
      )}
    </section>
  );
}

export default Producers;
