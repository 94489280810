import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
import { useNavigation } from "../../context/navigationContext";
const Autocomplete = ({
  endPoint,
  customClassName,
  selectedValue,
  setSelectedValue,
  errorClassName,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showSuggestionBox, setShowSuggestionBox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const { navigate } = useNavigation();

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!inputValue || inputValue.length < 3) {
        return;
      }

      setShowSuggestionBox(true);

      setLoading(true);

      try {
        const response = await api.get(
          `${endPoint}?name=${encodeURIComponent(inputValue)}`
        );
        handleResponseStatus(response, navigate);
        const data = response.data;
        setResults(data.data);

        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    if (selectedValue == null) {
      fetchSuggestions();
    }
  }, [inputValue, selectedValue, navigate, endPoint]);

  const handleInputChange = (event) => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (!regex.test(event.target.value)) {
      return;
    }

    setInputValue(event.target.value);

    setSelectedValue(null);
  };

  const handleSelectValue = (value) => {
    setSelectedValue(value);
    setShowSuggestionBox(false);
    setInputValue(value.name);
    if (errorClassName) {
      document.querySelector(`.${errorClassName}`).classList.add("hidden");
    }
  };
  useEffect(() => {
    if (selectedValue === "") {
      setInputValue("");
    }
  }, [selectedValue]);

  const formatEndpoint = () => {
    const parts = endPoint.split("/");
    let result = parts[0];
    result = result.slice(0, 1).toUpperCase() + result.slice(1);

    if (result.endsWith("s")) {
      result = result.slice(0, -1);
    }
    return result;
  };

  return (
    <div className={`autocomplete-container ${customClassName}`}>
      <input type="text" onChange={handleInputChange} value={inputValue} />
      {showSuggestionBox && (
        <div className="autocomplete">
          {loading ? (
            <div className="autocomplete-loading">
              <TailSpin
                height="40"
                width="40"
                color="#FE2B0D"
                ariaLabel="tail-spin-loading"
                visible={loading}
              />
            </div>
          ) : (!results || results.length === 0) &&
            endPoint !== "collaborators/autocomplete" ? (
            <div
              className="autocomplete-result-row"
              onClick={() => {
                handleSelectValue({ name: inputValue });
              }}
            >
              Add new {formatEndpoint()}: {inputValue}
            </div>
          ) : (
            results?.map((r) => {
              return (
                <div
                  className="autocomplete-result-row"
                  key={r.id}
                  onClick={() => {
                    handleSelectValue(r);
                  }}
                >
                  {r.name}
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
