import "./stylesheets/terms.css";

function Disclaimer() {
  return (
    <section className="terms">
      <h2>Disclaimer</h2>
      <h4>
        <span className="term-bold">Welcome to</span> THA THROWDOWN!
      </h4>

      <div className="term-container">
        <p>
          Before you begin using our website, we kindly request you to carefully
          read and understand this Disclaimer document. By accessing or using
          our website{" "}
          <a href="https://www.thathrowdown.com" className="terms-email">
            www.thatthrowdown.com
          </a>{" "}
          you acknowledge that you have reviewed and agreed to the terms and
          conditions outlined herein. If you do not agree with any part of this
          Disclaimer, we advise you to discontinue using our services.
        </p>
      </div>

      <h4>1. Content Submission and Use:</h4>
      <div className="term-container">
        <p>
          <span className="term-bold">THA THROWDOWN </span>
          operates as an online marketplace for musical beats where users can
          submit, share, and sell their own musical beats. Users retain full
          ownership and responsibility for the content they submit to the
          website.
          <span className="term-bold"> THA THROWDOWN </span> does not claim
          ownership or liability for any beats, music, information, or
          documentation uploaded by users.
        </p>
      </div>

      <h4>2. User Responsibility:</h4>
      <div className="term-container">
        <p>
          All users of
          <span className="term-bold"> THA THROWDOWN </span>are solely
          responsible for the content they submit, including musical beats,
          information, documentation, and any interactions within the platform.
          Users must ensure that the content they provide is accurate, lawful,
          and does not infringe upon any third-party rights, including
          copyrights, trademarks, or any other intellectual property rights.
        </p>
      </div>

      <h4>3. Indemnification:</h4>
      <div className="term-container">
        <p>
          By using
          <span className="term-bold"> THA THROWDOWN </span>users agree to
          indemnify and hold harmless
          <span className="term-bold"> THA THROWDOWN</span>, its affiliates,
          partners, employees, and representatives from any and all claims,
          liabilities, damages, losses, and expenses (including reasonable
          attorney's fees) arising out of or in connection with:
          <div className="term-container">
            <p>
              <span className="term-bold">a. </span>The content submitted by the
              user, including any alleged infringement of third-party rights.
            </p>
          </div>
          <div className="term-container">
            <p>
              <span className="term-bold">b. </span>User interactions with other
              users or third parties on the website.
            </p>
          </div>
          <div className="term-container">
            <p>
              <span className="term-bold">c. </span>User violations of this
              Disclaimer or any applicable laws or regulations.
            </p>
          </div>
        </p>
      </div>

      <h4>4. Website Functionality:</h4>
      <div className="term-container">
        <p>
          <span className="term-bold">THA THROWDOWN </span>strives to maintain a
          secure and functional platform, but we cannot guarantee that our
          website will be uninterrupted or error-free. We are not liable for any
          technical issues, data loss, or other damages that may result from the
          use of our website.
        </p>
      </div>

      <h4>5. External Links and Third-Party Content:</h4>
      <div className="term-container">
        <p>
          Our website may contain links to external websites or third-party
          content. These links are provided for convenience and informational
          purposes only.
          <span className="term-bold"> THA THROWDOWN </span>does not endorse or
          have control over the content, policies, or practices of these
          external websites. We are not responsible for any damages or
          liabilities arising from your interaction with such third-party
          content.
        </p>
      </div>

      <h4>6. Changes to the Website and Disclaimer:</h4>
      <div className="term-container">
        <p>
          <span className="term-bold">THA THROWDOWN </span>reserves the right to
          modify, suspend, or discontinue any part of the website or this
          Disclaimer at any time without prior notice. It is the user's
          responsibility to review this Disclaimer periodically for any updates
          or changes.
        </p>
      </div>

      <h4>7. Jurisdiction:</h4>
      <div className="term-container">
        <p>
          This Disclaimer shall be governed by and construed in accordance with
          the laws of the United Kingdom, without regard to its conflicts of law
          principles. Any legal action or proceeding arising out of or related
          to this Disclaimer shall be brought exclusively in the courts of
          United Kingdom, and users hereby consent to the personal jurisdiction
          of such courts.
        </p>
      </div>

      <div className="terms-placeholder"></div>
      <div className="term-container">
        <p>
          By using <span className="term-bold">THA THROWDOWN</span>, users
          signify their acceptance and agreement to this Disclaimer. If you do
          not agree with any part of this document, please refrain from using
          our services.
        </p>
      </div>
      <div className="term-container">
        <p>
          For any questions or concerns about this Disclaimer, please contact us
          at{" "}
          <a href="mailto:info@thathrowdown.com" className="terms-email">
            info@thathrowdown.com
          </a>
          .
        </p>
      </div>
      <div className="term-container">
        <p>Last updated: July- 2023</p>
      </div>
      <div className="term-container">
        <p>
          <span className="term-bold">THA THROWDOWN</span>
        </p>
      </div>
    </section>
  );
}

export default Disclaimer;
