import "./../stylesheets/Trending.css";
import "./../stylesheets/beatsLanding.css";
import { TailSpin } from "react-loader-spinner";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImgLoader from "./../imgLoader";
import BuyPopup from "./../buyPopup";
import Popup from "reactjs-popup";
import { useNavigation } from "../../context/navigationContext";
import { useCurrencyContext } from "../../context/currencyContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { usePlayerContext } from "../../context/playerContext";
import { useAuthContext } from "../../context/authContext";
function Albums({
  beats,
  loading,
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const { navigate, navigateToArtists, navigateToAlbum } = useNavigation();
  const { formatCurrency } = useCurrencyContext();

  const { isAuthenticated } = useAuthContext();
  const [loaderCount, setLoaderCount] = useState(10);

  const { playerState } = usePlayerContext();

  const handleProducerClick = (event) => {
    const id = event.target.dataset.id;
    navigateToArtists(id);
  };

  const handleBeatClick = (event) => {
    const beat_id = event.target.closest(".beats-music-card").dataset.id;
    navigateToAlbum(beat_id);
  };
  const [buyPopupBeat, setBuyPopupBeat] = useState(null);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);

  const handleAddToCart = async (id, price) => {
    if (isAuthenticated) {
      const response = await handleCart(id, "album", price);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = beats.find((obj) => obj.slug.toString() === id);
      const response = await handleLocalCart(desiredObject, "album", price);
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setPopupMessage("Added to cart");
        setShowMessagePopup(true);
      } else {
        setPopupMessage("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };
  useEffect(() => {
    const width = window.innerWidth;

    if (width > 900) {
      setLoaderCount(10);
    } else if (width > 600) {
      setLoaderCount(6);
    } else if (width > 300) {
      setLoaderCount(4);
    } else {
      setLoaderCount(2);
    }
  }, []);

  return (
    <>
      <section className="beatsTracks beats-open">
        <div className="beats-cards">
          {!beats &&
            Array.from({ length: loaderCount }).map((_, index) => (
              <div
                key={index}
                className="beats-music-card beats__music__player"
              >
                <Skeleton className="music-card--img" />
                <div>
                  <p className="price">
                    <Skeleton
                      count={0.35}
                      containerClassName="skeleton-loading"
                    />
                  </p>
                  <h3 className="music-card--music-name">
                    <Skeleton
                      count={0.7}
                      containerClassName="skeleton-loading"
                    />
                  </h3>
                  <div className="music-card--artist-price">
                    <Skeleton
                      count={0.45}
                      containerClassName="skeleton-loading"
                    />
                  </div>
                </div>
              </div>
            ))}
          {beats &&
            beats.map((beat, index) => {
              return (
                <div
                  className="beats-music-card beats__music__player"
                  data-id={beat.slug}
                  key={index}
                  data-url={beat.play_url}
                >
                  <Link to={`/albums/${beat.slug}`}>
                    <ImgLoader
                      src={beat.cover_path}
                      className="beats-music-card--img"
                      type="beat"
                    />
                  </Link>
                  <div>
                    <p className="beats-price">
                      <span
                        onClick={() => {
                          setBuyPopupBeat(beat);
                          setIsBuyPopupOpen(true);
                        }}
                      >
                        {beat.price === 0 ? "Free" : formatCurrency(beat.price)}
                      </span>{" "}
                      &#x2022;{" "}
                      {`${beat.tracks.length} ${
                        beat.tracks.length === 1 ? "Track" : "Tracks"
                      }`}
                    </p>
                    <h3
                      className="beats-music-card--music-name"
                      onClick={handleBeatClick}
                    >
                      {beat.name}
                    </h3>

                    <div className="beats-music-card--artist-price">
                      <div>
                        <p
                          className="beats-music-card-artist-name"
                          data-id={beat?.artist?.slug}
                          onClick={handleProducerClick}
                        >
                          {beat.artist.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {loading && beats && (
          <TailSpin
            height="40"
            width="40"
            color="#FE2B0D"
            ariaLabel="tail-spin-loading"
            visible={loading}
            wrapperClass="loading"
            className="loading"
          />
        )}
      </section>
      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
      <BuyPopup
        beat={buyPopupBeat}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        handleAddToCart={handleAddToCart}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />
    </>
  );
}

export default Albums;
