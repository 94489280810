import "./stylesheets/footer.css";

import Facebook from "./../images/facebook.png";
import Twitter from "./../images/twitter.png";
import Whatsapp from "./../images/whatsapp.png";
import Instagram from "./../images/instgram.svg";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [date, setDate] = useState("");
  const getDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    setDate(currentYear);
  };
  useEffect(() => {
    getDate();
  }, []);
  const handleTwitter = () => {
    window.location.href = "https://twitter.com/thathrowdown";
  };
  const handleFacebook = () => {
    window.location.href = "https://www.facebook.com/thathrowdown";
  };
  const handleInstagram = () => {
    window.location.href = "https://www.instagram.com/thathrowdown/";
  };
  const handleWhatsapp = () => {
    window.location.href = "https://wa.me/+447950981434";
  };
  return (
    <section className="footer">
      <div className="footer__links">
        <div className="footer__links_div">
          <div className="hidden-box">
            <ul className="footer__links-flex">
              <li>
                <Link to="/aboutus">About Us</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/login">Sign in</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__links_div">
          <div className="hidden-box">
            <ul className="footer__links-flex">
              <li>
                <Link to="/signup">Create Account</Link>
              </li>
              <li>
                <Link to="/beats">Beats</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__links_div">
          <div className="hidden-box">
            <ul className="footer__links-flex">
              <li>
                <Link to="/terms">Terms of Services</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-social-links">
        <div className="footer-link-btn twitter" onClick={handleTwitter}>
          <img src={Twitter} alt="" />
        </div>
        <div className="footer-link-btn instagram" onClick={handleInstagram}>
          <img src={Instagram} alt="" />
        </div>
        <div className="footer-link-btn" onClick={handleWhatsapp}>
          <img src={Whatsapp} alt="" />
        </div>

        <div className="footer-link-btn" onClick={handleFacebook}>
          <img src={Facebook} alt="" />
        </div>
      </div>

      <p className="footer-copyright">
        Copyright © {date} All rights reserved by thathrowdown.
      </p>
    </section>
  );
}

export default Footer;
