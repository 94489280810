import { initialPlayerState } from "../context/playerContext";
import {
  PLAY,
  PAUSE,
  SET_CURRENT_TIME,
  SET_TOTAL_TIME,
  SET_VOLUME,
  ADD_TO_QUEUE,
  PLAY_SPECIFIC_QUEUE_ITEM,
  PLAY_NOW,
  CLEAR_INFO_MESSAGE,
  PLAY_NEXT,
  PLAY_PREV,
  SET_LIKED,
  SHOW_INFO_MESSAGE,
  TOGGLE_MOBILE_STATE,
  PLAY_STARTING,
} from "./../constants/actionTypes";

const playerReducer = (state = initialPlayerState, action) => {
  switch (action.type) {
    case PLAY:
      return {
        ...state,
        isPlaying: true,
      };
    case PAUSE:
      return {
        ...state,
        isPlaying: false,
      };
    case SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };
    case SET_TOTAL_TIME:
      return {
        ...state,
        totalTime: action.payload,
      };
    case SET_VOLUME:
      return {
        ...state,
        volume: action.payload,
      };
    case SET_LIKED:
      return {
        ...state,
        isLiked: action.payload,
      };
    case ADD_TO_QUEUE: {
      let queue = state.queue;

      if (state.queue.length && state.queue[0].hidden) {
        queue = state.queue.slice(1);
      }

      let itemsToAdd;

      if (Array.isArray(action.payload)) {
        itemsToAdd = action.payload;
      } else {
        itemsToAdd = [action.payload];
      }

      const updatedQueue = [...queue];
      const seenSlugs = new Set(queue.map((item) => item?.slug));

      let msg = "Item already in Queue";

      for (const item of itemsToAdd) {
        if (!seenSlugs.has(item?.slug)) {
          updatedQueue.push(item);
          seenSlugs.add(item?.slug);
          msg = "Added to Queue";
        }
      }

      if (!state.currentItemIndex && updatedQueue.length > 0) {
        return {
          ...state,
          queue: updatedQueue,
          currentItemIndex: 0,
          starting: false,
          isPlaying: true,
          infoPopupMessage: msg,
          volume: 50,
        };
      }

      return { ...state, queue: updatedQueue, infoPopupMessage: msg };
    }
    case CLEAR_INFO_MESSAGE: {
      return {
        ...state,
        infoPopupMessage: null,
      };
    }
    case SHOW_INFO_MESSAGE: {
      return {
        ...state,
        infoPopupMessage: action.payload,
      };
    }
    case PLAY_STARTING: {
      const newSong = action.payload;

      newSong.hidden = true;

      return {
        ...state,
        queue: [newSong],
        currentItemIndex: 0,
        starting: true,
        isPlaying: false,
        currentTime: 0,
        totalTime: 0,
        volume: 50,
      };
    }
    case PLAY_NOW: {
      let queue = state.queue;

      if (state.queue.length && state.queue[0].hidden) {
        queue = state.queue.slice(1);
      }

      const newSong = action.payload;

      const isCurrentlyPlaying =
        state.currentItemIndex !== null &&
        queue[state.currentItemIndex]?.slug === newSong?.slug;

      if (isCurrentlyPlaying) {
        return {
          ...state,
          isPlaying: true,
          starting: false,
          currentTime: 0,
        };
      }

      const existingSongIndex = queue.findIndex(
        (item) => item?.slug === newSong?.slug
      );

      let updatedQueue = [...queue];
      let newIndex;

      if (existingSongIndex !== -1) {
        newIndex = existingSongIndex;
      } else {
        updatedQueue.push(newSong);
        newIndex = updatedQueue.length - 1;
      }

      return {
        ...state,
        queue: updatedQueue,
        currentItemIndex: newIndex,
        isPlaying: true,
        starting: false,
        currentTime: 0,
        totalTime: 0,
        volume: 50,
      };
    }
    case PLAY_SPECIFIC_QUEUE_ITEM: {
      const item = action.payload;

      const index = state.queue.findIndex(
        (queueItem) => queueItem?.slug === item?.slug
      );

      if (
        index < 0 ||
        index >= state.queue.length ||
        index === state.currentItemIndex
      ) {
        return state;
      }

      return {
        ...state,
        currentItemIndex: index,
        currentTime: 0,
        totalTime: 0,
        isPlaying: true,
      };
    }
    case PLAY_NEXT: {
      if (state.currentItemIndex >= state.queue.length - 1) {
        const totTime = state.queue.length === 1 ? state.totalTime : 0;
        return {
          ...state,
          currentTime: 0,
          totalTime: totTime,
          isLiked: false,
          currentItemIndex: 0,
        };
      }

      return {
        ...state,
        currentItemIndex: state.currentItemIndex + 1,
        currentTime: 0,
        totalTime: 0,
        isLiked: false,
      };
    }
    case PLAY_PREV: {
      if (state.currentItemIndex === 0) {
        const totTime = state.queue.length === 1 ? state.totalTime : 0;

        return {
          ...state,
          currentTime: 0,
          totalTime: totTime,
          isPlaying: true,
          isLiked: false,
          currentItemIndex: state.queue.length - 1,
        };
      }

      return {
        ...state,
        currentItemIndex: state.currentItemIndex - 1,
        currentTime: 0,
        totalTime: 0,
        isPlaying: true,
        isLiked: false,
      };
    }
    case TOGGLE_MOBILE_STATE: {
      const oldState = state.mobileExpanded;

      return {
        ...state,
        mobileExpanded: !oldState,
      };
    }
    default:
      return state;
  }
};

export default playerReducer;
