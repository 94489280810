export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const SET_SELECTED_CURRENCY = "SET_SELECTED_CURRENCY";
export const SET_CURRENCY_RATES = "SET_CURRENCY_RATES";
export const FETCH_CURRENCY_RATES_REQUEST = "FETCH_CURRENCY_RATES_REQUEST";
export const FETCH_CURRENCY_RATES_SUCCESS = "FETCH_CURRENCY_RATES_SUCCESS";
export const FETCH_CURRENCY_RATES_FAILURE = "FETCH_CURRENCY_RATES_FAILURE";
export const FETCH_WALLET_SUCCESS = "FETCH_WALLET_SUCCESS";
export const FETCH_WALLET_FAILURE = "FETCH_WALLET_FAILURE";
export const FETCH_BEATS_PURCHASES_SUCCESS = "FETCH_BEATS_PURCHASES_SUCCESS";
export const FETCH_BEATS_PURCHASES_FAILURE = "FETCH_BEATS_PURCHASES_FAILURE";
export const FETCH_ALBUMS_PURCHASES_SUCCESS = "FETCH_ALBUMS_PURCHASES_SUCCESS";
export const FETCH_ALBUMS_PURCHASES_FAILURE = "FETCH_ALBUMS_PURCHASES_FAILURE";
export const FETCH_MUSIC_PURCHASES_SUCCESS = "FETCH_MUSIC_PURCHASES_SUCCESS";
export const FETCH_MUSIC_PURCHASES_FAILURE = "FETCH_MUSIC_PURCHASES_FAILURE";
export const LIKE_TOGGLE = "LIKE_TOGGLE";

export const ADD_TO_QUEUE = "ADD_TO_QUEUE";
export const CLEAR_INFO_MESSAGE = "CLEAR_INFO_MESSAGE";
export const SHOW_INFO_MESSAGE = "SHOW_INFO_MESSAGE";
export const PLAY_NOW = "PLAY_NOW";
export const PLAY_SPECIFIC_QUEUE_ITEM = "PLAY_SPECIFIC_QUEUE_ITEM";
export const PLAY = "PLAY";
export const PAUSE = "PAUSE";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const SET_TOTAL_TIME = "SET_TOTAL_TIME";
export const SET_VOLUME = "SET_VOLUME";
export const SET_LIKED = "SET_LIKED";
export const PLAY_NEXT = "PLAY_NEXT";
export const PLAY_PREV = "PLAY_PREV";
export const TOGGLE_MOBILE_STATE = "TOGGLE_MOBILE_STATE";
export const PLAY_STARTING = "PLAY_STARTING";
