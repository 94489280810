import "./../stylesheets/messages.css";
import sample from "./../../images/user.png";
import done from "./../../images/checkmark-outline.svg";
import cross from "./../../images/cross.svg";
import cancel from "./../../images/close-outline.svg";
import alert from "./../../images/alert-outline.svg";
import warning from "./../../images/warning.svg";
import attachment from "./../../images/attach-outline.svg";
import submit from "./../../images/bid.png";
import commission from "./../../images/commission.svg";
import accept from "./../../images/accept.svg";
import info from "./../../images/info.svg";
import more from "./../../images/list-dark.svg";
import chats from "./../../images/chats-circle.svg";
import down from "./../../images/dropdown.svg";
import imageAttachment from "./../../images/file-image.svg";
import audioAttachment from "./../../images/file-audio.svg";
import documentAttachment from "./../../images/file-doc.svg";
import download from "./../../images/download-dark.svg";
import * as Sentry from "@sentry/react";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { TailSpin } from "react-loader-spinner";
import MessagesWorkstreamPopup from "./messagesWorkstreamPopup.js";
import MessagesChatlistMobile from "./messageChatlistMobile";
import MessagesChatlistPopup from "./messagesChatlistPopup";
import InfiniteScroll from "react-infinite-scroller";
import Popup from "reactjs-popup";
import Echo from "laravel-echo";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
window.Pusher = require("pusher-js");

function Messages({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { isAuthenticated, user, wallet, token } = useAuthContext();
  const { selectedCurrency, rates, formatCurrency } = useCurrencyContext();
  const Id = useParams();

  const activeChat = Id.chatId ? parseInt(Id.chatId, 10) : null;
  const [chatList, setChatList] = useState(null);
  const [chatMessages, setChatMessages] = useState(null);
  const [chatCommission, setChatCommission] = useState(null);

  const [messageText, setMessageText] = useState("");

  const [showUpdatePricePopup, setShowUpdatePricePopup] = useState(false);
  const [counterBidInput, setCounterBidInput] = useState(
    selectedCurrency === "GBP" ? "£" : "N"
  );
  const [counterBidErrorMessage, setCounterBidErrorMessage] = useState();

  const [showCommissionDetailsPopup, setShowCommissionDetailsPopup] =
    useState(false);
  const [showConfirmCancelPopup, setShowConfirmCancelPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);

  const [saveChanges, setSaveChanges] = useState(false);
  const [messagesNextPage, setMessagesNextPage] = useState(null);

  const [showDisputePopup, setShowDisputePopup] = useState(false);
  const [disputeErrorMessage, setDisputeErrorMessage] = useState("");
  const [disputeInput, setDisputeInput] = useState("");

  const [showDisputeConfirmation, setShowDisputeConfirmation] = useState(false);

  const [messageUnreadDividerIndex, setMessageUnreadDividerIndex] =
    useState(null);

  const [messageScroll, setMessageScroll] = useState(null);

  const [feedToggle, setFeedToggle] = useState("workstream");
  const [attachmentToggle, setAttachmentToggle] = useState("Show all");
  const [allAttachments, setAllAttachments] = useState(null);
  const [attachments, setAttachments] = useState(null);

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const messageContainerRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    const getConversations = async () => {
      try {
        const response = await api.get(`conversations`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        const unsorted = data.data;
        const sortedChatList = unsorted.sort((a, b) => {
          if (a.last_message && b.last_message) {
            return (
              new Date(b.last_message.created_at) -
              new Date(a.last_message.created_at)
            );
          }

          if (a.last_message) {
            return new Date(a.last_message.created_at) - new Date(b.created_at);
          } else if (b.last_message) {
            return new Date(b.last_message.created_at) - new Date(a.created_at);
          }

          return new Date(b.created_at) - new Date(a.created_at);
        });

        setChatList(sortedChatList);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getConversations();
  }, [navigate, isAuthenticated, setShowUnauthenticatedPopup, Id]);

  const [echo, setEcho] = useState(null);

  useEffect(() => {
    const temp = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_SOKETI_KEY,
      wsHost: process.env.REACT_APP_MIX_PUSHER_HOST,
      wssHost: process.env.REACT_APP_MIX_PUSHER_HOST,
      wsPort: process.env.REACT_APP_MIX_PUSHER_WS_PORT,
      wssPort: process.env.REACT_APP_MIX_PUSHER_WSS_PORT,
      forceTLS: false,
      encrypted: false,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      cluster: process.env.REACT_APP_MIX_PUSHER_CLUSTER,
      authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      },
    });

    setEcho(temp);
  }, [token]);

  useEffect(() => {
    if (echo && chatMessages) {
      echo
        .private(`conversations.${activeChat}`)
        .listen("MessagePosted", (e) => {
          if (chatMessages.length > 0) {
            if (chatMessages[chatMessages.length - 1].id !== e.id) {
              const newChatMessages = [...chatMessages, e];
              setChatMessages(newChatMessages);
              updateLastMessage(e);
            }
          } else {
            setChatMessages([...chatMessages, e]);
            updateLastMessage(e);
          }
        });
    }

    const updateLastMessage = (newMessage) => {
      if (!chatList || !chatList.some((chat) => chat.id === activeChat)) {
        return;
      }

      const chatIndex = chatList.findIndex((chat) => chat.id === activeChat);

      if (chatIndex === -1) {
        return;
      }

      const updatedChatList = chatList.map((chat, index) =>
        index === chatIndex
          ? {
              ...chat,
              last_message: chat.last_message
                ? {
                    ...chat.last_message,
                    id: newMessage.id,
                    content: newMessage.content,
                    created_at: newMessage.created_at,
                    posted_by: newMessage.sender.id,
                  }
                : {
                    id: newMessage.id,
                    content: newMessage.content,
                    created_at: newMessage.created_at,
                    posted_by: newMessage.sender.id,
                  },
            }
          : chat
      );

      setChatList(updatedChatList);
    };

    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [activeChat, chatMessages, echo, chatList]);

  const loadNext = async () => {
    if (messagesNextPage) {
      try {
        const response = await api.get(messagesNextPage);
        handleResponseStatus(response, navigate);
        const data = response.data;
        setMessagesNextPage(data.pagination.next_page_url);
        const reversedChatMessages = data.pagination.data.slice().reverse();
        setChatMessages([...reversedChatMessages, ...chatMessages]);
        setChatCommission(data.commission);
        setMessageScroll(null);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    const getChatMessages = async (activeChat) => {
      try {
        const response = await api.get(`conversations/${activeChat}/messages`);

        handleResponseStatus(response, navigate);
        const data = response.data;
        setMessagesNextPage(data.pagination.next_page_url);
        const reversedChatMessages = data.pagination.data.slice().reverse();
        setChatMessages(reversedChatMessages);
        if (reversedChatMessages && reversedChatMessages.length > 0) {
          setMessageScroll(
            reversedChatMessages[reversedChatMessages.length - 1].id
          );
        } else {
          setMessageScroll(null);
        }

        setChatCommission(data.commission);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    if (activeChat) {
      setChatCommission(null);
      setChatMessages(null);
      getChatMessages(activeChat);
    }
  }, [activeChat, navigate]);

  const getChatCommission = async (activeChat) => {
    try {
      const response = await api.get(`conversations/${activeChat}/commission`);
      handleResponseStatus(response, navigate);
      const data = response.data;
      setChatCommission(data.data);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleSendMessage = async () => {
    const msgText = messageText;
    setMessageText("");

    if (msgText.length < 1) {
      return;
    }
    const body = { content: msgText.replaceAll("\n", "\\n") };

    try {
      const response = await api.post(
        `conversations/${activeChat}/messages`,
        body
      );
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      setMessageText("");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      if (
        messageScroll &&
        messageScroll === chatMessages[chatMessages.length - 1].id
      ) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    }
  }, [chatMessages, messageScroll, activeChat]);

  const handleAccept = async () => {
    try {
      const response = await api.post(
        `commissions/${chatCommission.id}/accept`,
        {}
      );
      handleResponseStatus(response, navigate);
      getChatCommission(activeChat);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleReject = async () => {
    try {
      const response = await api.post(
        `commissions/${chatCommission.id}/reject`,
        {}
      );
      handleResponseStatus(response, navigate);

      getChatCommission(activeChat);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleCancel = async () => {
    setShowConfirmCancelPopup(false);
    try {
      const response = await api.post(
        `commissions/${chatCommission.id}/cancel`,
        {}
      );
      handleResponseStatus(response, navigate);
      getChatCommission(activeChat);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleUpdatePrice = async () => {
    setShowUpdatePricePopup(false);
    let amount = 0;
    if (selectedCurrency === "GBP") {
      amount = counterBidInput.slice(1);
    } else {
      amount = (counterBidInput.slice(1) / rates).toFixed(2);
    }

    try {
      const response = await api.post(
        `commissions/${chatCommission.id}/update`,
        { budget: amount }
      );
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      getChatCommission(activeChat);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const [paymentMethod, setPaymentMethod] = useState(null);

  const handlePayment = async (type) => {
    setSaveChanges(true);

    const body = {
      payment_method: type === "paystack" ? "paystack" : "paypal",
    };

    try {
      const response = await api.post(
        `commissions/${chatCommission.id}/create-payment`,
        body
      );

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;

      if (data.link) {
        localStorage.setItem("commission_vendor_id", data.vendor_order_id);
        localStorage.setItem("commission_id", chatCommission.id);
        window.location.href = data.link;
      } else if (data.success) {
        setShowPaymentPopup(false);
        getChatCommission(activeChat);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleComplete = async () => {
    setShowConfirmPopup(false);
    try {
      const response = await api.post(
        `commissions/${chatCommission.id}/complete`,
        {}
      );
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      getChatCommission(activeChat);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleCounterBidInputChange = (event) => {
    setCounterBidInput(formatInputCurrency(event.target.value));

    if (event.target.value.length <= 1) {
      setCounterBidErrorMessage("The input field cannot be empty!");
      document.querySelector(".bid-error").classList.remove("hidden");
    } else {
      document.querySelector(".bid-error").classList.add("hidden");
    }
  };

  function formatInputCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `${selectedCurrency === "GBP" ? "£" : "N"}${value}`;

    return value;
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = document.querySelector(".image-upload");
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }

    try {
      const response = await api.get(
        `conversations/${chatCommission.id}/upload?filename=${file.name}`,
        {}
      );
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

      const data = response.data;

      if (file.type.startsWith("image/")) {
        const img = document.querySelector(".image-upload");
        const res = await fetch(img.src);
        const blob = await res.blob();
        await fetch(data.url, {
          method: "PUT",
          body: blob,
        });
      } else {
        const formData = new FormData();
        formData.append("file", file);

        await fetch(data.url, {
          method: "PUT",
          body: formData,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const handleDisputeInputChange = (event) => {
    setDisputeInput(event.target.value);

    if (event.target.value.length < 50) {
      setDisputeErrorMessage("The description must be at least 50 characters!");
      document.querySelector(".dispute-error").classList.remove("hidden");
    } else {
      document.querySelector(".dispute-error").classList.add("hidden");
    }
  };

  const handleDispute = async () => {
    if (disputeInput.length < 50) {
      setDisputeErrorMessage("The description must be at least 50 characters!");
      document.querySelector(".dispute-error").classList.remove("hidden");
      return;
    }

    try {
      const response = await api.get(
        `commissions/${chatCommission.id}/dispute`,
        { description: disputeInput }
      );
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      setShowDisputePopup(false);
      setShowCommissionDetailsPopup(false);
      setShowDisputeConfirmation(true);
      setDisputeInput("");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleDownload = async (messageId) => {
    try {
      const response = await api.get(`conversations/${messageId}/download`);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      window.open(data, "_blank");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!chatMessages || !user) {
      return;
    }

    const markMessagesRead = async () => {
      try {
        const response = await api.post(
          `conversations/${activeChat}/messages/read`,
          {}
        );
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    for (const message of chatMessages) {
      if (!message.read_status && message.sender.id !== user.id) {
        setMessageUnreadDividerIndex(message.id);
        markMessagesRead();
        break;
      }
    }
  }, [chatMessages, user, activeChat, navigate, setShowUnauthenticatedPopup]);

  useEffect(() => {
    const getAttachments = async () => {
      try {
        const response = await api.get(
          `conversations/${activeChat}/attachments`,
          {}
        );
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;
        setAllAttachments(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    if (feedToggle === "attachments") {
      getAttachments();
    }
  }, [feedToggle, setShowUnauthenticatedPopup, navigate, activeChat]);

  useEffect(() => {
    if (!allAttachments) {
      return;
    }

    if (attachmentToggle === "Show all") {
      setAttachments(allAttachments);
    } else if (attachmentToggle === "Audio") {
      const audioAttachments = allAttachments.filter((attachment) =>
        attachment.upload.match(/\.(mp3|wav|ogg|flac|aac)$/i)
      );
      setAttachments(audioAttachments);
    } else if (attachmentToggle === "Images") {
      const imageAttachments = allAttachments.filter((attachment) =>
        attachment.upload.match(/\.(jpg|jpeg|png|gif|bmp|webp)$/i)
      );
      setAttachments(imageAttachments);
    } else if (attachmentToggle === "Documents") {
      const documentAttachments = allAttachments.filter(
        (attachment) =>
          !attachment.upload.match(
            /\.(mp3|wav|ogg|flac|aac|jpg|jpeg|png|gif|bmp|webp)$/i
          )
      );
      setAttachments(documentAttachments);
    }
  }, [allAttachments, attachmentToggle]);

  return (
    <>
      {user !== null && (
        <section className="messages-section">
          <div className="messages-box-whole">
            {!chatList && (
              <div className="select-chat-placeholder-mobile">
                <TailSpin
                  height="70"
                  width="70"
                  color="#000000"
                  ariaLabel="tail-spin-loading"
                  visible={true}
                  wrapperClass="loading"
                  className="loading"
                />
              </div>
            )}

            {!activeChat && chatList && (
              <MessagesChatlistMobile
                activeChat={activeChat}
                chatList={chatList}
                user={user}
              />
            )}

            <div className="messages-sidebar">
              <div className="messages-sidebar-heading">
                <h3 className="messages-top-bar-heading">Messages</h3>
              </div>
              <div className="messages-items">
                {chatList &&
                  chatList.map((chat) => {
                    const formatMessageTime = (createdAt) => {
                      const messageDate = new Date(createdAt);

                      if (!isSameDate(messageDate, new Date())) {
                        return messageDate.toLocaleString("en-US", {
                          day: "numeric",
                          month: "long",
                        });
                      } else {
                        return messageDate.toLocaleString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        });
                      }
                    };

                    return (
                      <div
                        style={
                          activeChat === chat.id
                            ? { backgroundColor: "#f5f5f5" }
                            : null
                        }
                        key={chat.id}
                        className="messages-item"
                        onClick={() => {
                          if (activeChat !== chat.id) {
                            navigate(`/messages/${chat.id}`);
                          }
                        }}
                      >
                        <img
                          alt=""
                          src={
                            chat.members[0].id === user.id
                              ? chat.members[1].profile_image_url
                                ? chat.members[1].profile_image_url
                                : sample
                              : chat.members[0].profile_image_url
                              ? chat.members[0].profile_image_url
                              : sample
                          }
                          className="messages-item-image"
                        ></img>
                        <div className="messages-item-detail">
                          <div className="messages-item-detail-div">
                            <h3 className="messages-item-detail-name">
                              {chat.members[0].id === user.id
                                ? chat.members[1].name
                                : chat.members[0].name}
                            </h3>
                            <p
                              className="messages-item-detail-description"
                              style={
                                chat.unread_messages && chat.unread_messages > 0
                                  ? { fontWeight: "500" }
                                  : null
                              }
                            >
                              {chat.last_message
                                ? `${
                                    chat.last_message.posted_by === user.id
                                      ? `You`
                                      : chat.members.find(
                                          (member) =>
                                            member.id ===
                                            chat.last_message.posted_by
                                        ).first_name
                                  }: ${
                                    chat.last_message.content.length > 5
                                      ? chat.last_message.content.substring(
                                          0,
                                          5
                                        ) + "..."
                                      : chat.last_message.content === ""
                                      ? "Attachment"
                                      : chat.last_message.content
                                  }`
                                : " "}
                            </p>
                          </div>
                          <div className="messages-item-detail-time">
                            {chat.unread_messages != null &&
                              chat.unread_messages > 0 && (
                                <div className="message-item-detail-unread">
                                  {chat.unread_messages}
                                </div>
                              )}

                            <p className="last_message_time">
                              {chat.last_message
                                ? formatMessageTime(
                                    chat.last_message.created_at
                                  )
                                : formatMessageTime(chat.created_at)}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {activeChat && chatList && chatMessages && chatCommission && (
              <>
                <div className="messages-chatbox">
                  <div className="chatbox-top-heading">
                    <MessagesChatlistPopup
                      trigger={
                        <div className="chatbox-top-heading-chats">
                          <img src={chats} alt="" />
                        </div>
                      }
                      activeChat={activeChat}
                      chatList={chatList}
                      user={user}
                    />
                    <div className="chatbox-top-heading-details">
                      <img
                        alt=""
                        src={
                          chatList.find((chat) => chat.id === activeChat)
                            .members[0].id === user.id
                            ? chatList.find((chat) => chat.id === activeChat)
                                .members[1].profile_image_url
                              ? chatList.find((chat) => chat.id === activeChat)
                                  .members[1].profile_image_url
                              : sample
                            : chatList.find((chat) => chat.id === activeChat)
                                .members[0].profile_image_url
                            ? chatList.find((chat) => chat.id === activeChat)
                                .members[0].profile_image_url
                            : sample
                        }
                      ></img>
                      <div className="chatbox-top-heading-detail">
                        <h3>
                          {chatList.find((chat) => chat.id === activeChat)
                            .members[0].id === user.id
                            ? chatList.find((chat) => chat.id === activeChat)
                                .members[1].name
                            : chatList.find((chat) => chat.id === activeChat)
                                .members[0].name}
                        </h3>
                      </div>
                    </div>
                    <MessagesWorkstreamPopup
                      trigger={
                        <div className="chatbox-top-heading-more">
                          <img src={more} alt="" />
                        </div>
                      }
                      chatCommission={chatCommission}
                      feedToggle={feedToggle}
                      setFeedToggle={setFeedToggle}
                      attachmentToggle={attachmentToggle}
                      setAttachmentToggle={setAttachmentToggle}
                      attachments={attachments}
                      handleDownload={handleDownload}
                      rates={rates}
                      selectedCurrency={selectedCurrency}
                    />
                  </div>
                  <div className="chatbox-messages" ref={messageContainerRef}>
                    <InfiniteScroll
                      loadMore={loadNext}
                      hasMore={true}
                      initialLoad={false}
                      isReverse={true}
                      useWindow={false}
                      getScrollParent={() => messageContainerRef.current}
                      threshold={5}
                    >
                      <>
                        {chatCommission && (
                          <div
                            className="message-commission"
                            key={Math.random()}
                          >
                            <div className="commission-status-bar" />
                            <div className="commission-details">
                              <div className="commission-details-top">
                                <div className="commission-details-top__title">
                                  <h3>{chatCommission.name}</h3>
                                  <h4 className="commission-price-heading">
                                    {formatCurrency(
                                      chatCommission.budget * 100
                                    )}
                                  </h4>
                                </div>
                                <div className="commission-details-top__status">
                                  {chatCommission.status === "REQUESTED" && (
                                    <>
                                      <img
                                        src={done}
                                        alt=""
                                        style={{ backgroundColor: "#F29423" }}
                                      />
                                      <p
                                        style={{ color: "#F29423" }}
                                        className="commission-status-pending"
                                      >
                                        Offer Pending
                                      </p>
                                    </>
                                  )}
                                  {chatCommission.status === "ACCEPTED" && (
                                    <>
                                      <img
                                        src={done}
                                        alt=""
                                        style={{ backgroundColor: "#2C7919" }}
                                      />
                                      <p
                                        style={{ color: "#2C7919" }}
                                        className="commission-accepted-status"
                                      >
                                        Offer Accepted
                                      </p>
                                    </>
                                  )}

                                  {chatCommission.status === "REJECTED" && (
                                    <>
                                      <img
                                        src={cancel}
                                        alt=""
                                        style={{ backgroundColor: "#F55446" }}
                                      />
                                      <p
                                        style={{ color: "#F55446" }}
                                        className="commission-status-rejected"
                                      >
                                        Offer Rejected
                                      </p>
                                    </>
                                  )}
                                  {chatCommission.status === "CANCELLED" && (
                                    <>
                                      <img
                                        src={cancel}
                                        alt=""
                                        style={{ backgroundColor: "#F55446" }}
                                      />
                                      <p
                                        style={{ color: "#F55446" }}
                                        className="commission-status-cancelled"
                                      >
                                        Offer Cancelled
                                      </p>
                                    </>
                                  )}
                                  {chatCommission.status === "COMPLETED" && (
                                    <>
                                      <img
                                        src={done}
                                        alt=""
                                        style={{ backgroundColor: "#2C7919" }}
                                      />
                                      <p
                                        style={{ color: "#2C7919" }}
                                        className="commission-completed-status"
                                      >
                                        Offer Completed
                                      </p>
                                    </>
                                  )}
                                  {chatCommission.status === "IN_DISPUTE" && (
                                    <>
                                      <img
                                        src={alert}
                                        alt=""
                                        style={{ backgroundColor: "#F55446" }}
                                      />
                                      <p style={{ color: "#F55446" }}>
                                        Offer In Dispute
                                      </p>
                                    </>
                                  )}
                                  {chatCommission.status === "NEGOTIATING" && (
                                    <>
                                      <img
                                        src={alert}
                                        alt=""
                                        style={{ backgroundColor: "#F29423" }}
                                      />
                                      <p style={{ color: "#F29423" }}>
                                        Offer Negotiating
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="commission-producer-details">
                                <img
                                  src={
                                    chatCommission.requested_by
                                      .profile_image_url
                                      ? chatCommission.requested_by
                                          .profile_image_url
                                      : sample
                                  }
                                  alt=""
                                />
                                <div>
                                  <h4>{chatCommission.requested_by.name}</h4>
                                </div>
                                <div className="commission-button-container">
                                  {chatCommission.status === "REQUESTED" &&
                                    chatCommission.requested_to.id ===
                                      user.id && (
                                      <>
                                        <button
                                          className="commission-more commission-accept-button"
                                          onClick={handleAccept}
                                        >
                                          Accept
                                        </button>
                                        <button
                                          className="commission-more reject-commission-button"
                                          onClick={handleReject}
                                        >
                                          Reject
                                        </button>
                                      </>
                                    )}

                                  {chatCommission.status === "ACCEPTED" &&
                                    chatCommission.payment_status !==
                                      "IN_ESCROW" &&
                                    chatCommission.requested_by.id ===
                                      user.id && (
                                      <button
                                        className="commission-more commission-proceed-to-payment"
                                        onClick={() => {
                                          setShowPaymentPopup(true);
                                        }}
                                      >
                                        Proceed to Payment
                                      </button>
                                    )}

                                  {chatCommission.status === "ACCEPTED" &&
                                    chatCommission.payment_status ===
                                      "IN_ESCROW" &&
                                    chatCommission.requested_by.id ===
                                      user.id && (
                                      <button
                                        className="commission-more complete-commission-button"
                                        onClick={() => {
                                          setShowConfirmPopup(true);
                                        }}
                                      >
                                        Complete Order
                                      </button>
                                    )}

                                  {chatCommission.status === "REQUESTED" &&
                                    chatCommission.requested_by.id ===
                                      user.id && (
                                      <button
                                        className="commission-more commission-update-price-button"
                                        onClick={() => {
                                          setShowUpdatePricePopup(true);
                                        }}
                                      >
                                        Update Price
                                      </button>
                                    )}

                                  <button
                                    className="commission-more commission-details-popup"
                                    onClick={() => {
                                      setShowCommissionDetailsPopup(true);
                                    }}
                                  >
                                    Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {chatMessages &&
                          chatMessages.map((message, index) => {
                            const showDateDivider =
                              index === 0 ||
                              !isSameDate(
                                new Date(message.created_at),
                                new Date(chatMessages[index - 1].created_at)
                              );

                            const type =
                              message.sender.id === user.id
                                ? "sent"
                                : "received";

                            const sameSender =
                              !showDateDivider &&
                              index > 0 &&
                              chatMessages[index - 1].sender.id ===
                                message.sender.id;

                            const localTime = new Date(
                              message.created_at
                            ).toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            });

                            const date = new Date(message.created_at);

                            let AttachmentType;

                            if (message.upload) {
                              const fileExtension = message.upload
                                .split(".")
                                .pop()
                                .toLowerCase();
                              AttachmentType = [
                                "jpg",
                                "jpeg",
                                "png",
                                "gif",
                                "bmp",
                                "webp",
                              ].includes(fileExtension)
                                ? "Image"
                                : ["mp3", "wav", "ogg", "flac", "aac"].includes(
                                    fileExtension
                                  )
                                ? "Audio"
                                : "Document";
                            }

                            return (
                              <>
                                {showDateDivider && (
                                  <div
                                    className="chatbox-date-divider"
                                    key={Math.random()}
                                  >
                                    <span />
                                    {isSameDate(date, new Date())
                                      ? "Today"
                                      : date.toLocaleString("en-US", {
                                          day: "numeric",
                                          month: "long",
                                        })}
                                    <span />
                                  </div>
                                )}

                                {messageUnreadDividerIndex === message.id && (
                                  <div
                                    className="chatbox-date-divider"
                                    key={Math.random()}
                                  >
                                    <span />
                                    Unread Messagess
                                    <span />
                                  </div>
                                )}

                                <div
                                  className="message-row"
                                  key={message.id}
                                  data-message-key={message.id}
                                >
                                  {type === "received" && (
                                    <div className="message-text">
                                      {!sameSender && (
                                        <img
                                          src={
                                            message.sender.profile_image_url
                                              ? message.sender.profile_image_url
                                              : sample
                                          }
                                          alt=""
                                        />
                                      )}
                                      {sameSender && (
                                        <div style={{ width: "7%" }}></div>
                                      )}
                                      {message.content !== "" && (
                                        <div
                                          className="message-content"
                                          style={{
                                            borderRadius: "0px 15px 15px 15px",
                                          }}
                                        >
                                          <div className="message-content_text">
                                            {message.content}
                                          </div>
                                          <div className="message-content_time">
                                            {localTime}
                                          </div>
                                        </div>
                                      )}
                                      {message.content === "" &&
                                        message.upload !== null && (
                                          <div
                                            className="message-content-attachment"
                                            style={{
                                              borderRadius:
                                                "0px 15px 15px 15px",
                                            }}
                                          >
                                            <div className="message-content_attachment">
                                              <img
                                                src={
                                                  AttachmentType === "Image"
                                                    ? imageAttachment
                                                    : AttachmentType === "Audio"
                                                    ? audioAttachment
                                                    : documentAttachment
                                                }
                                                alt=""
                                              />
                                              <div>
                                                <h5>{AttachmentType} File</h5>
                                              </div>
                                              <img
                                                src={download}
                                                alt=""
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  handleDownload(message.id);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  )}

                                  {type === "sent" && (
                                    <div
                                      className="message-text"
                                      style={{
                                        marginLeft: "auto",
                                        justifyContent: "end",
                                      }}
                                    >
                                      {message.content !== "" && (
                                        <div
                                          className="message-content"
                                          style={{
                                            marginLeft: "0",
                                            marginRight: "2.5%",
                                            borderRadius: "15px 0px 15px 15px",
                                          }}
                                        >
                                          <div
                                            className="message-content_text"
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >
                                            {message.content}
                                          </div>
                                          <div className="message-content_time">
                                            {localTime}
                                          </div>
                                        </div>
                                      )}
                                      {message.content === "" &&
                                        message.upload !== null && (
                                          <div
                                            className="message-content-attachment"
                                            style={{
                                              marginLeft: "0",
                                              marginRight: "2.5%",
                                              borderRadius:
                                                "15px 0px 15px 15px",
                                            }}
                                          >
                                            <div className="message-content_attachment">
                                              <img
                                                src={download}
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                alt=""
                                                onClick={() => {
                                                  handleDownload(message.id);
                                                }}
                                              />
                                              <div>
                                                <h5>{AttachmentType} File</h5>
                                              </div>
                                              <img
                                                src={
                                                  AttachmentType === "Image"
                                                    ? imageAttachment
                                                    : AttachmentType === "Audio"
                                                    ? audioAttachment
                                                    : documentAttachment
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {!sameSender && (
                                        <img
                                          src={
                                            message.sender.profile_image_url
                                              ? message.sender.profile_image_url
                                              : sample
                                          }
                                          alt=""
                                        />
                                      )}
                                      {sameSender && (
                                        <div style={{ width: "7%" }}></div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </>
                    </InfiniteScroll>
                  </div>
                  <div className="chatbox-bottom">
                    <div className="chatbox-bottom-attachments">
                      <label
                        htmlFor="file-upload"
                        className="message-add-attachment"
                      >
                        <img src={attachment} alt="" />
                        Attach File
                      </label>
                      <img
                        alt=""
                        className="image-upload"
                        style={{ display: "none" }}
                      />
                      <input
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="chatbox-bottom-new-message">
                      <textarea
                        placeholder="Write a message"
                        onChange={(event) => {
                          setMessageText(event.target.value);
                        }}
                        className="commission-send-message"
                        value={messageText}
                        onKeyDown={(event) => {
                          if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault();
                            handleSendMessage();
                          }
                        }}
                      />
                      <button
                        disabled={messageText.length < 1}
                        onClick={handleSendMessage}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>

                <div className="messages-workstream">
                  <div className="workstream-top">
                    <h3>Workstream Summary</h3>
                    <div className="workstream-paid">
                      <p>Paid out</p>
                      <p>
                        {chatCommission.payment_status === "COMPLETED"
                          ? formatCurrency(chatCommission.budget * 100)
                          : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
                      </p>
                    </div>
                    <div className="workstream-escrow">
                      <p>In Escrow</p>
                      <p className="escrow-payment-amount">
                        {chatCommission.payment_status === "IN_ESCROW"
                          ? formatCurrency(chatCommission.budget * 100)
                          : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
                      </p>
                    </div>
                    <div className="workstream-escrow">
                      <p>Commission Fee</p>
                      <p>{formatCurrency(chatCommission.budget * 100)}</p>
                    </div>
                    <div className="workstream-unpaid">
                      <p>Unpaid Invoice</p>
                      <p>
                        {chatCommission.payment_status === "PENDING"
                          ? formatCurrency(chatCommission.budget * 100)
                          : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
                      </p>
                    </div>
                  </div>
                  <div className="workstream-navigation">
                    <button
                      className="workstream-feed"
                      style={
                        feedToggle === "workstream"
                          ? { color: "#ea6d62" }
                          : null
                      }
                      onClick={() => {
                        if (feedToggle !== "workstream") {
                          setFeedToggle("workstream");
                        }
                      }}
                    >
                      Workstream
                    </button>
                    <button
                      className="workstream-attachements"
                      style={
                        feedToggle === "attachments"
                          ? { color: "#ea6d62" }
                          : null
                      }
                      onClick={() => {
                        if (feedToggle !== "attachments") {
                          setFeedToggle("attachments");
                        }
                      }}
                    >
                      Attachments
                    </button>
                  </div>
                  {feedToggle === "workstream" && (
                    <div className="feed-details">
                      {chatCommission.commission_progress.map((status) => {
                        return (
                          <div className="feed-item" key={status.id}>
                            <div
                              className="feed-item-color"
                              style={
                                status.status === "REQUESTED" ||
                                status.status === "NEGOTIATING" ||
                                status.payment_status === "ON_HOLD"
                                  ? { backgroundColor: "rgb(242, 148, 35)" }
                                  : status.status === "ACCEPTED" ||
                                    status.status === "COMPLETED"
                                  ? { backgroundColor: "#229e04" }
                                  : { backgroundColor: "#fe2b0d" }
                              }
                            />
                            <div className="feed-item-type-image">
                              {(status.status === "ACCEPTED" &&
                                status.payment_status === "IN_ESCROW") ||
                              (status.status === "CANCELLED" &&
                                status.payment_status === "REFUNDED") ||
                              status.payment_status === "ON_HOLD" ? (
                                <img src={commission} alt="" />
                              ) : (
                                <CurrencySymbol
                                  currency={
                                    selectedCurrency === "GBP"
                                      ? "pound"
                                      : "naira"
                                  }
                                />
                              )}
                            </div>
                            <div className="feed-item-detail">
                              <h3>
                                {((status.status === "ACCEPTED" &&
                                  status.payment_status === "IN_ESCROW") ||
                                  status.status === "COMPLETED" ||
                                  (status.status === "CANCELLED" &&
                                    status.payment_status === "REFUNDED")) &&
                                status.payment_status !== "ON_HOLD"
                                  ? formatCurrency(chatCommission.budget * 100)
                                  : status.payment_status !== "ON_HOLD"
                                  ? `Commission #${status.commission_id}`
                                  : ""}
                                {status.status === "ACCEPTED" &&
                                  status.payment_status === "IN_ESCROW" &&
                                  " added to Escrow"}
                                {status.status === "COMPLETED" &&
                                  status.payment_status !== "ON_HOLD" &&
                                  " paid to producer"}
                                {status.status === "REQUESTED" && " Requested"}
                                {status.status === "NEGOTIATING" &&
                                  " Negotiating"}
                                {status.status === "ACCEPTED" &&
                                  status.payment_status !== "IN_ESCROW" &&
                                  " Accepted"}
                                {status.status === "REJECTED" && " Rejected"}
                                {status.status === "CANCELLED" &&
                                  status.payment_status !== "REFUNDED" &&
                                  status.payment_status !== "ON_HOLD" &&
                                  " Cancelled"}
                                {status.status === "IN_DISPUTE" &&
                                  " In Dispute"}
                                {status.status === "CANCELLED" &&
                                  status.payment_status === "REFUNDED" &&
                                  " Refunded"}
                                {status.payment_status === "ON_HOLD" &&
                                  " Payment On Hold"}
                              </h3>
                              <p>
                                {formatCurrency(chatCommission.budget * 100)}
                              </p>
                              <p>
                                {new Date(status.created_at)
                                  .toLocaleString("en-US", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/,/g, "")}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {feedToggle === "attachments" && (
                    <>
                      <div className="attachment-feed-container">
                        <Popup
                          className="attachment-file-popup"
                          trigger={
                            <button>
                              {attachmentToggle}
                              <img src={down} alt="" />
                            </button>
                          }
                          arrow={false}
                          position="bottom center"
                        >
                          {(close) => (
                            <>
                              <div
                                onClick={() => {
                                  if (attachmentToggle !== "Show all") {
                                    setAttachmentToggle("Show all");
                                  }
                                  close();
                                }}
                              >
                                All
                              </div>
                              <div
                                onClick={() => {
                                  if (attachmentToggle !== "Audio") {
                                    setAttachmentToggle("Audio");
                                    close();
                                  }
                                }}
                              >
                                Audio
                              </div>
                              <div
                                onClick={() => {
                                  if (attachmentToggle !== "Images") {
                                    setAttachmentToggle("Images");
                                  }
                                  close();
                                }}
                              >
                                Images
                              </div>
                              <div
                                onClick={() => {
                                  if (attachmentToggle !== "Documents") {
                                    setAttachmentToggle("Documents");
                                  }
                                  close();
                                }}
                              >
                                Documents
                              </div>
                            </>
                          )}
                        </Popup>
                      </div>

                      <div className="attachment-feed">
                        {attachments &&
                          attachments.map((attachment) => {
                            const fileExtension = attachment.upload
                              .split(".")
                              .pop()
                              .toLowerCase();

                            const type = [
                              "jpg",
                              "jpeg",
                              "png",
                              "gif",
                              "bmp",
                              "webp",
                            ].includes(fileExtension)
                              ? "Image"
                              : ["mp3", "wav", "ogg", "flac", "aac"].includes(
                                  fileExtension
                                )
                              ? "Audio"
                              : "Document";
                            return (
                              <div
                                className="attachment-feed-row"
                                key={attachment.id}
                              >
                                <img
                                  src={
                                    type === "Image"
                                      ? imageAttachment
                                      : type === "Audio"
                                      ? audioAttachment
                                      : documentAttachment
                                  }
                                  alt=""
                                />
                                <div>
                                  <h5>{type} File</h5>
                                  <p>
                                    {new Date(attachment.created_at)
                                      .toLocaleString("en-US", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })
                                      .replace(/,/g, "")}
                                  </p>
                                </div>
                                <img
                                  src={download}
                                  alt=""
                                  onClick={() => {
                                    handleDownload(attachment.id);
                                  }}
                                />
                              </div>
                            );
                          })}

                        {!attachments && (
                          <div className="attachment-load-placeholder">
                            <TailSpin
                              height="70"
                              width="70"
                              color="#000000"
                              ariaLabel="tail-spin-loading"
                              visible={true}
                              wrapperClass="loading"
                              className="loading"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {!activeChat && (
              <div className="select-chat-placeholder">
                Click on a chat to view messages
              </div>
            )}

            {activeChat && (!chatCommission || !chatMessages) && (
              <div className="select-chat-placeholder">
                <TailSpin
                  height="70"
                  width="70"
                  color="#000000"
                  ariaLabel="tail-spin-loading"
                  visible={true}
                  wrapperClass="loading"
                  className="loading"
                />
              </div>
            )}
          </div>
        </section>
      )}

      <Popup
        className="counter-offer-popup"
        open={showUpdatePricePopup}
        onOpen={() => {
          setShowUpdatePricePopup(true);
        }}
        onClose={() => {
          setShowUpdatePricePopup(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowUpdatePricePopup(false);
            }}
          />
          <div className="place-bid-popup-heading-container">
            <h3>Update Price</h3>
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={warning} alt="" className="bid-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {counterBidErrorMessage}
            </Popup>
          </div>
          <div className="counter-offer-popup-container">
            <input
              type="text"
              placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}20`}
              value={counterBidInput}
              className="update-price-input-field"
              onChange={handleCounterBidInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleUpdatePrice();
                }
              }}
            />
            <div className="button-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowUpdatePricePopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-container__submit"
                onClick={async (event) => {
                  await handleUpdatePrice();
                }}
              >
                <img src={submit} alt="" />
                Update Price
              </button>
            </div>
          </div>
        </>
      </Popup>

      <Popup
        className="place-bid-popup"
        open={showDisputePopup}
        onOpen={() => {
          setShowDisputePopup(true);
        }}
        onClose={() => {
          setShowDisputePopup(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowDisputePopup(false);
            }}
          />
          <div className="place-bid-popup-container dispute-popup-container">
            <div className="place-bid-popup-heading-container">
              <h3>Dispute Commission</h3>
              <Popup
                className="upload-error-popup"
                trigger={
                  <img
                    src={warning}
                    alt=""
                    className="dispute-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                {disputeErrorMessage}
              </Popup>
            </div>

            <textarea
              placeholder="Description"
              value={disputeInput}
              onChange={handleDisputeInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleDispute();
                }
              }}
            />

            <div className="button-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowDisputePopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-container__dispute"
                onClick={handleDispute}
              >
                Send Dispute Request
              </button>
            </div>
          </div>
          <div className="place-bid-popup-info">
            <h5>
              <img src={info} alt="" />
              Submit a dispute
            </h5>
            <p>
              This will halt all communication and payments for this commission
            </p>
            <p>The admin will be notified and will help resolve the dispute</p>
          </div>
        </>
      </Popup>

      <Popup
        className="place-bid-popup"
        open={showConfirmPopup}
        onOpen={() => {
          setShowConfirmPopup(true);
        }}
        onClose={() => {
          setShowConfirmPopup(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowConfirmPopup(false);
            }}
          />
          <div className="place-bid-popup-container dispute-popup-container">
            <div className="place-bid-popup-heading-container">
              <h3>Complete Commission</h3>
            </div>

            <div className="button-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowConfirmPopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-container__dispute"
                onClick={handleComplete}
              >
                Complete Commission
              </button>
            </div>
          </div>
          <div className="place-bid-popup-info">
            <h5>
              <img src={info} alt="" />
              Complete this commission
            </h5>
            <p>
              By completing this commission you agree to release funds to the
              producer after a period of 14 days
            </p>
          </div>
        </>
      </Popup>

      {showCommissionDetailsPopup && activeChat && chatCommission && (
        <Popup
          className="commission-details-popup"
          open={showCommissionDetailsPopup}
          onOpen={() => {
            setShowCommissionDetailsPopup(true);
          }}
          onClose={() => {
            setShowCommissionDetailsPopup(false);
          }}
          modal
          nested
        >
          <>
            <img
              className="commission-details-popup__close"
              src={cross}
              alt=""
              onClick={() => {
                setShowCommissionDetailsPopup(false);
              }}
            />
            <div className="place-bid-popup-heading-container">
              <h3>Contract Details</h3>
            </div>
            <div className="commission-details-popup-container">
              <div className="commission-details-popup-container__row">
                <label>Participants:</label>
                <div className="commission-details-participants-container">
                  <div className="commission-details-participant">
                    <img
                      src={
                        chatCommission.requested_by.profile_image_url
                          ? chatCommission.requested_by.profile_image_url
                          : sample
                      }
                      alt=""
                    />
                    {chatCommission.requested_by.name}
                  </div>
                  <div className="commission-details-participant">
                    <img
                      src={
                        chatCommission.requested_to.profile_image_url
                          ? chatCommission.requested_to.profile_image_url
                          : sample
                      }
                      alt=""
                    />
                    {chatCommission.requested_to.name}
                  </div>
                </div>
              </div>

              <div className="commission-details-popup-container__row">
                <label>Commission ID:</label>
                <div className="contract-details-popup__detail-box">
                  <div
                    className="bullet"
                    style={{ backgroundColor: "green" }}
                  />
                  {chatCommission.id}
                </div>
              </div>

              <div className="commission-details-popup-container__row">
                <label>Budget:</label>
                <div className="contract-details-popup__detail-box">
                  <div
                    className="bullet"
                    style={{ backgroundColor: "green" }}
                  />
                  {formatCurrency(chatCommission.budget * 100)}
                </div>
              </div>

              <div className="commission-details-popup-container__row">
                <label>Amount in escrow:</label>
                <div className="contract-details-popup__detail-box">
                  <div
                    className="bullet"
                    style={{
                      backgroundColor: `${
                        chatCommission.payment_status === "IN_ESCROW"
                          ? "green"
                          : "red"
                      }`,
                    }}
                  />
                  {chatCommission.payment_status === "IN_ESCROW"
                    ? formatCurrency(chatCommission.budget * 100)
                    : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
                </div>
              </div>

              <div className="commission-details-popup-container__row">
                <label>Work Description</label>
              </div>

              <div className="commission-details-popup-container__row">
                <p className="commission-details-popup-container__row-description">
                  {chatCommission.description}
                </p>
              </div>

              <div className="commission-details-popup-container__row">
                <label>Project Status:</label>
                <div className="contract-details-popup__detail-box">
                  {chatCommission.status === "REQUESTED" && (
                    <>
                      <div
                        className="bullet"
                        style={{ backgroundColor: "#F29423" }}
                      ></div>
                      <p>Requested</p>
                    </>
                  )}
                  {chatCommission.status === "ACCEPTED" && (
                    <>
                      <div
                        className="bullet"
                        style={{ backgroundColor: "green" }}
                      ></div>
                      <p>Accepted</p>
                    </>
                  )}
                  {chatCommission.status === "REJECTED" && (
                    <>
                      <div className="bullet"></div>
                      <p>Rejected</p>
                    </>
                  )}
                  {chatCommission.status === "CANCELLED" && (
                    <>
                      <div className="bullet"></div>
                      <p>Cancelled</p>
                    </>
                  )}
                  {chatCommission.status === "COMPLETED" && (
                    <>
                      <div
                        className="bullet"
                        style={{ backgroundColor: "green" }}
                      ></div>
                      <p>Completed</p>
                    </>
                  )}
                  {chatCommission.status === "IN_DISPUTE" && (
                    <>
                      <div className="bullet"></div>
                      <p>In Dispute</p>
                    </>
                  )}
                  {chatCommission.status === "NEGOTIATING" && (
                    <>
                      <div
                        className="bullet"
                        style={{ backgroundColor: "blue" }}
                      ></div>
                      <p>Negotiating</p>
                    </>
                  )}
                </div>
              </div>

              {chatCommission.status === "ACCEPTED" &&
                chatCommission.requested_by.id === user.id && (
                  <div
                    className="commission-details-popup-container__row cancel-commission cancel-commission-button"
                    onClick={() => {
                      setShowConfirmCancelPopup(true);
                    }}
                  >
                    Cancel Contract
                  </div>
                )}

              {chatCommission.status !== "IN_DISPUTE" && (
                <div
                  className="commission-details-popup-container__row cancel-commission"
                  onClick={() => {
                    setShowDisputePopup(true);
                  }}
                >
                  Dispute Contract
                </div>
              )}
            </div>
          </>
        </Popup>
      )}

      <Popup
        className="counter-offer-popup"
        open={showConfirmCancelPopup}
        onOpen={() => {
          setShowConfirmCancelPopup(true);
        }}
        onClose={() => {
          setShowConfirmCancelPopup(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowConfirmCancelPopup(false);
            }}
          />
          <div className="place-bid-popup-heading-container">
            <h3>Cancel Commission</h3>
          </div>
          <div className="counter-offer-popup-container">
            <h4 className="remove-beat-confirm-message">
              <img src={warning} alt="" />
              You are about to cancel this commission!
            </h4>
            <div className="button-container remove-beat-confirm-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowConfirmCancelPopup(false);
                }}
              >
                Return
              </button>
              <button
                className="button-container__submit remove-beat-confirm"
                onClick={() => {
                  handleCancel();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      </Popup>

      <Popup
        className="counter-offer-popup"
        open={showDisputeConfirmation}
        onClose={() => {
          setShowDisputeConfirmation(false);
        }}
        modal
      >
        <img
          className="counter-offer-popup__close"
          src={cross}
          alt=""
          onClick={() => {
            setShowDisputeConfirmation(false);
          }}
        />
        <div className="counter-offer-popup-container">
          <img className="offer-confirmation" src={accept} alt="" />
          <h4>Your Dispute Request has been sent</h4>
          <p>Our representative will contact you shortly</p>
        </div>
      </Popup>

      {chatCommission && (
        <Popup
          className="create-playlist-popup wallet-popup"
          open={showPaymentPopup}
          onOpen={() => {
            setShowPaymentPopup(true);
          }}
          onClose={() => {
            setShowPaymentPopup(false);
          }}
          modal
        >
          <div className="wallet-popup-heading">
            <h3>Commission Payment</h3>
          </div>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowPaymentPopup(false);
            }}
          />
          {user && wallet >= 0 && (
            <div className="wallet-popup-container commission-payment-popup-container">
              <div className="commission-payment-detail-container">
                <p>Commission Amount: </p>
                {formatCurrency(chatCommission.budget * 100)}
              </div>
              <div className="commission-payment-detail-container">
                <p>Wallet Balance: </p>
                <span
                  style={
                    chatCommission.budget - wallet / 100 > 0
                      ? { color: "#fe2b0d" }
                      : { color: "green" }
                  }
                >
                  {formatCurrency(wallet)}
                </span>
              </div>
              <div className="commission-payment-detail-container">
                <p>Additional Amount Required:</p>
                <span
                  style={
                    wallet / 100 - chatCommission.budget >= 0
                      ? { color: "green" }
                      : null
                  }
                >
                  {chatCommission.budget - wallet / 100 > 0
                    ? formatCurrency(chatCommission.budget * 100 - wallet)
                    : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
                </span>
              </div>

              {chatCommission.budget - wallet / 100 <= 0 && (
                <>
                  <p>
                    An amount of{" "}
                    <span style={{ color: "#fe2b0d" }}>
                      {formatCurrency(chatCommission.budget * 100)}
                    </span>{" "}
                    will be deducted from your wallet balance
                  </p>
                  <div className="commission-payment-detail-container">
                    <p>New Wallet Balance:</p>
                    <span style={{ color: "#fe2b0d" }}>
                      {formatCurrency(wallet - chatCommission.budget * 100)}
                    </span>
                  </div>

                  <div className="button-container">
                    <button
                      className="button-container__submit"
                      onClick={() => {
                        setPaymentMethod("paypal");
                        handlePayment();
                      }}
                    >
                      {saveChanges && (
                        <Bars
                          height="40"
                          width="40"
                          color="#fff"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass="loading-bars"
                          visible={saveChanges}
                        />
                      )}
                      {!saveChanges && "Proceed"}
                    </button>
                  </div>
                </>
              )}

              {chatCommission.budget - wallet / 100 > 0 && (
                <>
                  <p>
                    An additional amount of{" "}
                    <span style={{ color: "#fe2b0d" }}>
                      {chatCommission.budget * 100 - wallet}
                    </span>{" "}
                    will be deposited via Paypal/Paystack
                  </p>
                  <div className="commission-payment-detail-container">
                    <p>New Wallet Balance:</p>
                    <span style={{ color: "#fe2b0d" }}>
                      {selectedCurrency === "GBP" ? "£" : "N"}00.00
                    </span>
                  </div>

                  <div className="button-container">
                    <button
                      className="button-container__submit"
                      onClick={() => {
                        setPaymentMethod("paypal");
                        handlePayment("paypal");
                      }}
                      style={{ backgroundColor: "#f7c039", color: "black" }}
                    >
                      {saveChanges && paymentMethod === "paypal" && (
                        <Bars
                          height="40"
                          width="40"
                          color="#fff"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass="loading-bars"
                          visible={true}
                        />
                      )}
                      {!(saveChanges && paymentMethod === "paypal") &&
                        "Proceed to Paypal"}
                    </button>
                    <button
                      className="button-container__submit"
                      onClick={() => {
                        setPaymentMethod("paystack");
                        handlePayment("paystack");
                      }}
                    >
                      {saveChanges && paymentMethod === "paystack" && (
                        <Bars
                          height="40"
                          width="40"
                          color="#fff"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass="loading-bars"
                          visible={true}
                        />
                      )}
                      {!(saveChanges && paymentMethod === "paystack") &&
                        "Proceed to Paystack"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </Popup>
      )}
    </>
  );
}
export default Messages;

const CurrencySymbol = ({ currency }) => {
  const getSymbol = () => {
    switch (currency) {
      case "pound":
        return "£";
      case "naira":
        return "₦";
      default:
        return "";
    }
  };

  return <div className={`currency-symbol ${currency}`}>{getSymbol()}</div>;
};
