import "./../stylesheets/playlistLanding.css";
import "./../../index.css";
import "./../../Fonts/fonts.css";
import art from "./../../images/1.png";
import note from "./../../images/musical-note.svg";
import edit from "./../../images/edit-image.svg";
import remove from "./../../images/delete.svg";
import cross from "./../../images/cross-black.svg";
import play from "./../../images/play_button.svg";
import warning from "./../../images/warning.svg";
import search from "./../../images/search-icon-dark.png";
import moreDark from "./../../images/more-dark.svg";
import * as Sentry from "@sentry/react";
import PlaylistRows from "./playlistRows";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import { usePlayerContext } from "../../context/playerContext";
import { addToQueue } from "../../actions/playerActions";

function PlaylistLanding({
  setShowUnauthenticatedPopup,
  handleCart,
  handleLocalCart,
  setUpdateCartCounter,
  updateCartCounter,
}) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates } = useCurrencyContext();

  const [playlist, setPlaylist] = useState(null);
  const [isEditPlaylistPopupOpen, setIsEditPlaylistPopupOpen] = useState(false);
  const [playlistTitle, setPlaylistTitle] = useState(
    playlist ? playlist?.name : ""
  );
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

  const [searchBeats, setSearchBeats] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchBeatsNullError, setSearchBeatsNullError] = useState(false);

  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const { id } = useParams();

  const [showConfirmRemovePlaylist, setShowConfirmRemovePlaylist] =
    useState(false);

  const getPlaylistData = async () => {
    try {
      const response = await api.get(`playlist/${id}`);
      handleResponseStatus(response, navigate);
      const data = response.data;
      data.data.beats = Object.values(data.data.items);

      setPlaylist(data.data);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
    const getPlaylistData = async () => {
      try {
        const response = await api.get(`playlist/${id}`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        data.data.beats = Object.values(data.data.items);

        setPlaylist(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getPlaylistData();
  }, [navigate, isAuthenticated, id, setShowUnauthenticatedPopup]);

  const handlePlayAll = async () => {
    const items = [];
    playlist.beats.forEach((item) => {
      items.push(item.item);
    });

    playerDispatch(addToQueue(items));
  };

  const handlePlayAllOrdered = async (slug) => {
    const startIndex = playlist.beats.findIndex(
      (beat) => beat.item.slug === slug
    );

    if (startIndex === -1) {
      return;
    }

    const itemsArray = playlist.beats.map((item) => item.item);

    const subsequentBeats = [
      ...itemsArray.slice(startIndex),
      ...itemsArray.slice(0, startIndex),
    ];

    playerDispatch(addToQueue(subsequentBeats));
  };

  const handleDeletePlaylist = async () => {
    try {
      const response = await api.delete(`playlist/${playlist.id}`);
      handleResponseStatus(response, navigate);
      navigate("/playlists", { state: { redirectByDelete: true } });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleSearchInput = async (event) => {
    setSearchInput(event.target.value);
    if (event.target.value.length === 0) {
      document.querySelector(".clear-search").classList.add("hidden");
      setSearchBeats(null);
      setSearchBeatsNullError(false);
      document.querySelector(".search-error").classList.add("hidden");
    } else if (event.target.value.length < 3)
      document.querySelector(".search-error").classList.remove("hidden");
    else document.querySelector(".search-error").classList.add("hidden");
  };

  const getSearchBeats = async function () {
    setSearchBeatsNullError(false);

    if (searchInput.length === 0) {
      return;
    } else if (searchInput.length < 3) {
      document.querySelector(".search-error").classList.remove("hidden");
      return;
    }
    try {
      const response = await api.post(`search/beats`, {
        term: searchInput,
        mode: "or",
      });
      const responseSong = await api.post(`search/songs`, {
        term: searchInput,
        mode: "or",
      });

      handleResponseStatus(response, navigate);
      handleResponseStatus(responseSong, navigate);

      const data = response.data;
      const dataSongs = responseSong.data;
      const filteredBeats = data.data.filter((beat) => {
        return !playlist.beats.some(
          (playlistBeat) => playlistBeat.slug === beat.slug
        );
      });
      const filteredSongs = dataSongs.data.filter((beat) => {
        return !playlist.beats.some(
          (playlistBeat) => playlistBeat.slug === beat.slug
        );
      });
      setSearchBeats([...filteredBeats, ...filteredSongs]);
      document.querySelector(".clear-search").classList.remove("hidden");
      if (filteredBeats.length === 0 && filteredSongs.length === 0) {
        setSearchBeatsNullError(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleAddToPlaylist = async (id, type) => {
    let body;
    if (type === "song") {
      body = { playlist: playlist.id, songs: [id] };
    } else {
      body = { playlist: playlist.id, beats: [id] };
    }

    try {
      const response = await api.post(`playlist/add`, body);

      handleResponseStatus(response, navigate);
      if (response.status === 200) {
        getPlaylistData();
        setSearchBeats(searchBeats.filter((beat) => beat.id !== id));
        setPopupMessage(
          `${type === "song" ? "Song" : "Beat"} added to playlist`
        );
        setShowMessagePopup(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handlePlaylistTitleInputChange = (event) => {
    setPlaylistTitle(event.target.value);
    setIsUpdateButtonDisabled(false);

    if (event.target.value.length < 5) {
      setTitleErrorMessage("Minimum characters are 5!");
      document.querySelector(".title-error").classList.remove("hidden");
    } else {
      document.querySelector(".title-error").classList.add("hidden");
    }
  };

  function checkValues() {
    let valid = true;
    if (playlistTitle.length < 5) {
      document.querySelector(".title-error").classList.remove("hidden");

      valid = false;
    }

    return valid;
  }

  const handleSubmitEditPlaylistForm = async (event) => {
    event.preventDefault();

    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    if (!checkValues()) return;
    setIsUpdateButtonDisabled(true);

    try {
      const response = await api.put(`playlist/${playlist.id}`, {
        name: playlistTitle,
      });

      handleResponseStatus(response, navigate);

      if (response.status === 422) {
        setTitleErrorMessage("A playlist with this name already exists!");
        document.querySelector(".title-error").classList.remove("hidden");
        return;
      } else if (response.status === 200) {
        getPlaylistData();
        setPlaylistTitle("");
        setIsEditPlaylistPopupOpen(false);
        setPopupMessage("Playlist updated successfully");
        setShowMessagePopup(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleRemoveFromPlaylist = async (event, beatId) => {
    event.preventDefault();
    try {
      const response = await api.post(`playlist/remove`, {
        playlist: playlist.id,
        items: [beatId],
      });

      handleResponseStatus(response, navigate);
      if (response.status === 200) {
        getPlaylistData();
        setPopupMessage("Beat removed from playlist");
        setShowMessagePopup(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const { playerState, playerDispatch } = usePlayerContext();

  return (
    <>
      {playlist && (
        <>
          <section
            className="playlist-landing-topbar"
            style={{
              backgroundImage: `url(${
                playlist.image === null ? art : playlist.image
              })`,
            }}
          >
            <div className="playlist-landing-overlay">
              <div className="playlist-landing__cover-image">
                <img src={playlist.image ? playlist.image : art} alt="" />
                <div className="play-button">
                  <button onClick={handlePlayAll}>
                    <img src={play} alt="" />
                  </button>
                </div>
              </div>
              <div className="playlist-landing-detail-container">
                <h2>{playlist.name}</h2>
                <h3>
                  <img src={note} alt="" />
                  {playlist.items_count === 0
                    ? "No Tracks added"
                    : playlist.items_count === 1
                    ? "1 Track added"
                    : `${playlist.items_count} Tracks added`}
                </h3>
                <p>
                  {new Date(playlist.created_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
                <div className="playlist-landing-detail-container__buttons">
                  <Popup
                    className="create-playlist-popup"
                    trigger={
                      <button>
                        <img src={edit} alt="" />
                        Edit
                      </button>
                    }
                    modal
                    open={isEditPlaylistPopupOpen}
                    onOpen={() => {
                      setPlaylistTitle(playlist.name);
                      setIsEditPlaylistPopupOpen(true);
                    }}
                    onClose={() => {
                      setIsEditPlaylistPopupOpen(false);
                    }}
                  >
                    <div className="create-playlist-popup-heading">
                      <h3>Edit Playlist</h3>
                      <img
                        src={cross}
                        alt=""
                        onClick={() => {
                          setIsEditPlaylistPopupOpen(false);
                        }}
                      />
                      ;
                    </div>
                    <div className="create-playlist-popup-container">
                      <p>
                        Playlist title
                        <Popup
                          className="upload-error-popup"
                          trigger={
                            <img
                              src={warning}
                              alt=""
                              className="title-error hidden"
                            ></img>
                          }
                          on="hover"
                          position="top center"
                          arrow={false}
                        >
                          {titleErrorMessage}
                        </Popup>
                      </p>
                      <input
                        type="text"
                        onChange={handlePlaylistTitleInputChange}
                        value={playlistTitle}
                        placeholder={playlist.name}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleSubmitEditPlaylistForm(event);
                          }
                        }}
                      />

                      <button
                        disabled={isUpdateButtonDisabled}
                        className="create-playlist-button"
                        onClick={handleSubmitEditPlaylistForm}
                      >
                        Update Playlist
                      </button>
                    </div>
                  </Popup>
                  <button
                    onClick={() => {
                      setShowConfirmRemovePlaylist(true);
                    }}
                  >
                    <img src={remove} alt="" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section className="playlist-landing-search">
            <h2>Let's find tracks for your playlist</h2>
            <div className="playlist-landing-search__input-container">
              <img src={search} alt="" onClick={getSearchBeats} />
              <input
                type="text"
                placeholder="Search tracks"
                value={searchInput}
                onChange={handleSearchInput}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    getSearchBeats();
                  }
                }}
              />
              <Popup
                className="upload-error-popup"
                trigger={
                  <img src={warning} alt="" className="search-error hidden" />
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                Enter at least 3 characters!
              </Popup>

              <img
                className="clear-search hidden"
                src={cross}
                alt=""
                onClick={() => {
                  setSearchInput("");
                  setSearchBeats(null);
                  setSearchBeatsNullError(false);
                }}
              />
            </div>
            <div className="beats-rows search-beat-rows">
              {searchBeats && (
                <>
                  {searchBeats.map((beatRow, index) => {
                    return (
                      <div
                        className="beat-rows--item"
                        data-id={beatRow.slug}
                        key={index}
                        data-url={
                          beatRow.play_url
                            ? beatRow.play_url
                            : beatRow.preview_path
                            ? beatRow.preview_path
                            : ""
                        }
                      >
                        <div className="beat-rows-details beats__music__player">
                          <p className="beat-rows-number">{index + 1}</p>
                          <div className="beat-rows-image">
                            <img
                              alt="beat"
                              src={
                                beatRow.artwork_url
                                  ? beatRow.artwork_url
                                  : beatRow.cover_path
                                  ? beatRow.cover_path
                                  : art
                              }
                            ></img>
                          </div>
                          <div className="beat-rows-name">
                            <h4>{beatRow.name}</h4>
                            <p className="beat-tag">
                              {beatRow?.producer?.name}
                            </p>
                          </div>
                        </div>
                        <div className="tags">
                          {beatRow.bpm && (
                            <p className="beat-rows-bpm beat-rows-tag">
                              {beatRow.bpm} BPM
                            </p>
                          )}
                          {beatRow?.genres?.slice(0, 2).map((genre) => (
                            <p className="beat-rows-tag" key={genre.id}>
                              {genre.name}
                            </p>
                          ))}
                          {beatRow.genres?.length > 3 && (
                            <Popup
                              className="extra-genres-popup"
                              trigger={
                                <p
                                  className="beat-rows-tag"
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={moreDark} alt="" />
                                </p>
                              }
                              on="hover"
                              arrow={false}
                              position="top center"
                            >
                              {beatRow?.genres?.slice(2).map((genre) => (
                                <p key={genre.id}>{genre.name}</p>
                              ))}
                            </Popup>
                          )}
                        </div>

                        <button
                          className="playlist-add-beat"
                          onClick={() =>
                            handleAddToPlaylist(
                              beatRow.slug,
                              beatRow.cover_path ? "song" : "beat"
                            )
                          }
                        >
                          Add to Playlist
                        </button>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            {searchBeatsNullError && (
              <h3 className="playlist-search-null-error">No results found</h3>
            )}
          </section>

          {playlist && (
            <PlaylistRows
              beats={playlist.beats}
              showRemoveFromPlaylist={true}
              handleRemoveFromPlaylist={handleRemoveFromPlaylist}
              playlistPlay={handlePlayAllOrdered}
              rates={rates}
              selectedCurrency={selectedCurrency}
              handleCart={handleCart}
              handleLocalCart={handleLocalCart}
              setUpdateCartCounter={setUpdateCartCounter}
              updateCartCounter={updateCartCounter}
            />
          )}
        </>
      )}
      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>

      <Popup
        className="counter-offer-popup"
        open={showConfirmRemovePlaylist}
        onOpen={() => {
          setShowConfirmRemovePlaylist(true);
        }}
        onClose={() => {
          setShowConfirmRemovePlaylist(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowConfirmRemovePlaylist(false);
            }}
          />
          <div className="place-bid-popup-heading-container">
            <h3>Delete Playlist</h3>
          </div>
          <div className="counter-offer-popup-container">
            <h4 className="remove-beat-confirm-message">
              <img src={warning} alt="" />
              <span className="warning__message">Warning: </span>Deleting this
              playlist will permanently remove all its tracks. Are you sure you
              want to proceed?
            </h4>
            <div className="button-container remove-beat-confirm-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowConfirmRemovePlaylist(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-container__submit remove-beat-confirm"
                onClick={() => {
                  handleDeletePlaylist();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      </Popup>
    </>
  );
}

export default PlaylistLanding;
