import "./../stylesheets/editProfile.css";
import Warning from "./../../images/warning.svg";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
import { useNavigation } from "../../context/navigationContext";
function Social({ user, handlePrevious, handleNext, handleUpdateUser }) {
  const { navigate } = useNavigation();

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [soundcloud, setSoundcloud] = useState("");

  function isValidUrl(url) {
    const urlRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:facebook|instagram|x|soundcloud)\.com\/[a-zA-Z0-9._-]+/;
    return urlRegex.test(url);
  }

  function handleFacebookInputChange(event) {
    setFacebook(event.target.value);
    if (isValidUrl(event.target.value))
      document.querySelector(".facebook-error").classList.add("hidden");
    else document.querySelector(".facebook-error").classList.remove("hidden");
  }

  function handleInstagramInputChange(event) {
    setInstagram(event.target.value);
    if (isValidUrl(event.target.value))
      document.querySelector(".instagram-error").classList.add("hidden");
    else document.querySelector(".instagram-error").classList.remove("hidden");
  }

  function handleTwitterInputChange(event) {
    setTwitter(event.target.value);
    if (isValidUrl(event.target.value))
      document.querySelector(".twitter-error").classList.add("hidden");
    else document.querySelector(".twitter-error").classList.remove("hidden");
  }

  function handleSoundcloudInputChange(event) {
    setSoundcloud(event.target.value);
    if (isValidUrl(event.target.value))
      document.querySelector(".soundcloud-error").classList.add("hidden");
    else document.querySelector(".soundcloud-error").classList.remove("hidden");
  }

  const handleSubmit = async () => {
    const body = {
      facebook_url:
        facebook === ""
          ? user.facebook_url === null
            ? null
            : user.facebook_url
          : facebook.toString(),
      twitter_url:
        twitter === ""
          ? user.twitter_url === null
            ? null
            : user.twitter_url
          : twitter,
      soundcloud_url:
        soundcloud === ""
          ? user.soundcloud_url === null
            ? null
            : user.soundcloud_url
          : soundcloud,
      instagram_url:
        instagram === ""
          ? user.instagram_url === null
            ? null
            : user.instagram_url
          : instagram,
    };

    try {
      const response = await api.put("profile", body);
      handleResponseStatus(response, navigate);
      await handleUpdateUser();
      if (response.status === 200) {
        handleNext();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      setFacebook(user.facebook_url);
      setInstagram(user.instagram_url);
      setTwitter(user.twitter_url);
      setSoundcloud(user.soundcloud_url);
    };
    handleLoad();
  }, [
    user.facebook_url,
    user.instagram_url,
    user.twitter_url,
    user.soundcloud_url,
  ]);

  return (
    <section className="social-section">
      <section className="edit-profile">
        <div className="input-div">
          <label>
            Facebook
            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="facebook-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Please enter a valid URL!
            </Popup>
          </label>
          <input
            type="text"
            className="facebook-url"
            value={facebook}
            onChange={(event) => {
              handleFacebookInputChange(event);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label>
            Twitter
            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="twitter-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Please enter a valid URL!
            </Popup>
          </label>
          <input
            type="text"
            className="twitter-url"
            value={twitter}
            onChange={(event) => {
              handleTwitterInputChange(event);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label>
            Instagram
            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="instagram-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Please enter a valid URL!
            </Popup>
          </label>
          <input
            type="text"
            className="instagram-url"
            value={instagram}
            onChange={(event) => {
              handleInstagramInputChange(event);
            }}
          ></input>
        </div>
        <div className="input-div">
          <label>
            Soundcloud
            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="soundcloud-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Please enter a valid URL!
            </Popup>
          </label>
          <input
            type="text"
            className="soundcloud-url"
            value={soundcloud}
            onChange={(event) => {
              handleSoundcloudInputChange(event);
            }}
          ></input>
        </div>
      </section>
      <div className="edit-profile-button-container">
        <button className="previous" onClick={handlePrevious}>
          Previous
        </button>
        <button className="save-continue" onClick={handleSubmit}>
          Save & Continue
        </button>
      </div>
    </section>
  );
}

export default Social;
