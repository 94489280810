// context/NavigationContext.js

import React, { createContext, useContext, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  const handleNavigateToBeat = useCallback(
    (beatId) => {
      navigate(`/beats/${beatId}`);
    },
    [navigate]
  );

  const handleNavigateToMusic = useCallback(
    (musicId) => {
      navigate(`/music/${musicId}`);
    },
    [navigate]
  );

  const handleNavigateToAlbum = useCallback(
    (albumId) => {
      navigate(`/albums/${albumId}`);
    },
    [navigate]
  );

  const handleNavigateToProducer = useCallback(
    (producerId) => {
      navigate(`/producers/${producerId}`);
    },
    [navigate]
  );

  const handleNavigateToArtists = useCallback(
    (artistId) => {
      navigate(`/artists/${artistId}`);
    },
    [navigate]
  );

  const handleNavigateToComments = useCallback(
    (type, id) => {
      navigate(`/${type}/${id}/comments`);
    },
    [navigate]
  );

  const handleNavigation = useCallback(
    (path, state) => {
      navigate(path, state);
    },
    [navigate]
  );

  const contextValue = useMemo(
    () => ({
      navigateToBeat: handleNavigateToBeat,
      navigateToMusic: handleNavigateToMusic,
      navigateToAlbum: handleNavigateToAlbum,
      navigateToProducer: handleNavigateToProducer,
      navigateToArtists: handleNavigateToArtists,
      navigateToComments: handleNavigateToComments,
      navigate: handleNavigation,
    }),
    [
      handleNavigateToBeat,
      handleNavigateToMusic,
      handleNavigateToAlbum,
      handleNavigateToProducer,
      handleNavigateToArtists,
      handleNavigateToComments,
      handleNavigation,
    ]
  );

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
