import "./../stylesheets/main.css";
import searchArrowDown from "./../../images/Path-2.png";
import searchIcon from "./../../images/search-icon-dark.png";
import Warning from "./../../images/warning2.svg";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";

function Main() {
  const navigate = useNavigate();

  const [searchCategory, setSearchCategory] = useState("Beats");
  const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearchInputChange = (event) => {
    const regex = /[^a-zA-Z0-9\s]/g;

    const filteredValue = event.target.value.replace(regex, "");
    setSearchInputValue(filteredValue);
    if (
      event.target.value.length === 0 ||
      (event.target.value.length >= 3 && event.target.value.length <= 25)
    ) {
      document.querySelector(".overlay-search-error").classList.add("hidden");
    } else {
      document
        .querySelector(".overlay-search-error")
        .classList.remove("hidden");
    }
  };

  const getSearchBeats = async () => {
    if (searchInputValue.length < 3 || searchInputValue.length > 25) {
      document
        .querySelector(".overlay-search-error")
        .classList.remove("hidden");
      return;
    }
    if (searchCategory === "Beats") {
      navigate(`/beats`, { state: { searchTerm: searchInputValue } });
    } else if (searchCategory === "Producers") {
      navigate(`/producers`, { state: { searchTerm: searchInputValue } });
    } else if (searchCategory === "Songs") {
      navigate(`/music/all`, { state: { searchTerm: searchInputValue } });
    } else if (searchCategory === "Albums") {
      navigate(`/albums/all`, {
        state: { searchTerm: searchInputValue },
      });
    }
  };

  return (
    <>
      <section className="hero-div">
        <div className="overlay-container">
          <h1 className="overlay-heading">
            Find afro <span className="overlay-heading-beats"> beats</span> and
            explore new music!
          </h1>
          <div className="overlay-search--container">
            <img
              src={searchIcon}
              alt=""
              className="overlay-search-container--left"
              onClick={getSearchBeats}
            />
            <input
              className="overlay-search"
              type="text"
              placeholder="Try Afrobeats, Hip Hop or Amapiano"
              onChange={handleSearchInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  getSearchBeats();
                }
              }}
              value={searchInputValue}
            />
            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="overlay-search-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Enter at least 3 characters and less than 25 characters!
            </Popup>
            <Popup
              className="main-search-category-popup"
              trigger={
                <button className="overlay-search-container--right">
                  {searchCategory}
                  <img
                    src={searchArrowDown}
                    alt=""
                    className="searchArrowDown"
                  />
                </button>
              }
              position="bottom center"
              arrow={false}
            >
              {(close) => (
                <>
                  <div
                    className={`search-category__option ${
                      searchCategory === "Beats"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Beats")
                        setSearchCategory("Beats");
                      close();
                    }}
                  >
                    Beats
                  </div>

                  <div
                    className={`search-category__option ${
                      searchCategory === "Producers"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Producers")
                        setSearchCategory("Producers");
                      close();
                    }}
                  >
                    Producers
                  </div>

                  <div
                    className={`search-category__option ${
                      searchCategory === "Songs"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Songs")
                        setSearchCategory("Songs");
                      close();
                    }}
                  >
                    Songs
                  </div>

                  <div
                    className={`search-category__option ${
                      searchCategory === "Albums"
                        ? "search-category__option-selected"
                        : ""
                    }`}
                    onClick={() => {
                      if (searchCategory !== "Albums")
                        setSearchCategory("Albums");
                      close();
                    }}
                  >
                    Albums
                  </div>
                </>
              )}
            </Popup>
          </div>
        </div>
      </section>
    </>
  );
}

export default Main;
