import "./../stylesheets/findProducer.css";
import microPhone from "./../../images/microphone.png";
import { useNavigate } from "react-router-dom";

function FindProducer() {
  const navigate = useNavigate();

  const handleButton = () => {
    navigate("/producers");
  };
  return (
    <section className="findproducer">
      <div className="findproducer-inner">
        <div className="inner-image producer-image">
          <img src={microPhone} alt="" />
        </div>

        <div className="findProducer-text">
          <h2>Get the best producer to make your next hit song</h2>
          <p>
            Unlock the magic of your next hit song with our top-tier music
            producers. As the leading hub for Afrobeats, we proudly offer the
            largest selection of authentic and captivating Afrobeats sounds.
            Experience the essence of Afrobeats excellence right here, right now
          </p>

          <button className="findproducer-inner-btn" onClick={handleButton}>
            Find a Producer
          </button>
        </div>
      </div>
    </section>
  );
}

export default FindProducer;
