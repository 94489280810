import Main from "./main";
import Trending from "./Trending";
import "./../stylesheets/Home.css";
import FindProducer from "./findProducer";
import PopularProducer from "./popularProducer";
import SellBeat from "./sellBeat";
import TrendingSongs from "./trendingSongs";
import TrendingAlbums from "./trendingAlbums";
import SellSong from "./sellSongs";
import FeaturedSongs from "./featuredSongs";

function Home({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  return (
    <div className="app-container">
      <Main />
      <Trending
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        updateCartCounter={updateCartCounter}
        setUpdateCartCounter={setUpdateCartCounter}
        handleLocalCart={handleLocalCart}
        handleCart={handleCart}
      />
      <TrendingSongs
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        updateCartCounter={updateCartCounter}
        setUpdateCartCounter={setUpdateCartCounter}
        handleLocalCart={handleLocalCart}
        handleCart={handleCart}
      />
      <TrendingAlbums
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        updateCartCounter={updateCartCounter}
        setUpdateCartCounter={setUpdateCartCounter}
        handleLocalCart={handleLocalCart}
        handleCart={handleCart}
      />
      <FeaturedSongs
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        updateCartCounter={updateCartCounter}
        setUpdateCartCounter={setUpdateCartCounter}
        handleLocalCart={handleLocalCart}
        handleCart={handleCart}
      />
      <PopularProducer />
      <FindProducer />
      <SellBeat />
      <SellSong />
    </div>
  );
}

export default Home;
