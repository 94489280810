import "./stylesheets/userPage.css";
import "./../index.css";
import "./../Fonts/fonts.css";
import Musics from "./common/musics.js";
import Albums from "./common/albums.js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useNavigation } from "../context/navigationContext";
import handleResponseStatus from "../utils/handleResponseStatus";
import api from "../utils/api";
function Artist({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const [songs, setSongs] = useState({});
  const [albums, setAlbums] = useState({});
  const [selector, setSelector] = useState("music");

  const { navigate } = useNavigation();

  const Id = useParams();
  useEffect(() => {
    const handleLoad = async () => {
      try {
        const response = await api.get(`artists/${Id.id}/songs`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setSongs(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
      try {
        const response = await api.get(`artists/${Id.id}/albums`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setAlbums(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [Id.id, navigate, setShowUnauthenticatedPopup]);

  return (
    <>
      {songs && (
        <section className="user-profile">
          <div className="navigation">
            <button
              className={`packs-button ${selector === "music" ? "active" : ""}`}
              onClick={() => {
                if (selector !== "music") setSelector("music");
              }}
            >
              Music Tracks
            </button>
            <button
              className={`packs-button ${
                selector === "albums" ? "active" : ""
              }`}
              onClick={() => {
                if (selector !== "albums") setSelector("albums");
              }}
            >
              Albums
            </button>
          </div>
        </section>
      )}
      {selector === "albums" && albums.length >= 1 && (
        <>
          <div className="heading-albums">
            <h3 className="heading-beat-count">
              {albums.length === 1
                ? `${albums.length} Album`
                : `${albums.length} Albums`}
            </h3>
          </div>
          <Albums
            beats={albums}
            setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
            updateCartCounter={updateCartCounter}
            setUpdateCartCounter={setUpdateCartCounter}
            handleLocalCart={handleLocalCart}
            handleCart={handleCart}
          />
        </>
      )}
      {selector === "music" && songs.length >= 1 && (
        <>
          <div className="heading-albums">
            <h3 className="heading-beat-count">Singles</h3>
          </div>
          <Musics
            beats={songs}
            setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
            updateCartCounter={updateCartCounter}
            setUpdateCartCounter={setUpdateCartCounter}
            handleLocalCart={handleLocalCart}
            handleCart={handleCart}
          />
        </>
      )}
    </>
  );
}

export default Artist;
