import "./../stylesheets/loading.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

function TooManyRequests() {
  const navigate = useNavigate();

  const [redirectCountdown, setRedirectCountdown] = useState(60);

  useEffect(() => {
    if (redirectCountdown === 0) {
      navigate(-1);
    }
  }, [redirectCountdown, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (redirectCountdown > 0) {
        setRedirectCountdown(redirectCountdown - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [redirectCountdown]);

  return (
    <section className="loading-section">
      <div className="loading-div">
        <TailSpin
          height="80"
          width="80"
          color="#FE2B0D"
          ariaLabel="tail-spin-loading"
          visible={true}
          wrapperClass="loading"
          className="loading"
        />

        <div className="loading-description too-many-requests-description">
          <h3>Too many requests</h3>
          <h4>You will be redirected in {redirectCountdown} seconds</h4>
        </div>
      </div>
    </section>
  );
}
export default TooManyRequests;
