// src/hooks/useLogin.js
import { useState } from "react";
import { useAuthContext } from "../../context/authContext";

const useLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showEmail, setShowEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const { login, isLoading } = useAuthContext();

  const handleLogin = async () => {
    try {
      const data = await login(email, password);
      return data;
    } catch (err) {
      console.log(err);
    }
    setError(null);
  };
  //   const handleAddToCart = async() => {

  //   };
  const handleGoogle = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/redirect/google`;

    window.location.href = url;
  };
  return {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    error,
    showEmail,
    setShowEmail,
    showPassword,
    setShowPassword,
    handleGoogle,
    saveChanges,
    setSaveChanges,
    isLoading,
  };
};

export default useLogin;
