import "./../stylesheets/upload.css";
import Add from "./../../images/AddPortfolio.png";
import Warning from "./../../images/warning.svg";
import downBtn from "./../../images/down-button-dark.png";
import cross from "./../../images/cross-black.svg";
import more from "./../../images/more-dark.svg";
import UploadCloud from "./../../images/upload-cloud.png";
import Tick from "./../../images/thumbnail-tick.png";
import Remove from "./../../images/remove-thumbnail.png";
import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Autocomplete from "./autocomplete";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function EditAlbum({ setShowUnauthenticatedPopup }) {
  const Id = useParams();
  const { navigate } = useNavigation();
  // const [user, setUser] = useState(null);
  const [albumName, setAlbumName] = useState("");
  const [artist, setArtist] = useState(null);
  const { user, isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates, convertRate, formatCurrency } =
    useCurrencyContext();
  const [description, setDescription] = useState("");
  const [recordLabel, setRecordLabel] = useState("");
  const [tracks, setTracks] = useState([]);
  const [album, setAlbum] = useState(null);
  const [price, setPrice] = useState(0);
  const [payWhatYouLike, setPayWhatYouLike] = useState("no");

  const [popupGenre, setPopupGenre] = useState([]);

  const [collaborator, setCollaborator] = useState("");
  const [popupCollaborator, setPopupCollaborator] = useState([]);
  const [split, setSplit] = useState("");
  const [collaborators, setCollaborators] = useState([]);

  const [popupStates, setPopupStates] = useState({
    genre: false,
    collaborator: false,
    homepage: false,
    reach: false,
    featured: false,
  });
  const [saveChanges, setSaveChanges] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const handleLoad = async () => {
      try {
        const response = await api.get(`albums/${Id.id}`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setAlbum(data.data);
        setAlbumName(data.data.name);
        setDescription(data.data.description);
        setPrice(data.data.price / 100);
        setArtist(data.data.artist);
        setRecordLabel(data.data.label.name);
        setTracks(data.data.tracks);
        setPayWhatYouLike(data.data.pay_what_you_like ? "yes" : "no");
        for (let i = 0; i < data.data.genres.length; i++) {
          setPopupGenre((prevGenre) =>
            prevGenre.map((genre, j) =>
              genre.name === data.data.genres[i]?.name
                ? { ...genre, checked: true }
                : genre
            )
          );
        }
        let tempCollaborators = [];
        for (let i = 0; i < data.data.collaborators.length; i++) {
          if (data.data.collaborators[i].user.id !== user.id) {
            tempCollaborators.push({
              user: data.data.collaborators[i].user.id,
              split: Number(data.data.collaborators[i].split / 100),
              role: data.data.collaborators[i].role.id,
              roleName: data.data.collaborators[i].role.name,
              userName: data.data.collaborators[i].user.name,
            });
          }
        }
        setCollaborators(tempCollaborators);
        setImage(data.data.cover_path);
        setSelectedUploadFile(data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [Id.id, navigate, setShowUnauthenticatedPopup, user]);
  const [selectedUploadFile, setSelectedUploadFile] = useState(null);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  }, [navigate, isAuthenticated]);

  function handleAlbumNameChange(event) {
    setAlbumName(event.target.value);

    if (event.target.value.length === 0)
      document.querySelector(".name-error").classList.remove("hidden");
    else document.querySelector(".name-error").classList.add("hidden");
  }
  const handleUploadFileChange = async (event) => {
    document.querySelector(".image-error").classList.add("hidden");
    setSelectedUploadFile(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".artwork-thumbnail");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };

  const handleRemove = (event) => {
    document.querySelector(".image-error").classList.remove("hidden");
    document.querySelector(".artwork-thumbnail").src = null;
    setSelectedUploadFile(null);
  };
  function handleDescriptionChange(event) {
    setDescription(event.target.value);
    if (event.target.value.length !== 0 && event.target.value.length > 500) {
      document.querySelector(".description-error").classList.remove("hidden");
    } else if (
      event.target.value.length !== 0 &&
      event.target.value.length < 50
    ) {
      document.querySelector(".description-error").classList.remove("hidden");
    } else {
      document.querySelector(".description-error").classList.add("hidden");
    }
  }

  function handleRecordLabelChange(event) {
    setRecordLabel(event.target.value);

    if (event.target.value.length === 0)
      document.querySelector(".record-label-error").classList.remove("hidden");
    else document.querySelector(".record-label-error").classList.add("hidden");
  }

  function handleCollaboratorSplitChange(event) {
    setSplit(event.target.value);

    if (event.target.value.length === 0)
      document.querySelector(".split-error").classList.remove("hidden");
    else document.querySelector(".split-error").classList.add("hidden");
  }
  function formatInputCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `${selectedCurrency === "GBP" ? "£" : "N"}${value}`;

    return value;
  }

  function handlePriceChange(event) {
    setPrice(formatInputCurrency(event.target.value));
    if (event.target.value === "£" || event.target.value === "N") {
      document.querySelector(".price-label-error").classList.remove("hidden");
      return;
    }
    const standardValue = convertRate(event.target.value?.slice(1));
    if (!standardValue) {
      document.querySelector(".price-label-error").classList.remove("hidden");
      return;
    }
    if (
      event.target.value.length < 1 ||
      standardValue === 0 ||
      standardValue < 1 ||
      standardValue > 500
    ) {
      document.querySelector(".price-label-error").classList.remove("hidden");
    } else document.querySelector(".price-label-error").classList.add("hidden");
  }
  function handleAddCollaborator(event) {
    event.preventDefault();
    let valid = true;
    if (collaborator == null) {
      document
        .querySelector(".collaborator-name-error")
        .classList.remove("hidden");
      valid = false;
    }

    if (split.length === 0) {
      document.querySelector(".split-error").classList.remove("hidden");
      valid = false;
    }

    if (!checkGenreChecked(popupCollaborator)) {
      document
        .querySelector(".collaborator-role-error")
        .classList.remove("hidden");
      valid = false;
    }

    if (!valid) {
      return;
    }

    const role = popupCollaborator.find((role) => role.checked);
    setCollaborators([
      ...collaborators,
      {
        user: collaborator.id,
        split: Number(split),
        role: role.id,
        roleName: role.name,
        userName: collaborator.name,
      },
    ]);
    setCollaborator("");
    setSplit("");
    const id = role.id;
    setPopupCollaborator((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: false } : { ...genre, checked: false }
      )
    );
  }

  function checkGenreChecked(obj) {
    for (let key in obj) {
      const skill = obj[key];
      if (
        skill &&
        typeof skill === "object" &&
        skill.hasOwnProperty("checked") &&
        skill.checked
      )
        return true;
    }

    return false;
  }

  function checkValues() {
    let valid = true;

    if (albumName.length === 0) {
      document.querySelector(".name-error").classList.remove("hidden");
      valid = false;
    }

    if (recordLabel.length === 0) {
      document.querySelector(".record-label-error").classList.remove("hidden");
      valid = false;
    }

    if (!checkGenreChecked(popupGenre)) {
      document.querySelector(".genre-error").classList.remove("hidden");
      valid = false;
    }
    if (price.length === 0) {
      document.querySelector(".price-label-error").classList.remove("hidden");
      valid = false;
    }
    if (price !== album.price / 100) {
      const standardValue = convertRate(price?.slice(1));

      if (
        price?.slice(1).length === 0 ||
        parseInt(standardValue) < 1 ||
        parseInt(standardValue) > 500
      ) {
        document.querySelector(".price-label-error").classList.remove("hidden");
        valid = false;
      } else {
        document.querySelector(".price-label-error").classList.add("hidden");
      }
    }

    if (!selectedUploadFile) {
      document.querySelector(".image-error").classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  useState(() => {
    const getGenres = async () => {
      try {
        const response = await api.get("genres");
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;

        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre, index) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setPopupGenre(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getRoles = async () => {
      try {
        const response = await api.get("roles");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre, index) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setPopupCollaborator(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getGenres();
    getRoles();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setShowError(false);
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    if (!checkValues()) {
      setShowError(true);
      setErrorMessage("Incorrect values entered!");
      return;
    }
    setSaveChanges(true);
    const genres = [];
    popupGenre.forEach((genre) => {
      if (genre.checked === true) {
        genres.push(genre.id);
      }
    });

    const collaboratorsFiltered = [];

    collaborators.forEach((collaborator) => {
      collaboratorsFiltered.push({
        user: collaborator.user,
        split: collaborator.split,
        role: collaborator.role,
      });
    });

    const body = {
      name: albumName,
      price:
        price === album.price
          ? album.price / 100
          : selectedCurrency === "GBP"
          ? price.slice(1) * 100
          : parseFloat(((price.slice(1) * 100) / rates).toFixed(2)),
      pay_what_you_like: payWhatYouLike === "yes",
      genres: genres,
      collaborators: collaboratorsFiltered,
    };

    try {
      const response = await api.put(`albums/${Id.id}/update`, body);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      var img = document.querySelector(".artwork-thumbnail");
      if (img.src !== album.cover_path) {
        const res = await fetch(img.src);
        const blob = await res.blob();
        const type = blob.type.split("/")[1];
        const cover = `image.${type}`;
        const response = await api.put(`albums/${Id.id}/update_cover`, {
          cover: cover,
        });
        const data = response.data;
        if (data?.cover_path) {
          await fetch(data?.cover_path, {
            method: "PUT",
            body: blob,
          });
        }
      }
      if (response.status === 200) {
        navigate("/user/uploads/albums");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  const handleGenreCheckboxChange = (id) => {
    setPopupGenre((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: !genre.checked } : genre
      )
    );

    setPopupGenre((updatedGenre) => {
      if (checkGenreChecked(updatedGenre)) {
        document.querySelector(".genre-error").classList.add("hidden");
      } else {
        document.querySelector(".genre-error").classList.remove("hidden");
      }
      return updatedGenre;
    });
  };

  const handleRoleCheckboxChange = (id) => {
    setPopupCollaborator((prevGenre) =>
      prevGenre.map((genre, i) =>
        i === id ? { ...genre, checked: true } : { ...genre, checked: false }
      )
    );

    setPopupCollaborator((updatedGenre) => {
      if (checkGenreChecked(updatedGenre)) {
        document
          .querySelector(".collaborator-role-error")
          .classList.add("hidden");
      } else {
        document
          .querySelector(".collaborator-role-error")
          .classList.remove("hidden");
      }
      return updatedGenre;
    });
  };

  return (
    <div>
      <section className="upload-beats">
        <h1 className="upload-beats__heading-primary">Update Album</h1>

        <form>
          <div className="upload-form__form-input">
            <div className="form-input_song">
              <div>
                <h4 className="upload-beats__heading-secondary">
                  Album Information
                </h4>
                <label className="form-beats_label">
                  Album name
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="name-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a name!
                  </Popup>
                </label>
                <input
                  type="text"
                  className="form-beats-input input-name"
                  value={albumName}
                  onChange={(event) => handleAlbumNameChange(event)}
                />

                <label className="form-beats_label">
                  Description (optional)
                </label>
                <textarea
                  value={description}
                  disabled
                  className=" custom-textarea form-beats-input input-description"
                  onChange={(event) => handleDescriptionChange(event)}
                />

                <label className="form-beats_label">
                  Artist name
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="artist-name-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a name!
                  </Popup>
                </label>
                <input
                  disabled
                  type="text"
                  className="form-beats-input input-artist-name"
                  value={artist?.name}
                />

                <label className="form-beats_label">
                  Record Label Name
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="record-label-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a record label!
                  </Popup>
                </label>
                <input
                  disabled
                  type="text"
                  className="form-beats-input input-artist-name"
                  value={recordLabel}
                  onChange={(event) => handleRecordLabelChange(event)}
                />
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">
                  Album Tracks
                </h4>

                <div className="collaborators-list">
                  {tracks.map((track, index) => {
                    return (
                      <div key={index} className="collaborator-row">
                        <span className="collaborator-split">
                          {track.name}
                          {"   "}
                        </span>
                        {track.isrc && ` - ${track.isrc}`}
                        <span className="collaborator-role">
                          ({track.price / 100})
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">
                  Album Pricing and Sales
                </h4>
                <label className="form-beats_label">
                  Album Price
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="price-label-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    {`Oops! The price must be between ${formatCurrency(
                      100
                    )} and ${formatCurrency(50000)} for this album`}
                  </Popup>
                </label>
                {price && (
                  <input
                    type="tel"
                    className="form-beats-input liscense-price"
                    defaultValue={price}
                    value={price}
                    placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}20`}
                    onChange={handlePriceChange}
                  />
                )}
                <p className="Checkmark-questions">
                  Pay what you like - allowing fans to contribute more than the
                  set price
                </p>
                <div className="radio-btn-flex">
                  <label className="container">
                    No
                    <input
                      type="radio"
                      name="payWhatYouLike"
                      value="no"
                      checked={payWhatYouLike === "no"}
                      onChange={() => {
                        setPayWhatYouLike("no");
                      }}
                    />
                    <span className="Checkmark"></span>
                  </label>
                  <label className="container">
                    Yes
                    <input
                      type="radio"
                      name="payWhatYouLike"
                      value="yes"
                      checked={payWhatYouLike === "yes"}
                      onChange={() => {
                        setPayWhatYouLike("yes");
                      }}
                    />
                    <span className="Checkmark"></span>
                  </label>
                </div>
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">
                  Tagging and Categories
                </h4>
                <label className="form-beats_label">
                  Genre Tags
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="genre-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please select at least 1 genre!
                  </Popup>
                </label>
                <Popup
                  className="genre-dropdown"
                  trigger={
                    <div className="form-beats-input form-option">
                      {!checkGenreChecked(popupGenre) && <>Select</>}
                      {checkGenreChecked(popupGenre) && (
                        <>
                          {popupGenre
                            .filter((genre) => genre.checked)
                            .slice(0, 7)
                            .map((genre) => (
                              <button
                                className="genre-remove-button"
                                key={genre.name}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  handleGenreCheckboxChange(
                                    popupGenre.findIndex(
                                      (g) => g.id === genre.id
                                    )
                                  );
                                }}
                              >
                                <img src={cross} alt="remove" />
                                {genre.name}
                              </button>
                            ))}
                          {popupGenre.filter((genre) => genre.checked).length >
                            7 && (
                            <div className="genre-remove-button">
                              <img src={more} alt="" />
                            </div>
                          )}
                        </>
                      )}
                      <img
                        src={downBtn}
                        style={
                          popupStates.genre
                            ? { transform: "rotate(180deg)" }
                            : { transform: "none" }
                        }
                        alt=""
                        className="upload-beat-genre-dropdown-button"
                      />
                    </div>
                  }
                  onOpen={() => handlePopupOpen("genre")}
                  onClose={() => handlePopupClose("genre")}
                  position="bottom center"
                  arrow={false}
                >
                  {popupGenre.map((genre, index) => {
                    return (
                      <div className="service-item" key={index}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={genre.checked || false}
                            onChange={() => handleGenreCheckboxChange(index)}
                          />
                          {genre.name}
                        </label>
                      </div>
                    );
                  })}
                </Popup>
              </div>

              <div>
                <h4 className="upload-beats__heading-secondary">
                  Optional Collaborator
                </h4>
                <div className="upload-song-dark">
                  <label className="form-beats_label">
                    Collaborator User-Name
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="collaborator-name-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please provide a name!
                    </Popup>
                  </label>
                  <Autocomplete
                    endPoint="collaborators/autocomplete"
                    customClassName={"input-name"}
                    selectedValue={collaborator}
                    setSelectedValue={setCollaborator}
                  />
                  <label className="form-beats_label">
                    Collaborator Role
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="collaborator-role-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please select at least 1 role!
                    </Popup>
                  </label>
                  <Popup
                    className="genre-dropdown genre-dropdown-dark"
                    trigger={
                      <div className="form-beats-input form-option">
                        {!checkGenreChecked(popupCollaborator) && <>Select</>}
                        {checkGenreChecked(popupCollaborator) && (
                          <>
                            {
                              popupCollaborator.find((genre) => genre.checked)
                                ?.name
                            }
                          </>
                        )}
                        <img
                          src={downBtn}
                          style={
                            popupStates.collaborator
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                          alt=""
                          className="upload-beat-genre-dropdown-button"
                        />
                      </div>
                    }
                    onOpen={() => handlePopupOpen("collaborator")}
                    onClose={() => handlePopupClose("collaborator")}
                    position="bottom center"
                    arrow={false}
                  >
                    {(close) => (
                      <>
                        {popupCollaborator.map((genre, index) => {
                          return (
                            <div className="service-item" key={index}>
                              <label
                                className="checkbox-container"
                                onClick={() => {
                                  handleRoleCheckboxChange(index);
                                  close();
                                }}
                              >
                                {genre.name}
                              </label>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </Popup>
                  <label className="form-beats_label">
                    Revenue Split
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="split-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please provide a revenue!
                    </Popup>
                  </label>
                  <input
                    type="number"
                    value={split}
                    className="form-beats-input input-revenue"
                    onChange={(event) => handleCollaboratorSplitChange(event)}
                  />
                </div>
                <button
                  className="add-collaborator"
                  onClick={handleAddCollaborator}
                >
                  <img src={Add} alt="" />
                  Add {collaborators.length === 0 ? "" : "Another"} Collaborator
                </button>
                <div className="collaborators-list">
                  {collaborators.map((collaborator, index) => {
                    return (
                      <div key={index} className="collaborator-row">
                        <span className="collaborator-split">
                          {collaborator.split}%
                        </span>
                        -{" "}
                        {collaborator.userName
                          ? collaborator.userName
                          : collaborator.user.name}
                        <span className="collaborator-role">
                          (
                          {collaborator.roleName
                            ? collaborator.roleName
                            : collaborator.role.name}
                          )
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="form-input_artwork-upload">
              <div className="artwork_img-box">
                <h3>
                  Upload Cover Artwork
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="image-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a valid image!
                  </Popup>
                </h3>
                <p>Artwork Dimension</p>
                <p>Prefferred: 1500x1500px, Minimum: 500x500px</p>
                <div>
                  {!selectedUploadFile && (
                    <label
                      htmlFor="file-upload-artwork"
                      className={`user-file-upload-label ${
                        selectedUploadFile ? "" : "upload-animation"
                      }`}
                    >
                      <img src={UploadCloud} alt="" />
                      Drag and drop picture here <br /> Or
                      <p className="upload-picture">Upload Picture</p>
                    </label>
                  )}
                  {!selectedUploadFile && (
                    <input
                      type="file"
                      id="file-upload-artwork"
                      accept="image/png, image/jpeg, image/gif, image/svg"
                      onChange={handleUploadFileChange}
                    />
                  )}
                  {selectedUploadFile && (
                    <p className="profile-selected-file upload-animation">
                      <img src={image} alt="" className="artwork-thumbnail" />
                    </p>
                  )}
                  {selectedUploadFile && (
                    <div className="imageName-delete user-delete upload-animation">
                      <img src={Tick} alt="" className="tick" />
                      <span className="img_file-name">
                        {selectedUploadFile.name}
                      </span>
                      <img src={Remove} alt="" onClick={handleRemove} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="upload-container">
            <button className="save-changes" onClick={handleSubmit}>
              {saveChanges && (
                <Bars
                  height="40"
                  width="40"
                  color="#fff"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass="loading-bars"
                  visible={saveChanges}
                />
              )}
              {!saveChanges && "Upload Track"}
            </button>

            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className={`api-error ${!showError ? "hidden" : ""}`}
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {errorMessage}
            </Popup>
          </div>
        </form>
      </section>
    </div>
  );
}

export default EditAlbum;
