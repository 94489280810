import "./../stylesheets/error.css";
import errorImage from "./../../images/errorLogo.svg";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

function ServerError() {
  const navigate = useNavigate();

  const [redirectCountdown, setRedirectCountdown] = useState(10);

  useEffect(() => {
    if (redirectCountdown === 0) {
      window.location.href = "/";
    }
  }, [redirectCountdown, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (redirectCountdown > 0) {
        setRedirectCountdown(redirectCountdown - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [redirectCountdown]);

  return (
    <section className="error-section">
      <div className="error-div">
        <div className="error-image-div">
          <img alt="" src={errorImage}></img>
        </div>
        <div className="loading-description too-many-requests-description">
          <h3>A server error has occurred</h3>
          <h4>
            Please try again later
            <br />
            You will be redirected back to the homepage in {
              redirectCountdown
            }{" "}
            seconds
          </h4>
        </div>
      </div>
    </section>
  );
}
export default ServerError;
