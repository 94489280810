import "./../stylesheets/Trending.css";
import "./../stylesheets/beatsLanding.css";
import * as Sentry from "@sentry/react";
import { TailSpin } from "react-loader-spinner";
import play from "./../../images/play_button.svg";
import add from "./../../images/add.svg";
import download from "./../../images/download.svg";
import more from "./../../images/more.svg";
import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import AddToPlaylistPopup from "./../addToPlaylistPopup";
import SharePopup from "./../sharePopup";
import BuyPopup from "./../buyPopup";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImgLoader from "./../imgLoader";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import { usePlayerContext } from "../../context/playerContext";
import { addToQueue, playNow } from "../../actions/playerActions";
function Beats({
  beats,
  loading,
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const { navigate, navigateToProducer, navigateToComments, navigateToBeat } =
    useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { formatCurrency } = useCurrencyContext();

  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [hoveredPlayButton, setHoveredPlayButton] = useState(null);

  const [isAddToPlaylistPopupOpen, setIsAddToPlaylistPopupOpen] =
    useState(false);
  const [addPopupBeat, setAddPopupBeat] = useState();

  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [sharePopupBeat, setSharePopupBeat] = useState();

  const [buyPopupBeat, setBuyPopupBeat] = useState(null);
  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);

  const [loaderCount, setLoaderCount] = useState(10);

  const handleProducerClick = (event) => {
    const id = event.target.dataset.id;
    navigateToProducer(id);
  };

  const { playerState, playerDispatch } = usePlayerContext();

  const handlePlayItem = (item) => {
    playerDispatch(playNow(item));
  };

  const handleAddToQueue = (item) => {
    playerDispatch(addToQueue(item));
  };

  const handleDownload = async (event) => {
    if (isAuthenticated) {
      const id = event.target.closest(".beats-music-card").dataset.id;

      try {
        const response = await api.get(`beats/${id}/download`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        window.location.href = data;
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      navigate("/login");
    }
  };
  const handleComment = (event) => {
    const id = event.target.dataset.id;
    navigateToComments("beats", id);
  };
  const handleAddToCart = async (id) => {
    if (isAuthenticated) {
      const response = await handleCart(id, "beat");
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = beats.find((obj) => obj.slug.toString() === id);
      const response = await handleLocalCart(desiredObject, "beat");
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setPopupMessage("Added to cart");
        setShowMessagePopup(true);
      } else {
        setPopupMessage("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };
  const handleBeatClick = (event) => {
    const beat_id = event.target.closest(".beats-music-card").dataset.id;
    navigateToBeat(beat_id);
  };

  useEffect(() => {
    const width = window.innerWidth;

    if (width > 900) {
      setLoaderCount(10);
    } else if (width > 600) {
      setLoaderCount(6);
    } else if (width > 300) {
      setLoaderCount(4);
    } else {
      setLoaderCount(2);
    }
  }, []);

  return (
    <>
      <section className="beatsTracks beats-open">
        <div className="beats-cards">
          {!beats &&
            Array.from({ length: loaderCount }).map((_, index) => (
              <div
                key={index}
                className="beats-music-card beats__music__player"
              >
                <Skeleton className="music-card--img" />
                <div>
                  <p className="price">
                    <Skeleton
                      count={0.35}
                      containerClassName="skeleton-loading"
                    />
                  </p>
                  <h3 className="music-card--music-name">
                    <Skeleton
                      count={0.7}
                      containerClassName="skeleton-loading"
                    />
                  </h3>
                  <div className="music-card--artist-price">
                    <Skeleton
                      count={0.45}
                      containerClassName="skeleton-loading"
                    />
                  </div>
                </div>
              </div>
            ))}
          {beats &&
            beats.map((beat, index) => {
              return (
                <div
                  className="beats-music-card beats__music__player"
                  data-id={beat.slug}
                  key={index}
                  data-url={beat.play_url}
                >
                  <div className="beats-play_button">
                    <div className="beats-play-div">
                      <button
                        className="beats-play play__button__beats"
                        onClick={() => {
                          handlePlayItem(beat);
                        }}
                      >
                        <img src={play} alt="play"></img>
                      </button>
                    </div>
                    <div className="beats-extras">
                      {beat.is_free && (
                        <button onClick={handleDownload}>
                          <img alt="" src={download}></img>
                        </button>
                      )}
                      {!beat.is_free && (
                        <div className="beats-extras__placeholder"></div>
                      )}
                      <button
                        onClick={() => {
                          handleAddToQueue(beat);
                        }}
                      >
                        <img alt="" src={add} data-id={beat.slug}></img>
                      </button>
                      <Popup
                        className="beat-rows-popup"
                        trigger={
                          <button className="more-play-beat">
                            <img alt="" src={more}></img>
                          </button>
                        }
                        position={["bottom left", "bottom center"]}
                        keepTooltipInside={"body"}
                        arrow={false}
                        onOpen={(event) => {
                          setHoveredPlayButton(
                            event.target.closest(".beats-play_button")
                          );
                          event.target
                            .closest(".beats-play_button")
                            .classList.add("beats-play_button-hovered");
                        }}
                        onClose={() => {
                          hoveredPlayButton.classList.remove(
                            "beats-play_button-hovered"
                          );
                        }}
                      >
                        {(close) => (
                          <>
                            <div
                              className="popup-option"
                              onClick={() => {
                                close();
                                handlePlayItem(beat);
                              }}
                              data-id={beat.slug}
                            >
                              Play
                            </div>
                            {!beat.is_free && (
                              <div
                                className="popup-option"
                                onClick={(event) => {
                                  close();

                                  handleAddToCart(beat.slug);
                                }}
                                data-id={beat.slug}
                              >
                                Add To Cart
                              </div>
                            )}
                            <div
                              className="popup-option"
                              onClick={(event) => {
                                close();
                                handleProducerClick(event);
                              }}
                              data-id={beat?.producer?.slug}
                            >
                              View Producer
                            </div>
                            <div
                              className="popup-option"
                              onClick={() => {
                                close();
                                setAddPopupBeat(beat);
                                setIsAddToPlaylistPopupOpen(true);
                              }}
                            >
                              Add to Playlist
                            </div>
                            <div
                              className="popup-option"
                              onClick={(event) => {
                                close();
                                handleComment(event);
                              }}
                              data-id={beat.slug}
                            >
                              Comment
                            </div>
                            <div
                              className="popup-option"
                              onClick={() => {
                                close();
                                setSharePopupBeat(beat);
                                setIsSharePopupOpen(true);
                              }}
                            >
                              Share
                            </div>
                          </>
                        )}
                      </Popup>
                    </div>
                  </div>

                  <ImgLoader
                    src={beat.artwork_url}
                    className="beats-music-card--img"
                    type="beat"
                  />
                  <div>
                    <p className="beats-price">
                      <span
                        onClick={() => {
                          setBuyPopupBeat(beat);
                          setIsBuyPopupOpen(true);
                        }}
                      >
                        {beat.price === 0 ? "Free" : formatCurrency(beat.price)}
                      </span>{" "}
                      &#x2022; {beat.bpm}BPM
                    </p>
                    <h3
                      className="beats-music-card--music-name"
                      onClick={handleBeatClick}
                    >
                      {beat.name}
                    </h3>

                    <div className="beats-music-card--artist-price">
                      <div>
                        <p
                          className="beats-music-card-artist-name"
                          data-id={beat?.producer?.slug}
                          onClick={handleProducerClick}
                        >
                          {beat.producer.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {loading && beats && (
          <TailSpin
            height="40"
            width="40"
            color="#FE2B0D"
            ariaLabel="tail-spin-loading"
            visible={loading}
            wrapperClass="loading"
            className="loading"
          />
        )}
      </section>
      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>

      {isAddToPlaylistPopupOpen && (
        <AddToPlaylistPopup
          isAddToPlaylistPopupOpen={isAddToPlaylistPopupOpen}
          setIsAddToPlaylistPopupOpen={setIsAddToPlaylistPopupOpen}
          beat={addPopupBeat}
          setPopupMessage={setPopupMessage}
          setShowMessagePopup={setShowMessagePopup}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}
      {isSharePopupOpen && (
        <SharePopup
          isSharePopupOpen={isSharePopupOpen}
          setIsSharePopupOpen={setIsSharePopupOpen}
          beat={sharePopupBeat}
          type={"beat"}
        />
      )}

      <BuyPopup
        beat={buyPopupBeat}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        handleAddToCart={handleAddToCart}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />
    </>
  );
}

export default Beats;
