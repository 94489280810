// reducers/authReducer.js

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  FETCH_WALLET_SUCCESS,
  FETCH_WALLET_FAILURE,
  FETCH_BEATS_PURCHASES_SUCCESS,
  FETCH_BEATS_PURCHASES_FAILURE,
  FETCH_ALBUMS_PURCHASES_SUCCESS,
  FETCH_ALBUMS_PURCHASES_FAILURE,
  FETCH_MUSIC_PURCHASES_SUCCESS,
  FETCH_MUSIC_PURCHASES_FAILURE,
  LIKE_TOGGLE,
} from "../constants/actionTypes";

const initialState = {
  user: null,
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token")).access_token
    : null,
  isAuthenticated: localStorage.getItem("token") ? true : false,
  isLoading: false,
  error: null,
  wallet: null,
  purchases: {
    beats: null,
    albums: null,
    songs: null,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, isLoading: true, error: null };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        isLoading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case LOGOUT:
      return { ...state, isAuthenticated: false, user: null, token: null };
    case FETCH_PROFILE_SUCCESS:
      return { ...state, user: action.payload, isAuthenticated: true };
    case FETCH_PROFILE_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_WALLET_SUCCESS:
      return { ...state, wallet: action.payload };
    case FETCH_WALLET_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_BEATS_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: { ...state.purchases, beats: action.payload },
      };
    case FETCH_BEATS_PURCHASES_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_ALBUMS_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: { ...state.purchases, albums: action.payload },
      };
    case FETCH_ALBUMS_PURCHASES_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_MUSIC_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: { ...state.purchases, songs: action.payload },
      };
    case FETCH_MUSIC_PURCHASES_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    case LIKE_TOGGLE: {
      const { type, newState, id } = action.payload;

      const user = {
        ...state.user,
        [`liked_${type}`]: [...state.user[`liked_${type}`]],
      };

      if (newState === "like") {
        user[`liked_${type}`].push(id);
      } else {
        user[`liked_${type}`] = user[`liked_${type}`].filter(
          (item) => item !== id
        );
      }

      return {
        ...state,
        user,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
