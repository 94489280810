import "./../stylesheets/userPage.css";
import "./../../Fonts/fonts.css";
import cross from "./../../images/cross.svg";
import warning from "./../../images/warning.svg";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import BeatsRows from "./../common/beatsRows";
import Popup from "reactjs-popup";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import { usePlayerContext } from "../../context/playerContext";
function UserBeats({ setShowUnauthenticatedPopup }) {
  const [loading, setLoading] = useState(false);
  const { navigate } = useNavigation();
  const { user } = useAuthContext();
  const { selectedCurrency, rates } = useCurrencyContext();
  const [userBeats, setUserBeats] = useState([]);
  const [showConfirmRemovePopup, setShowConfirmRemovePopup] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [beatRemoveId, setBeatRemoveId] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  const { playerState } = usePlayerContext();

  useEffect(() => {
    const getNextPage = async () => {
      if (nextPage) {
        const response = await api.get(nextPage);

        const data = response.data;
        setUserBeats([...userBeats, ...data.data]);
        setNextPage(data.links.next?.split("api/")[1]);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [userBeats, nextPage]);
  const handleLoad = async () => {
    if (user) {
      setLoading(true);

      try {
        const response = await api.get(`producers/${user.slug}/beats`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const dataBeats = response.data;
        setUserBeats(dataBeats.data);
        setLoading(false);
        setNextPage(dataBeats.links.next?.split("api/")[1]);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const handleRemoveBeatConfirm = (id) => {
    setShowConfirmRemovePopup(true);
    setBeatRemoveId(id);
  };

  const handleRemoveBeat = async () => {
    setShowConfirmRemovePopup(false);
    try {
      const response = await api.delete(`beats/${beatRemoveId}`, {});
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      handleLoad();
      setPopupMessage("Beat removed");
      setShowMessagePopup(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  const handleUpdateBeat = (id) => {
    navigate(`/update/beat/${id}`);
  };

  useEffect(() => {
    const handleLoad = async () => {
      if (user) {
        setLoading(true);
        try {
          const response = await api.get(`producers/${user.slug}/beats`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
          const dataBeats = response.data;
          setUserBeats(dataBeats.data);
          setLoading(false);
          setNextPage(dataBeats.links.next?.split("api/")[1]);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };
    if (user) {
      handleLoad();
    }
  }, [user, navigate, setUserBeats, setShowUnauthenticatedPopup]);

  return (
    <>
      <section className="userBeats">
        <div className="heading">
          <h3 className="heading-beat-count">
            {userBeats.length === 1
              ? `${userBeats.length} Beat Uploaded`
              : `${userBeats.length} Beats Uploaded`}
          </h3>
        </div>
      </section>
      {userBeats && (
        <BeatsRows
          beats={userBeats}
          showUpdateBeat={true}
          handleUpdateBeat={handleUpdateBeat}
          showRemoveBeat={true}
          handleRemoveBeat={handleRemoveBeatConfirm}
          hideMore={true}
          hideBuy={true}
          hideDownload={true}
          rates={rates}
          selectedCurrency={selectedCurrency}
          loading={loading}
        />
      )}

      <Popup
        className="counter-offer-popup"
        open={showConfirmRemovePopup}
        onOpen={() => {
          setShowConfirmRemovePopup(true);
        }}
        onClose={() => {
          setShowConfirmRemovePopup(false);
        }}
        modal
        nested
      >
        <>
          <img
            className="counter-offer-popup__close"
            src={cross}
            alt=""
            onClick={() => {
              setShowConfirmRemovePopup(false);
            }}
          />
          <div className="place-bid-popup-heading-container">
            <h3>Remove beat</h3>
          </div>
          <div className="counter-offer-popup-container">
            <h4 className="remove-beat-confirm-message">
              <img src={warning} alt="" />
              You are about to remove this beat
            </h4>
            <div className="button-container remove-beat-confirm-container">
              <button
                className="button-container__cancel"
                onClick={() => {
                  setShowConfirmRemovePopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-container__submit remove-beat-confirm"
                onClick={() => {
                  handleRemoveBeat();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      </Popup>

      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
    </>
  );
}

export default UserBeats;
