import "./../stylesheets/editProfile.css";
import userProfile from "../../images/user.png";
import editIcon from "../../images/edit-image.svg";
import Warning from "./../../images/warning.svg";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import * as Sentry from "@sentry/react";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
import { useNavigation } from "../../context/navigationContext";
function Profile({ user, handleNext, handleUpdateUser }) {
  const { navigate } = useNavigation();

  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [image, setImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState("");
  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".profile-image-div");
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  function handleFirstNameInput(event) {
    setFirstName(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      event.target.value.length >= 3 &&
      event.target.value.length <= 50 &&
      regex.test(event.target.value)
    )
      document.querySelector(".firstName-error").classList.add("hidden");
    else document.querySelector(".firstName-error").classList.remove("hidden");
  }

  function handleLastNameInput(event) {
    setLastName(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      event.target.value.length >= 3 &&
      event.target.value.length <= 50 &&
      regex.test(event.target.value)
    )
      document.querySelector(".lastName-error").classList.add("hidden");
    else document.querySelector(".lastName-error").classList.remove("hidden");
  }

  function handleUserNameInput(event) {
    setUsername(event.target.value);
    if (event.target.value.length > 0)
      document.querySelector(".userName-error").classList.add("hidden");
    else document.querySelector(".userName-error").classList.remove("hidden");
  }

  function checkValues() {
    let valid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      firstName.length < 3 ||
      firstName.length > 50 ||
      !regex.test(firstName)
    ) {
      valid = false;
      document.querySelector(".firstName-error").classList.remove("hidden");
    }

    if (lastName.length < 3 || lastName.length > 50 || !regex.test(lastName)) {
      valid = false;
      document.querySelector(".lastName-error").classList.remove("hidden");
    }

    if (username.length === 0) {
      valid = false;
      document.querySelector(".userName-error").classList.remove("hidden");
    }

    return valid;
  }

  const handleSubmit = async () => {
    if (!checkValues()) return false;
    if (image) {
      const body = { avatar: image.name };

      try {
        const response = await api.post("profile/avatar", body);
        handleResponseStatus(response, navigate);

        const data = response.data;
        var img = document.querySelector(".profile-image-div");
        const res = await fetch(img.src);
        const blob = await res.blob();
        const imageUpload = await fetch(data.avatar, {
          method: "PUT",
          body: blob,
        });
        if (imageUpload.status === 200) {
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    const body = {
      name: username === "" ? user.name : username,
      first_name: firstName === "" ? user.first_name : firstName,
      last_name: lastName === "" ? user.last_name : lastName,
    };

    try {
      const response = await api.put("profile", body);
      handleResponseStatus(response, navigate);
      handleUpdateUser();
      if (response.status === 200) {
        handleNext();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setUsername(user.name);
      setDefaultImage(user.profile_image_url);
    };
    handleLoad();
  }, [user.first_name, user.last_name, user.name, user.profile_image_url]);

  return (
    <section className="profile-section active-section">
      <section className="edit-profile">
        <div className="upload-profile-image">
          <img
            alt="edit-icon"
            className="edit-icon"
            src={editIcon}
            onClick={() => document.getElementById("profile-image").click()}
          ></img>
          <img
            alt="profile"
            className="profile-image-div"
            src={defaultImage === null ? userProfile : defaultImage}
            onClick={() => document.getElementById("profile-image").click()}
          ></img>

          <input
            type="file"
            id="profile-image"
            accept="image/*"
            className="profile-image"
            onChange={handleFileChange}
          />
          <label
            className="profile-image-label"
            onClick={() => document.getElementById("profile-image").click()}
          >
            Upload Profile Picture
          </label>
        </div>

        <div className="input-div">
          <label>First Name</label>
          <div className="edit-input-container">
            <input
              type="text"
              defaultValue={firstName}
              className="first-name-input"
              onChange={(event) => handleFirstNameInput(event)}
            ></input>
            <Popup
              className="edit-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="firstName-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              First Name must be at least 3 characters long!
            </Popup>
          </div>
        </div>

        <div className="input-div">
          <label>Last Name</label>
          <div className="edit-input-container">
            <input
              type="text"
              defaultValue={lastName}
              className="last-name-input"
              onChange={(event) => handleLastNameInput(event)}
            ></input>
            <Popup
              className="edit-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="lastName-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Last Name must be at least 3 characters long!
            </Popup>
          </div>
        </div>
        <div className="input-div">
          <label>User Name</label>
          <div className="edit-input-container">
            <input
              type="text"
              className="user-name-input"
              defaultValue={username}
              onChange={(event) => handleUserNameInput(event)}
            ></input>
            <Popup
              className="edit-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="userName-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Username cannot be empty!
            </Popup>
          </div>
        </div>
      </section>
      <div className="edit-profile-button-container">
        <button className="save-continue" onClick={handleSubmit}>
          Save & Continue
        </button>
      </div>
    </section>
  );
}

export default Profile;
