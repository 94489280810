// actions/authActions.js
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  FETCH_WALLET_SUCCESS,
  FETCH_WALLET_FAILURE,
  FETCH_BEATS_PURCHASES_SUCCESS,
  FETCH_BEATS_PURCHASES_FAILURE,
  FETCH_ALBUMS_PURCHASES_SUCCESS,
  FETCH_ALBUMS_PURCHASES_FAILURE,
  FETCH_MUSIC_PURCHASES_SUCCESS,
  FETCH_MUSIC_PURCHASES_FAILURE,
  LIKE_TOGGLE,
} from "../constants/actionTypes";
import api from "../utils/api";

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: LOGOUT,
});

export const fetchProfileSuccess = (user) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: user,
});

export const fetchProfileFailure = (error) => ({
  type: FETCH_PROFILE_FAILURE,
  payload: error,
});

export const fetchWalletSuccess = (wallet) => ({
  type: FETCH_WALLET_SUCCESS,
  payload: wallet,
});

export const fetchWalletFailure = (error) => ({
  type: FETCH_WALLET_FAILURE,
  payload: error,
});

export const fetchBeatsPurchasesSuccess = (purchases) => ({
  type: FETCH_BEATS_PURCHASES_SUCCESS,
  payload: purchases,
});

export const fetchBeatsPurchasesFailure = (error) => ({
  type: FETCH_BEATS_PURCHASES_FAILURE,
  payload: error,
});

export const fetchAlbumsPurchasesSuccess = (purchases) => ({
  type: FETCH_ALBUMS_PURCHASES_SUCCESS,
  payload: purchases,
});

export const fetchAlbumsPurchasesFailure = (error) => ({
  type: FETCH_ALBUMS_PURCHASES_FAILURE,
  payload: error,
});

export const fetchMusicPurchasesSuccess = (purchases) => ({
  type: FETCH_MUSIC_PURCHASES_SUCCESS,
  payload: purchases,
});

export const fetchMusicPurchasesFailure = (error) => ({
  type: FETCH_MUSIC_PURCHASES_FAILURE,
  payload: error,
});

export const likeToggle = (payload) => ({
  type: LIKE_TOGGLE,
  payload: payload,
});

export const login = (email, password) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const response = await api.post("login", { email, password });
    if (response.error) {
      return response;
    }
    response.data.data.expires_in =
      response.data.data.expires_in * 1000 + Date.now();
    localStorage.setItem("token", JSON.stringify(response.data?.data));
    dispatch(fetchProfile(response.data?.data?.access_token));
    dispatch(fetchWallet(response.data?.data?.access_token));
    dispatch(fetchBeatsPurchases(response.data?.data?.access_token));
    dispatch(fetchAlbumsPurchases(response.data?.data?.access_token));
    dispatch(fetchMusicPurchases(response.data?.data?.access_token));
    return response;
  } catch (error) {
    dispatch(loginFailure(error.message));
    throw error;
  }
};
export const callbackLogin = () => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON.parse(tokenData).access_token : null;
    dispatch(fetchProfile(token));
    dispatch(fetchWallet(token));
    dispatch(fetchBeatsPurchases(token));
    dispatch(fetchAlbumsPurchases(token));
    dispatch(fetchMusicPurchases(token));
  } catch (error) {
    dispatch(loginFailure(error.message));
    throw error;
  }
};
export const getPurchased = () => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON.parse(tokenData).access_token : null;
    dispatch(fetchBeatsPurchases(token));
    dispatch(fetchAlbumsPurchases(token));
    dispatch(fetchMusicPurchases(token));
  } catch (error) {
    dispatch(loginFailure(error.message));
    throw error;
  }
};
export const register =
  (first_name, last_name, email, password, password_confirmation, type) =>
  async (dispatch) => {
    dispatch(loginRequest());
    try {
      const response = await api.post("register", {
        first_name,
        last_name,
        email,
        password,
        password_confirmation,
        type,
      });
      if (response.error) {
        return response;
      }
      response.data.data.expires_in =
        response.data.data.expires_in * 1000 + Date.now();
      localStorage.setItem("token", JSON.stringify(response.data?.data));
      dispatch(fetchProfile(response.data?.data?.access_token));
      dispatch(fetchWallet(response.data?.data?.access_token));
      dispatch(fetchBeatsPurchases(response.data?.data?.access_token));
      dispatch(fetchAlbumsPurchases(response.data?.data?.access_token));
      dispatch(fetchMusicPurchases(response.data?.data?.access_token));
      return response;
    } catch (error) {
      dispatch(loginFailure(error.message));
      throw error;
    }
  };
export const fetchProfile = (token) => async (dispatch) => {
  try {
    const tokenData = localStorage.getItem("token");
    const token = tokenData ? JSON.parse(tokenData).access_token : null;
    if (token) {
      const response = await api.get("profile");
      dispatch(fetchProfileSuccess(response.data.data));
    }
  } catch (error) {
    dispatch(fetchProfileFailure(error.message));
  }
};

export const fetchWallet = (token) => async (dispatch) => {
  try {
    if (token) {
      const response = await api.get("wallet/balance");
      dispatch(fetchWalletSuccess(response.data.balance));
    }
  } catch (error) {
    dispatch(fetchWalletFailure(error.message));
  }
};

export const fetchBeatsPurchases = (token) => async (dispatch) => {
  try {
    if (token) {
      const response = await api.get("purchase/beats");

      dispatch(fetchBeatsPurchasesSuccess(response.data.data));
    }
  } catch (error) {
    dispatch(fetchBeatsPurchasesFailure(error.message));
  }
};

export const fetchAlbumsPurchases = (token) => async (dispatch) => {
  try {
    if (token) {
      const response = await api.get("purchase/albums");

      dispatch(fetchAlbumsPurchasesSuccess(response.data.data));
    }
  } catch (error) {
    dispatch(fetchAlbumsPurchasesFailure(error.message));
  }
};

export const fetchMusicPurchases = (token) => async (dispatch) => {
  try {
    if (token) {
      const response = await api.get("purchase/songs");
      dispatch(fetchMusicPurchasesSuccess(response.data.data));
    }
  } catch (error) {
    dispatch(fetchMusicPurchasesFailure(error.message));
  }
};

export const checkAuthStatus = () => (dispatch) => {
  const tokenData = localStorage.getItem("token");
  const token = tokenData ? JSON.parse(tokenData).access_token : null;
  if (token) {
    dispatch(fetchProfile(token));
    dispatch(fetchWallet(token));
    dispatch(fetchBeatsPurchases(token));
    dispatch(fetchAlbumsPurchases(token));
    dispatch(fetchMusicPurchases(token));
  } else {
    // dispatch(logout());
  }
};

export const toggleLike = (slug, type, newState, id) => async (dispatch) => {
  const tokenData = localStorage.getItem("token");
  const token = tokenData ? JSON.parse(tokenData).access_token : null;

  try {
    if (token) {
      dispatch(
        likeToggle({ slug: slug, type: type, newState: newState, id: id })
      );
      const response = await api.post(`${type}/${slug}/${newState}`);
      return response;
    }
  } catch (error) {
    dispatch(
      likeToggle({ slug: slug, type: type, newState: !newState, id: id })
    );
    console.error(error);
  }
};
