import "./../stylesheets/upload.css";
import Tick from "./../../images/thumbnail-tick.png";
import Remove from "./../../images/remove-thumbnail.png";
import UploadCloud from "./../../images/upload-cloud.png";
import Warning from "./../../images/warning.svg";
import downBtn from "./../../images/down-button-dark.png";
import cross from "./../../images/cross-black.svg";
import more from "./../../images/more-dark.svg";
import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function UpdateBeat({ setShowUnauthenticatedPopup }) {
  const Id = useParams();
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { selectedCurrency, rates, formatCurrency } = useCurrencyContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [popupGenre, setPopupGenre] = useState([]);
  const [checkedGenres, setCheckedGenres] = useState([]);
  const [BPM, setBPM] = useState(0);
  const [price, setPrice] = useState(0);
  const [enableBidding, setEnableBidding] = useState("no");
  const [downloadOptions, setDownloadOptions] = useState("no");
  const [licenseType, setLicenseType] = useState("no");
  const [popupStates, setPopupStates] = useState({
    genre: false,
    files: false,
  });
  const [image, setImage] = useState(null);

  const [popupFiles, setPopupFiles] = useState([]);
  const [saveChanges, setSaveChanges] = useState(false);

  const [selectedUploadFile, setSelectedUploadFile] = useState(null);
  const [beat, setBeat] = useState({});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const handleLoad = async () => {
      try {
        const response = await api.get(`beats/${Id.id}`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        setBeat(data.data);
        setName(data.data.name);
        setDescription(data.data.description);
        setPrice(data.data.price / 100);
        setBPM(data.data.bpm);
        setLicenseType(data.data.is_exclusive === true ? "yes" : "no");
        setDownloadOptions(data.data.is_free === true ? "yes" : "no");
        setEnableBidding(data.data.is_biddable === true ? "yes" : "no");
        setImage(data.data.artwork_url);
        setSelectedUploadFile(true);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [Id.id, navigate, setShowUnauthenticatedPopup]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  }, [navigate, isAuthenticated]);

  useEffect(() => {
    const getFileParts = async function () {
      try {
        const response = await api.get(`parts`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        const uniqueParts = [];
        const uniqueNames = new Set();

        data.data.forEach((part) => {
          if (!uniqueNames.has(part.name)) {
            uniqueNames.add(part.name);
            uniqueParts.push(part);
          }
        });

        const initialFiles = uniqueParts.map((part) => ({
          name: part.name,
          id: part.id,
          checked: false,
        }));

        setPopupFiles(initialFiles);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getFileParts();
  }, [navigate, setShowUnauthenticatedPopup]);

  const handleUploadFileChange = async (event) => {
    document.querySelector(".image-error").classList.add("hidden");
    setSelectedUploadFile(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".artwork-thumbnail");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };

  function handleBeatNameChange(event) {
    setName(event.target.value);

    if (event.target.value.length === 0)
      document.querySelector(".name-error").classList.remove("hidden");
    else document.querySelector(".name-error").classList.add("hidden");
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);

    if (event.target.value.length < 10)
      document.querySelector(".description-error").classList.remove("hidden");
    else document.querySelector(".description-error").classList.add("hidden");
  }

  function handleBPMChange(event) {
    setBPM(event.target.value);
    if (event.target.value < 60)
      document.querySelector(".BPM-error").classList.remove("hidden");
    else document.querySelector(".BPM-error").classList.add("hidden");
  }

  function checkGenreChecked(obj) {
    for (let key in obj) {
      const skill = obj[key];
      if (
        skill &&
        typeof skill === "object" &&
        skill.hasOwnProperty("checked") &&
        skill.checked
      )
        return true;
    }

    return false;
  }

  function checkValues() {
    let valid = true;

    if (name.length === 0) {
      document.querySelector(".name-error").classList.remove("hidden");
      valid = false;
    }

    if (description.length < 10) {
      document.querySelector(".description-error").classList.remove("hidden");
      valid = false;
    }

    if (checkedGenres.length === 0) {
      document.querySelector(".genre-error").classList.remove("hidden");
      valid = false;
    }

    if (BPM < 60) {
      document.querySelector(".BPM-error").classList.remove("hidden");
      valid = false;
    }

    if (!checkGenreChecked(popupFiles) && licenseType === "yes") {
      document.querySelector(".files-error").classList.remove("hidden");
      valid = false;
    }

    if (!selectedUploadFile) {
      document.querySelector(".image-error").classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  useState(() => {
    const getGenres = async () => {
      try {
        const response = await api.get("genres");
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre, index) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setPopupGenre(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getGenres();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowError(false);

    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    if (!checkValues()) {
      setShowError(true);
      setErrorMessage("Incorrect values entered!");

      return;
    }

    setSaveChanges(true);
    const files = [];
    popupFiles.forEach((genre) => {
      if (genre.checked === true) {
        files.push(genre.id);
      }
    });
    var img = document.querySelector(".artwork-thumbnail");

    const license = licenseType === "no" ? false : true;
    let body;
    if (downloadOptions === "yes") {
      body = {
        name: name,
        description: description,
        bpm: BPM,
        is_free: true,
        is_exclusive: license,
        genres: checkedGenres,
        parts: files,
      };
    } else {
      body = {
        name: name,
        description: description,
        bpm: BPM,
        is_free: false,
        is_exclusive: license,
        price:
          price === beat.price / 100
            ? beat.price / 100
            : selectedCurrency === "GBP"
            ? price.slice(1)
            : parseFloat((price.slice(1) / rates).toFixed(2)),
        genres: checkedGenres,
        parts: files,
      };
    }
    if (img.src !== beat.artwork_url) {
      const res = await fetch(img?.src);
      const blob = await res.blob();
      const type = blob.type.split("/")[1];
      body.artwork = `image.${type}`;
    }
    try {
      const response = await api.put(`beats/${Id.id}`, body);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

      const data = response.data;
      const checkBidding = enableBidding === "yes" ? true : false;

      if (beat.is_biddable !== checkBidding) {
        try {
          const response = await api.put(`bids/bid-flag/${Id.id}`, {});
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
      if (data.artwork) {
        const res = await fetch(img.src);
        const blob = await res.blob();
        const imageUpload = await fetch(data?.artwork, {
          method: "PUT",
          body: blob,
        });
        if (imageUpload.status === 200) {
        } else if (imageUpload.status === 403) {
          setShowError(true);
          setErrorMessage("Authentication error! Please login again");
          return;
        }
      }
      navigate("/user/uploads/beats");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleRemove = (event) => {
    document.querySelector(".image-error").classList.remove("hidden");
    document.querySelector(".artwork-thumbnail").src = null;
    setSelectedUploadFile(null);
  };

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  const handleEnableBiddingRadioButtonChange = (event) => {
    setEnableBidding(event.target.value);
  };
  // const handleDownloadOptionsRadioButtonChange = (event) => {
  //   setDownloadOptions(event.target.value);
  // };

  const handleFileCheckboxChange = (index) => {
    setPopupFiles((prevFiles) =>
      prevFiles.map((file, i) =>
        i === index ? { ...file, checked: !file.checked } : file
      )
    );

    setPopupFiles((updatedFiles) => {
      if (checkGenreChecked(updatedFiles)) {
        document.querySelector(".files-error").classList.add("hidden");
      } else {
        document.querySelector(".files-error").classList.remove("hidden");
      }
      return updatedFiles;
    });
  };

  useEffect(() => {
    if (beat.genres) {
      const initialCheckedGenres = popupGenre
        .filter((genre) =>
          beat.genres.some((beatGenre) => beatGenre.id === genre.id)
        )
        .map((genre) => genre.id);
      setCheckedGenres(initialCheckedGenres);
    }
  }, [beat.genres, popupGenre]);

  const handleGenreCheckboxChange = (id) => {
    setCheckedGenres((prevCheckedGenres) => {
      const updatedGenres = prevCheckedGenres.includes(id)
        ? prevCheckedGenres.filter((genreId) => genreId !== id)
        : [...prevCheckedGenres, id];

      if (updatedGenres.length === 0)
        document.querySelector(".genre-error").classList.remove("hidden");
      else document.querySelector(".genre-error").classList.add("hidden");

      return updatedGenres;
    });
  };

  return (
    <div>
      <section className="upload-beats">
        <h1 className="upload-beats__heading-primary">Update beats</h1>
        <h4 className="upload-beats__heading-secondary">General Information</h4>

        <form>
          <div className="upload-form__form-input">
            <div className="form-imput_beats">
              <label className="form-beats_label">
                Beat name
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="name-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Please provide a name!
                </Popup>
              </label>
              {beat && (
                <input
                  type="text"
                  defaultValue={beat.name}
                  className="form-beats-input input-name"
                  placeholder="my beat dance-all"
                  onChange={(event) => handleBeatNameChange(event)}
                />
              )}
              <label className="form-beats_label">
                Description
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="description-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Minimum characters are 10!
                </Popup>
              </label>
              {beat && (
                <textarea
                  className=" custom-textarea form-beats-input input-description"
                  onChange={(event) => handleDescriptionChange(event)}
                  defaultValue={beat.description}
                ></textarea>
              )}
              <label className="form-beats_label">
                Primary Genre
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="genre-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Please select at least 1 genre!
                </Popup>
              </label>
              <Popup
                className="genre-dropdown"
                trigger={
                  <div className="form-beats-input form-option">
                    {checkedGenres.length === 0 && <>Select</>}
                    {checkedGenres.length !== 0 && (
                      <>
                        {popupGenre
                          .filter((genre) => checkedGenres.includes(genre.id))
                          .slice(0, 7)
                          .map((genre) => (
                            <button
                              className="genre-remove-button"
                              key={genre.name}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleGenreCheckboxChange(genre.id);
                              }}
                            >
                              <img src={cross} alt="remove" />
                              {genre.name}
                            </button>
                          ))}
                        {checkedGenres.length > 7 && (
                          <div className="genre-remove-button">
                            <img src={more} alt="" />
                          </div>
                        )}
                      </>
                    )}
                    <img
                      src={downBtn}
                      style={
                        popupStates.genre
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" }
                      }
                      alt=""
                    />
                  </div>
                }
                onOpen={() => handlePopupOpen("genre")}
                onClose={() => handlePopupClose("genre")}
                position="bottom center"
                arrow={false}
              >
                {popupGenre.map((genre) => {
                  return (
                    <div className="service-item" key={genre.id}>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={checkedGenres.includes(genre.id)}
                          onChange={() => handleGenreCheckboxChange(genre.id)}
                        />
                        {genre.name}
                      </label>
                    </div>
                  );
                })}
              </Popup>
              <label className="form-beats_label">
                BPM (beats per minute){" "}
                <Popup
                  className="upload-error-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="BPM-error hidden"
                    ></img>
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  BPM must be a number greater/equal to 60!
                </Popup>
              </label>
              <input
                type="number"
                defaultValue={beat.bpm}
                className="form-beats-input BPM input-bpm"
                onChange={(event) => handleBPMChange(event)}
              />

              {/* <div>
                <p className="Checkmark-questions">Free Download Options</p>
                <div className="radio-btn-flex">
                  <label className="container">
                    No
                    <input
                      type="radio"
                      name="downloadOptions"
                      value="no"
                      checked={downloadOptions === "no"}
                      onChange={handleDownloadOptionsRadioButtonChange}
                    />
                    <span className="Checkmark"></span>
                  </label>
                  <label className="container">
                    Yes, with voice tag (MP3 Version)
                    <input
                      type="radio"
                      name="downloadOptions"
                      value="yes"
                      checked={downloadOptions === "yes"}
                      onChange={handleDownloadOptionsRadioButtonChange}
                    />
                    <span className="Checkmark"></span>
                  </label>
                </div>
              </div> */}
              {downloadOptions === "no" && (
                <div>
                  <p className="Checkmark-questions">
                    Do you want to enable bidding on beats by customers?
                  </p>

                  <div className="radio-btn-flex">
                    <label className="container">
                      No
                      <input
                        type="radio"
                        name="enableBidding"
                        value="no"
                        checked={enableBidding === "no"}
                        onChange={handleEnableBiddingRadioButtonChange}
                      />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="container">
                      Yes
                      <input
                        type="radio"
                        name="enableBidding"
                        value="yes"
                        checked={enableBidding === "yes"}
                        onChange={handleEnableBiddingRadioButtonChange}
                      />
                      <span className="Checkmark"></span>
                    </label>
                  </div>
                </div>
              )}

              {licenseType === "yes" && (
                <div>
                  <label className="form-beats_label hide-group_label">
                    Files included in Zip Folder
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="files-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Please select at least 1 file to upload!
                    </Popup>
                  </label>

                  <Popup
                    className="genre-dropdown file-dropdown"
                    trigger={
                      <div className="form-beats-input form-option hide-group_button">
                        {!checkGenreChecked(popupFiles) && <>Select Files</>}
                        {checkGenreChecked(popupFiles) && (
                          <>
                            {popupFiles
                              .filter((file) => file.checked)
                              .slice(0, 7)
                              .map((file) => (
                                <button
                                  className="genre-remove-button"
                                  key={file.name}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    handleFileCheckboxChange(
                                      popupFiles.findIndex(
                                        (f) => f.id === file.id
                                      )
                                    );
                                  }}
                                >
                                  <img src={cross} alt="remove" />
                                  {file.name}
                                </button>
                              ))}
                            {popupFiles.filter((file) => file.checked).length >
                              7 && (
                              <div className="genre-remove-button">
                                <img src={more} alt="" />
                              </div>
                            )}
                          </>
                        )}
                        <img
                          src={downBtn}
                          style={
                            popupStates.files
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                          alt=""
                        />
                      </div>
                    }
                    onOpen={() => handlePopupOpen("files")}
                    onClose={() => handlePopupClose("files")}
                    position="bottom center"
                    arrow={false}
                  >
                    {popupFiles.map((file, index) => (
                      <div className="service-item" key={index}>
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={file.checked}
                            onChange={() => handleFileCheckboxChange(index)}
                          />
                          {file.name}
                        </label>
                      </div>
                    ))}
                  </Popup>
                </div>
              )}
              {downloadOptions === "no" && beat.price && (
                <div>
                  <p className="tax">
                    TTD will charge a 20% commission on for successful sales
                  </p>
                  <label className="form-beats_label">License Price</label>
                  <input
                    type="tel"
                    defaultValue={formatCurrency(beat.price)}
                    className="form-beats-input liscense-price"
                    placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}20`}
                    onChange={(event) => setPrice(event.target.value)}
                  />
                </div>
              )}
            </div>

            <div className="form-input_artwork-upload">
              <div className="artwork_img-box">
                <h3>
                  Upload Cover Artwork
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="image-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please provide a valid image!
                  </Popup>
                </h3>
                <p>Artwork Dimension</p>
                <p>Preferred: 1500x1500px, Minimum: 500x500px</p>
                <div>
                  {!selectedUploadFile && (
                    <label
                      htmlFor="file-upload-artwork"
                      className={`user-file-upload-label ${
                        selectedUploadFile ? "" : "upload-animation"
                      }`}
                    >
                      <img src={UploadCloud} alt="" />
                      Drag and drop picture here <br /> Or
                      <p className="upload-picture">Upload Picture</p>
                    </label>
                  )}
                  {!selectedUploadFile && (
                    <input
                      type="file"
                      id="file-upload-artwork"
                      accept="image/png, image/jpeg, image/gif, image/svg"
                      onChange={handleUploadFileChange}
                    />
                  )}
                  {selectedUploadFile && (
                    <p className="profile-selected-file upload-animation">
                      <img src={image} alt="" className="artwork-thumbnail" />
                    </p>
                  )}
                  {selectedUploadFile && (
                    <div className="imageName-delete user-delete upload-animation">
                      <img src={Tick} alt="" className="tick" />
                      <span className="img_file-name">
                        {selectedUploadFile.name}
                      </span>
                      <img src={Remove} alt="" onClick={handleRemove} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="upload-container">
            <button className="save-changes" onClick={handleSubmit}>
              {saveChanges && (
                <Bars
                  height="40"
                  width="40"
                  color="#fff"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass="loading-bars"
                  visible={saveChanges}
                />
              )}
              {!saveChanges && "Save Changes"}
            </button>

            <Popup
              className="upload-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className={`api-error ${!showError ? "hidden" : ""}`}
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {errorMessage}
            </Popup>
          </div>
        </form>
      </section>
    </div>
  );
}

export default UpdateBeat;
