import "./stylesheets/terms.css";

function Terms() {
  return (
    <section className="terms">
      <h2>Terms of Services</h2>
      <div className="term-container">
        ⦁
        <p>
          By visiting our website and platform, you unequivocally agree to our
          terms and conditions herein expressly stated or on implied terms. This
          “Terms and Conditions” regulates our interaction and modus operandi
          for any user on our website.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          The website consists of two classes of users which are:
          <div className="term-container">
            ⦁<p className="term-bold">Buyer</p>
          </div>
          <div className="term-container">
            ⦁<p className="term-bold">Seller</p>
          </div>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          The <span className="term-bold">Buyer</span> consists of persons who
          intend, are able and willing to purchase products, particularly, media
          content through our platform. They shall be bound by all terms and
          conditions that regulate online trading and payment precautions.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          The <span className="term-bold">Sellers</span> on the other end are
          those who have genuine and original media content and are presumed to
          be the owners of the media content or other products brought to our
          website or platforms.
        </p>
      </div>

      <h3>INDEMNITY CLAUSE</h3>
      <div className="term-container">
        ⦁
        <p>
          We are by this, indemnified from cases of identity theft, usage of
          stolen credit/debit cards for purchase, uploading of content by
          sellers that don't belong to them - plagiarism, unlawful and
          uncivilized conducts, and other unlawful activities.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Any user on our website agrees to submit themselves to our management
          control and any act done or carried out that is contrary to our
          community standards shall be liable to instant suspension and or
          removal or restrictions from other services and use of our website.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          The sharing of all sales that shall be done on the websites shall be
          shared in ration 80:20; the Seller goes with 80% while the 20% is
          retained by us.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          The payment of 80% shall be made to the sellers' account that they
          provide.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Our company shall also manage the matter pertaining to transfer of
          ownership, once proof of ownership is not in doubt. It is assumed that
          the transferor is the true owner of the media content or product as
          the case may be.
        </p>
      </div>

      <h3>MUSICAL BEATS</h3>
      <div className="term-container">
        ⦁
        <p>
          The website sells musical beats. There are two types of beats:
          <div className="term-container">
            ⦁<p className="term-bold">Exclusive beats</p>
          </div>
          <div className="term-container">
            ⦁<p className="term-bold">Non exclusive beats</p>
          </div>
        </p>
      </div>

      <div className="term-container">
        ⦁
        <p>
          Upon sale of an <span className="term-bold">Exclusive beat</span>,
          intellectual property ownership is transferred to the buyer and same
          is free to use same in the way and manner he deems fit and is not
          restricted from selling the said beats
        </p>
      </div>

      <div className="term-container">
        ⦁
        <p>
          Upon sale of the <span className="term-bold">Non Exclusive beat</span>
          , the seller still retains ownership and has the right to still sell
          copies of that musical beat and deal with it the way and manner they
          deem fit.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          We also have subscription service that allows users access more
          specialized services than regular users. These subscriptions are done
          monthly and yearly. The subscription service has an auto-renewal
          feature. User would need to disable the auto-renewal feature if they
          do not intend to renew their subscription upon expiration of the
          initial subscription.
        </p>
      </div>

      <h3>CONTROL</h3>
      <div className="term-container">
        ⦁
        <p>
          If users abuse our website or app in any way, we retain the sole right
          to terminate their account at our sole discretion. Users must guide
          against abuse of our platform, service or website.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Abuse refers to an improper and unauthorized use of our website for
          purposes against our terms and condition. It also include content that
          offends public policy, immoral and unlawful contents
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Where any content is found to infringe on any copyright it is our sole
          duty to take down such content and we shall not be liable for such
          infringement. Users are only allowed to share or create content they
          own rights to (originality of content creation is key)
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          All usernames and users' account MUST NOT infringe any trademark, logo
          or other content that are solely property of another
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Certain orders can be cancelled and content expunged at our sole
          discretion once if it does not meet up with our community standard or
          where there are irregularities in its creation or it contains any
          errors that can corrupt the system, contain incisive messages or
          content, unwholesome content or mislead our audience
        </p>
      </div>

      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">INTELLECTUAL PROPERTY CLAUSE: </span>
          All the contents, logo and other visual media created on our website
          is our property and is protected by copyright laws.
        </p>
      </div>

      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">TERMINATION CLAUSE: </span>
          Any accounts on our website or users' access to our website and app,
          can be terminated in case of abuses or at our sole discretion.
        </p>
      </div>

      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">GOVERNING LAW CLAUSE: </span>
          This agreement is governed by relevant entertainment laws and
          regulations of the United Kingdom, International Treaties and
          Conventions.
        </p>
      </div>

      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">LINKS TO OTHER WEBSITES CLAUSE: </span>
          We are not responsible for any third party websites that you link to.
          Users are responsible for reading and agreeing (or disagreeing) with
          the Terms and Conditions or Privacy Policies of these third parties.
        </p>
      </div>

      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">CONTENT CLAUSE: </span>
          All users that they own the rights to the content they have created.
          Users shall give our company a license to share this content, deal on
          it on our website and to make it available to other users. If any
          content is found to be a copyright infringement, we shall not hesitate
          to take necessary steps required by law not limited to immediate
          removal of such content.
        </p>
      </div>
    </section>
  );
}

export default Terms;
