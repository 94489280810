import "./../../index.css";
import * as Sentry from "@sentry/react";
import "./../../Fonts/fonts.css";
import searchicon from "./../../images/search-icon.svg";
import dropdown from "./../../images/dropdown.svg";
import column from "./../../images/columns.svg";
import row from "./../../images/rows.svg";
import Warning from "./../../images/warning2.svg";
import musicButton from "./../../images/musicButton.svg";
import AlbumsButton from "./../../images/albumsButton.svg";
import Musics from "./../common/musics";
import MusicRows from "./../common/musicRows";

import "./../stylesheets/beatsLanding.css";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../../context/navigationContext";
import { useCurrencyContext } from "../../context/currencyContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
function MusicLandingDetailed({
  type,
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const getSongsPath = `${
    type === "trending"
      ? "top/songs"
      : type === "featured"
      ? "highlights?location=featured_music"
      : type === "home"
      ? "highlights?location=home_page"
      : "songs"
  }`;
  const { navigate } = useNavigation();
  const { selectedCurrency, rates } = useCurrencyContext();
  const [beats, setBeats] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [showColumn, setShowColumn] = useState(true);
  const [showRow, setShowRow] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const [searchInput, setSearchInput] = useState(
    location.state ? location.state.searchTerm : ""
  );

  useEffect(() => {
    if (location.state) {
      setSearchInput(location.state.searchTerm);
      document.querySelector(".beats-search-input").value =
        location.state.searchTerm;
    }
  }, [location.state]);

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [genreFilters, setGenreFilters] = useState([]);

  const [priceFiltersLower, setPriceFiltersLower] = useState(
    selectedCurrency === "GBP" ? "£" : "N"
  );
  const [priceFiltersUpper, setPriceFiltersUpper] = useState(
    selectedCurrency === "GBP" ? "£" : "N"
  );

  const [priceFiltersCheckbox, setPriceFiltersCheckbox] = useState([
    { name: "Free beats", checked: false },
  ]);

  function handleSelector(event) {
    const type = event.currentTarget.dataset.type;

    if (type === "row" && !showRow) {
      setShowRow(true);
      setShowColumn(false);
    } else if (type === "column" && !showColumn) {
      setShowColumn(true);
      setShowRow(false);
    }
  }
  useEffect(() => {
    function getDeviceType() {
      const userAgent = navigator.userAgent;

      if (/iPad/.test(userAgent)) {
        return "iPad";
      } else if (/Mobile/.test(userAgent)) {
        return "Mobile";
      } else {
        return "Desktop";
      }
    }
    if (getDeviceType() === "Mobile") {
      setShowRow(true);
      setShowColumn(false);
    }
  }, []);
  const getAllBeats = async function () {
    try {
      const response = await api.get(getSongsPath);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const dataBeats = response.data;
      if (getSongsPath === "songs" || getSongsPath === "top/songs") {
        setBeats(dataBeats?.data);
        setNextPage(dataBeats.links.next?.split("api/")[1]);
      } else {
        setBeats(dataBeats?.songs);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    const getGenres = async function () {
      try {
        const response = await api.get(`genres`);

        handleResponseStatus(response, navigate);
        const data = response.data;
        const uniqueGenres = [];
        const uniqueNames = new Set();

        data.data.forEach((genre) => {
          if (!uniqueNames.has(genre.name)) {
            uniqueNames.add(genre.name);
            uniqueGenres.push(genre);
          }
        });

        const initialFilters = uniqueGenres.map((genre) => ({
          name: genre.name,
          id: genre.id,
          checked: false,
        }));

        setGenreFilters(initialFilters);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    getGenres();
  }, [navigate]);

  useEffect(() => {
    const getNextPage = async () => {
      try {
        setLoading(true);
        if (!nextPage) {
          setLoading(false);
          return;
        } else {
          const response = await api.get(nextPage);
          handleResponseStatus(response, navigate);
          const data = response.data;
          setNextPage(data.links.next?.split("api/")[1]);
          setBeats([...beats, ...data.data]);
        }
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [nextPage, beats, setBeats, navigate, getSongsPath]);

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleSearchInput = async (event) => {
    await delay(1500);

    setSearchInput(event.target.value.replace(/[\\"]/g, ""));
    if (event.target.value.length === 0) {
      getAllBeats();
      document.querySelector(".search-error").classList.add("hidden");
    } else if (event.target.value.length < 3)
      document.querySelector(".search-error").classList.remove("hidden");
    else document.querySelector(".search-error").classList.add("hidden");
  };

  const getTopFilterBeats = async function (genre) {
    const body = {
      genres: [genre],
      top: type === "trending" || type === "featured",
    };

    try {
      const response = await api.post(`search/songs`, body);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      return data.data;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleFilterButtonClick = async (id) => {
    document.querySelector(".beats-search-input").value = "";

    if (selectedFilter === id) {
      setSelectedFilter(null);
      await getAllBeats();
    } else {
      setAppliedFilters({
        genres: null,
        minPrice: null,
        maxPrice: null,
        isFree: null,
        isExclusive: null,
      });
      setGenreFilters((prevFilters) =>
        prevFilters.map((filter) => ({
          ...filter,
          checked: false,
        }))
      );
      setPriceFiltersLower(selectedCurrency === "GBP" ? "£" : "N");
      setPriceFiltersUpper(selectedCurrency === "GBP" ? "£" : "N");
      setPriceFiltersCheckbox((prevFilters) =>
        prevFilters.map((filter) => ({ ...filter, checked: false }))
      );
      setSearchInput("");

      setSelectedFilter(id);
      setBeats(await getTopFilterBeats(id));
      setNextPage(null);
    }
  };

  const [popupStates, setPopupStates] = useState({
    popup1: false,
    popup2: false,
    popup3: false,
  });

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  const [appliedFilters, setAppliedFilters] = useState({
    genres: null,
    minPrice: null,
    maxPrice: null,
    isFree: null,
  });

  useEffect(() => {
    const getAllBeats = async function () {
      try {
        const response = await api.get(getSongsPath);

        handleResponseStatus(response, navigate);
        const dataBeats = response.data;
        if (type === "featured" || type === "home") {
          setBeats(dataBeats.songs);
        } else {
          setBeats(dataBeats.data);
        }
        setNextPage(dataBeats.links.next?.split("api/")[1]);
        setLoading(false);
      } catch (err) {}
    };

    const getPopupFiltersBeats = async function () {
      if (
        Object.values(appliedFilters).every((value) => value === null) &&
        searchInput.length < 3
      ) {
        getAllBeats();
        return;
      }

      setLoading(true);
      setBeats([]);
      setNextPage(null);

      let result = null;

      if (
        (appliedFilters.genres && appliedFilters.genres.length > 0) ||
        appliedFilters.isFree ||
        appliedFilters.maxPrice ||
        searchInput.length >= 3
      ) {
        const bodyString = {
          genres:
            appliedFilters.genres && appliedFilters.genres.length > 0
              ? appliedFilters.genres
              : null,
          term: searchInput.length >= 3 ? searchInput : null,
          price: appliedFilters.isFree
            ? 0
            : appliedFilters.maxPrice
            ? selectedCurrency === "GBP"
              ? parseFloat((appliedFilters.maxPrice * 100).toFixed(0))
              : parseFloat((appliedFilters.maxPrice / rates).toFixed(2) * 100)
            : null,
          min_price:
            appliedFilters.minPrice && appliedFilters.minPrice !== 0
              ? selectedCurrency === "GBP"
                ? appliedFilters.minPrice * 100
                : (appliedFilters.minPrice / rates) * 100
              : null,
        };

        if (type === "trending" || type === "featured") {
          bodyString.top = true;
        }
        const data1 = bodyString;
        for (const key in data1) {
          if (data1[key] === null) {
            delete data1[key];
          }
        }

        try {
          const response = await api.post(`search/songs`, data1);
          handleResponseStatus(response, navigate);
          const data = response.data;
          result = data.data;
        } catch (error) {
          Sentry.captureException(error);
        }
      }

      setBeats(result);
      setNextPage(null);
      setLoading(false);
    };

    getPopupFiltersBeats();
  }, [
    appliedFilters,
    searchInput,
    navigate,
    selectedCurrency,
    rates,
    getSongsPath,
    type,
  ]);

  const handleGenreFilterCheckboxChange = (updatedId) => {
    setGenreFilters((prevGenres) =>
      prevGenres.map((genre) =>
        genre.id === updatedId ? { ...genre, checked: !genre.checked } : genre
      )
    );

    if (genreFilters.find((filter) => filter.id === updatedId).checked) {
      if (
        genreFilters
          .filter((filter) => filter.checked)
          .map((filter) => filter.id).length === 1
      ) {
        setAppliedFilters((prevFilters) => ({ ...prevFilters, genres: null }));
      } else {
        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          genres: genreFilters
            .filter((filter) => filter.checked)
            .map((filter) => filter.id)
            .filter((id) => id !== updatedId),
        }));
      }
    } else {
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        genres: [
          ...genreFilters
            .filter((filter) => filter.checked)
            .map((filter) => filter.id),
          updatedId,
        ],
      }));
    }
  };

  function formatCurrency(value) {
    value = value.replace(/[^0-9£]/g, "");
    value = value.replace(/[^0-9N]/g, "");

    value = value.replace(/£/g, "");
    value = value.replace(/N/g, "");

    value = `${selectedCurrency === "GBP" ? "£" : "N"}${value}`;

    return value;
  }

  function handlePriceFilterLowerChange(event) {
    setPriceFiltersLower(formatCurrency(event.target.value.trim()));

    if (!priceFiltersUpper && !event.target.value) {
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        minPrice: null,
        maxPrice: null,
      }));
      document.querySelector(".price-error").classList.add("hidden");
    } else if (!priceFiltersUpper) {
      document.querySelector(".price-error").classList.remove("hidden");
    }
  }

  function handlePriceFilterUpperChange(event) {
    setPriceFiltersUpper(formatCurrency(event.target.value.trim()));

    if (!priceFiltersLower && !event.target.value) {
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        minPrice: null,
        maxPrice: null,
      }));
      document.querySelector(".price-error").classList.add("hidden");
    } else if (!event.target.value) {
      document.querySelector(".price-error").classList.remove("hidden");
      return;
    }
    document.querySelector(".price-error").classList.add("hidden");
  }

  const setPriceFilters = () => {
    if (!priceFiltersLower && !priceFiltersUpper) {
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        minPrice: null,
        maxPrice: null,
      }));
      document.querySelector(".price-error").classList.add("hidden");
    } else if (!priceFiltersUpper || priceFiltersUpper.length === 1) {
      document.querySelector(".price-error").classList.remove("hidden");
      return;
    } else if (!priceFiltersLower) {
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        minPrice: 0,
        maxPrice: priceFiltersUpper.slice(1),
      }));
    } else {
      setAppliedFilters((prevFilters) => ({
        ...prevFilters,
        minPrice: priceFiltersLower.slice(1),
        maxPrice: priceFiltersUpper.slice(1),
      }));
    }
  };

  const handleProducerButtonClick = () => {
    navigate("/albums/all");
  };

  const handlePriceFilterCheckboxChange = (index) => {
    setPriceFiltersCheckbox((prevPrice) =>
      prevPrice.map((price, i) =>
        i === index ? { ...price, checked: !price.checked } : price
      )
    );

    if (priceFiltersCheckbox[index].checked) {
      if (priceFiltersCheckbox[index].name === "Free beats") {
        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          isFree: null,
        }));
      } else if (priceFiltersCheckbox[index].name === "Exclusive only") {
        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          isExclusive: null,
        }));
      } else if (priceFiltersCheckbox[index].name === "Bid beats") {
        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          isBiddable: null,
        }));
      }
    } else {
      if (priceFiltersCheckbox[index].name === "Free beats") {
        setPriceFiltersLower(`${selectedCurrency === "GBP" ? "£0" : "N0"}`);
        setPriceFiltersUpper(`${selectedCurrency === "GBP" ? "£0" : "N0"}`);

        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          minPrice: null,
          maxPrice: null,
          isFree: true,
        }));
      } else if (priceFiltersCheckbox[index].name === "Exclusive only") {
        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          isExclusive: true,
        }));
      } else if (priceFiltersCheckbox[index].name === "Bid beats") {
        setAppliedFilters((prevFilters) => ({
          ...prevFilters,
          isBiddable: true,
        }));
      }
    }
  };

  return (
    <>
      <section className="beats-topbar">
        <h3 className="beats-heading">
          Explore{" "}
          {type === "trending"
            ? "Trending "
            : type === "featured"
            ? "Featured "
            : null}
          Music:
        </h3>
        <div className="display-options">
          <button
            className="beats-option beats-button button-selected"
            data-type="beats"
          >
            <img alt="" src={musicButton}></img>Music
          </button>
          <button
            className="producers-option beats-button"
            data-type="producers"
            onClick={handleProducerButtonClick}
          >
            <img alt="" src={AlbumsButton}></img>Albums
          </button>

          <div className="beats-search-box">
            <img alt="" className="beats-search-icon" src={searchicon}></img>
            <div className="search-input-container">
              <input
                className="beats-search-input"
                type="text"
                placeholder="Search Music"
                defaultValue={searchInput}
                onInput={(event) => handleSearchInput(event)}
              ></input>
              <Popup
                className="upload-error-popup"
                trigger={
                  <img
                    src={Warning}
                    alt=""
                    className="search-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                Enter at least 3 characters!
              </Popup>
            </div>
          </div>
          {type !== "all" && (
            <button
              className="button-selected button-selected-music"
              onClick={() => {
                navigate("/music/all");
              }}
            >
              View All Music
            </button>
          )}
        </div>
        <div className="filters">
          <div className="filters-div">
            <h4>Filters:</h4>
            <div className="filters-dropdowns">
              <Popup
                className="filter-popup"
                trigger={
                  <button className="beats-genre">
                    Genre
                    <img
                      alt=""
                      style={
                        popupStates.popup1
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" }
                      }
                      src={dropdown}
                    ></img>
                  </button>
                }
                arrow={false}
                onOpen={() => handlePopupOpen("popup1")}
                onClose={() => handlePopupClose("popup1")}
                position="bottom left"
              >
                <div className="genre-dropdown">
                  {genreFilters.map((genre) => (
                    <div className="service-item" key={genre.id}>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={genre.checked}
                          onChange={() =>
                            handleGenreFilterCheckboxChange(genre.id)
                          }
                        />
                        {genre.name}
                      </label>
                    </div>
                  ))}
                </div>
              </Popup>

              <Popup
                className="filter-popup"
                trigger={
                  <button className="beats-price">
                    Price
                    <img
                      alt=""
                      style={
                        popupStates.popup3
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" }
                      }
                      src={dropdown}
                    ></img>
                  </button>
                }
                arrow={false}
                onOpen={() => handlePopupOpen("popup3")}
                onClose={() => handlePopupClose("popup3")}
                position="bottom left"
              >
                <div className="price-dropdown">
                  <div className="price-range">
                    <input
                      type="text"
                      onChange={(event) => handlePriceFilterLowerChange(event)}
                      value={priceFiltersLower}
                      placeholder={`${selectedCurrency === "GBP" ? "£" : "N"}0`}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setPriceFilters();
                        }
                      }}
                    ></input>
                    <p>to</p>
                    <input
                      type="text"
                      onChange={(event) => handlePriceFilterUpperChange(event)}
                      value={priceFiltersUpper}
                      placeholder={`${
                        selectedCurrency === "GBP" ? "£" : "N"
                      }70`}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setPriceFilters();
                        }
                      }}
                    ></input>
                    <Popup
                      className="upload-error-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="price-error hidden"
                        ></img>
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Enter a value!
                    </Popup>
                  </div>
                  {priceFiltersCheckbox.map((price, index) => (
                    <div className="service-item" key={index}>
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={price.checked}
                          onChange={() =>
                            handlePriceFilterCheckboxChange(index)
                          }
                        />
                        {price.name}
                      </label>
                    </div>
                  ))}
                </div>
              </Popup>
            </div>
          </div>
          <div className="beats-search-options">
            {genreFilters.slice(0, 8).map((genre) => (
              <div
                className={`search-option-button ${
                  selectedFilter === genre.id
                    ? "search-option-button-selected"
                    : ""
                }`}
                key={genre.id}
                onClick={() => handleFilterButtonClick(genre.id)}
              >
                <img alt="" src={searchicon}></img>
                <p>{genre.name}</p>
              </div>
            ))}
          </div>
          <div className="filter-tiles">
            <button
              className="tile"
              onClick={(event) => handleSelector(event)}
              data-type="column"
            >
              <img
                alt=""
                className="column-image"
                src={column}
                style={{ opacity: showColumn ? 1 : 0.3 }}
              ></img>
            </button>
            <button
              className="tile"
              onClick={(event) => handleSelector(event)}
              data-type="row"
            >
              <img
                alt=""
                className="row-image"
                src={row}
                style={{ opacity: showRow ? 1 : 0.3 }}
              ></img>
            </button>
          </div>
        </div>
      </section>

      {showColumn && (
        <Musics
          beats={beats}
          loading={loading}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          updateCartCounter={updateCartCounter}
          setUpdateCartCounter={setUpdateCartCounter}
          handleLocalCart={handleLocalCart}
          handleCart={handleCart}
        />
      )}
      {showRow && (
        <MusicRows
          beats={beats}
          loading={loading}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          updateCartCounter={updateCartCounter}
          setUpdateCartCounter={setUpdateCartCounter}
          handleLocalCart={handleLocalCart}
          handleCart={handleCart}
        />
      )}
    </>
  );
}

export default MusicLandingDetailed;
