import React, { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  logout as logoutAction,
  checkAuthStatus,
  register,
  fetchProfile,
  callbackLogin,
  toggleLike,
  getPurchased,
} from "../actions/authActions";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, isLoading, error, wallet, purchases } =
    useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  const loginUser = async (email, password) => {
    try {
      const response = await dispatch(login(email, password)); // Dispatch the login action
      return response; // Return the response from the login action
    } catch (error) {
      console.error("Login failed:", error);
      throw error; // Re-throw the error to handle it in components
    }
  };

  const registerUser = async ({
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
    type,
  }) => {
    try {
      const response = await dispatch(
        register(
          first_name,
          last_name,
          email,
          password,
          password_confirmation,
          type
        )
      ); // Dispatch the login action
      return response; // Return the response from the login action
    } catch (error) {
      console.error("Registration failed:", error);
      throw error; // Re-throw the error to handle it in components
    }
  };
  const loginCallback = async () => {
    try {
      const response = await dispatch(callbackLogin()); // Dispatch the login action
      return response; // Return the response from the login action
    } catch (error) {
      console.error("Callback login failed:", error);
      throw error; // Re-throw the error to handle it in components
    }
  };
  
  const getPurchases = async () => {
    try {
      const response = await dispatch(getPurchased()); // Dispatch the login action
      return response; // Return the response from the login action
    } catch (error) {
      console.error("Callback login failed:", error);
      throw error; // Re-throw the error to handle it in components
    }
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("playing_queue");
    dispatch(logoutAction());
  };

  const refreshProfile = async () => {
    if (!isAuthenticated) {
      return null;
    }

    try {
      const tokenData = localStorage.getItem("token");
      const token = tokenData ? JSON.parse(tokenData).access_token : null;
      const response = await dispatch(fetchProfile(token));
      return response;
    } catch (error) {
      console.error("Refresh failed:", error);
      throw error;
    }
  };

  const likeToggle = async (slug, type, newState, id) => {
    try {
      const response = await dispatch(toggleLike(slug, type, newState, id));
      return response;
    } catch (error) {
      console.error("Like/Unlike Failed");
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        error,
        wallet,
        purchases,
        login: loginUser,
        logout,
        register: registerUser,
        refreshProfile,
        likeToggle,
        loginCallback,
        getPurchases,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
