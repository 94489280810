import "./../stylesheets/loading.css";
import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function ConfirmPayment({ message, setShowUnauthenticatedPopup }) {
  const [Message, setMessage] = useState(null);
  const { navigate } = useNavigation();

  useEffect(() => {
    setMessage(message);
  }, [message]);

  useEffect(() => {
    const handleLoad = async () => {
      const type = window.location.href
        .split("confirm_payment/")[1]
        .split("?")[0];
      if (type === "product") {
        navigate("/user");
        return;
      }
      if (localStorage.getItem(`${type}_vendor_id`)) {
        const vendor_id = localStorage.getItem(`${type}_vendor_id`);

        if (type === "commission") {
          const id = localStorage.getItem("commission_id");
          try {
            const response = await api.post(
              `commissions/${id}/confirm-payment`,
              { id: vendor_id }
            );

            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            if (data.success === true) {
              localStorage.removeItem(`${type}_vendor_id`);
              localStorage.removeItem("commission_id");
              window.location.href = `/messages/${id}`;
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (type === "deposit") {
          try {
            const response = await api.post(`wallet/confirm`, {
              id: vendor_id,
            });

            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            if (data.success === true) {
              localStorage.removeItem(`${type}_vendor_id`);
              window.location.href = "/wallet";
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (type === "purchase") {
          try {
            const response = await api.post(`purchase/confirm`, {
              id: vendor_id,
            });
            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            if (data.success === true) {
              localStorage.removeItem(`${type}_vendor_id`);
              navigate("/purchases/beats");
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (type === "withdraw") {
          const vendor_id = localStorage.getItem(`withdraw_vendor_id`);
          try {
            const response = await api.get(
              `transfer-methods/confirm?payment_account=${vendor_id}`
            );

            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            if (data.success === true) {
              localStorage.removeItem(`${type}_vendor_id`);
              window.location.href = "/accounts";
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (type === "product") {
          navigate("/user");
        }
      } 
      // else {
      //   navigate("/");
      // }
    };
    handleLoad();
  }, [navigate, setShowUnauthenticatedPopup]);
  return (
    <section className="loading-section">
      <div className="loading-div">
        <TailSpin
          height="80"
          width="80"
          color="#FE2B0D"
          ariaLabel="tail-spin-loading"
          visible={true}
          wrapperClass="loading"
          className="loading"
        />

        <div className="loading-description">
          <h3>{Message}</h3>
        </div>
      </div>
    </section>
  );
}
export default ConfirmPayment;
