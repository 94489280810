import "./stylesheets/terms.css";

function Privacy() {
  return (
    <section className="terms">
      <h2>Privacy Policy</h2>
      <div className="term-container">
        ⦁
        <p>
          If you are a customer with a visual impairment or other disability or
          a customer who seeks support in another language, you may access the
          THATHROWDOWN Policy by emailing.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          This Privacy Policy applies to any Site and Service that links to this
          Privacy Policy. This Policy does not apply to third-party websites,
          products, or services, even if they link to our Site or Services. We
          recommend you review the privacy practices of those third parties
          before connecting your THATHROWDOWN account or sharing any personal
          data. We also encourage you to read this Privacy Policy to understand
          how your personal data will be treated as you make full use of our
          Services. Unless otherwise defined in this Privacy Policy, capitalized
          terms used in this Privacy Policy have the same meanings as in our
          Terms of Service.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          By using the Services, you agree to the collection and use of
          information in accordance with this Privacy Policy. We ask that you do
          not use the Services if you do not agree to any of the provisions in
          this Privacy Policy. For more information about our privacy practices,
          please contact us by emailing us at{" "}
          <a href="mailto:info@thathrowdown.com" className="terms-email">
            info@thathrowdown.com
          </a>
        </p>
      </div>
      <h3>DEFINITIONS</h3>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">Personal Data: </span>
          Personal Data means any information that relates to an identified or
          identifiable individual. Usage Data : Usage Data is data collected
          automatically either generated by the use of the Services or from the
          Services infrastructure itself.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">Cookies: </span>
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">Data Controller: </span>
          Data Controller means a person who (either alone or jointly or in
          common with other persons) determines the purposes for which and the
          manner in which any personal data are, or are to be, processed. For
          the purpose of this Privacy Policy, we are a Data Controller of your
          data provided in order for you t access our services.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">
            Data Processor (Service Providers):{" "}
          </span>
          Data Processor means any person (other than an employee of the Data
          Controller) who processes the data on behalf of the Data Controller.
          We may use the services of various Service Providers in order to
          process your data more effectively. One of which may include the
          services of our payment service provider.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">Data Subject: </span>
          Data Subject is any living individual who is the subject of Personal
          Data.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">User: </span>
          The User is the individual using our Services. The User corresponds to
          the Data Subject, who is the subject of Personal Data.
        </p>
      </div>
      <h3>PERSONAL DATA</h3>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">Collection of Personal Data: </span>
          We collect several different types of Personal Data for various
          purposes to provide and improve our Services to you. We process this
          information pursuant to our legitimate interest in providing you the
          Services and the best user experience on our Sites, as well as where
          necessary to perform any contract between you and us.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Other than the online contact information required to obtain parental
          consent, we do not knowingly collect any Personal Information from
          Users under the age of 13. If you are a User under the age of 13,
          please do not send any Personal Information to us. If we learn we have
          collected Personal Information from a User under the age of 13, we
          will delete that information as quickly as possible. If you believe
          that a User under the age of 13 may have provided us Personal
          Information in violation of this Privacy Policy, please contact us at{" "}
          <a href="mailto:info@thathrowdown.com" className="terms-email">
            info@thathrowdown.com
          </a>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Personal Data we collect will be apparent by the context of the page,
          and may include, but is not limited to the following:
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Account Information: </span>
              When you register for an account, we collect your first and last
              name, email address, and you create a password for your
              THATHROWDOWN user account. We may also collect your payment and
              financial information such as your PayPal email address or billing
              address.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Public Profile Information: </span>
              You may choose to add information to your profile on THATHROWDOWN,
              which may be made public. Such information may include your
              username, the city or country you live in, profile photo, and any
              other likewise information.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Communications: </span>
              When you communicate with us or other Users through the Sites and
              Services or otherwise communicate with us by email, we collect
              information about the communication and any other information you
              <br />
              In addition to the uses described below, we may use your Personal
              Data to contact you with newsletters, marketing or promotional
              materials and other information that may be of interest to you.
              You may opt out of receiving any, or all, of these communications
              from us by following the unsubscribe link or instructions provided
              in any email we send or by contacting us.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Usage Data: </span>
              We may also collect information that your browser sends whenever
              you visit our Services or when you access the Services by or
              through a mobile device.
              <br /> This Usage Data may include information such as your
              computer's Internet Protocol address (e.g. IP address), browser
              type, browser version, the pages of our Services that you visit,
              the time and date of your visit, the time spent on those pages,
              unique device identifiers and other diagnostic data.
              <br />
              When you access the Services by or through a mobile device, this
              Usage Data may include information such as the type of mobile
              device you use, your mobile device unique ID, the IP address of
              your mobile device, your mobile operating system, the type of
              mobile Internet browser you use, unique device identifiers and
              other diagnostic data. Usage Data helps us recognize you as well
              as provide personalized content and advertising across all of your
              devices.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Location Data: </span>
              We may use and store information about your location if you give
              us permission to do so. We use this data to provide features of
              our Services, to improve and customize our Services.
              <br />
              You can enable or disable location services when you use our
              Services at any time, through your device settings.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Tracking Cookies Data: </span>
              When you visit our websites, we may collect information from you
              automatically, such as the activity on our Services, through
              cookies or similar tracking technologies.
              <br />
              Cookies are sent to your browser from a website and stored on your
              device. Tracking technologies also used are beacons, tags, and
              scripts to collect and track information and to improve and
              analyze our Services. We may also allow our business partners to
              use cookies and similar tracking technologies on the Services or
              work with third parties to track your behavior on our behalf.
              <br />
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Services.
              Please review our Cookie Policy for information relating to our
              use of cookies and similar tracking technologies.
            </p>
          </div>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">Service Providers: </span>
          We may receive Personal Data about you from our Service Providers and
          business partners, such as those that conduct payment processing,
          analytics, advertising, hosting, marketing, customer and technical
          support, and other services. Please review the remainder of this
          Privacy Policy to learn more about how and why we use this
          information.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          <span className="term-bold">
            Information from third-party sources:{" "}
          </span>
          We may collect Personal Data from third-party sources, including
          things that other third parties may post publicly about you or
          otherwise provide to us. If you register for a THATHROWDOWN account
          through a third-party application, we may collect certain information
          about you. For example, if you register using your Facebook account,
          Facebook will, with your consent, share your Facebook user ID, first
          and last name, email address and other profile information. Facebook
          collects this information pursuant to the. To control the information
          that we receive from Facebook, please update your privacy setting in
          your Facebook account. If you register for an account using your
          Google account, Google will, with your consent, share certain profile
          information with us. To control the information that we receive from
          Google, you can update your setting in the Google activity controls
          page.
        </p>
      </div>
      <h3>USE OF PERSONAL DATA</h3>
      <div className="term-container">
        ⦁
        <p>
          Our primary goal in collecting personal information is to provide you,
          the user, with a customized experience on our website. We use the
          collected data for various purposes including:
          <div className="term-container">
            ⦁
            <p>
              To provide and maintain our Sites and Services, including to take
              steps to enter into a contract for sale or services, bill you for
              services and process payments and fulfill transactions;
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To enable and allow you to participate in interactive features of
              our Sites and Services and provide you with a personalized
              service, content and ads;
            </p>
          </div>
          <div className="term-container">
            ⦁<p>To create custom audiences on social media sites;</p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To provide you with better products and services and improve and
              grow our business, including to perform research and development,
              understand our customer base and purchasing trends and understand
              the effectiveness of our marketing;
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To operate and maintain safe, secure and reliable Sites and
              Services;
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To provide customer support, including to contact you in response
              to an inquiry that you sent, and send administrative messages,
              technical notices, updates, alerts and other information;
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To provide you with news, special offers and general information
              about other goods, services and events which we, and/or our
              sellers offer that are similar to those that you have already
              purchased or enquired about unless you have opted not to receive
              such information;
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To track the progress and number of entries in our promotions and
              contests;
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To contact you on behalf of certain artists or other third parties
              and recommend artists to our business partners for music,
              publishing engagements, and other opportunities;
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To perform accounting, audits and other internal functions; and
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              To comply with a request or order from courts, law enforcement or
              other government authorities
            </p>
          </div>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          THATHROWDOWN may also use personal information for other business
          purposes, including the following:
          <div className="term-container">
            <span className="term-roman">(i)</span>
            <p>perform accounting, audits and other internal functions;</p>
          </div>
          <div className="term-container">
            <span className="term-roman">(ii)</span>
            <p>
              compliance with our legal obligations or to assert or defend a
              legal claim;
            </p>
          </div>
          <div className="term-container">
            <span className="term-roman">(iii)</span>
            <p>general business administration;</p>
          </div>
          <div className="term-container">
            <span className="term-roman">(iv)</span>
            <p>processing employment applications; and</p>
          </div>
          <div className="term-container">
            <span className="term-roman">(v)</span>
            <p>systems and data security.</p>
          </div>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          In addition, if we feel that a user abuses the THATHROWDOWN.com site
          in any way, we reserve the right to use and share certain information
          with third parties. Abuses include (but are not limited to) possible
          copyright infringement, possible libel and slander, and possible
          fraudulent or illegal activity.
        </p>
      </div>
      <h3>SHARING OF PERSONAL DATA</h3>
      <div className="term-container">
        ⦁
        <p>
          THATHROWDOWN does not rent, or trade your personal information with
          unaffiliated third parties. We share your Personal Data with the
          entities listed below.
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Business Partners: </span>
              When one or more of our business partners co-sponsor a service,
              promotion and/or contest, we may share some or all of the
              information collected in connection with such service, promotion
              or contest with the co-sponsor(s). If you do not want your
              information to be shared, you will be able to choose not to allow
              the transfer by not using or signing up for that particular
              service, promotion or contest.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Service Providers: </span>
              We may employ third party companies and individuals to facilitate
              our Services, to provide the Services on our behalf, to perform
              Service-related services or to assist us in analyzing how our
              Services are used. For example, when you make a purchase through
              our online store, or sign up to receive promotional items from us,
              we may share some of your personal information with third parties
              helping us to complete your transaction or send you the items you
              signed up to receive. When we share your information with such
              third parties, we will work to ensure that only the information
              necessary to complete your transaction is disclosed. This Privacy
              Policy does not apply to such third parties. These third parties
              have access to your Personal Data only to perform these tasks on
              our behalf and are obligated not to disclose or use it for any
              other purpose.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Public Profile: </span>
              Any information in your profile will be accessible by other Users
              of the Sites and Services. If you listen to any music recordings
              uploaded by another User, the fact that you have listened to those
              recordings will be shared with that User. Similarly, if you
              comment on a user's profile, all Users will be able to see your
              comment.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Social Media: </span>
              If you share our content through social media, for example by
              liking us on Facebook, following or tweeting about us on Twitter,
              or giving us a '+1' via Google Plus, those social networks will
              record that you have done so and may set a cookie for this
              purpose.
              <br />
              In some cases, where a page on our website includes content from a
              social network, such as a Twitter feed, or Facebook comments box,
              those services may set a cookie even where you do not click a
              button. As is the case for all cookies, we cannot access those set
              by social networks, just as those social networks cannot access
              cookies we set ourselves.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">
                Third Party Platform Advertising:{" "}
              </span>
              We may share your information with third party platform providers
              who assist us in serving advertising regarding the Services to
              others who may be interested in the Services. We also partner with
              third parties (such as Facebook, Google and Twitter) who use
              cookies and similar tracking technologies to serve targeted
              advertising/content to you via the relevant third-party platform
              based on your profile/interests (also known as “interest-based
              advertising”). Your information is used by the third-party
              platform provider to identify your account and serve
              advertisements to you. While we will not share information that
              identifies you by name with unaffiliated third parties for their
              own use, such third parties may be able to identify you with
              sufficient data from other sources. You can control what
              advertisements you receive via the privacy settings on the
              relevant provider's platform and you should consult the third
              party's help/support center for more information.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">
                Facebook Conversion Tracking Pixel:{" "}
              </span>
              Our website utilizes the Conversion Tracking Pixel service of
              Facebook. This tool allows us to follow the actions of users after
              they are redirected to a provider's website by clicking on a
              Facebook advertisement. We are thus able to record the efficiency
              of Facebook advertisements for statistical and market research
              purposes. The collected data remain anonymous and we cannot see
              the personal data of any individual user, however the collected
              data is saved and processed by Facebook. Facebook is able to
              connect this data, such as pages visited and videos watched, with
              your Facebook account and the data is used for their own
              advertising purposes in accordance with their policy found under:
              Please click here if you would like to revoke your permission:
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Combined and Aggregated Data: </span>
              We may analyze aggregated, de-identified data and share these
              analytics, including to marketing agencies, media agencies and
              analytics providers. We may also combine information from the
              Services with other information we obtain. Additionally,
              information collected about you from a particular browser or
              device may be linked to information collected from another
              computer or device that may relate to you.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Google Analytics: </span>
              We may use third-party Service Providers to monitor and analyze
              the use of our Services: Google Analytics is a web analytics
              service offered by Google that tracks and reports website traffic.
              Google uses the data collected to track and monitor the use of our
              Services. This data is shared with other Google services. Google
              may use the collected data to contextualize and personalize the
              ads of its own advertising network.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Business Transaction: </span>
              If you make a purchase, we may share your email with the Seller so
              that they can reach out to you, offer products, or offer items
              that are similar to those that you have already purchased or
              enquired about. Also, If THATHROWDOWN is involved in a merger,
              acquisition or asset sale, your Personal Data may be transferred.
              We will provide notice before your Personal Data is transferred
              and becomes subject to a different Privacy Policy.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">
                Disclosure for Law Enforcement:{" "}
              </span>
              Under certain circumstances, THATHROWDOWN may be required to
              disclose your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
              <div className="term-container">
                ⦁
                <p>
                  <span className="term-bold">
                    Legal Requirements: <br />
                    THATHROWDOWN may disclose your Personal Data in the good
                    faith belief that such action is necessary to:
                  </span>
                  <div className="term-container">
                    ⦁<p>To comply with a legal obligation;</p>
                  </div>
                  <div className="term-container">
                    ⦁
                    <p>
                      To protect and defend the rights or property of
                      THATHROWDOWN;
                    </p>
                  </div>
                  <div className="term-container">
                    ⦁
                    <p>
                      To prevent or investigate possible wrongdoing in
                      connection with the Services;
                    </p>
                  </div>
                  <div className="term-container">
                    ⦁
                    <p>
                      To protect the personal safety of users of the Services or
                      the public;
                    </p>
                  </div>
                  <div className="term-container">
                    ⦁<p>To protect against legal liability.</p>
                  </div>
                </p>
              </div>
            </p>
          </div>
        </p>
      </div>
      <h3>SECURITY AND RETENTION</h3>
      <div className="term-container">
        ⦁
        <p>
          We maintain appropriate technical, administrative and physical
          safeguards to help protect the security of your Personal Data against
          unauthorized access, use or disclosure. The security of your data is
          important to us, but remember that no method of transmission over the
          Internet, or method of electronic storage is 100% secure.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          We will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy. We will retain and use
          your Personal Data to the extent necessary for our legitimate and
          essential business purposes, to comply with our legal obligations (for
          example, if we are required to retain your data to comply with
          applicable laws), resolve disputes, and enforce our legal agreements
          and policies.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          We will also retain Usage Data for internal analysis purposes. Usage
          Data is generally retained for a shorter period of time, except when
          this data is used to strengthen the security or to improve the
          functionality of our Services, or we are legally obligated to retain
          this data for longer time periods.
        </p>
      </div>
      <h3>TRANSFER OF DATA</h3>
      <div className="term-container">
        ⦁
        <p>
          Your information, including Personal Data, may be transferred to - and
          maintained on - computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          If you are located outside United Kingdom and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United Kingdom and other countries and process it
          there.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          THATHROWDOWN will take all steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
      </div>

      <h3>YOUR RIGHTS AND CHOICES</h3>
      <div className="term-container">
        ⦁
        <p>
          THATHROWDOWN aims to take reasonable steps to allow you to correct,
          amend, delete, or limit the use of your Personal Data.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Whenever made possible, you can update your Personal Data directly
          within your account settings section. If you are unable to change your
          Personal Data, please contact us to make the required changes.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          If you wish to be informed what Personal Data we hold about you and if
          you want it to be removed from our systems, please contact us{" "}
          <a href="mailto:info@thathrowdown.com" className="terms-email">
            info@thathrowdown.com
          </a>
        </p>
      </div>

      <h3>COOKIE POLICY AND INTERNET-BASED ADVERTISING</h3>
      <div className="term-container">
        ⦁
        <p>
          When you visit our websites, we may collect information from you
          automatically through cookies or similar technologies. We also rely on
          partners to provide many features of our Sites and Services using data
          about your use of the Sites and Services. We use cookies for the
          following purposes:
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Site Operations: </span>
              To Enable features that are necessary for providing you the
              services on our Sites, such as keeping you signed in, tracking
              content views, and remembering your preferences.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Analytics: </span>
              To allow us to understand how our Sites and Services are being
              used, track site performance and make improvements.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Advertising: </span>
              To deliver tailored advertising based on your preferences or
              interests across services and devices and measuring effectiveness
              of ads.
            </p>
          </div>
          <div className="term-container">
            ⦁
            <p>
              <span className="term-bold">Social Media: </span>
              To enable the sharing of content from our Sites through social
              networking and other sites. You can modify your browser settings
              decline or accept cookies. However, in a few cases, some of our
              Site features may not function as designed.
            </p>
          </div>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          If you wish to opt-out of our sharing of the limited data that is
          gathered when you visit our Site for purposes of targeted digital
          advertising, we encourage you to visit the website for more
          information about opting out of seeing targeted digital
          advertisements. Similarly, you can learn about your options to opt-out
          of mobile app tracking by certain advertising networks through your
          device settings. You may also visit these websites for more
          information on how you can opt back in to the sharing of data. Please
          note that THATHROWDOWN does not maintain this web tracking data in an
          identifiable manner.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Please note that opting-out of advertising networks does not mean that
          you will not receive advertisements while using our Sites and Services
          or on other websites.
        </p>
      </div>

      <h3>PAYMENTS</h3>
      <div className="term-container">
        ⦁
        <p>
          We may provide paid products and/or services within the Services. In
          that case, we use third-party services for payment processing (e.g.
          payment processors).
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          The payment processors we work with are:
          <div className="term-container">
            ⦁<p>PayPal</p>
          </div>
          <div className="term-container">
            ⦁<p>PayStack (Coming Soon)</p>
          </div>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Our Services may contain links to other websites that are not operated
          by us. If you click on a third-party link, you will be directed to
          that third party's website. We strongly advise you to review the
          Privacy Policy of every site you visit.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party websites or services.
        </p>
      </div>

      <h3>DATA PROTECTION</h3>
      <div className="term-container">
        ⦁
        <p>
          This Data Protection section applies to all users of any services
          owned or controlled by THATHROWDOWN. Furthermore, this section extends
          to Service Providers, Contractors, Affiliates, Vendors, Affiliates,
          Agents, and Entities; their parent company and subsidiaries, or their
          respective employees, officers, directors, members, managers,
          shareholders, agents, vendors, licensors, licensees, contractors,
          customers, successors, and assigns (each referred to as “Parties” in
          the following paragraph). By executing any Agreement with
          THATHROWDOWN, which uses any personal information, Parties agree to
          abide by the following paragraph. Violation of this section is cause
          for termination of Service or rights granted by THATHROWDOWN.
          Moreover, if an agreement has been executed between THATHROWDOWN and a
          Party, violation of this section is considered a material breach.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          Each party shall, at its own expense, ensure that it complies with and
          assists THATHROWDOWN to comply with the requirements of all
          legislation and regulatory requirements in force from time to time
          relating to the use of personal data, including (without limitation):
          <div className="term-container">
            <span className="term-roman">(i)</span>
            <p>
              any data protection legislation from time to time in force in the
              UK including the Data Protection Act 1998 or 2018 and any
              successor legislation
            </p>
          </div>
          <div className="term-container">
            <span className="term-roman">(ii)</span>
            <p>
              for so long as and to the extent that the law of the EU has legal
              effect in the UK, the General Data Protection Regulation ((EU)
              2016/679) (“GDPR”) and any other directly applicable EU regulation
              relating to privacy; and
            </p>
          </div>
          <div className="term-container">
            <span className="term-roman">(iii)</span>
            <p>
              and any applicable U.S Data Protection Laws, not limited to the
              California Consumer Privacy Act (CCPA).
            </p>
          </div>
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          This clause is in addition to, and does not reduce, remove or replace,
          a party's obligations arising from such requirements. Parties confirm
          that they will not rent or sell customer lists, contact details or
          other data without the customers' express prior approval. Either party
          may treat a breach of this clause as a reason for termination of this
          Agreement.
        </p>
      </div>

      <h3>CHANGES TO THIS PRIVACY POLICY</h3>
      <div className="term-container">
        ⦁
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          We will let you know via prominent notice on our Services, prior to
          the change becoming effective and update the "effective date" at the
          top of this Privacy Policy.
        </p>
      </div>
      <div className="term-container">
        ⦁
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </div>

      <h3>CONTACT US</h3>
      <div className="term-container">
        ⦁
        <p>
          If you have any questions about this Privacy Policy or wish to
          exercise one of your Data Subject rights, please contact us by
          emailing us:{" "}
          <a href="mailto:info@thathrowdown.com" className="terms-email">
            info@thathrowdown.com
          </a>
        </p>
      </div>
    </section>
  );
}

export default Privacy;
