import Nigeria from "./../../images/nigeria.svg";
import UK from "./../../images/UK.svg";

import Popup from "reactjs-popup";

export default function NavbarCountryPopup({
  trigger,
  handlePopupOpen,
  handleCart,
  handlePopupClose,
  selectedCurrency,
  changeCurrency,
}) {
  return (
    <>
      <Popup
        className="navbar-popup country-popup"
        trigger={trigger}
        onOpen={() => {
          handlePopupOpen("countryPopup");
          handleCart();
        }}
        onClose={() => handlePopupClose("countryPopup")}
        position="bottom center"
      >
        {(close) => (
          <div className="country__options">
            <button
              className={`country__options ${
                selectedCurrency === "GBP" ? "currency_selected" : ""
              }`}
              onClick={() => {
                changeCurrency("GBP");
                close();
              }}
            >
              <img src={UK} alt="GBP"></img>
              UK
            </button>
            <button
              className={`country__options ${
                selectedCurrency === "NGN" ? "currency_selected" : ""
              }`}
              onClick={() => {
                changeCurrency("NGN");
                close();
              }}
            >
              <img src={Nigeria} alt="NGN"></img>
              Nigeria
            </button>
          </div>
        )}
      </Popup>
    </>
  );
}
