import commission from "./../../images/commission.svg";
import down from "./../../images/dropdown.svg";
import imageAttachment from "./../../images/file-image.svg";
import audioAttachment from "./../../images/file-audio.svg";
import documentAttachment from "./../../images/file-doc.svg";
import download from "./../../images/download-dark.svg";
import { TailSpin } from "react-loader-spinner";
import Popup from "reactjs-popup";
import { useCurrencyContext } from "../../context/currencyContext";
function MessagesWorkstreamPopup({
  trigger,
  chatCommission,
  feedToggle,
  setFeedToggle,
  attachmentToggle,
  setAttachmentToggle,
  attachments,
  handleDownload,
}) {
  const { selectedCurrency, formatCurrency } = useCurrencyContext();
  return (
    <Popup
      className="messages-sidebar-popup workstream-sidebar-popup"
      trigger={trigger}
      arrow={false}
      keepTooltipInside={"body"}
      closeOnDocumentClick={false}
    >
      <div className="workstream-top">
        <h3>Workstream Summary</h3>
        <div className="workstream-paid">
          <p>Paid out</p>
          <p>
            {chatCommission.payment_status === "COMPLETED"
              ? formatCurrency(chatCommission.budget * 100)
              : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
          </p>
        </div>
        <div className="workstream-escrow">
          <p>In Escrow</p>
          <p>
            {chatCommission.payment_status === "IN_ESCROW"
              ? formatCurrency(chatCommission.budget * 100)
              : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
          </p>
        </div>
        <div className="workstream-escrow">
          <p>Commission Fee</p>
          <p>{formatCurrency(chatCommission.budget * 100)}</p>
        </div>
        <div className="workstream-unpaid">
          <p>Unpaid Invoice</p>
          <p>
            {chatCommission.payment_status === "PENDING"
              ? formatCurrency(chatCommission.budget * 100)
              : `${selectedCurrency === "GBP" ? "£" : "N"}00.00`}
          </p>
        </div>
      </div>
      <div className="workstream-navigation">
        <button
          className="workstream-feed"
          style={feedToggle === "workstream" ? { color: "#ea6d62" } : null}
          onClick={() => {
            if (feedToggle !== "workstream") {
              setFeedToggle("workstream");
            }
          }}
        >
          Workstream
        </button>
        <button
          className="workstream-attachements"
          style={feedToggle === "attachments" ? { color: "#ea6d62" } : null}
          onClick={() => {
            if (feedToggle !== "attachments") {
              setFeedToggle("attachments");
            }
          }}
        >
          Attachments
        </button>
      </div>
      {feedToggle === "workstream" && (
        <div className="feed-details">
          {chatCommission.commission_progress.map((status) => {
            return (
              <div className="feed-item" key={status.id}>
                <div
                  className="feed-item-color"
                  style={
                    status.status === "REQUESTED" ||
                    status.status === "NEGOTIATING"
                      ? { backgroundColor: "rgb(242, 148, 35)" }
                      : status.status === "ACCEPTED" ||
                        status.status === "COMPLETED"
                      ? { backgroundColor: "#229e04" }
                      : { backgroundColor: "#fe2b0d" }
                  }
                />
                <div className="feed-item-type-image">
                  {(status.status === "ACCEPTED" &&
                    status.payment_status === "IN_ESCROW") ||
                  (status.status === "CANCELLED" &&
                    status.payment_status === "REFUNDED") ||
                  status.payment_status === "ON_HOLD" ? (
                    <img src={commission} alt="" />
                  ) : (
                    <CurrencySymbol
                      currency={selectedCurrency === "GBP" ? "pound" : "naira"}
                    />
                  )}
                </div>
                <div className="feed-item-detail">
                  <h3>
                    {(status.status === "ACCEPTED" &&
                      status.payment_status === "IN_ESCROW") ||
                    status.status === "COMPLETED" ||
                    (status.status === "CANCELLED" &&
                      status.payment_status === "REFUNDED")
                      ? formatCurrency(chatCommission.budget * 100)
                      : `Commission #${status.commission_id}`}
                    {status.status === "ACCEPTED" &&
                      status.payment_status === "IN_ESCROW" &&
                      " added to Escrow"}
                    {status.status === "COMPLETED" && " paid to producer"}
                    {status.status === "REQUESTED" && " Requested"}
                    {status.status === "NEGOTIATING" && " Negotiating"}
                    {status.status === "ACCEPTED" &&
                      status.payment_status !== "IN_ESCROW" &&
                      " Accepted"}
                    {status.status === "REJECTED" && " Rejected"}
                    {status.status === "CANCELLED" &&
                      status.payment_status !== "REFUNDED" &&
                      " Cancelled"}
                    {status.status === "IN_DISPUTE" && " In Dispute"}
                    {status.status === "CANCELLED" &&
                      status.payment_status === "REFUNDED" &&
                      " Refunded"}
                  </h3>
                  <p>{formatCurrency(status.budget)}</p>
                  <p>
                    {new Date(status.created_at)
                      .toLocaleString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/,/g, "")}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {feedToggle === "attachments" && (
        <>
          <div className="attachment-feed-container">
            <Popup
              className="attachment-file-popup"
              trigger={
                <button>
                  {attachmentToggle}
                  <img src={down} alt="" />
                </button>
              }
              arrow={false}
            >
              {(close) => (
                <>
                  <div
                    onClick={() => {
                      if (attachmentToggle !== "Show all") {
                        setAttachmentToggle("Show all");
                      }
                      close();
                    }}
                  >
                    All
                  </div>
                  <div
                    onClick={() => {
                      if (attachmentToggle !== "Audio") {
                        setAttachmentToggle("Audio");
                        close();
                      }
                    }}
                  >
                    Audio
                  </div>
                  <div
                    onClick={() => {
                      if (attachmentToggle !== "Images") {
                        setAttachmentToggle("Images");
                      }
                      close();
                    }}
                  >
                    Images
                  </div>
                  <div
                    onClick={() => {
                      if (attachmentToggle !== "Documents") {
                        setAttachmentToggle("Documents");
                      }
                      close();
                    }}
                  >
                    Documents
                  </div>
                </>
              )}
            </Popup>
          </div>

          <div className="attachment-feed">
            {attachments &&
              attachments.map((attachment) => {
                const fileExtension = attachment.upload
                  .split(".")
                  .pop()
                  .toLowerCase();

                const type = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "webp",
                ].includes(fileExtension)
                  ? "Image"
                  : ["mp3", "wav", "ogg", "flac", "aac"].includes(fileExtension)
                  ? "Audio"
                  : "Document";
                return (
                  <div className="attachment-feed-row" key={attachment.id}>
                    <img
                      src={
                        type === "Image"
                          ? imageAttachment
                          : type === "Audio"
                          ? audioAttachment
                          : documentAttachment
                      }
                      alt=""
                    />
                    <div>
                      <h5>{type} File</h5>
                      <p>
                        {new Date(attachment.created_at)
                          .toLocaleString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(/,/g, "")}
                      </p>
                    </div>
                    <img
                      src={download}
                      alt=""
                      onClick={() => {
                        handleDownload(attachment.id);
                      }}
                    />
                  </div>
                );
              })}

            {!attachments && (
              <div className="attachment-load-placeholder">
                <TailSpin
                  height="70"
                  width="70"
                  color="#000000"
                  ariaLabel="tail-spin-loading"
                  visible={true}
                  wrapperClass="loading"
                  className="loading"
                />
              </div>
            )}
          </div>
        </>
      )}
    </Popup>
  );
}
export default MessagesWorkstreamPopup;

const CurrencySymbol = ({ currency }) => {
  const getSymbol = () => {
    switch (currency) {
      case "pound":
        return "£";
      case "naira":
        return "₦";
      default:
        return "";
    }
  };

  return <div className={`currency-symbol ${currency}`}>{getSymbol()}</div>;
};
