import "./../stylesheets/editProfile.css";
import Warning from "./../../images/warning.svg";
import * as Sentry from "@sentry/react";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
import { useNavigation } from "../../context/navigationContext";

function AboutMe({
  user,
  handleNext,
  handlePrevious,
  handleUpdateUser,
  setShowUnauthenticatedPopup,
}) {
  const [servicesLoad, setServicesLoad] = useState([]);
  const [checkedServices, setCheckedServices] = useState([]);
  const { navigate } = useNavigation();

  const [about, setAbout] = useState("");

  function handleAboutChange(event) {
    setAbout(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      event.target.value.length < 40 ||
      event.target.value.length > 100 ||
      !regex.test(event.target.value)
    )
      document.querySelector(".about-error").classList.remove("hidden");
    else document.querySelector(".about-error").classList.add("hidden");
  }

  function checkValues() {
    let valid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (checkedServices.length === 0) {
      document.querySelector(".check-error").classList.remove("hidden");
      valid = false;
    }

    if (about.length < 40 || about.length > 100 || !regex.test(about)) {
      document.querySelector(".about-error").classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  const handleSubmit = async () => {
    if (!checkValues()) return false;

    const body = {
      about: about === "" ? user.about : about,
      services: checkedServices,
    };

    try {
      const response = await api.put("profile", body);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      handleUpdateUser();
      handleNext();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (user.services) {
      const initialCheckedServices = servicesLoad
        .filter((service) =>
          user.services.some((userService) => userService.id === service.id)
        )
        .map((service) => service.id);
      setCheckedServices(initialCheckedServices);
    }
  }, [user.services, servicesLoad]);

  const handleCheckboxChange = (id) => {
    setCheckedServices((prevCheckedServices) => {
      const updatedServices = prevCheckedServices.includes(id)
        ? prevCheckedServices.filter((serviceId) => serviceId !== id)
        : [...prevCheckedServices, id];

      if (updatedServices.length === 0)
        document.querySelector(".check-error").classList.remove("hidden");
      else document.querySelector(".check-error").classList.add("hidden");

      return updatedServices;
    });
  };

  useEffect(() => {
    const handleLoad = async () => {
      setAbout(user.about);
      try {
        const response = await api.get("services");

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

        const data = response.data;

        setServicesLoad(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [user, navigate, setShowUnauthenticatedPopup]);
  return (
    <section className="about-section">
      <section className="edit-profile">
        <div className="services">
          <h3>
            Services
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={Warning} alt="" className="check-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Please select at least 1 service!
            </Popup>
          </h3>
          {servicesLoad.map((service) => {
            return (
              <div
                className="service-item"
                data-id={service.id}
                key={service.id}
              >
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={checkedServices.includes(service.id)}
                    onChange={() => handleCheckboxChange(service.id)}
                  />
                  {service.name}
                </label>
              </div>
            );
          })}
        </div>
        <div className="about-you">
          <h3>
            About You{" "}
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={Warning} alt="" className="about-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              The number of characters must be between 40 and 100
            </Popup>
          </h3>
          <textarea
            className="about-you-textarea"
            placeholder="write here, minimum characters allowed are 40"
            defaultValue={about}
            onChange={(event) => handleAboutChange(event)}
          ></textarea>
        </div>
      </section>
      <div className="edit-profile-button-container">
        <button className="previous" onClick={handlePrevious}>
          Previous
        </button>
        <button className="save-continue" onClick={handleSubmit}>
          Save & Continue
        </button>
      </div>
    </section>
  );
}

export default AboutMe;
