import "./../stylesheets/popularProducer.css";
import Producer1 from "./../../images/user.png";

import left from "./../../images/left.svg";
import right from "./../../images/right.svg";
import { useRef, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImgLoader from "./../imgLoader";
import api from "../../utils/api";
import { useNavigation } from "../../context/navigationContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
function PopularProducer() {
  const [dataSplit, setDataSplit] = useState(null);
  const { navigateToProducer, navigate } = useNavigation();
  const handleProducerClick = (event) => {
    const id = event.target.closest(".producer-item").dataset.id;
    navigateToProducer(id);
  };
  const responsiveness = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const producerCarouselRef = useRef(null);

  const handleProducerCarouselNext = () => {
    if (producerCarouselRef.current) {
      producerCarouselRef.current.next();
    }
  };

  const handleProducerCarouselPrevious = () => {
    if (producerCarouselRef.current) {
      producerCarouselRef.current.previous();
    }
  };

  useEffect(() => {
    const getProducers = async function () {
      try {
        const response = await api.get("top/producers");
        handleResponseStatus(response, navigate);

        const data = response.data;
        setDataSplit(data.data);
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    getProducers();
  }, [navigate]);

  return (
    <section className="popular-producer">
      <div className="heading-home">
        <h1 className="trending-tracks">Top Producers</h1>
        <div className="arrow-keys">
          <Link to="/producers">SEE ALL</Link>
          <button onClick={handleProducerCarouselPrevious}>
            <img alt="" src={left}></img>
          </button>
          <button onClick={handleProducerCarouselNext}>
            <img alt="" src={right}></img>
          </button>
        </div>
      </div>

      <div className="popular-producer-artist">
        <Carousel
          ref={producerCarouselRef}
          responsive={responsiveness}
          itemClass="carousel-item"
          arrows={false}
          renderButtonGroupOutside={true}
        >
          {!dataSplit &&
            Array.from({ length: 5 }).map((_, index) => (
              <div className="producer-item" key={index}>
                <div className="artist-img-holder">
                  <Skeleton className="artist-img" circle={true} />
                </div>
                <span>
                  <Skeleton count={0.5} containerClassName="skeleton-loading" />
                </span>
                <p>
                  <Skeleton count={0.7} containerClassName="skeleton-loading" />
                </p>
              </div>
            ))}
          {dataSplit &&
            dataSplit.map((producer, index) => {
              return (
                <div
                  className="producer-item"
                  key={index}
                  data-id={producer.slug}
                  onClick={handleProducerClick}
                >
                  <div className="artist-img-holder">
                    <ImgLoader
                      src={
                        producer.profile_image_url === null
                          ? Producer1
                          : producer.profile_image_url
                      }
                      className="artist-img"
                      type="producer"
                    />
                  </div>
                  <span>{producer.name}</span>
                  <p>
                    {producer.beats_count === 1
                      ? `${producer.beats_count} Beat Uploaded`
                      : `${producer.beats_count} Beats Uploaded`}
                  </p>
                </div>
              );
            })}
        </Carousel>
      </div>
    </section>
  );
}

export default PopularProducer;
