import "./stylesheets/playlistLanding.css";
import "./../index.css";
import "./../Fonts/fonts.css";
import * as Sentry from "@sentry/react";
import add from "./../images/Add-Orange.svg";
import art from "./../images/1.png";
import cross from "./../images/cross-black.svg";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import CreatePlaylistPopup from "./createPlaylistPopup";
import Skeleton from "react-loading-skeleton";
import { useAuthContext } from "../context/authContext";
import handleResponseStatus from "../utils/handleResponseStatus";
import { useNavigation } from "../context/navigationContext";
import api from "../utils/api";
function AddToPlaylistPopup({
  isAddToPlaylistPopupOpen,
  setIsAddToPlaylistPopupOpen,
  beat,
  setPopupMessage,
  setShowMessagePopup,
  setShowUnauthenticatedPopup,
  type,
}) {
  const { isAuthenticated } = useAuthContext();
  const { navigate } = useNavigation();
  const itemType = type ? type : "beat";

  const [playlists, setPlaylists] = useState(null);

  const [isCreatePlaylistPopupOpen, setIsCreatePlaylistPopupOpen] =
    useState(false);

  const getPlaylists = async () => {
    if (!isAuthenticated) {
      return;
    }

    try {
      const response = await api.get(`playlist`);

      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;

      if (response.status === 200 || response.status === 201) {
        setPlaylists(data.data);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    const handleLoad = async () => {
      if (!isAuthenticated) {
        return;
      }

      try {
        const response = await api.get(`playlist`);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        if (response.status === 200 || response.status === 201) {
          setPlaylists(data.data);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    handleLoad();
  }, [navigate, setShowUnauthenticatedPopup, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  });

  const handleSubmitAddToPlaylist = async (event) => {
    event.preventDefault();

    const playlistId =
      event.target.closest(".create-playlist-popup-playlist-container-row") !==
      null
        ? event.target.closest(".create-playlist-popup-playlist-container-row")
            .dataset.id
        : event.target.dataset.id;

    const body = {
      playlist: playlistId,
    };
    if (itemType === "beat") {
      body.beats = [beat.slug];
    } else if (itemType === "song") {
      body.songs = [beat.slug];
    } else if (itemType === "album") {
      body.albums = [beat.slug];
    }

    try {
      const response = await api.post(`playlist/add`, body);

      if (response.status === 422) {
        setIsAddToPlaylistPopupOpen(false);
        setPopupMessage("Beat is already in playlist!");
        setShowMessagePopup(true);
        return;
      }
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      setIsAddToPlaylistPopupOpen(false);

      setPopupMessage(
        `${itemType === "beat" ? "Beat" : "Song"} added to playlist`
      );

      setShowMessagePopup(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <section>
        <Popup
          className="create-playlist-popup"
          modal
          open={isAddToPlaylistPopupOpen}
          onOpen={() => {
            if (!isAuthenticated) {
              navigate("/login");
              return;
            }
            setIsAddToPlaylistPopupOpen(true);
          }}
          onClose={() => {
            setIsAddToPlaylistPopupOpen(false);
          }}
          nested
        >
          <div className="create-playlist-popup-heading">
            <h3>Add to Playlist</h3>
            <img
              src={cross}
              alt=""
              onClick={() => {
                setIsAddToPlaylistPopupOpen(false);
              }}
            />
          </div>
          <div className="create-playlist-popup-playlist-container">
            {playlists && (
              <>
                {playlists.map((playlist) => {
                  return (
                    <>
                      <div
                        className="create-playlist-popup-playlist-container-row"
                        data-id={playlist.id}
                        key={playlist.id}
                      >
                        <img
                          src={playlist.image ? playlist.image : art}
                          alt=""
                          className="create-playlist-popup-playlist-container-row__img"
                        />
                        <div className="create-playlist-popup-playlist-container-row__title">
                          <h3>{playlist.name}</h3>
                          <h4>
                            {playlist.items_count === 1
                              ? `${playlist.items_count} Track`
                              : `${playlist.items_count} Tracks`}{" "}
                          </h4>
                        </div>
                        <button onClick={handleSubmitAddToPlaylist}>Add</button>
                      </div>
                    </>
                  );
                })}
              </>
            )}

            {playlists && playlists.length === 0 && (
              <h3>No playlists created </h3>
            )}
            {!playlists &&
              Array.from({ length: 3 }).map((_, index) => (
                <div className="create-playlist-popup-playlist-container-row">
                  <Skeleton containerClassName="create-playlist-popup-playlist-container-row__img" />
                  <div
                    className="create-playlist-popup-playlist-container-row__title"
                    style={{ width: "50%" }}
                  >
                    <h3>
                      <Skeleton count={0.6} />
                    </h3>
                    <h4>
                      <Skeleton count={0.4} />
                    </h4>
                  </div>
                  <button />
                </div>
              ))}
          </div>
          <button
            className="create-playlist-popup-playlist__create-button create-playlist-button"
            onClick={() => {
              setIsCreatePlaylistPopupOpen(true);
            }}
          >
            <img src={add} alt="" />
            Create Playlist
          </button>
        </Popup>
      </section>

      {isCreatePlaylistPopupOpen && (
        <CreatePlaylistPopup
          isCreatePlaylistPopupOpen={isCreatePlaylistPopupOpen}
          setIsCreatePlaylistPopupOpen={setIsCreatePlaylistPopupOpen}
          getPlaylists={getPlaylists}
          setShowMessagePopup={setShowMessagePopup}
          setPopupMessage={setPopupMessage}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        ></CreatePlaylistPopup>
      )}
    </>
  );
}

export default AddToPlaylistPopup;
