import "./../stylesheets/findProducer.css";
import microPhone from "./../../images/sellSong.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useAuthContext } from "../../context/authContext";
function SellSong() {
  const navigate = useNavigate();
  const [isSeller, setIsSeller] = useState(false);
  const { isAuthenticated, user } = useAuthContext();

  useEffect(() => {
    if (user) {
      if (user.role === "buyer") {
        setIsSeller(false);
      } else {
        setIsSeller(true);
      }
    }
  }, [user]);

  const handleSellBeats = async () => {
    if (isAuthenticated) {
      if (isSeller) {
        navigate("/upload/song");
      }
    } else {
      localStorage.setItem(
        "previous",
        JSON.stringify({ location: "/upload/music" })
      );
      navigate("/login");
    }
  };

  return (
    <section className="findproducer">
      <div className="findproducer-inner">
        <div className="inner-image producer-image">
          <img src={microPhone} alt="" />
        </div>

        <div className="findProducer-text">
          <h2>
            Start making money by selling your music. Turn your passion into
            profit.
          </h2>
          <p>
            Afrobeats is here to stay and we are proud to be the largest hub for
            Afrobeats music producers so if you are looking for that authentic
            Afrobeat sound, you have come to the right place.
          </p>

          {isSeller && (
            <button
              className="findproducer-inner-btn sell-beat-upload-button"
              onClick={handleSellBeats}
            >
              Sell Songs
            </button>
          )}
          {!isSeller && !isAuthenticated && (
            <Popup
              className="login-error-popup sell-beat-error-popup"
              trigger={
                <button className="findproducer-inner-btn disabled-sell-beat">
                  Sell Songs
                </button>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              You must be logged in to sell your songs!
            </Popup>
          )}

          {!isSeller && isAuthenticated && (
            <Popup
              className="login-error-popup sell-beat-error-popup"
              trigger={
                <button className="findproducer-inner-btn disabled-sell-beat">
                  Sell Songs
                </button>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              You must be a seller to sell your songs!
            </Popup>
          )}
        </div>
      </div>
    </section>
  );
}

export default SellSong;
