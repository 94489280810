import image from "./../../images/success3.png";
import "./../stylesheets/notFound.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
function UploadSuccess({ typeUpload }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonClick = () => {
    navigate(
      `/user/uploads/${
        typeUpload === "Album"
          ? "albums"
          : typeUpload === "Song"
          ? "music"
          : "beats"
      }`
    );
  };
  return (
    <section className="error-section">
      <div className="error-div">
        <div className="error-image-div">
          <img alt="" src={image}></img>
        </div>
        <div className="product-not-found-description">
          Upload Successful! 🎵 Your {typeUpload} has been successfully
          uploaded. You'll be able to see it on the site in just a few minutes.
          {typeUpload === "Album"
            ? "Albums might take a bit longer, but don't worry—we're just making sure everything is all good!"
            : ""}
        </div>
        <div>
          <button className="save-changes" onClick={buttonClick}>
            Profile
          </button>
        </div>
      </div>
    </section>
  );
}
export default UploadSuccess;
