import "./../stylesheets/beat.css";

function BeatDescription({ beat }) {
  return (
    <section className="beat-section-description-div">
      <p className="beat-section-description-p">{beat.description}</p>
    </section>
  );
}

export default BeatDescription;
