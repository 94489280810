import image from "./../../images/oops.jpg";
import "./../stylesheets/notFound.css";
import React, { useState } from "react";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function Unverified() {
  const [emailSent, setEmailSent] = useState(false);
  const { navigate } = useNavigation();
  const buttonClick = async () => {
    // navigate(
    //   `/upload/${
    //     typeUpload === "Album"
    //       ? "album"
    //       : typeUpload === "Song"
    //       ? "song"
    //       : "beat"
    //   }`
    // );

    try {
      const response = await api.post(`email/verification-notification`);
      if (response.status === 200) {
        setEmailSent(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const homeClick = () => {
    navigate("/");
  };
  return (
    <section className="error-section">
      {!emailSent && (
        <div className="error-div">
          <div className="error-image-div">
            <img alt="" src={image}></img>
          </div>
          <div className="product-not-found-description">
            Your Account is not verified yet. Click the button below to verify
            the Account. If your account remains unverified some functionality
            may not be available.
          </div>
          <div className="buttons__failure">
            <button className="save-changes" onClick={buttonClick}>
              Verify Now!
            </button>
            <button className="save-changes" onClick={homeClick}>
              Home
            </button>
          </div>
        </div>
      )}
      {emailSent && (
        <div className="error-div">
          <div className="product-not-found-description">
            Email has been sent to your registered email. Please check the email
            and verify your account following the described steps in the email.
          </div>
          <div className="buttons__failure">
            <button className="save-changes" onClick={homeClick}>
              Verified? Go to Home
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
export default Unverified;
