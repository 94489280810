import {
  PLAY,
  PAUSE,
  SET_CURRENT_TIME,
  SET_TOTAL_TIME,
  SET_VOLUME,
  ADD_TO_QUEUE,
  PLAY_SPECIFIC_QUEUE_ITEM,
  PLAY_NOW,
  CLEAR_INFO_MESSAGE,
  PLAY_NEXT,
  PLAY_PREV,
  SET_LIKED,
  SHOW_INFO_MESSAGE,
  TOGGLE_MOBILE_STATE,
  PLAY_STARTING,
} from "./../constants/actionTypes";

export const playNow = (item) => ({
  type: PLAY_NOW,
  payload: item,
});

export const playStarting = (item) => ({
  type: PLAY_STARTING,
  payload: item,
});

export const addToQueue = (items) => ({
  type: ADD_TO_QUEUE,
  payload: items,
});

export const clearInfoMessage = () => ({
  type: CLEAR_INFO_MESSAGE,
});

export const showInfoMessage = (message) => ({
  type: SHOW_INFO_MESSAGE,
  payload: message,
});

export const playSpecificQueueItem = (item) => ({
  type: PLAY_SPECIFIC_QUEUE_ITEM,
  payload: item,
});

export const play = () => ({
  type: PLAY,
});

export const pause = () => ({
  type: PAUSE,
});

export const setCurrentTime = (currentTime) => ({
  type: SET_CURRENT_TIME,
  payload: currentTime,
});

export const setTotalTime = (totalTime) => ({
  type: SET_TOTAL_TIME,
  payload: totalTime,
});

export const setVolume = (volume) => ({
  type: SET_VOLUME,
  payload: volume,
});

export const setLiked = (isLiked) => ({
  type: SET_LIKED,
  payload: isLiked,
});

export const playNext = () => ({
  type: PLAY_NEXT,
});

export const playPrev = () => ({
  type: PLAY_PREV,
});

export const toggleMobileState = () => ({
  type: TOGGLE_MOBILE_STATE,
});
