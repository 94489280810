import "./../../index.css";
import "./../../Fonts/fonts.css";

import * as Sentry from "@sentry/react";
import Producers from "./../common/producers";

import "./../stylesheets/beatsLanding.css";
import React, { useEffect, useState } from "react";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function FeaturedArtistLanding() {
  const { navigate } = useNavigation();

  const [featuredArtists, setFeaturedArtists] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNextPage = async (e) => {
      setLoading(true);

      try {
        if (nextPage === null) {
          setLoading(false);

          return;
        } else {
          const response = await api.get(nextPage);
          handleResponseStatus(response, navigate);
          const data = response.data;
          setNextPage(data.links.next?.split("api/")[1]);
          setFeaturedArtists([...featuredArtists, ...data.data]);
        }
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    var debounceTimeout;
    var section = document.querySelector(".footer");
    function checkSectionVisibility() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(function () {
        var sectionRect = section.getBoundingClientRect();

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          getNextPage();
        }
      }, 200);
    }

    window.addEventListener("scroll", checkSectionVisibility);

    return () => {
      window.removeEventListener("scroll", checkSectionVisibility);
    };
  }, [nextPage, featuredArtists, setFeaturedArtists, navigate]);

  useEffect(() => {
    const getAllFeaturedArtists = async function () {
      setLoading(true);

      try {
        const response = await api.get(`highlights?location=featured_artist`);

        handleResponseStatus(response, navigate);
        const data = response.data;
        setFeaturedArtists(data.artists);
        if (data.artists.links) {
          setNextPage(data.artists.links.next);
        }
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    getAllFeaturedArtists();
  }, [navigate]);

  return (
    <>
      <section className="beats-topbar">
        <h3 className="beats-heading" style={{ marginBottom: 0 }}>
          Explore Featured Artists:
        </h3>
      </section>
      <Producers producers={featuredArtists} loading={loading} />
    </>
  );
}

export default FeaturedArtistLanding;
