import { useEffect, useState } from "react";
import "./../stylesheets/musicCarousel.css";
import Banner from "./../../images/bannerUpdated.jpeg";
import Disk from "./../../images/disk.svg";
import { useNavigation } from "../../context/navigationContext";
import { usePlayerContext } from "../../context/playerContext";
import { playNow } from "../../actions/playerActions";

const MusicLandingCarouselSlide = ({ item, mobile }) => {
  const { navigateToArtists, navigateToAlbum, navigateToMusic } =
    useNavigation();

  const [isImgLoaded, setIsImgLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = item.cover_path;
    img.onload = () => {
      setIsImgLoaded(true);
    };
  }, [item]);
  const handleNameClick = () => {
    if (item.tracks) {
      navigateToAlbum(item.slug);
    } else {
      navigateToMusic(item.slug);
    }
  };

  const handleArtistClick = () => {
    navigateToArtists(item?.artist?.slug);
  };

  const handlePlayClick = () => {
    if (item.tracks) {
      navigateToAlbum(item.slug);
    } else {
      handlePlayItem(item);
    }
  };

  const { playerDispatch } = usePlayerContext();

  const handlePlayItem = (item) => {
    playerDispatch(playNow(item));
  };

  return (
    <>
      {!mobile && (
        <div className="music-carousel-slide-container" id={item.id}>
          <div className="music-carousel-slide" onClick={handleNameClick}>
            <img src={isImgLoaded ? item.cover_path : Banner} alt="1" />
            <div className="music-carousel-filter" />
            <div className="music-carousel-info">
              <h2>
                New {item.tracks ? "Album" : "Song"} '
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNameClick();
                  }}
                >
                  {item.name}
                </span>
                '
                <br />
                by{" "}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArtistClick();
                  }}
                >
                  {item.artist.name}
                </span>
              </h2>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlayClick();
                }}
              >
                Play {item.tracks ? "Album" : "Song"} <img src={Disk} alt="" />
              </button>
            </div>
          </div>
        </div>
      )}
      {mobile && (
        <div className="music-carousel-slide-container-mobile">
          <div className="music-carousel-slide" onClick={handleNameClick}>
            <img src={isImgLoaded ? item.cover_path : Banner} alt="1" />
            <div className="music-carousel-filter" />
            <div className="music-carousel-info">
              <h2>
                New {item.tracks ? "Album" : "Song"} '
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNameClick();
                  }}
                >
                  {item.name}
                </span>
                '
                <br />
                by{" "}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArtistClick();
                  }}
                >
                  {item.artist.name}
                </span>
              </h2>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlayClick();
                }}
              >
                Play {item.tracks ? "Album" : "Song"} <img src={Disk} alt="" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MusicLandingCarouselSlide;
