import "./stylesheets/sharePopup.css";
import "./../index.css";
import "./../Fonts/fonts.css";

import cross from "./../images/cross-black.svg";

import Popup from "reactjs-popup";
import {
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  EmailIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

function SharePopup({ isSharePopupOpen, setIsSharePopupOpen, beat, type }) {
  const webURL = process.env.REACT_APP_FRONTEND_URL;

  const shareURL = type
    ? `${webURL}/${
        type === "song" ? "music" : type === "album" ? "albums" : "beats"
      }/${beat.slug}`
    : webURL;

  const shareQuote =
    type === "song" || type === "album"
      ? `Listen to this 🔥 ${beat.name} by ${
          type === "beat" ? beat.producer.name : beat.artist.name
        }! 🎵 Discover Afrobeats songs and albums on `
      : `Listen to this 🔥 ${beat.name} by ${
          type === "beat" ? beat.producer.name : beat.artist.name
        }! 🎵 Explore exclusive Afrobeats instrumentals and 💼 hire talented producers for your unique sound at `;

  return (
    <section>
      <Popup
        className="share-popup"
        modal
        open={isSharePopupOpen}
        onOpen={() => {}}
        onClose={() => {
          setIsSharePopupOpen(false);
        }}
        nested
      >
        <div className="share-popup-heading">
          <h3>Share</h3>
          <img
            src={cross}
            alt=""
            onClick={() => {
              setIsSharePopupOpen(false);
            }}
          />
        </div>
        <div className="share-popup-container">
          <div className="share-popup-container__option">
            <FacebookShareButton url={shareURL} quote={shareQuote}>
              <FacebookIcon round={true} />
              Facebook
            </FacebookShareButton>
          </div>
          <div className="share-popup-container__option">
            <TelegramShareButton url={shareURL} title={shareQuote}>
              <TelegramIcon round={true} />
              Telegram
            </TelegramShareButton>
          </div>
          <div className="share-popup-container__option">
            <EmailShareButton url={shareURL} subject={shareQuote}>
              <EmailIcon round={true} />
              Email
            </EmailShareButton>
          </div>
          <div className="share-popup-container__option">
            <TwitterShareButton url={shareURL} title={shareQuote}>
              <TwitterIcon round={true} />
              Twitter
            </TwitterShareButton>
          </div>
          <div className="share-popup-container__option">
            <WhatsappShareButton url={shareURL} title={shareQuote}>
              <WhatsappIcon round={true} />
              Whatsapp
            </WhatsappShareButton>
          </div>
        </div>
      </Popup>
    </section>
  );
}

export default SharePopup;
