import image from "./../../images/oops.jpg";
import "./../stylesheets/notFound.css";
import { useNavigate } from "react-router-dom";
function ProductNotFound({ typeUpload }) {
  const navigate = useNavigate();
  const buttonClick = () => {
    navigate(
      `/user/uploads/${
        typeUpload === "Album"
          ? "albums"
          : typeUpload === "Song"
          ? "music"
          : "beats"
      }`
    );
  };
  return (
    <section className="error-section">
      <div className="error-div">
        <div className="error-image-div">
          <img alt="" src={image}></img>
        </div>
        <div className="product-not-found-description">
          Your song is uploaded, but the Selected isn't available. You haven't
          been charged. Click below to go to your profile. Thanks!
        </div>
        <div>
          <button className="save-changes" onClick={buttonClick}>
            Profile
          </button>
        </div>
      </div>
    </section>
  );
}
export default ProductNotFound;
