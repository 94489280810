import "./../stylesheets/editProfile.css";
import "./../../index.css";
import "./../../Fonts/fonts.css";
import Profile from "./profile";
import AboutMe from "./about-me";
import Social from "./social";
import Password from "./changePassword";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/authContext";
import { useNavigation } from "../../context/navigationContext";
import { useDispatch } from "react-redux";
import { fetchProfile } from "../../actions/authActions";
function EditProfile({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();

  const { user, isAuthenticated, token } = useAuthContext();
  const dispatch = useDispatch();
  useEffect(() => {
    const getUser = async () => {
      if (!isAuthenticated) {
        navigate("/login");
      }
    };

    getUser();
  }, [navigate, isAuthenticated]);

  const handleUpdateUser = async () => {
    await dispatch(fetchProfile(token));
  };

  const [selectedOption, setSelectedOption] = useState("profile");

  const handleSelector = (option) => {
    if (option === selectedOption) return;

    setSelectedOption(option);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNext = () => {
    if (selectedOption === "profile") {
      setSelectedOption("aboutMe");
    } else if (selectedOption === "aboutMe") {
      setSelectedOption("social");
    } else if (selectedOption === "social") {
      setSelectedOption("changePW");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePrevious = () => {
    if (selectedOption === "changePW") {
      setSelectedOption("social");
    } else if (selectedOption === "social") {
      setSelectedOption("aboutMe");
    } else if (selectedOption === "aboutMe") {
      setSelectedOption("profile");
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <section className="editProfile">
        <h3>Edit Profile</h3>
        <div className="edit-profile-main">
          <div className="options">
            <div className="options-div">
              <button
                className={`selector ${
                  selectedOption === "profile" ? "selected-button" : ""
                }`}
                onClick={() => handleSelector("profile")}
              >
                Profile
              </button>
              <button
                className={`selector ${
                  selectedOption === "aboutMe" ? "selected-button" : ""
                }`}
                onClick={() => handleSelector("aboutMe")}
              >
                About Me
              </button>
              <button
                className={`selector ${
                  selectedOption === "social" ? "selected-button" : ""
                }`}
                onClick={() => handleSelector("social")}
              >
                Social Media
              </button>
              <button
                className={`selector ${
                  selectedOption === "changePW" ? "selected-button" : ""
                }`}
                onClick={() => handleSelector("changePW")}
              >
                Password
              </button>
            </div>
          </div>

          {user && user.id && (
            <>
              {selectedOption === "profile" && (
                <Profile
                  user={user}
                  handleNext={handleNext}
                  handleUpdateUser={handleUpdateUser}
                ></Profile>
              )}
              {selectedOption === "aboutMe" && (
                <AboutMe
                  user={user}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  handleUpdateUser={handleUpdateUser}
                  setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
                ></AboutMe>
              )}
              {selectedOption === "social" && (
                <Social
                  user={user}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  handleUpdateUser={handleUpdateUser}
                ></Social>
              )}
              {selectedOption === "changePW" && (
                <Password handlePrevious={handlePrevious}></Password>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default EditProfile;
