import "./../stylesheets/UploadProfile.css";
import UploadCloud from "./../../images/upload-cloud.png";
import Artwork from "./../../images/artwork-thumbnail.png";
import Tick from "./../../images/thumbnail-tick.png";
import Remove from "./../../images/remove-thumbnail.png";
import Warning from "./../../images/warning.svg";
import * as Sentry from "@sentry/react";
import downBtn from "./../../images/down-button-dark.png";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useNavigation } from "../../context/navigationContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import api from "../../utils/api";
import cross from "./../../images/cross-black.svg";

function UploadProfile({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { user } = useAuthContext();

  const [selectedUploadFile, setSelectedUploadFile] = useState(null);
  const [popupSkills, setPopupSkills] = useState([]);
  const [about, setAbout] = useState("");

  const handleRemove = (event) => {
    document.querySelector(".image-error").classList.remove("hidden");
    document.querySelector(".artwork-thumbnail").src = null;
    setSelectedUploadFile(null);
  };

  function handleTextChange(event) {
    setAbout(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;

    if (
      event.target.value.length < 40 ||
      event.target.value.length > 100 ||
      !regex.test(event.target.value)
    )
      document.querySelector(".about-error").classList.remove("hidden");
    else document.querySelector(".about-error").classList.add("hidden");
  }

  function checkSkillChecked(obj) {
    for (let key in obj) {
      const skill = obj[key];
      if (
        skill &&
        typeof skill === "object" &&
        skill.hasOwnProperty("checked") &&
        skill.checked
      )
        return true;
    }

    return false;
  }

  function checkValues() {
    let valid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (!selectedUploadFile) {
      document.querySelector(".image-error").classList.remove("hidden");
      valid = false;
    }
    if (!checkSkillChecked(popupSkills)) {
      document.querySelector(".skill-error").classList.remove("hidden");
      valid = false;
    }
    if (about.length < 40 || about.length > 100 || !regex.test(about)) {
      document.querySelector(".about-error").classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkValues()) return;

    if (selectedUploadFile !== null) {
      try {
        const response = await api.post(`profile/avatar`, {
          avatar: selectedUploadFile.name,
        });
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        var img = document.querySelector(".artwork-thumbnail");
        const res = await fetch(img.src);
        const blob = await res.blob();
        const imageUpload = await fetch(data.avatar, {
          method: "PUT",
          body: blob,
        });
        if (imageUpload.status === 200) {
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
    const services = [];
    popupSkills.forEach((skill) => {
      if (skill.checked === true) {
        services.push(skill.id);
      }
    });

    const about = document.querySelector(".upload-custom-textarea").value;

    try {
      const response = await api.put(`profile`, {
        about: about,
        genres: [],
        services: services,
      });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      if (data.data) {
        window.location.href = "/user";
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  const handleUploadFileChange = async (event) => {
    document.querySelector(".image-error").classList.add("hidden");
    setSelectedUploadFile(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".artwork-thumbnail");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };

  const handleCheckboxChange = (id) => {
    setPopupSkills((prevSkills) =>
      prevSkills.map((skill) =>
        skill.id === id ? { ...skill, checked: !skill.checked } : skill
      )
    );

    setPopupSkills((updatedSkills) => {
      if (checkSkillChecked(updatedSkills)) {
        document.querySelector(".skill-error").classList.add("hidden");
      } else {
        document.querySelector(".skill-error").classList.remove("hidden");
      }
      return updatedSkills;
    });
  };

  useEffect(() => {
    const handleLoad = async () => {
      if (!user) {
        navigate("/login");
        return;
      }
      try {
        const response = await api.get(`services`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        setPopupSkills(data.data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    handleLoad();
  }, [user, navigate, setShowUnauthenticatedPopup]);
  return (
    <div>
      <section className="upload-profile">
        {user && (
          <p className="email-notification">
            We have sent an email to{" "}
            <span className="email__span">{user.email}</span> with a link to
            verify your email.
          </p>
        )}
        {user && (
          <h1 className="welcome-user">
            Welcome <span className="username__span">{user.name}</span>
          </h1>
        )}

        <p className="complete-notification">
          Complete Your Profile And Keep It Updated To Help Us Connect You With
          The Right People.
        </p>
        <form>
          <div className="upload-profile_input">
            <label className="upload-beats_label">
              Profile Picture
              <Popup
                className="upload-error-popup"
                trigger={
                  <img
                    src={Warning}
                    alt=""
                    className="image-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                Please provide an image!
              </Popup>
            </label>
            <div>
              {!selectedUploadFile && (
                <label
                  htmlFor="file-upload-streaming"
                  className={`user-file-upload-label ${
                    selectedUploadFile ? "" : "upload-animation"
                  }`}
                >
                  <img src={UploadCloud} alt="" />
                  Drag and drop picture here <br /> Or
                  <p className="upload-picture">Upload Picture</p>
                </label>
              )}
              {!selectedUploadFile && (
                <input
                  type="file"
                  id="file-upload-streaming"
                  accept="image/png, image/jpeg, image/gif, image/svg"
                  onChange={handleUploadFileChange}
                />
              )}
              {selectedUploadFile && (
                <p className="profile-selected-file upload-animation">
                  <img src={Artwork} alt="" className="artwork-thumbnail" />
                </p>
              )}
              {selectedUploadFile && (
                <div className="imageName-delete user-delete upload-animation">
                  <img src={Tick} alt="" className="tick" />
                  <span className="img_file-name">
                    {selectedUploadFile.name}
                  </span>
                  <img src={Remove} alt="" onClick={handleRemove} />
                </div>
              )}
            </div>

            <label className="upload-beats_label">
              Your Skills
              <Popup
                className="upload-error-popup"
                trigger={
                  <img
                    src={Warning}
                    alt=""
                    className="skill-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                Please select at least 1 skill!
              </Popup>
            </label>
            <Popup
              className="genre-dropdown genre-dropdown-dark"
              trigger={
                <div className="form-beats-input form-option">
                  {!checkSkillChecked(popupSkills) && <>Select Skills</>}
                  {checkSkillChecked(popupSkills) && (
                    <>
                      {popupSkills
                        .filter((genre) => genre.checked)
                        ?.slice(0, 7)
                        .map((genre) => (
                          <button
                            className="genre-remove-button"
                            key={genre.name}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              handleCheckboxChange(
                                popupSkills.find((g) => g.id === genre.id)?.id
                              );
                            }}
                          >
                            <img src={cross} alt="remove" />
                            {genre.name}
                          </button>
                        ))}
                    </>
                  )}
                  <img
                    src={downBtn}
                    // style={
                    //   popupStates.collaborator
                    //     ? { transform: "rotate(180deg)" }
                    //     : { transform: "none" }
                    // }
                    alt=""
                    className="upload-beat-genre-dropdown-button"
                  />
                </div>
              }
              position="bottom center"
              arrow={false}
            >
              {popupSkills.map((skill) => {
                return (
                  <div
                    className="service-item"
                    data-id={skill.id}
                    key={skill.id}
                  >
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={skill.checked || false}
                        onChange={() => handleCheckboxChange(skill.id)}
                      />
                      {skill.name}
                    </label>
                  </div>
                );
              })}
            </Popup>

            <label className="upload-beats_label">
              About You
              <Popup
                className="upload-error-popup"
                trigger={
                  <img
                    src={Warning}
                    alt=""
                    className="about-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                The number of characters must be between 40 and 100!
              </Popup>
            </label>
            <textarea
              className=" upload-custom-textarea upload-beats-input "
              placeholder="write here, minimum character allowed is 40"
              onChange={(event) => handleTextChange(event)}
            ></textarea>

            <button className="save-profile" onClick={handleSubmit}>
              Save Profile
            </button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default UploadProfile;
