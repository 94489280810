// reducers/currencyReducers.js
import {
  SET_SELECTED_CURRENCY,
  SET_CURRENCY_RATES,
  FETCH_CURRENCY_RATES_REQUEST,
  FETCH_CURRENCY_RATES_SUCCESS,
  FETCH_CURRENCY_RATES_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  selectedCurrency: "GBP", // Initial selected currency
  rates: {},
  expiration: null,
  isLoading: false,
  error: null,
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload,
      };
    case SET_CURRENCY_RATES:
      return {
        ...state,
        rates: parseFloat(action.payload.rates?.replace(/,/g, "")),
        expiration: action.payload.expiration,
      };
    case FETCH_CURRENCY_RATES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_CURRENCY_RATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rates: action.payload.rates,
        expiration: action.payload.expiration,
      };
    case FETCH_CURRENCY_RATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default currencyReducer;
