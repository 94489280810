import "./../stylesheets/withdraw.css";
import Warning from "./../../images/warning.svg";
import paystackLogo from "./../../images/paystack-2.svg";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import Popup from "reactjs-popup";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function WithdrawPaystack({ setShowUnauthenticatedPopup }) {
  const Id = useParams();
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const [saveChanges, setSaveChanges] = useState(false);

  const [withdrawalAmountInput, setWithdrawalAmountInput] = useState("N");

  useEffect(() => {
    const handleLoad = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }
    };
    handleLoad();
  }, [navigate, isAuthenticated]);

  const handleWithdrawAmountInputChange = (event) => {
    setWithdrawalAmountInput(formatCurrency(event.target.value));

    if (
      event.target.value.length <= 1 ||
      parseInt(event.target.value.slice(1)) < 10
    ) {
      document.querySelector(".amount-error").classList.remove("hidden");
    } else {
      document.querySelector(".amount-error").classList.add("hidden");
    }
  };

  function formatCurrency(value) {
    value = value.replace(/[^0-9]/g, "");
    value = `N${value}`;

    return value;
  }

  const [contractErrorMessage, setContractErrorMessage] = useState("");

  const handleSubmit = async () => {
    document.querySelector(".contract-error").classList.add("hidden");

    if (isAuthenticated) {
      setSaveChanges(true);
      const body = {
        payment_account: Id.id,
        amount: withdrawalAmountInput.slice(1) * 100,
      };

      try {
        const response = await api.post(`withdraw/create`, body);

        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        if (response.status === 422) {
          const data = await response.json();
          const error = data.error;
          setContractErrorMessage(error);
          document.querySelector(".contract-error").classList.remove("hidden");
          setSaveChanges(false);
        }

        if (data.success) {
          navigate("/wallet");
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };
  return (
    <section className="withdraw-section">
      <div className="withdraw-top-heading">
        <h3>Withdraw Funds</h3>
      </div>
      <div className="withdraw-main">
        <div className="withdraw-main-heading">
          <h3>Paystack</h3>
          <img alt="" src={paystackLogo}></img>
        </div>

        <div className="withdraw-form">
          <div className="withdrawal-amount">
            <p>Withdrawal Amount</p>
            <input
              placeholder="Amount"
              value={withdrawalAmountInput}
              onChange={(event) => {
                handleWithdrawAmountInputChange(event);
              }}
            />
            <Popup
              className="login-error-popup"
              trigger={
                <img src={Warning} alt="" className="amount-error hidden" />
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              You cannot withdraw less than K10!
            </Popup>
            <Popup
              className="login-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="contract-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {contractErrorMessage}
            </Popup>
          </div>

          <div className="withdraw-button">
            <button
              onClick={handleSubmit}
              className="withdraw-button-click"
              disabled={
                withdrawalAmountInput.length <= 1 ||
                parseInt(withdrawalAmountInput.slice(1)) < 10
              }
            >
              {saveChanges && (
                <Bars
                  height="40"
                  width="40"
                  color="#fff"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass="loading-bars"
                  visible={saveChanges}
                />
              )}
              {!saveChanges && "Withdraw Funds"}
            </button>
          </div>
        </div>
        <div className="withdraw-guidelines">
          <div className="border-div"></div>
          <div>
            <h3>
              Minimum: <span>N10</span>
            </h3>
            <h3>
              Withdrawal Fees: <span>No Fees</span>
            </h3>

            <h3>
              Withdrawal Time: <span>Up To 24 Hours</span>
            </h3>

            <h3>Processing Information: </h3>
            <p>
              Transaction Processing Fees Depend On Your Corresponding Bank.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default WithdrawPaystack;
