// currencyContext.js

import React, { createContext, useContext, useEffect, useReducer } from "react";
import currencyReducer from "../reducers/currencyReducers";
import {
  fetchCurrencyRates,
  setSelectedCurrency,
} from "../actions/currencyActions";

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const initialState = {
    selectedCurrency: localStorage.getItem("selectedCurrency") || "GBP",
    rates: JSON.parse(localStorage.getItem("currencyRates")) || {},
    expiration: localStorage.getItem("currencyExpiration") || null,
    isLoading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(currencyReducer, initialState);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const data = await fetchCurrencyRates()(dispatch);
        const { rates, expiration } = data;

        const tempRates = rates.replace(/,/g, "");

        localStorage.setItem(
          "currencyRates",
          JSON.stringify(parseFloat(tempRates))
        );
        localStorage.setItem("currencyExpiration", expiration);
      } catch (error) {
        console.error("Failed to fetch currency rates:", error);
      }
    };

    const storedExpiration = localStorage.getItem("currencyExpiration");
    const currentTime = new Date().getTime();
    if (
      !storedExpiration ||
      currentTime > new Date(storedExpiration).getTime()
    ) {
      fetchInitialData();
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("selectedCurrency", state.selectedCurrency);
  }, [state.selectedCurrency]);

  const selectCurrency = (currency) => {
    dispatch(setSelectedCurrency(currency));
  };
  const roundAndFormat = (number) => {
    const rounded = Math.ceil(number);
    return rounded;
  };

  const formatCurrency = (amount) => {
    const { selectedCurrency } = state;
    const rates = parseFloat(JSON.parse(localStorage.getItem("currencyRates")));
    const isNGN = selectedCurrency === "NGN";
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: selectedCurrency,
      minimumFractionDigits: isNGN ? 0 : 2,
      maximumFractionDigits: isNGN ? 0 : 2,
    });

    if (selectedCurrency === "GBP") {
      return formatter.format(amount / 100);
    } else {
      const rate = rates || 1;
      const convertedAmount = (amount / 100) * rate;
      const roundedAmount = roundAndFormat(convertedAmount);
      return formatter.format(roundedAmount).replace("NGN", "₦");
    }
  };
  const convertRate = (amount) => {
    const { selectedCurrency } = state;
    const rates = parseFloat(JSON.parse(localStorage.getItem("currencyRates")));

    const rate = rates || 1;
    if (selectedCurrency === "GBP") {
      return amount;
    } else {
      return parseFloat((amount / rate).toFixed(3));
    }
  };
  return (
    <CurrencyContext.Provider
      value={{
        ...state,
        selectCurrency,
        formatCurrency,
        convertRate,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrencyContext = () => useContext(CurrencyContext);
