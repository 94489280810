import "./../stylesheets/withdraw.css";
import paypal from "./../../images/paypal.png";
import Warning from "./../../images/warning.svg";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import * as Sentry from "@sentry/react";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function Withdraw() {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const method = useParams();

  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");

  const [addBankError, setAddBankError] = useState("");

  useEffect(() => {
    const handleLoad = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }
    };
    handleLoad();
  }, [navigate, isAuthenticated]);

  const handleNameChange = (event) => {
    setName(formatName(event.target.value));

    if (event.target.value.length <= 3) {
      document.querySelector(".name-error").classList.remove("hidden");
    } else {
      document.querySelector(".name-error").classList.add("hidden");
    }
  };
  const checkName = (name) => {
    if (name.length <= 3) {
      return false;
    } else {
      return true;
    }
  };
  const checkRegion = (region) => {
    if (region < 1) {
      return false;
    }
    return true;
  };
  const checkAccountNumber = (accountNumber) => {
    if (accountNumber.length <= 6) {
      return false;
    }
    return true;
  };
  const checkBank = (bank) => {
    if (bank.length < 1) {
      return false;
    }
    return true;
  };
  function formatName(value) {
    value = value.replace(/^\s+/, "");
    value = value.replace(/[^a-zA-Z\s]/g, "");
    value = value.replace(/\b\w/g, (match) => match.toUpperCase());

    return value;
  }

  const handleRegionChange = (event) => {
    setRegion(formatName(event.target.value));

    if (event.target.value.length < 1) {
      document.querySelector(".region-error").classList.remove("hidden");
    } else {
      document.querySelector(".region-error").classList.add("hidden");
    }
  };

  const handleAccountNumberChange = (event) => {
    setAccountNumber(formatAccountNumber(event.target.value));

    if (event.target.value.length <= 6) {
      document.querySelector(".account-error").classList.remove("hidden");
    } else {
      document.querySelector(".account-error").classList.add("hidden");
    }
  };

  function formatAccountNumber(value) {
    value = value.replace(/[a-zA-Z]/g, (match) => match.toUpperCase());
    value = value.replace(/[^a-zA-Z0-9]/g, "");

    return value;
  }

  const handleBankInputChange = (event) => {
    setBank(formatName(event.target.value));

    if (event.target.value.length < 1) {
      document.querySelector(".bank-error").classList.remove("hidden");
    } else {
      document.querySelector(".bank-error").classList.add("hidden");
    }
  };
  useEffect(() => {
    if (
      checkName(name) &&
      checkAccountNumber(accountNumber) &&
      checkRegion(region) &&
      checkBank(bank)
    ) {
      document
        .querySelector(".withdraw-button-click")
        .classList.remove("button-disabled");
    } else {
      document
        .querySelector(".withdraw-button-click")
        .classList.add("button-disabled");
    }
  }, [name, accountNumber, region, bank]);
  const handleSubmit = async () => {
    document.querySelector(".add-bank-error").classList.add("hidden");

    if (!isAuthenticated) {
      navigate("/login");
    }

    const body = {
      payment_method: "bank_transfer",
      acc_name: name,
      region: region,
      bank_name: bank,
      acc_num: accountNumber,
    };

    try {
      const response = await api.post(`transfer-methods/add`, body);

      handleResponseStatus(response, navigate);
      const data = response.data;
      if (response.status === 200 || response.status === 201) {
        navigate("/accounts");
      } else {
        setAddBankError(data.error.replace("_", " "));
        document.querySelector(".add-bank-error").classList.remove("hidden");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <section className="withdraw-section">
      <div className="withdraw-top-heading">
        <h3>
          Add Bank Account
          <Popup
            className="login-error-popup"
            trigger={
              <img src={Warning} alt="" className="add-bank-error hidden"></img>
            }
            on="hover"
            position="top center"
            arrow={false}
          >
            {addBankError}
          </Popup>
        </h3>
      </div>
      <div className="withdraw-main">
        {method.method === "paypal" && (
          <div className="withdraw-main-heading">
            <h3>Paypal</h3>
            <img alt="" src={paypal}></img>
          </div>
        )}
        {method.method === "bank" && (
          <div className="withdraw-main-heading">
            <h3>Bank Transfer</h3>
            <img alt="" src={bank}></img>
          </div>
        )}
        <div className="withdraw-form">
          <div className="withdrawal-name">
            <p>Bank Account Holder Name</p>
            <input
              placeholder=""
              className="withdrawal-name-input"
              onChange={handleNameChange}
              value={name}
            />
            <Popup
              className="login-error-popup"
              trigger={
                <img src={Warning} alt="" className="name-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Invalid Name Entered
            </Popup>
          </div>
          <div className="withdrawal-country">
            <p>Country or Region</p>
            <input
              placeholder=""
              className="withdrawal-country-input"
              onChange={handleRegionChange}
              value={region}
            />
            <Popup
              className="login-error-popup"
              trigger={
                <img src={Warning} alt="" className="region-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Field cannot be empty!
            </Popup>
          </div>
          <div className="withdrawal-account">
            <p>NUBAN / Bank Account Number</p>
            <input
              placeholder=""
              className="withdrawal-account-input"
              onChange={handleAccountNumberChange}
              value={accountNumber}
            />
            <Popup
              className="login-error-popup"
              trigger={
                <img
                  src={Warning}
                  alt=""
                  className="account-error hidden"
                ></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Invalid Account Number Entered
            </Popup>
          </div>
          <div className="withdrawal-bank">
            <p>Bank Name</p>
            <input
              placeholder=""
              className="withdrawal-bank-input"
              onChange={handleBankInputChange}
              value={bank}
            />
            <Popup
              className="login-error-popup"
              trigger={
                <img src={Warning} alt="" className="bank-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Invalid Bank Entered
            </Popup>
          </div>
          <div className="withdraw-button ">
            <button onClick={handleSubmit} className="withdraw-button-click">
              Add Account
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Withdraw;
