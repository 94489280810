import "./../stylesheets/loading.css";
import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
function Cancel({ setUserLogin, message, setShowUnauthenticatedPopup }) {
  const [Message, setMessage] = useState(null);
  const { navigate } = useNavigation();

  useEffect(() => {
    setMessage(message);
  }, [message]);

  useEffect(() => {
    const handleLoad = async () => {
      const type = window.location.href.split("cancel/")[1].split("?token")[0];
      if (type === "product") {
        navigate("/user");
        return;
      }
      if (localStorage.getItem(`${type}_vendor_id`)) {
        const vendor_id = localStorage.getItem(`${type}_vendor_id`);
        if (type === "commission") {
          const id = localStorage.getItem("commission_id");

          try {
            const response = await api.post(
              `commissions/${id}/cancel-payment`,
              { id: vendor_id }
            );

            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            if (data.success === true) {
              localStorage.removeItem(`${type}_vendor_id`);
              localStorage.removeItem("commission_id");
              navigate("/messages");
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (type === "deposit") {
          try {
            const response = await api.post(`wallet/cancel`, { id: vendor_id });

            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            if (data.success === true) {
              localStorage.removeItem(`${type}_vendor_id`);
              navigate("/wallet");
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (type === "withdraw") {
          localStorage.removeItem(`${type}_vendor_id`);
          navigate("/accounts");
        } else if (type === "purchase") {
          try {
            const response = await api.post(`purchase/cancel`, {
              id: vendor_id,
            });

            handleResponseStatus(
              response,
              navigate,
              setShowUnauthenticatedPopup
            );
            const data = response.data;
            if (data.success === true) {
              localStorage.removeItem(`${type}_vendor_id`);
              navigate("/user");
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (type === "product") {
          navigate("/user");
        }
      } else {
        navigate("/");
      }
    };
    handleLoad();
  }, [navigate, setUserLogin, setShowUnauthenticatedPopup]);
  return (
    <section className="loading-section">
      <div className="loading-div">
        <TailSpin
          height="80"
          width="80"
          color="#FE2B0D"
          ariaLabel="tail-spin-loading"
          visible={true}
          wrapperClass="loading"
          className="loading"
        />

        <div className="loading-description">
          <h3>{Message}</h3>
        </div>
      </div>
    </section>
  );
}
export default Cancel;
