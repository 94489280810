import "./../stylesheets/setup.css";
import "./../stylesheets/login.css";
import Google from "./../../images/google-logo.png";

import Warning from "./../../images/warning.svg";
import Popup from "reactjs-popup";
import { Link, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import React, { useRef, useEffect } from "react";
import useLogin from "./useLogin";
import { useLocation } from "react-router-dom";
import api from "../../utils/api";
// import * as Sentry from "@sentry/react";
function Login() {
  const {
    email,
    setEmail,
    setPassword,
    handleLogin,
    showPassword,
    setShowPassword,
    saveChanges,
    showEmail,
    setShowEmail,
    handleGoogle,
    setSaveChanges,
  } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event) => {
    setSaveChanges(true);
    event.preventDefault();
    const response = await handleLogin();
    if (response.status) {
      if (response.status !== 200) {
        document.querySelector(".password-error").classList.remove("hidden");
        setSaveChanges(false);
        passwordRef.current.focus();
      } else {
        const queryParams = new URLSearchParams(location.search);
        const redirectUrl = queryParams.get("redirect_url");
        await HandleCartTransfer();

        if (redirectUrl) {
          navigate(`/${redirectUrl}`);
        } else {
          navigate("/");
        }
        //implement cart functionality for login
      }
    }
    setSaveChanges(false);
  };

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (showEmail) {
      emailRef.current.focus();
    }
  }, [showEmail]);

  useEffect(() => {
    if (showPassword) {
      passwordRef.current.focus();
    }
  }, [showPassword]);

  function handleContinueButtonClick(event) {
    event.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(email)) {
      setShowEmail(false);
      setShowPassword(true);
    } else {
      document.querySelector(".email-error").classList.remove("hidden");
    }
  }

  function handleEmailInputChange(event) {
    setEmail(event.target.value);
    document.querySelector(".email-error").classList.add("hidden");
  }
  const HandleCartTransfer = async () => {
    if (localStorage.getItem("cart")) {
      const cart = JSON.parse(localStorage.getItem("cart")).data;
      try {
        cart.forEach(async (element) => {
          let body = {};
          if (!element.price) {
            body[element.type] = element.item.slug;
          } else {
            body[element.type] = element.item.slug;
            body.price = element.price;
          }

          const response = await api.post("cart/add", body);
          if (response.status === 429) {
            navigate("/error/requests");
          } else if (response.status === 500) {
            navigate("serverError");
          }
        });
      } catch (error) {}
      localStorage.removeItem("cart");
    }
  };
  // const handleSigninButtonClick = async function (event) {
  //   event.preventDefault();
  //   setSaveChanges(true);
  //   try {
  //     const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //       body: JSON.stringify({
  //         email,
  //         password,
  //       }),
  //     });

  //     const data = await res.json();
  //     if (res.status === 429) {
  //       navigate("/error/requests");
  //     } else if (res.status === 500) {
  //       navigate("serverError");
  //     } else if (res.status !== 200) {
  //       document.querySelector(".password-error").classList.remove("hidden");
  //       setSaveChanges(false);
  //       passwordRef.current.focus();
  //     } else {
  //       data.data.expires_in = data.data.expires_in * 1000 + Date.now();
  //       const access_token = data.data.access_token;
  //       localStorage.setItem("access_token", JSON.stringify(data.data));
  //       window.dispatchEvent(new Event("storage"));
  //       // await HandleCartTransfer(access_token);
  //       setUserLogin(true);
  //       if (localStorage.getItem("previous")) {
  //         const previous = JSON.parse(
  //           localStorage.getItem("previous")
  //         ).location;
  //         if (previous === "/login" || previous === "/signup") {
  //           navigate("/");
  //         } else {
  //           localStorage.setItem("keep_signed_in", keepSignedIn);
  //           navigate(previous);
  //           setSaveChanges(false);
  //         }
  //       } else {
  //         navigate("/user");
  //         setSaveChanges(false);
  //       }
  //     }
  //   } catch (err) {
  //     Sentry.captureException(err);
  //   }
  // };

  function handlePasswordInputChange(event) {
    setPassword(event.target.value);
    document.querySelector(".password-error").classList.add("hidden");
  }

  return (
    <>
      <section className="section__login">
        <div className="login__container">
          <h2 className="login__heading">Login to your account</h2>
          {showEmail && (
            <form className="login__form">
              <div className="login__group">
                <label htmlFor="Email">Email</label>
                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="Email"
                    id="Email"
                    placeholder="Email"
                    type="text"
                    ref={emailRef}
                    onChange={(event) => handleEmailInputChange(event)}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="email-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Invalid Email Format
                  </Popup>
                </div>
              </div>
              <button
                className="btn__primary"
                onClick={handleContinueButtonClick}
              >
                Continue
              </button>
            </form>
          )}
          {showPassword && (
            <form className="login__form">
              <div className="login__group">
                <label htmlFor="password">Password</label>
                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="password"
                    id="password"
                    placeholder="Password"
                    type="password"
                    ref={passwordRef}
                    disabled={saveChanges}
                    onChange={(event) => handlePasswordInputChange(event)}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="password-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Incorrect Email/Password!
                  </Popup>
                </div>
              </div>
              <div className="forgot__password">
                Forgot your password?{" "}
                <Link to="/forgotPassword">Reset it here</Link>
              </div>

              <label className="signup__checkbox--container">
                <div className="agree-checkbox-container">
                  <input
                    className="agree-checkbox"
                    type="checkbox"
                    onChange={(event) => {
                      // setKeepSignedIn(event.target.checked);
                    }}
                  />
                </div>
                <span>Keep me signed in</span>
              </label>

              <button
                className="btn__primary"
                onClick={handleSubmit}
                disabled={saveChanges}
              >
                {saveChanges && (
                  <Bars
                    height="40"
                    width="40"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-bars"
                    visible={saveChanges}
                  />
                )}
                {!saveChanges && "Sign In"}
              </button>
            </form>
          )}
          <div className="buttons">
            <button className="btn__signins" onClick={handleGoogle}>
              <img src={Google} className="navbar-logo" alt="Throwdown logo" />
              <p>Sign in with Google</p>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
