import "./stylesheets/checkout.css";
import paypal from "./../images/paypal.png";
import { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import paystackLogo from "./../images/paystack-2.svg";
import * as Sentry from "@sentry/react";
import Popup from "reactjs-popup";
import tool from "./../images/tool-tip.svg";
import handleResponseStatus from "../utils/handleResponseStatus";
import { useNavigation } from "../context/navigationContext";
import { useAuthContext } from "../context/authContext";
import { useCurrencyContext } from "../context/currencyContext";
import api from "../utils/api";
function Checkout({ setShowUnauthenticatedPopup }) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [saveChanges, setSaveChanges] = useState(false);
  const [serviceFee, setServiceFee] = useState(0);

  const { formatCurrency } = useCurrencyContext();

  useEffect(() => {
    const handleLoad = async () => {
      if (isAuthenticated) {
        try {
          const response = await api.get(`cart/summary`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
          const data = response.data;
          setCart(data.data);
          if (data.data.length === 0) {
            navigate("/");
          }
          let service = 0;
          if (data.price !== 0) {
            service = data.price * 0.036 + 30;
          }
          setServiceFee(service);
          setTotalPrice(data.price + service);
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        // navigate("/login");
      }
    };
    handleLoad();
  }, [navigate, isAuthenticated, setShowUnauthenticatedPopup]);

  const [selectedDepositMethod, setSelectedDepositMethod] = useState();

  const handlePaypalButton = async () => {
    setSelectedDepositMethod("Paypal");
    setSaveChanges(true);
    try {
      const response = await api.post(`purchase/create`, {
        payment_method: "paypal",
      });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      if (data.vendor_order_id) {
        window.localStorage.setItem("purchase_vendor_id", data.vendor_order_id);
        window.location.href = data.link;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handlePaystackButton = async () => {
    setSaveChanges(true);
    try {
      const response = await api.post(`purchase/create`, {
        payment_method: "paystack",
      });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      if (data.vendor_order_id) {
        window.localStorage.setItem("purchase_vendor_id", data.vendor_order_id);
        window.location.href = data.link;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <section className="checkout-section">
      {cart && (
        <div className="checkout-summary">
          <h3 className="checkout-heading">Summary Order</h3>
          <div className="checkout-summary-items">
            {cart &&
              cart.map((item) => {
                return (
                  <div className="checkout-summary-item" key={item.id}>
                    <div className="checkout-summary-item-div">
                      {item.beat && (
                        <img
                          alt=""
                          className="checkout-summary-item-image"
                          src={item.beat.artwork_url}
                        ></img>
                      )}
                      {item.song && (
                        <img
                          alt=""
                          className="checkout-summary-item-image"
                          src={item.song.cover_path}
                        ></img>
                      )}
                      {item.album && (
                        <img
                          alt=""
                          className="checkout-summary-item-image"
                          src={item.album.cover_path}
                        ></img>
                      )}
                      <div className="checkout-summary-item-details">
                        {item.beat && (
                          <h3 className="checkout-summary-item-name">
                            {item.beat?.name}
                          </h3>
                        )}
                        {item.song && (
                          <h3 className="checkout-summary-item-name">
                            {item.song?.name}
                          </h3>
                        )}
                        {item.album && (
                          <h3 className="checkout-summary-item-name">
                            {item.album?.name}
                          </h3>
                        )}
                        {item.beat && item.beat.producer && (
                          <h3 className="checkout-summary-item-artist">
                            {item.beat.producer.name}
                          </h3>
                        )}
                        {item.song && item.song.artist && (
                          <h3 className="checkout-summary-item-artist">
                            {item.song.artist.name}
                          </h3>
                        )}
                        {item.album && item.album.artist && (
                          <h3 className="checkout-summary-item-artist">
                            {item.album.artist.name}
                          </h3>
                        )}
                      </div>
                    </div>
                    {
                      <h3 className="checkout-summary-item-price">
                        {item?.price ? formatCurrency(item.price) : "Free"}
                      </h3>
                    }
                  </div>
                );
              })}
          </div>
          <div className="checkout-summary-item-total-div">
            <Popup
              className="upload-error-popup service-fee-text"
              trigger={<img src={tool} alt="" />}
              position="top left"
              on="hover"
            >
              Service Fee: This small fee helps us maintain our platform and
              ensure a seamless experience. Thank you for supporting us!
            </Popup>
            <h3 className="checkout-summary-item-total-heading">Service Fee</h3>
            <h3 className="checkout-summary-item-total-price">
              {formatCurrency(serviceFee)}
            </h3>
          </div>
          <div className="checkout-summary-item-total-div">
            <h3 className="checkout-summary-item-total-heading">Order Total</h3>
            <h3 className="checkout-summary-item-total-price">
              {formatCurrency(totalPrice)}
            </h3>
          </div>
        </div>
      )}
      <div className="checkout-form">
        <h3 className="checkout-heading">Payment Details</h3>
        <div className="checkout-form-complete">
          <div className="checkout-form-paypal">
            <p>Checkout with</p>
            <button className="paypal-button" onClick={handlePaypalButton}>
              {saveChanges && selectedDepositMethod === "Paypal" && (
                <Bars
                  height="40"
                  width="40"
                  color="#012169"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass="loading-bars"
                  visible={saveChanges}
                />
              )}
              {!(saveChanges && selectedDepositMethod === "Paypal") && (
                <img src={paypal} alt="" />
              )}
            </button>
            <button className="paypal-button" onClick={handlePaystackButton}>
              {saveChanges && selectedDepositMethod === "Paystack" && (
                <Bars
                  height="40"
                  width="40"
                  color="#012169"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass="loading-bars"
                  visible={saveChanges}
                />
              )}
              {!(saveChanges && selectedDepositMethod === "Paystack") && (
                <img src={paystackLogo} alt="" />
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Checkout;
