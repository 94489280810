import "./../stylesheets/beat.css";
import like from "./../../images/beat-like.svg";
import like_filled from "./../../images/like-filled.svg";
import * as Sentry from "@sentry/react";
import share from "./../../images/beat-share.svg";
import comment from "./../../images/beat-comment.svg";
// import playlist from "./../../images/beat-playlist.svg";
import cart from "./../../images/cart.svg";
import AlbumDescription from "./albumDescription";
import AlbumComments from "./albumComments";
import Popup from "reactjs-popup";
// import AddToPlaylistPopup from "./../addToPlaylistPopup";
import SharePopup from "./../sharePopup";
import BuyPopup from "./../buyPopup";
import ImgLoader from "././../imgLoader";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import { usePlayerContext } from "../../context/playerContext";
function Album({
  setShowUnauthenticatedPopup,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
}) {
  const { navigate, navigateToArtists, navigateToComments, navigateToAlbum } =
    useNavigation();
  const { formatCurrency } = useCurrencyContext();
  const { user, isAuthenticated } = useAuthContext();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  // const [isAddToPlaylistPopupOpen, setIsAddToPlaylistPopupOpen] =
  //   useState(false);
  // const [addPopupBeat, setAddPopupBeat] = useState();

  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

  const [isLiked, setIsLiked] = useState(false);
  const [description, setDescription] = useState(true);
  const [comments, setComments] = useState(false);
  const [album, setAlbum] = useState({});

  const Id = useParams();
  const handleProducerClick = (event) => {
    const id = event.target.dataset.id;
    navigateToArtists(id);
  };

  const { playerState } = usePlayerContext();

  useEffect(() => {
    const loadLikes = async () => {
      if (user) {
        try {
          const ids = user.liked_albums;
          const id_int = parseInt(Id.id, 10);

          if (ids.includes(id_int)) {
            document.querySelector(".extra-like-image").src = like_filled;
            setIsLiked(true);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };
    loadLikes();
  }, [Id.id, user]);

  useEffect(() => {
    const handleLoad = async () => {
      try {
        const response = await api.get(`albums/${Id.id}`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;

        setAlbum(data.data);
        if (window.location.pathname.split("/")[3] === "comments") {
          setComments(true);
          setDescription(false);

          document.querySelector(".active").classList.remove("active");
          document
            .querySelector(".beat-section-comments")
            .classList.add("active");
        } else {
          setDescription(true);
          setComments(false);

          document.querySelector(".active").classList.remove("active");
          document
            .querySelector(".beat-section-description-button")
            .classList.add("active");
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    handleLoad();
  }, [Id.id, navigate, setShowUnauthenticatedPopup]);

  const handleLike = async (event) => {
    if (isAuthenticated) {
      if (isLiked) {
        document.querySelector(".extra-like-image").src = like;
        setIsLiked(false);
        setPopupMessage("Album unliked!");
        setShowMessagePopup(true);
        const quantity = document.querySelector(".like-quantity");
        quantity.innerHTML = quantity.innerHTML - 1;

        try {
          const response = await api.delete(`albums/${Id.id}/unlike`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        try {
          const response = await api.post(`albums/${Id.id}/like`);
          handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);

          const data = response.data;
          if (data.message === "OK") {
            document.querySelector(".extra-like-image").src = like_filled;
            setIsLiked(true);
            setPopupMessage("Album liked!");
            setShowMessagePopup(true);
            const quantity = document.querySelector(".like-quantity");
            quantity.innerHTML = parseInt(quantity.innerHTML, 10) + 1;
          }
          if (data.error === "You`ve already liked the Album.") {
            document.querySelector(".extra-like-image").src = like_filled;
            const quantity = document.querySelector(".like-quantity");
            quantity.innerHTML = parseInt(quantity.innerHTML, 10) + 1;
            setIsLiked(true);
            setPopupMessage("Album liked!");
            setShowMessagePopup(true);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    } else {
      setPopupMessage("You must be logged in to like an Album!");
      setShowMessagePopup(true);
    }
  };

  const handleCommentClick = () => {
    if (!comments) {
      const id = Id.id;
      navigateToComments("albums", id);
    }
  };

  const handleDescription = () => {
    navigateToAlbum(Id.id);
  };

  const handleComments = () => {
    if (!comments) {
      const id = Id.id;
      navigateToComments("albums", id);
    }
  };

  const handleAddToCart = async (id, price) => {
    if (isAuthenticated) {
      const response = await handleCart(id, "album", price);
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = album;
      const response = await handleLocalCart(desiredObject, "album", price);
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setPopupMessage("Added to cart");
        setShowMessagePopup(true);
      } else {
        setPopupMessage("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };
  return (
    <>
      {album && (
        <section className="top-heading">
          <div className="beat-section-image" data-id={album.slug}>
            <ImgLoader
              src={album.cover_path}
              className="beat-section-artwork"
              type="beat"
            />
          </div>
          <div className="beat-section-description">
            <h2 className="beat-section-name">{album.name}</h2>
            {album.artist && (
              <h3
                className="beat-section-artist"
                onClick={handleProducerClick}
                data-id={album?.artist?.slug}
              >
                {album?.artist?.name}
              </h3>
            )}
            {album.created_at && (
              <h3 className="beat-section-date">
                Release Date: {album.created_at.split("T")[0]}
              </h3>
            )}
            <div className="beat-section-extras">
              <div className="extras-like">
                <div>
                  <h3 className="like-quantity">{album.likes}</h3>
                  <button className="like-button" onClick={handleLike}>
                    <img alt="" src={like} className="extra-like-image"></img>
                  </button>
                </div>
                <h4 className="extra-like-name">Likes</h4>
              </div>
              <div className="extras-share">
                <div>
                  <h3 className="share-quantity">‎</h3>
                  <button
                    className="share-button"
                    onClick={() => {
                      setIsSharePopupOpen(true);
                    }}
                  >
                    <img alt="" src={share}></img>
                  </button>
                </div>
                <h4 className="extra-share-name">Share</h4>
              </div>
              <div className="extras-comment">
                <div>
                  <h3 className="comment-quantity">
                    {album.comments_count ? album.comments_count : 0}
                  </h3>
                  <button
                    className="comment-button"
                    onClick={handleCommentClick}
                  >
                    <img alt="" src={comment}></img>
                  </button>
                </div>
                <h4 className="extra-comment-name">Comments</h4>
              </div>
              {/* <div className="extras-playlist">
                <div>
                  <button
                    className="playlist-button"
                    onClick={() => {
                      setAddPopupBeat(album);
                      setIsAddToPlaylistPopupOpen(true);
                    }}
                  >
                    <img alt="" src={playlist}></img>
                  </button>
                </div>
                <h4 className="extra-playlist-name">Add to Playlist</h4>
              </div> */}
            </div>
            <div className="beat-section-buy-beat">
              {album && (
                <button
                  className="beat-section-buy-button"
                  onClick={() => {
                    setIsBuyPopupOpen(true);
                  }}
                >
                  <img alt="" src={cart}></img>
                  {album.price === 0 ? "Free" : formatCurrency(album.price)}
                </button>
              )}
            </div>
            <div className="beat-section-options">
              <button
                className="beat-section-description-button active"
                onClick={handleDescription}
              >
                Description
              </button>
              <button
                className="beat-section-comments"
                onClick={handleComments}
              >
                Comments
              </button>
            </div>
          </div>
        </section>
      )}

      <Popup
        className={`add-queue-popup ${
          playerState.currentItemIndex !== null ? "add-queue-player-popup" : ""
        }`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>

      {description && album && <AlbumDescription album={album} />}
      {comments && album && (
        <AlbumComments
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}

      <BuyPopup
        beat={album}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        handleAddToCart={handleAddToCart}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />

      {/* {isAddToPlaylistPopupOpen && (
        <AddToPlaylistPopup
          isAddToPlaylistPopupOpen={isAddToPlaylistPopupOpen}
          setIsAddToPlaylistPopupOpen={setIsAddToPlaylistPopupOpen}
          beat={addPopupBeat}
          setPopupMessage={setPopupMessage}
          setShowMessagePopup={setShowMessagePopup}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
          type={"album"}
        />
      )} */}

      {isSharePopupOpen && (
        <SharePopup
          isSharePopupOpen={isSharePopupOpen}
          setIsSharePopupOpen={setIsSharePopupOpen}
          beat={album}
          type={"album"}
        />
      )}
    </>
  );
}

export default Album;
