import React, { useState } from "react";
import Google from "./../../images/google-logo.png";

import Warning from "./../../images/warning.svg";
import Popup from "reactjs-popup";
import { Bars } from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import { Link } from "react-router-dom";
import { useNavigation } from "../../context/navigationContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useAuthContext } from "../../context/authContext";

function Signup({ setShowUnauthenticatedPopup }) {
  const { register } = useAuthContext();

  const { navigate } = useNavigation();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("");
  const [agree, setAgree] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [showRest, setShowRest] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);

  const [emailError, setEmailError] = useState("Invalid Email Format");

  function handleEmailInputChange(event) {
    setEmail(event.target.value);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(event.target.value)) {
      setEmailError("Invalid Email Format");
      document.querySelector(".email-error").classList.remove("hidden");
    } else {
      document.querySelector(".email-error").classList.add("hidden");
    }
  }

  function handleFirstNameInput(event) {
    setFirstName(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (
      !regex.test(event.target.value) ||
      event.target.value?.length > 50 ||
      event.target.value?.length < 2
    ) {
      document.querySelector(".first-name-error").classList.remove("hidden");
    } else {
      document.querySelector(".first-name-error").classList.add("hidden");
    }
  }

  function handleLastNameInput(event) {
    setLastName(event.target.value);
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (
      !regex.test(event.target.value) ||
      event.target.value?.length > 50 ||
      event.target.value?.length < 2
    ) {
      document.querySelector(".last-name-error").classList.remove("hidden");
    } else {
      document.querySelector(".last-name-error").classList.add("hidden");
    }
  }

  function getPasswordStrength(password) {
    const containsUpperCase = /[A-Z]/;
    const containsLowerCase = /[a-z]/;
    const containsNumber = /[0-9]/;
    const containsSpecialChar = /[@$!%*?&]/;
    let passwordStrength = 0;

    if (containsUpperCase.test(password)) passwordStrength++;
    if (containsLowerCase.test(password)) passwordStrength++;
    if (containsNumber.test(password)) passwordStrength++;
    if (containsSpecialChar.test(password)) passwordStrength++;

    return passwordStrength;
  }

  function handlePasswordInput(event) {
    setPassword(event.target.value);
    if (
      getPasswordStrength(event.target.value) < 3 ||
      event.target.value.length < 8
    ) {
      document.querySelector(".password-error").classList.remove("hidden");
    } else {
      document.querySelector(".password-error").classList.add("hidden");
    }

    if (event.target.value === confirmPassword)
      document.querySelector(".confirm-password-error").classList.add("hidden");
    else
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
  }

  function handleConfirmPasswordInput(event) {
    setConfirmPassword(event.target.value);

    if (password === event.target.value)
      document.querySelector(".confirm-password-error").classList.add("hidden");
    else
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
  }

  function handleTypeInput(event) {
    document.querySelector(".type-error").classList.add("hidden");
    changeRadioLook();
    setType(event.target.value);
  }

  function handleAgreeInput(event) {
    setAgree(event.target.checked);
    if (event.target.checked)
      document.querySelector(".agree-error").classList.add("hidden");
    else document.querySelector(".agree-error").classList.remove("hidden");
  }

  function checkValues() {
    let valid = true;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (
      firstName.trim().length < 2 ||
      !regex.test(firstName) ||
      firstName.trim().length > 50
    ) {
      document.querySelector(".first-name-error").classList.remove("hidden");
      valid = false;
    }
    if (
      lastName.trim().length < 2 ||
      !regex.test(lastName) ||
      lastName.trim().length > 50
    ) {
      document.querySelector(".last-name-error").classList.remove("hidden");
      valid = false;
    }
    if (getPasswordStrength(password) < 3 || password.length < 8) {
      document.querySelector(".password-error").classList.remove("hidden");
      valid = false;
    }
    if (password !== confirmPassword) {
      document
        .querySelector(".confirm-password-error")
        .classList.remove("hidden");
      valid = false;
    }
    if (type.trim().length === 0) {
      document.querySelector(".type-error").classList.remove("hidden");
      valid = false;
    }
    if (!agree) {
      document.querySelector(".agree-error").classList.remove("hidden");
      valid = false;
    }

    return valid;
  }
  const handleGoogle = async (event) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/redirect/google`;

    window.location.href = url;
  };

  const handleContinueButtonClick = function (event) {
    event.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(email)) {
      setShowEmail(false);
      setShowRest(true);
    } else {
      setEmailError("Invalid Email Format");
      document.querySelector(".email-error").classList.remove("hidden");
    }
  };

  const handleSignupButtonClick = async function (event) {
    event.preventDefault();

    if (!checkValues()) return;
    setSaveChanges(true);

    try {
      const response = await register({
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
        type: type,
      });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      setSaveChanges(false);

      if (response.status === 200) {
        navigate("/signup/finish");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        setEmailError(response.error.message);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          document.querySelector(".email-error").classList.remove("hidden");
        }, 100);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const changeRadioLook = function () {
    const radioButtons = document.querySelectorAll('input[name="type"]');
    radioButtons.forEach((radio) => {
      radio.addEventListener("change", (event) => {
        const parent = event.target.closest(".signup__radio--label");
        if (parent) {
          document
            .querySelectorAll(".signup__radio--label")
            .forEach((option) => {
              option.classList.remove("checked");
            });
          parent.classList.add("checked");
        }
      });
    });
  };

  return (
    <>
      <section className="section__signup">
        <div className="login__container">
          <h2 className="login__heading">Open an account</h2>
          {showEmail && (
            <form className="login__form">
              <div className="login__group">
                <label htmlFor="Email">Email</label>

                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="Email"
                    id="Email"
                    placeholder="Email"
                    type="email"
                    onChange={(event) => handleEmailInputChange(event)}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="email-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    {emailError}
                  </Popup>
                </div>
              </div>
              <button
                className="btn__primary"
                onClick={handleContinueButtonClick}
              >
                Continue
              </button>
              <div className="buttons">
                <button className="btn__signins" onClick={handleGoogle}>
                  <img
                    src={Google}
                    className="navbar-logo"
                    alt="Throwdown logo"
                  />
                  <p>Sign up with Google</p>
                </button>
              </div>
            </form>
          )}

          {showRest && (
            <form className="login__form">
              <div className="login__group">
                <label htmlFor="Email">Email</label>

                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="Email"
                    id="Email"
                    placeholder="Email"
                    type="email"
                    onChange={(event) => handleEmailInputChange(event)}
                    value={email}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="email-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    {emailError}
                  </Popup>
                </div>
              </div>
              <div className="login__group">
                <label htmlFor="firstName">First Name</label>
                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="firstName"
                    defaultValue={firstName}
                    id="firstName"
                    placeholder="First Name"
                    type="text"
                    onChange={(event) => handleFirstNameInput(event)}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="first-name-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    This field cannot be empty!
                  </Popup>
                </div>
              </div>
              <div className="login__group">
                <label htmlFor="lastName">Last Name</label>
                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    defaultValue={lastName}
                    type="text"
                    onChange={(event) => handleLastNameInput(event)}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="last-name-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    This field cannot be empty!
                  </Popup>
                </div>
              </div>
              <div className="login__group">
                <label htmlFor="password">Password</label>
                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="password"
                    id="password"
                    placeholder="Password"
                    type="password"
                    onChange={(event) => handlePasswordInput(event)}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="password-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Password too short/weak!
                  </Popup>
                </div>
              </div>
              <div className="login__group">
                <label htmlFor="confirmPassword">Re-enter Password</label>
                <div className="login-input-container">
                  <input
                    autoComplete="off"
                    className="login__input"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    onChange={(event) => handleConfirmPasswordInput(event)}
                  />
                  <Popup
                    className="login-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="confirm-password-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Passwords do not match!
                  </Popup>
                </div>
              </div>

              <div className="signup__radio--container">
                <h3 className="signup__radio--heading">
                  What do you want to do?
                </h3>
                <div className="radio-input-container">
                  <Popup
                    className="login-error-popup type-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="type-error hidden"
                      ></img>
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Please select a type
                  </Popup>
                  <label className="signup__radio--label">
                    <input
                      type="radio"
                      name="type"
                      value="buyer"
                      onChange={(event) => {
                        handleTypeInput(event);
                      }}
                    />
                    <span>
                      <img
                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                        alt="Checked Icon"
                      />
                    </span>
                    Buy Songs,Beats & Hire Music Producers
                  </label>
                  <label className="signup__radio--label">
                    <input
                      type="radio"
                      name="type"
                      value="seller"
                      onChange={(event) => {
                        handleTypeInput(event);
                      }}
                    />
                    <span>
                      <img
                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                        alt="Checked Icon"
                      />
                    </span>
                    Sell Songs/Beats
                  </label>
                </div>
              </div>

              <Popup
                className="login-error-popup agree-error-popup"
                trigger={
                  <img
                    src={Warning}
                    alt=""
                    className="agree-error hidden"
                  ></img>
                }
                on="hover"
                position="top center"
                arrow={false}
              >
                You must agree to the Privacy Policy & Terms and conditions to
                continue
              </Popup>

              <label className="signup__checkbox--container">
                <div className="agree-checkbox-container">
                  <input
                    className="agree-checkbox"
                    type="checkbox"
                    onChange={(event) => {
                      handleAgreeInput(event);
                    }}
                  />
                </div>
                <span>
                  I have read, understood and I agree to{" "}
                  <Link to="/privacy" className="signup__terms--link">
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link to="/terms" className="signup__terms--link">
                    Terms and conditions
                  </Link>
                </span>
              </label>

              <button
                className="btn__primary"
                onClick={handleSignupButtonClick}
              >
                {saveChanges && (
                  <Bars
                    height="40"
                    width="40"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loading-bars"
                    visible={saveChanges}
                  />
                )}
                {!saveChanges && "Sign Up"}
              </button>
            </form>
          )}
        </div>
      </section>
    </>
  );
}

export default Signup;
