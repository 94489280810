import React, { useEffect, useState, useRef } from "react";

import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import MusicLandingCarouselSlide from "./musicLandingCarouselSlide";

import leftWhite from "./../../images/leftWhite.svg";
import right from "./../../images/rightWhite.svg";
const MusicLandingCarousel = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);
  const [carouselSlides, setCarouselSlides] = useState(null);
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function getDeviceType() {
      const userAgent = navigator.userAgent;

      if (/iPad/.test(userAgent)) {
        return "iPad";
      } else if (/Mobile/.test(userAgent)) {
        return "Mobile";
      } else {
        return "Desktop";
      }
    }

    if (getDeviceType() === "Mobile") {
      setMobile(true);
    }
  }, []);

  const offsetFn = (offsetFromRadius, index) => {
    if (!mobile) {
      const slidesCount = carouselSlides.length;
      let step = 40;
      let centerIndex = Math.floor(slidesCount / 2);
      let distanceFromCenter = index - centerIndex;
      let transformXValue = -50 - step * distanceFromCenter;
      let scaleValue = 1;
      if (slidesCount === 2) {
        centerIndex = Math.floor(slidesCount / 2);
        if (centerIndex < 0) {
          centerIndex = 0;
        }
        distanceFromCenter = index - centerIndex;
        if (Math.abs(distanceFromCenter) === 1) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 0) {
          scaleValue = 1;
        }
        transformXValue = -90 - step * distanceFromCenter;
        step = 10;
      } else if (slidesCount > 2) {
        centerIndex = Math.floor(slidesCount / slidesCount);

        distanceFromCenter = index - centerIndex;
        if (Math.abs(distanceFromCenter) === 2) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 1) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 0) {
          scaleValue = 0.9;
        } else if (Math.abs(distanceFromCenter) === 3) {
          scaleValue = 0.8;
        }
        transformXValue = -50 - step * distanceFromCenter;
        step = 10;
      } else {
        if (Math.abs(distanceFromCenter) === 2) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 1) {
          scaleValue = 0.9;
        } else if (Math.abs(distanceFromCenter) === 0) {
          scaleValue = 1;
        }
      }

      return {
        transform: `translate(${transformXValue}%, -50%) scale(${scaleValue})`,
      };
    } else {
      const slidesCount = carouselSlides.length;
      let step = 15;
      let centerIndex = Math.floor(slidesCount / 2);
      let distanceFromCenter = index - centerIndex;
      let transformXValue = -50 - step * distanceFromCenter;
      let scaleValue = 1;
      if (slidesCount === 2) {
        centerIndex = Math.floor(slidesCount / 2);
        if (centerIndex < 0) {
          centerIndex = 0;
        }
        distanceFromCenter = index - centerIndex;
        if (Math.abs(distanceFromCenter) === 1) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 0) {
          scaleValue = 1;
        }
        transformXValue = -65 - step * distanceFromCenter;
        step = 10;
      } else if (slidesCount > 2) {
        centerIndex = Math.floor(slidesCount / slidesCount);

        distanceFromCenter = index - centerIndex;
        if (Math.abs(distanceFromCenter) === 2) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 1) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 0) {
          scaleValue = 0.9;
        } else if (Math.abs(distanceFromCenter) === 3) {
          scaleValue = 0.8;
        }
        transformXValue = -50 - step * distanceFromCenter;
        step = 10;
      } else {
        if (Math.abs(distanceFromCenter) === 2) {
          scaleValue = 0.8;
        } else if (Math.abs(distanceFromCenter) === 1) {
          scaleValue = 0.9;
        } else if (Math.abs(distanceFromCenter) === 0) {
          scaleValue = 1;
        }
      }

      return {
        transform: `translate(${transformXValue}%, -50%) scale(${scaleValue})`,
      };
    }
  };

  useEffect(() => {
    if (carouselSlides) {
      intervalRef.current = setInterval(() => {
        const nextSlide = (currentSlide + 1) % carouselSlides.length;
        setCurrentSlide(nextSlide);
      }, 15000);

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [currentSlide, carouselSlides]);
  const nextSlideButton = () => {
    const nextSlide = (currentSlide + 1) % carouselSlides.length;
    setCurrentSlide(nextSlide);
  };
  const prevSlideButton = () => {
    const prevSlide = (currentSlide - 1) % carouselSlides.length;
    setCurrentSlide(prevSlide);
  };
  useEffect(() => {
    const setSlides = () => {
      const newSlides = items.map((item, index) => ({
        key: index,
        content: <MusicLandingCarouselSlide item={item} mobile={mobile} />,
      }));
      setCarouselSlides(newSlides);
    };

    if (items && items.length > 0) {
      setSlides();
    }
  }, [items, mobile]);

  return (
    <div style={{ width: "100%", height: "60rem", overflow: "hidden" }}>
      {!mobile && (
        <div
          style={{
            height: "80%",
            margin: "auto",
            overflow: "hidden",
            backgroundColor: "#131313",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            className="carousel__button__landing"
            onClick={prevSlideButton}
          >
            <img alt="" src={leftWhite}></img>
          </button>
          {carouselSlides && (
            <Carousel
              ref={carouselRef}
              slides={carouselSlides}
              goToSlide={currentSlide}
              offsetRadius={1}
              goToSlideDelay={15000}
              animationConfig={config.gentle}
              offsetFn={offsetFn}
              style={{ height: "100%" }}
            />
          )}
          <button
            className="carousel__button__landing"
            onClick={nextSlideButton}
          >
            <img alt="" src={right}></img>
          </button>
        </div>
      )}
      {mobile && (
        <div
          style={{
            height: "80%",
            margin: "auto",
            overflow: "hidden",
            backgroundColor: "#131313",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {carouselSlides && (
            <Carousel
              ref={carouselRef}
              slides={carouselSlides}
              goToSlide={currentSlide}
              offsetRadius={1}
              goToSlideDelay={15000}
              animationConfig={config.gentle}
              offsetFn={offsetFn}
              style={{ height: "100%" }}
            />
          )}
          <div className="mobile__carousel__nav">
            <button
              className="carousel__button__landing"
              onClick={prevSlideButton}
            >
              <img alt="" src={leftWhite}></img>
            </button>
            <button
              className="carousel__button__landing"
              onClick={nextSlideButton}
            >
              <img alt="" src={right}></img>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MusicLandingCarousel;
