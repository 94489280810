import "./stylesheets/playlistLanding.css";
import "./../index.css";
import "./../Fonts/fonts.css";
import * as Sentry from "@sentry/react";
import cross from "./../images/cross-black.svg";
import attachment from "./../images/Attachment.svg";
import warning from "./../images/warning.svg";
import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import Popup from "reactjs-popup";
import handleResponseStatus from "../utils/handleResponseStatus";
import { useNavigation } from "../context/navigationContext";
import { useAuthContext } from "../context/authContext";
import api from "../utils/api";
function CreatePlaylistPopup({
  isCreatePlaylistPopupOpen,
  setIsCreatePlaylistPopupOpen,
  getPlaylists,
  setPopupMessage,
  setShowMessagePopup,
  setShowUnauthenticatedPopup,
}) {
  const { navigate } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const [saveChanges, setSaveChanges] = useState(false);

  const [playlistTitle, setPlaylistTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  });

  const handlePlaylistTitleInputChange = (event) => {
    setPlaylistTitle(event.target.value);
    setIsCreateButtonDisabled(false);

    if (event.target.value.length < 5) {
      setTitleErrorMessage("Minimum characters are 5!");
      document.querySelector(".title-error").classList.remove("hidden");
    } else {
      document.querySelector(".title-error").classList.add("hidden");
    }
  };

  const handleImageUploadChange = (event) => {
    setIsCreateButtonDisabled(false);
    document.querySelector(".image-error").classList.add("hidden");
    setImageFile(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".hidden-image");
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  function checkValues() {
    let valid = true;
    if (playlistTitle.length < 5) {
      setTitleErrorMessage("Minimum characters are 5!");
      document.querySelector(".title-error").classList.remove("hidden");

      valid = false;
    }
    if (!imageFile) {
      document.querySelector(".image-error").classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  const handleSubmitCreatePlaylistForm = async (event) => {
    setSaveChanges(true);
    event.preventDefault();

    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    if (!checkValues()) {
      setSaveChanges(false);
      return;
    }
    setIsCreateButtonDisabled(true);

    try {
      const response = await api.post(`playlist`, {
        name: playlistTitle,
        image: imageFile.name,
        beats: null,
      });
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      const data = response.data;
      if (response.status === 422) {
        setSaveChanges(false);

        setIsCreateButtonDisabled(false);
        setTitleErrorMessage(data.message);
        document.querySelector(".title-error").classList.remove("hidden");
        return;
      } else if (response.status === 409) return;

      const img = document.querySelector(".hidden-image");
      const res = await fetch(img.src);

      const blob = await res.blob();
      const imageUpload = await fetch(data.image, {
        method: "PUT",
        body: blob,
      });
      if (imageUpload.status === 200) {
        getPlaylists();
        setIsCreatePlaylistPopupOpen(false);
        setImageFile(null);
        setPlaylistTitle("");
        setSaveChanges(false);
        setPopupMessage("Playlist created successfully");
        setShowMessagePopup(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <section>
      <Popup
        className="create-playlist-popup"
        modal
        open={isCreatePlaylistPopupOpen}
        onOpen={() => {
          setIsCreatePlaylistPopupOpen(true);
          setIsCreateButtonDisabled(false);
        }}
        onClose={() => {
          setIsCreatePlaylistPopupOpen(false);
        }}
        nested
      >
        <div className="create-playlist-popup-heading">
          <h3>Create New Playlist</h3>
          <img
            src={cross}
            alt=""
            onClick={() => {
              setIsCreatePlaylistPopupOpen(false);
            }}
          />
        </div>
        <div className="create-playlist-popup-container">
          <p>
            Playlist title
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={warning} alt="" className="title-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              {titleErrorMessage}
            </Popup>
          </p>
          <input type="text" onChange={handlePlaylistTitleInputChange} />
          <p>
            Upload playlist image
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={warning} alt="" className="image-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Upload a valid image file!
            </Popup>
          </p>

          <label
            className="create-playlist-popup__upload-image"
            htmlFor="playlist-image-upload"
          >
            <img src={attachment} alt="" />
            <img className="hidden-image" alt=""></img>
            {!imageFile && "Upload Image"}
            {imageFile &&
              (imageFile.name.length <= 11
                ? imageFile.name
                : `${imageFile.name.slice(0, 10)}....`)}
          </label>

          <input
            type="file"
            id="playlist-image-upload"
            accept="image/png, image/jpeg, image/gif, image/svg"
            onChange={handleImageUploadChange}
          />

          <button
            disabled={isCreateButtonDisabled}
            className="create-playlist-button"
            onClick={handleSubmitCreatePlaylistForm}
          >
            {saveChanges && (
              <Bars
                height="40"
                width="40"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="loading-bars"
                visible={saveChanges}
              />
            )}
            {!saveChanges && "Create Playlist"}
          </button>
        </div>
      </Popup>
    </section>
  );
}

export default CreatePlaylistPopup;
