// actions/currencyActions.js
import api from "../utils/api";
import {
  FETCH_CURRENCY_RATES_REQUEST,
  FETCH_CURRENCY_RATES_SUCCESS,
  FETCH_CURRENCY_RATES_FAILURE,
  SET_SELECTED_CURRENCY,
  SET_CURRENCY_RATES,
} from "../constants/actionTypes";

export const fetchCurrencyRatesRequest = () => ({
  type: FETCH_CURRENCY_RATES_REQUEST,
});

export const fetchCurrencyRatesSuccess = (rates) => ({
  type: FETCH_CURRENCY_RATES_SUCCESS,
  payload: rates,
});

export const fetchCurrencyRatesFailure = (error) => ({
  type: FETCH_CURRENCY_RATES_FAILURE,
  payload: error,
});

export const setSelectedCurrency = (currency) => ({
  type: SET_SELECTED_CURRENCY,
  payload: currency,
});

export const setCurrencyRates = (rates, expiration) => ({
  type: SET_CURRENCY_RATES,
  payload: { rates, expiration },
});

export const fetchCurrencyRates = () => async (dispatch) => {
  dispatch(fetchCurrencyRatesRequest());
  try {
    const response = await api.get("exchange-rate/default");
    const data = response.data?.data.value;
    const expiration = response.data?.data.expiry; // Assuming this is returned by your backend
    dispatch(fetchCurrencyRatesSuccess({ rates: data, expiration }));
    return { rates: data, expiration }; // Return the data directly
  } catch (error) {
    console.error("API Error:", error); // Log any errors
    dispatch(fetchCurrencyRatesFailure(error.message));
    throw error; // Rethrow the error for further handling
  }
};
