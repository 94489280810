import "./../stylesheets/Trending.css";
import "./../stylesheets/player.css";
import * as Sentry from "@sentry/react";
import image from "./../../images/1.png";
import more from "./../../images/more-vertical.svg";
import download from "./../../images/download.svg";
import cart from "./../../images/cart.svg";
import play from "./../../images/play_button.svg";
import moreDark from "./../../images/more-dark.svg";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import Popup from "reactjs-popup";
import BuyPopup from "./../buyPopup";
import AddToPlaylistPopup from "./../addToPlaylistPopup";
import SharePopup from "./../sharePopup";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImgLoader from "./../imgLoader";
import { useCurrencyContext } from "../../context/currencyContext";
import { useAuthContext } from "../../context/authContext";
import handleResponseStatus from "../../utils/handleResponseStatus";
import { useNavigation } from "../../context/navigationContext";
import api from "../../utils/api";
import { usePlayerContext } from "../../context/playerContext";
import { addToQueue, playNow } from "../../actions/playerActions";

function PlaylistRows({
  beats,
  loading,
  player,
  hideDownload,
  showRemoveFromPlaylist,
  hideMore,
  setShowUnauthenticatedPopup,
  showRemoveBeat,
  handleUpdateBeat,
  hideBuy,
  showUpdateBeat,
  handleRemoveBeat,
  showDownloadBeat,
  handleRemoveFromPlaylist,
  updateCartCounter,
  setUpdateCartCounter,
  handleLocalCart,
  handleCart,
  hideCertain,
  playlistPlay,
}) {
  const {
    navigate,
    navigateToProducer,
    navigateToComments,
    navigateToArtists,
    navigateToBeat,
    navigateToMusic,
  } = useNavigation();
  const { isAuthenticated } = useAuthContext();
  const { formatCurrency } = useCurrencyContext();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [type, setType] = useState("");
  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);
  const [buyPopupBeat, setBuyPopupBeat] = useState();
  const [isAddToPlaylistPopupOpen, setIsAddToPlaylistPopupOpen] =
    useState(false);
  const [addPopupBeat, setAddPopupBeat] = useState();

  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [sharePopupBeat, setSharePopupBeat] = useState();
  const { playerState } = usePlayerContext();

  const handleComment = (event, type) => {
    const id = event.target.dataset.id;
    navigateToComments(type, id);
  };

  const { playerDispatch } = usePlayerContext();

  const handleAddToQueue = (item) => {
    playerDispatch(addToQueue(item));
  };

  const handleDownload = async (event) => {
    if (isAuthenticated) {
      var id;
      id = event;
      try {
        const response = await api.get(`beats/${id}/download`);
        handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
        const data = response.data;
        window.location.href = data;
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      navigate("/login");
    }
  };

  const handleAddToCart = async (id, price) => {
    const desiredObject = beats.find(
      (obj) => obj.item.slug.toString() === id.toString()
    );
    if (isAuthenticated) {
      const response = await handleCart(
        id,
        desiredObject.item.artwork_url ? "beat" : "song",
        price
      );
      handleResponseStatus(response, navigate, setShowUnauthenticatedPopup);
      if (response?.error) {
        setPopupMessage(response?.error?.error);
        setShowMessagePopup(true);
        return;
      }
      setUpdateCartCounter(updateCartCounter + 1);
      setPopupMessage("Added to cart");
      setShowMessagePopup(true);
    } else {
      const desiredObject = beats.find((obj) => obj.slug.toString() === id);
      const response = await handleLocalCart(
        desiredObject,
        desiredObject.item.artwork_url ? "beat" : "song",
        price
      );
      if (response === "success") {
        setUpdateCartCounter(updateCartCounter + 1);
        setPopupMessage("Added to cart");
        setShowMessagePopup(true);
      } else {
        setPopupMessage("Item already in cart!");
        setShowMessagePopup(true);
      }
    }
  };

  const handleProducerClick = (event, type) => {
    const id = event.target.dataset.id;
    if (type === "music") {
      navigateToArtists(id);
    } else {
      navigateToProducer(id);
    }
  };

  const isPlaylistInQueue = () => {
    for (let i = 0; i < beats.length; i++) {
      if (!playerState.queue.some((item) => item.slug === beats[i].item.slug)) {
        return false;
      }
    }

    return true;
  };

  const handlePlay = async (item) => {
    if (isPlaylistInQueue()) {
      playerDispatch(playNow(item.item));
    } else {
      playlistPlay(item.item.slug);
    }
  };

  const handleBeatClick = (event, type) => {
    event.preventDefault();
    const beat_id = event.target.closest(".beat-rows--item").dataset.id;
    if (type === "music") {
      navigateToMusic(beat_id);
    } else {
      navigateToBeat(beat_id);
    }
  };

  return (
    <>
      <section className="beatsRows">
        <div className="beats-rows">
          {!beats &&
            Array.from({ length: 10 }).map((_, index) => (
              <div className="beat-rows--item" key={index}>
                <div className="beat-rows-details beats__music__player">
                  <p className="beat-rows-number">{index + 1}</p>
                  <Skeleton className="beat-rows-image" />

                  <div className="beat-rows-name" style={{ width: "100%" }}>
                    <Skeleton count={1} containerClassName="skeleton-loading" />
                    <p className="beat-tag">
                      <Skeleton count={0.5} />
                    </p>
                  </div>
                </div>

                <button
                  className="buy-beat"
                  style={{ marginLeft: "auto", color: "#fe2b0d" }}
                >
                  1
                </button>

                <button className="more-options">
                  <img alt="" src={more}></img>
                </button>
              </div>
            ))}
          {beats &&
            beats.map((beatRow, index) => {
              return (
                <div
                  className="beat-rows--item"
                  data-id={beatRow.item.slug}
                  key={index}
                  data-url={beatRow.item.play_url}
                  onClick={(event) => {
                    handlePlay(beatRow);
                    event.stopPropagation();
                  }}
                >
                  <div className="beat-rows-details beats__music__player">
                    <p className="beat-rows-number">{index + 1}</p>
                    <div className="beat-rows-image">
                      <ImgLoader
                        src={
                          beatRow.item.artwork_url
                            ? beatRow.item.artwork_url
                            : beatRow.item.cover_path
                            ? beatRow.item.cover_path
                            : image
                        }
                        type="beat"
                      />
                      <div className="beats-rows-play_button">
                        <button
                          className="beats-rows-play play__button__beats"
                          onClick={(event) => {
                            handlePlay(beatRow);
                            event.stopPropagation();
                          }}
                        >
                          <img src={play} alt="play"></img>
                        </button>
                      </div>
                    </div>
                    <div className="beat-rows-name">
                      <h4
                        onClick={(event) => {
                          handleBeatClick(
                            event,
                            beatRow.item.cover_path ? "music" : "beat"
                          );
                        }}
                      >
                        {beatRow.item.name}
                      </h4>
                      {beatRow.item.producer && (
                        <p
                          className="beat-tag"
                          data-id={beatRow.item.producer.slug}
                          onClick={(event) => {
                            handleProducerClick(
                              event,
                              beatRow.item.cover_path ? "music" : "beat"
                            );
                          }}
                        >
                          {beatRow.item.producer.name}
                        </p>
                      )}
                      {beatRow.item.artist && (
                        <p
                          className="beat-tag"
                          data-id={beatRow.item.artist?.slug}
                          onClick={(event) => {
                            handleProducerClick(
                              event,
                              beatRow.item.cover_path ? "music" : "beat"
                            );
                          }}
                        >
                          {beatRow.item.artist.name}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tags">
                    {beatRow.item.bpm && (
                      <p className="beat-rows-bpm beat-rows-tag">
                        {beatRow.item.bpm} BPM
                      </p>
                    )}
                    {beatRow.item.genres &&
                      beatRow.item.genres.slice(0, 2).map((genre) => (
                        <p className="beat-rows-tag" key={genre.id}>
                          {genre.name}
                        </p>
                      ))}
                    {beatRow.item.genres?.length > 3 && (
                      <Popup
                        className="extra-genres-popup"
                        trigger={
                          <p
                            className="beat-rows-tag"
                            style={{ cursor: "pointer" }}
                          >
                            <img src={moreDark} alt="" />
                          </p>
                        }
                        on="hover"
                        arrow={false}
                        position="top center"
                      >
                        {beatRow.item.genres.slice(2).map((genre) => (
                          <p key={genre.id}>{genre.name}</p>
                        ))}
                      </Popup>
                    )}
                  </div>
                  {beatRow.item.is_free && !hideDownload && (
                    <button
                      className="download-beat"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDownload(event);
                      }}
                    >
                      <img alt="" src={download}></img>Download
                    </button>
                  )}

                  {beatRow && !hideBuy && (
                    <button
                      className="buy-beat"
                      onClick={(event) => {
                        event.stopPropagation();
                        setBuyPopupBeat(beatRow.item);
                        setIsBuyPopupOpen(true);
                      }}
                    >
                      <img alt="" src={cart}></img>
                      {beatRow.item.price === 0
                        ? "Free"
                        : formatCurrency(beatRow.item.price)}
                    </button>
                  )}
                  {showUpdateBeat && (
                    <button
                      className="buy-beat edit-beat"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleUpdateBeat(beatRow.item.slug);
                      }}
                    >
                      Edit
                    </button>
                  )}
                  {showRemoveBeat && (
                    <button
                      className="buy-beat remove-beat"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleRemoveBeat(beatRow.item.slug);
                      }}
                    >
                      Remove
                    </button>
                  )}
                  {showDownloadBeat && (
                    <button
                      className=" buy-beat download-purchased-beat"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDownload(beatRow.item.slug);
                      }}
                    >
                      <img alt="" src={download}></img>Download
                    </button>
                  )}
                  {!hideMore && (
                    <Popup
                      className="beat-rows-popup"
                      trigger={
                        <button className="more-options">
                          <img alt="" src={more}></img>
                        </button>
                      }
                      arrow={false}
                      position="bottom right"
                    >
                      {(close) => (
                        <>
                          <div
                            className="popup-option"
                            onClick={() => {
                              close();
                              handlePlay(beatRow);
                            }}
                            data-id={beatRow.item.slug}
                          >
                            Play
                          </div>
                          {beatRow.item.is_free && !hideDownload && (
                            <div
                              className="popup-option download-popup-option"
                              onClick={(event) => {
                                close();
                                event.stopPropagation();
                                handleDownload(event);
                              }}
                              data-id={beatRow.item.slug}
                            >
                              Download
                            </div>
                          )}
                          {!hideCertain && !beatRow.is_free && (
                            <div
                              className="popup-option"
                              onClick={(event) => {
                                close();
                                handleAddToCart(beatRow.item.slug);
                              }}
                              data-id={beatRow.item.slug}
                            >
                              Add To Cart
                            </div>
                          )}
                          {beatRow.item.producer && (
                            <div
                              className="popup-option"
                              onClick={(event) => {
                                close();
                                handleProducerClick(
                                  event,
                                  beatRow.item.cover_path ? "music" : "beat"
                                );
                              }}
                              data-id={beatRow.item.producer.slug}
                            >
                              View Producer
                            </div>
                          )}
                          {beatRow.item.artist && (
                            <div
                              className="popup-option"
                              onClick={(event) => {
                                close();
                                handleProducerClick(
                                  event,
                                  beatRow.item.cover_path ? "music" : "beat"
                                );
                              }}
                              data-id={beatRow.item.artist?.slug}
                            >
                              View Artist
                            </div>
                          )}
                          <div
                            className="popup-option"
                            onClick={() => {
                              close();
                              setType(beatRow.item.cover_path ? "song" : "");
                              setAddPopupBeat(beatRow.item);
                              setIsAddToPlaylistPopupOpen(true);
                            }}
                          >
                            Add to Playlist
                          </div>

                          {showRemoveFromPlaylist && (
                            <div
                              className="popup-option"
                              onClick={(event) => {
                                close();
                                handleRemoveFromPlaylist(event, beatRow.id);
                              }}
                            >
                              Remove from Playlist
                            </div>
                          )}
                          <div
                            className="popup-option"
                            onClick={() => {
                              close();
                              handleAddToQueue(beatRow.item);
                            }}
                            data-id={beatRow.item.slug}
                          >
                            Add to Queue
                          </div>
                          <div
                            className="popup-option"
                            onClick={(event) => {
                              close();
                              handleComment(
                                event,
                                beatRow.item.cover_path ? "music" : "beat"
                              );
                            }}
                            data-id={beatRow.item.slug}
                          >
                            Comment
                          </div>
                          <div
                            className="popup-option"
                            onClick={() => {
                              close();
                              setSharePopupBeat(beatRow.item);
                              setIsSharePopupOpen(true);
                            }}
                          >
                            Share
                          </div>
                        </>
                      )}
                    </Popup>
                  )}
                </div>
              );
            })}
        </div>
        {beats && loading && (
          <TailSpin
            height="40"
            width="40"
            color="#FE2B0D"
            ariaLabel="tail-spin-loading"
            visible={loading}
            wrapperClass="loading"
            className="loading"
          />
        )}
      </section>
      <BuyPopup
        beat={buyPopupBeat}
        setShowBuyPopup={setIsBuyPopupOpen}
        showBuyPopup={isBuyPopupOpen}
        handleAddToCart={handleAddToCart}
        setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
      />

      <Popup
        className={`add-queue-popup ${player ? "add-queue-player-popup" : ""}`}
        modal
        open={showMessagePopup}
        onOpen={() => {
          setTimeout(() => {
            setShowMessagePopup(false);
          }, 1500);
        }}
      >
        {popupMessage}
      </Popup>
      {isAddToPlaylistPopupOpen && (
        <AddToPlaylistPopup
          isAddToPlaylistPopupOpen={isAddToPlaylistPopupOpen}
          setIsAddToPlaylistPopupOpen={setIsAddToPlaylistPopupOpen}
          beat={addPopupBeat}
          type={type}
          setPopupMessage={setPopupMessage}
          setShowMessagePopup={setShowMessagePopup}
          setShowUnauthenticatedPopup={setShowUnauthenticatedPopup}
        />
      )}
      {isSharePopupOpen && (
        <SharePopup
          isSharePopupOpen={isSharePopupOpen}
          setIsSharePopupOpen={setIsSharePopupOpen}
          beat={sharePopupBeat}
          type={sharePopupBeat?.artwork_url ? "beat" : "song"}
        />
      )}
    </>
  );
}

export default PlaylistRows;
