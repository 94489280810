import "./../stylesheets/findProducer.css";
import sellBeat from "./../../images/sellBeat.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { useAuthContext } from "../../context/authContext";
function SellBeat() {
  const navigate = useNavigate();
  const [isSeller, setIsSeller] = useState(false);
  const { isAuthenticated, user } = useAuthContext();

  useEffect(() => {
    if (user) {
      if (user.role === "buyer") {
        setIsSeller(false);
      } else {
        setIsSeller(true);
      }
    }
  }, [user]);

  const handleSellBeats = async () => {
    if (isAuthenticated) {
      if (isSeller) {
        navigate("/upload/beat");
      }
    } else {
      localStorage.setItem("previous", JSON.stringify({ location: "/upload" }));
      navigate("/login");
    }
  };
  return (
    <section className="sellBeat">
      <div className="findproducer-inner">
        <div className="findProducer-text">
          <h2>Sell Your Beats, Start making money selling your beats</h2>
          <p>
            Let your music thrive! Join our vibrant community of beat sellers.
            Showcase your talent and monetize your creations. Start selling your
            beats and connect with artists worldwide.
          </p>

          {isSeller && (
            <button
              className="findproducer-inner-btn sell-beat-upload-button"
              onClick={handleSellBeats}
            >
              Sell Beats
            </button>
          )}
          {!isSeller && !isAuthenticated && (
            <Popup
              className="login-error-popup sell-beat-error-popup"
              trigger={
                <button className="findproducer-inner-btn disabled-sell-beat">
                  Sell Beats
                </button>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              You must be logged in to sell your beats!
            </Popup>
          )}

          {!isSeller && isAuthenticated && (
            <Popup
              className="login-error-popup sell-beat-error-popup"
              trigger={
                <button className="findproducer-inner-btn disabled-sell-beat">
                  Sell Beats
                </button>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              You must be a seller to sell your beats!
            </Popup>
          )}
        </div>
        <div className="inner-image-sell">
          <img src={sellBeat} alt="" />
        </div>
      </div>
    </section>
  );
}

export default SellBeat;
